import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateMetaMask } from '../reducer/metaMask';
import {
  initializeWeb3,
  isMetamaskInstalled,
  askPermission,
  getNetworkId,
  initializeTorusWallet,
  getAccountAddress,
  getNetworkName,
  cacheLogin,
  isLoginCached,
  isLoginExpired,
  getLoginDetails,
  clearCachedLogin
} from '../utils/index';
import MettAlex from '../contractInterface/mettalexContract';
import { updateCapFloor } from '../reducer/tokenPrices';
import {
  decimals,
  commoditiesURL,
  commodityHistoryURL,
  commodityLatestHistoryURL,
  commodityLatestSpotURL,
  collateralTokenURL,
  TransactionHistoryRecordSize,
  basicAuth,
  networks
} from '../constant';
import {
  updateCommodityIsLoading,
  updateIsLoading,
  updateLoadingMessage,
  updateFilter
} from '../reducer/user';
import {
  updateNetwork,
  updateNetworkID,
  updateWalletAvailability,
  updateSelectedCommodityCheck
} from '../reducer/user';
import {
  updateCommodityPrices,
  updateCurrentCommodityIsLoading
} from '../reducer/currentCommodity';
import { updateCommodities, resetCommodities } from '../reducer/commdoities';
import { updateCollateralToken } from './collateralTokenSaga';
import { rewardTransactionURLCombined } from '../constant/index';
import { userMtlxRewardCommodityCombined } from './transactionHistory';
import { isEmpty } from 'lodash';

export const getStatus = (state) => state.user.filter;

function* startSaga() {
  yield takeLatest('FETCH_DATA', startupSaga);
  yield takeLatest('FETCH_CONSIDERED_COMMODITIES', fetchConsideredCommodities);
  yield takeLatest('FETCH_CAP_FLOOR', fetchCapFloor);
  yield takeLatest('REFRESH_PRICES', getLatestCommodityPrices);
  yield takeLatest('CONNECT_WITHOUT_WALLET', connectWithouWallet);
  yield takeLatest('CONNECT_WALLET', connectWallet);
  yield takeLatest('DISCONNECT_WALLET', disconnectWallet);
  yield takeLatest('FETCH_USER_VALUES', fetchUserRelatedDetails);
  yield call(startApp);
}

function* startApp(action) {
  let isLogin = yield isLoginCached();
  if (isLogin) {
    let loginExpired = yield isLoginExpired();
    let loginDetails = yield getLoginDetails();
    if (loginExpired) {
      yield clearCachedLogin();
      // continue connect without wallet
      yield connectWithouWallet();
    } else {
      if (loginDetails.walletType === 'Metamask') {
        yield put({
          type: 'CONNECT_WALLET',
          payload: { wallet: loginDetails.walletType, network: loginDetails.networkId }
        });
      } else {
        yield clearCachedLogin();
        // continue connect without wallet
        yield connectWithouWallet();
      }
    }
  } else {
    let walletAvailability = yield select((state) => state.user.walletAvailability);
    let address = yield select((state) => state.user.address);
    if (
      walletAvailability === true &&
      (address === process.env.REACT_APP_STATIC_WALLET_ADD || address === '')
    ) {
      yield connectWithouWallet();
    }
  }
}

function* connectWithouWallet(action) {
  yield put({ type: 'CONNECT_WALLET', payload: { wallet: 'NoWallet', network: 97 } });
}

function* disconnectWallet(action) {
  yield clearCachedLogin();
  window.location.reload();
}

function* connectWallet(action) {
  try {
    const isMetamask = yield isMetamaskInstalled();
    yield put(updateMetaMask({ isAvailable: isMetamask }));
    if (isMetamask && action.payload.wallet === 'Metamask') {
      let check = yield select((state) => state.user.selectedCommodityCheck);
      yield put(updateSelectedCommodityCheck(!check));
      yield put(resetCommodities());
      yield put(updateWalletAvailability(true));
      initializeWeb3();
      yield askPermission();
      yield call(startupSaga);
      const id = yield getNetworkId();
      const network = yield getNetworkName();
      if (id === 56) yield put(updateNetwork('bsc-mainnet'));
      else if (id === 97) yield put(updateNetwork('bsc-testnet'));
      else yield put(updateNetwork(network));

      yield cacheLogin({
        walletType: 'Metamask',
        networkId: id,
        address: yield getAccountAddress(),
        provider: null
      });
    } else if (action.payload.wallet === 'Torus') {
      const isWalletConnected = yield initializeTorusWallet(action.payload.network);
      if (isWalletConnected) {
        yield put(updateWalletAvailability(true));
        yield call(startupSaga);
        yield put(
          updateNetwork(
            action.payload.network === 56
              ? 'bsc-mainnet'
              : action.payload.network === 97
              ? 'bsc-testnet'
              : 'kovan'
          )
        );
        yield put(updateNetworkID(action.payload.network));
      }
    } else if (action.payload.wallet === 'NoWallet') {
      yield put(updateWalletAvailability(false));
      initializeWeb3();
      yield call(startupSaga);
      yield put(updateNetwork('bsc-testnet'));
      yield put(updateNetworkID(action.payload.network));
    }
  } catch (error) {
    console.log(error);
  }
}

export function* startupSaga() {
  try {
    let walletAvailability = yield select((state) => state.user.walletAvailability);
    yield put(updateCommodityIsLoading({ id: 0, value: 1 }));
    // yield put(updateLoadingMessage("Fetching values. Please wait."));
    let networkID;
    if (!walletAvailability) {
      networkID = 97;
    } else {
      networkID = yield getNetworkId();
    }
    yield put({ type: 'USDT_BALANCE' });
    yield put({ type: 'NETWORK' });
    if (!networks[networkID]) return;
    const collateralTokens = yield getCollateralTokens(networkID);
    if (collateralTokens.length === 0) {
      yield put(
        updateLoadingMessage(
          'No  collaterals deployed for given network. Please select kovan or bsc-mainnet'
        )
      );
      yield put(updateCommodityIsLoading({ id: 0, value: -1 }));
      localStorage.removeItem('collateralToken');
      return;
    }
    yield updateCollateralToken({ payload: collateralTokens });
    const collateralToken = yield select((state) => state.collateralToken);
    const tempcommodities = yield getExistingCommodity(networkID, collateralToken);
    const commodities = yield updateCommodityRewardsDetails(
      rewardTransactionURLCombined,
      tempcommodities
    );
    if (commodities.length === 0) {
      yield put(updateLoadingMessage('No  commodities deployed for given collateral and network.'));
      yield put(updateCommodityIsLoading({ id: 0, value: -1 }));
      localStorage.removeItem('collateralToken');
      return;
    }
    // yield put(updateCommodityIsLoading({ id: 0, value: -1 }));

    // FETCH ALL TRANSACTIONS
    // yield put({ type: 'TRANSACTION_HISTORY_ALL', payload: { page: 1, pageSize: TransactionHistoryRecordSize } })
    // yield put({ type: 'LIQUIDITY_TRANSACTION_HISTORY_ALL', payload: { page: 1, pageSize: 10 } })

    // FETCH USER TRANSACTIONS
    yield put({
      type: 'TRANSACTION_HISTORY_USER',
      payload: { page: 1, pageSize: TransactionHistoryRecordSize }
    });
    yield put({ type: 'LIQUIDITY_TRANSACTION_HISTORY_USER', payload: { page: 1, pageSize: 10 } });

    let allActiveCommodities = [],
      allSettledCommodities = [];
    commodities.filter((obj) => {
      return obj.is_settled === true
        ? allSettledCommodities.push(obj)
        : allActiveCommodities.push(obj);
    });

    if (allActiveCommodities.length === 0) {
      yield put(updateFilter({ active: false, settled: true }));
    } else if (allActiveCommodities.length === commodities.length) {
      yield put(updateFilter({ active: true, settled: false }));
    } else {
      yield put(updateFilter({ active: true, settled: false }));
    }

    let commodityStateObj = {};
    for (let index = 0; index < commodities.length; index++) {
      commodityStateObj[commodities[index].id] = commodities[index];
    }
    yield put(updateCommodities(commodityStateObj));

    let consideredCommodities;
    if (process.env.REACT_APP_REVISED_API_CALL === 'true') {
      let status = yield select(getStatus);
      if (status.active === true) {
        consideredCommodities = allActiveCommodities;
      } else {
        consideredCommodities = allSettledCommodities;
      }
    } else {
      consideredCommodities = commodities;
    }

    yield put({ type: 'FETCH_CONSIDERED_COMMODITIES', payload: consideredCommodities });

    // FETCH ALL TRANSACTIONS
    // yield put({ type: 'TRANSACTION_HISTORY_ALL', payload: { page: 1, pageSize: TransactionHistoryRecordSize } })
    // yield put({ type: 'LIQUIDITY_TRANSACTION_HISTORY_ALL', payload: { page: 1, pageSize: 10 } })

    // FETCH USER TRANSACTIONS
    yield put({
      type: 'TRANSACTION_HISTORY_USER',
      payload: { page: 1, pageSize: TransactionHistoryRecordSize }
    });
    yield put({ type: 'LIQUIDITY_TRANSACTION_HISTORY_USER', payload: { page: 1, pageSize: 10 } });
  } catch (error) {
    console.log(error);
  }
  yield put(updateCommodityIsLoading({ id: 0, value: -1 }));
}

export function* fetchConsideredCommodities(action) {
  try {
    let commodities = action.payload;

    for (let index = 0; index < commodities.length; index++) {
      yield put({
        type: 'INIT_POOL_DETAILS',
        payload: {
          ...commodities[index],
          long_address: commodities[index].long_token_contract_address,
          short_address: commodities[index].short_token_contract_address
        }
      });
      yield put({
        type: 'INIT_LIQUIDITY',
        payload: {
          liquidityPoolAddress: commodities[index].liquidity_pool_address,
          id: commodities[index].id
        }
      });
    }
    yield put({ type: 'FETCH_USER_VALUES', payload: commodities });
  } catch (error) {
    console.log(error);
  }
}

export function* fetchUserRelatedDetails(action) {
  const commodities = action.payload;
  try {
    let walletAvailability = yield select((state) => state.user.walletAvailability);
    let address;
    if (!walletAvailability) {
      address = process.env.REACT_APP_STATIC_WALLET_ADD;
    } else {
      address = yield getAccountAddress();
    }
    yield put({
      type: 'USER_TRANSACTION_HISTORY_ALL',
      payload: { page: 1, pageSize: 10, address: address }
    });

    let isRealToken = yield select((state) => state.collateralToken.isRealToken);
    const id = yield getNetworkId();
    if (isRealToken || id === 56) {
      //Do not load faucet transaction
      yield put({ type: 'FAUCET_TRANSACTIONS' });
    }
    for (var index = 0; index < commodities.length; index++) {
      const commodity = commodities[index];
      yield put({
        type: 'USER_WALLET_DETAILS',
        payload: {
          name: commodity.long_symbol,
          address: commodity.long_token_contract_address,
          decimal: commodity.decimal
        }
      });
      yield put({
        type: 'USER_WALLET_DETAILS',
        payload: {
          name: commodity.short_symbol,
          address: commodity.short_token_contract_address,
          decimal: commodity.decimal
        }
      });
      yield put({ type: 'FETCH_USER_COMMODITY_LIQUIDITY_DEPOSIT', payload: commodity });
      yield put({ type: 'FETCH_USER_COMMODITY_PNL', payload: commodity });
    }
  } catch (error) {
    console.log(error);
  }
}

// Not in Use
export function* fetchCapFloor(action) {
  const commodity = action.payload;
  const tokenPrices = yield select((state) => state.tokenPrices);
  if (tokenPrices[commodity.id]) return;
  // yield put(updateIsLoading(true));
  // yield put(updateLoadingMessage("Fetching values. Please wait."));
  const contract = MettAlex(commodity.mettalex_contract_address);
  const capFloors = yield contract.getCapFloor();
  yield put(updateCapFloor({ ...capFloors, id: commodity.id }));
  yield put(updateIsLoading(false));
}

export function* getLatestCommodityPrices(action) {
  const commodity = action.payload;
  let price_scaling = 1;
  if (commodity.price_scaling) price_scaling = commodity.price_scaling;
  yield put(updateCommodityIsLoading({ id: commodity.id, value: 1 }));
  // yield put(updateLoadingMessage("Fetching values. Please wait."));
  yield put(updateCurrentCommodityIsLoading(true));
  yield put(updateLoadingMessage('Fetching values. Please wait.'));
  let historyPrices = [];
  let historyPricesCode1 = [];
  let historyPricesCode2 = [];
  try {
    historyPrices = yield getHistoryPrice(
      commodityLatestHistoryURL,
      `commodity_symbol=${commodity.commodity_symbol}`,
      365
    );
    if (commodity.is_spread) {
      historyPricesCode1 = yield getHistoryPrice(
        commodityHistoryURL,
        `code=${commodity.code1}`,
        365
      );
      historyPricesCode2 = yield getHistoryPrice(
        commodityHistoryURL,
        `code=${commodity.code2}`,
        365
      );
    }
  } catch (err) {
    console.log(commodity.commodity_symbol, err);
  }
  let currentPrice = 0;
  let timeStamp = 0;
  try {
    const data = yield getCurrentPrice(
      commodityLatestSpotURL,
      `code=${commodity.commodity_symbol}`
    );
    currentPrice = (data[0] * price_scaling).toFixed(decimals.currentDigits);
    timeStamp = data[1];
  } catch (err) {
    console.log(commodity.commodity_symbol, err);
  }
  historyPrices.map((obj) => {
    obj['weighted_price'] = (parseFloat(obj['weighted_price']) * price_scaling).toFixed(
      decimals.currentDigits
    );
    return obj;
  });
  // yield put(updateHistoryPrices({ commodity_symbol: commodity.commodity_symbol, historyPrices }))
  // yield put(updateCurrentPrice({ commodity_symbol: commodity.commodity_symbol, currentPrice, timeStamp }))
  yield put(
    updateCommodityPrices({
      historyPricesCode2,
      historyPricesCode1,
      historyPrices,
      currentPrice,
      timeStamp
    })
  );
  yield put(updateCommodityIsLoading({ id: commodity.id, value: -1 }));
  yield put(updateCurrentCommodityIsLoading(false));
}

const getCurrentPrice = async (url, code) => {
  try {
    const response = await fetch(`${url}${code}`, {
      method: 'get',
      headers: new Headers({
        Authorization: basicAuth,
        'Content-Type': 'application/json'
      })
    });
    const result = await response.text();
    const data = JSON.parse(result);
    return [data.price, data.trade_date];
  } catch (error) {
    throw new Error(error);
  }
};

const getHistoryPrice = async (url, code, range) => {
  var endDate = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000);
  var day = endDate.getDate();
  var month = endDate.getMonth() + 1;
  var year = endDate.getFullYear();
  var end_date = `${month}-${day}-${year}`;

  var startDate = new Date(Date.now() - range * 24 * 60 * 60 * 1000);
  day = startDate.getDate();
  month = startDate.getMonth() + 1;
  year = startDate.getFullYear();
  var start_date = `${month}-${day}-${year}`;

  let dates = `&start_date=${start_date}&end_date=${end_date}`;
  try {
    const response = await fetch(`${url}${code}${dates}`, {
      method: 'get',
      headers: new Headers({
        Authorization: basicAuth,
        'Content-Type': 'application/json'
      })
    });
    const result = await response.text();
    const data = JSON.parse(result);
    if (data.data) return data.data;
    else return [];
  } catch (error) {
    throw new Error(error);
  }
};

const updateCommodityRewardsDetails = async (rewardTransactionURLCombined, commodities) => {
  let response = [];
  let commodityIDs = [];
  try {
    for (let index in commodities) {
      commodityIDs.push(commodities[index].id + '');
    }
    const liquidityData = await userMtlxRewardCommodityCombined(
      rewardTransactionURLCombined,
      commodityIDs.join('|').toString()
    );
    for (let index in commodities) {
      if (commodities[index].id in liquidityData) {
        response.push({
          ...commodities[index],
          mtlx_rewards_available:
            liquidityData[commodities[index].id]?.reward_contract_address &&
            !isEmpty(liquidityData[commodities[index].id].reward_contract_address)
              ? true
              : false,
          daily_drip_rate:
            liquidityData[commodities[index].id]?.daily_drip_rate &&
            liquidityData[commodities[index].id].daily_drip_rate > 0
              ? liquidityData[commodities[index].id].daily_drip_rate
              : 0
        });
      } else {
        response.push({
          ...commodities[index],
          mtlx_rewards_available: false,
          daily_drip_rate: 0
        });
      }
    }
    return response;
  } catch (error) {
    console.log('Failed to fetch reward details for the commodites. \n', error);
    // returning barebone commodites, so that program execution dosent stop.
    return commodities;
  }
};

const getExistingCommodity = async (networkID, collateralToken) => {
  try {
    const response = await fetch(
      `${commoditiesURL}?network_id=${networkID}&collateral_token=${collateralToken.address}`,
      {
        method: 'get',
        headers: new Headers({
          Authorization: basicAuth,
          'Content-Type': 'application/json'
        })
      }
    );
    const result = await response.text();
    const data = JSON.parse(result);
    if (data.result) return data.result;
    else if (typeof data === 'object') return data;
    else return [];
  } catch (error) {
    throw new Error(error);
  }
};

const getCollateralTokens = async (networkID) => {
  try {
    const response = await fetch(`${collateralTokenURL}?network_id=${networkID}`, {
      method: 'get',
      headers: new Headers({
        Authorization: basicAuth,
        'Content-Type': 'application/json'
      })
    });
    const result = await response.text();
    const data = JSON.parse(result);
    if (data.result) return data.result;
    else return [];
  } catch (error) {
    throw new Error(error);
  }
};

export default startSaga;
