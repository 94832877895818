import { takeEvery, takeLatest, put, fork, call, take, select } from 'redux-saga/effects';
import {
  getAccounts,
  getBalance,
  getNetworkName,
  getNetworkId,
  isMetamaskInstalled,
  metaMaskAccountsChanged,
  userUsdtBalance,
  userMtlxBalance,
  userEthBalance,
  clearCachedLogin
} from '../utils/index';
import {
  updateAccount,
  updateBalance,
  updateGraphType,
  updateNetwork,
  updateNetworkID,
  updateEarnButton,
  updateFilter,
  updateGraphSelected
} from '../reducer/user';
import positionTokenContract from './../contractInterface/positionTokenContract';
import { eventChannel } from 'redux-saga';
import mtlxContract from '../ABI/MTLX_20210517.json';
import ethContract from '../ABI/ETH.json';
import { updateCommodities } from '../reducer/commdoities';
import { updateCurrentBalance } from '../reducer/currentCommodity';
// import { initContract } from './startUpSaga';
import { networks } from '../constant';
export function* walletDetails(action) {
  try {
    let walletAvailability = yield select((state) => state.user.walletAvailability);
    let account;
    let balance = 0;
    if (!walletAvailability) {
      account = [process.env.REACT_APP_STATIC_WALLET_ADD];
    } else {
      account = yield getAccounts();
      balance = yield getBalance(action.payload.address, action.payload.decimal, account[0]);
    }
    yield put(updateAccount(account[0]));
    yield put(updateBalance({ id: action.payload.address, value: balance }));
  } catch (err) {
    console.log(err);
  }
}

export function* walletTokenDetails(action) {
  try {
    let walletAvailability = yield select((state) => state.user.walletAvailability);
    let account;
    let networkID;
    if (!walletAvailability) {
      account = [process.env.REACT_APP_STATIC_WALLET_ADD];
      networkID = 97;
    } else {
      account = yield getAccounts();
      networkID = yield getNetworkId();
    }
    const collateralToken = yield select((state) => state.collateralToken);
    yield put(updateAccount(account[0]));
    let longBalance = 0;
    let shortBalance = 0;
    let mtlxBalance = 0;
    let usdtBalance = 0;
    let ethBalance = 0;
    if (walletAvailability) {
      longBalance = yield getBalance(
        action.payload.long_token_contract_address,
        action.payload.decimal,
        account[0]
      );
      shortBalance = yield getBalance(
        action.payload.short_token_contract_address,
        action.payload.decimal,
        account[0]
      );
      if (collateralToken.address) {
        usdtBalance = yield userUsdtBalance(
          collateralToken.address,
          account[0],
          collateralToken.decimal
        );
      }
      if (mtlxContract.address[networkID.toString()]) {
        mtlxBalance = yield userMtlxBalance(mtlxContract.address[networkID.toString()], account[0]);
      }
      ethBalance = yield userEthBalance(ethContract.address, account[0]);
    }

    yield put(updateBalance({ id: 'USDT', value: usdtBalance }));
    yield put(updateCurrentBalance({ id: 'USDT', value: usdtBalance }));

    yield put(updateBalance({ id: 'MTLX', value: mtlxBalance }));
    yield put(updateCurrentBalance({ id: 'MTLX', value: mtlxBalance }));

    yield put(updateBalance({ id: 'ETH', value: ethBalance }));
    yield put(updateCurrentBalance({ id: 'ETH', value: ethBalance }));

    yield put(
      updateBalance({ id: action.payload.long_token_contract_address, value: longBalance })
    );
    yield put(
      updateCurrentBalance({ id: action.payload.long_token_contract_address, value: longBalance })
    );

    yield put(
      updateBalance({ id: action.payload.short_token_contract_address, value: shortBalance })
    );
    yield put(
      updateCurrentBalance({ id: action.payload.short_token_contract_address, value: shortBalance })
    );
  } catch (err) {
    console.log(err);
  }
}

function* networkId() {
  let walletAvailability = yield select((state) => state.user.walletAvailability);
  let network;
  let networkID;
  if (!walletAvailability) {
    network = 'bsc-testnet';
    networkID = 97;
  } else {
    network = yield getNetworkName();
    networkID = yield getNetworkId();
  }
  if (networks[networkID]) {
    yield put(updateNetwork(network));
  } else {
    yield put(updateNetwork(`${network} network not supported please switch to Kovan`));
  }
  yield put(updateNetworkID(networkID));
}

function createMetaMaskAccountChannel() {
  return eventChannel((emit) => {
    metaMaskAccountsChanged((account) => {
      emit(account);
    });
    return () => {
      console.log('Account changed');
    };
  });
}

export function* watchMetaMaskAccountChange() {
  const accountChannel = yield call(createMetaMaskAccountChannel);
  while (true) {
    try {
      yield take(accountChannel);
      const oldnetwork = yield select((state) => state.user.networkID);
      const newnetwork = yield getNetworkId();
      let walletAvailability = yield select((state) => state.user.walletAvailability);
      let account = yield getAccounts();
      if (account.length === 0 && walletAvailability === true) {
        yield clearCachedLogin();
        window.location.reload();
      }
      yield put({ type: 'NETWORK' });
      if (oldnetwork === newnetwork) {
        // only account has been changed
        account = yield getAccounts();
        yield put(updateAccount(account[0]));
        const commodities = yield select((state) => state.commodities);
        yield put({ type: 'USDT_BALANCE' });
        const result = [];
        for (var i in commodities) result.push(commodities[i]);
        yield put({ type: 'FETCH_USER_VALUES', payload: result });
      } else if (oldnetwork && newnetwork) {
        let accountAddress = yield select((state) => state.user.address);
        if (
          accountAddress !== process.env.REACT_APP_STATIC_WALLET_ADD &&
          walletAvailability === true
        ) {
          console.log('1st if condition');
          // wallet is connected and user account address is not same a static account address present in env.
          window.location.reload();
        }
      }
    } catch (err) {
      console.error('error in Channel:', err);
    }
  }
}

export function* getUsdtBalance() {
  try {
    let walletAvailability = yield select((state) => state.user.walletAvailability);
    let account;
    let networkID;
    if (!walletAvailability) {
      account = [process.env.REACT_APP_STATIC_WALLET_ADD];
      yield put(updateAccount(account[0]));
      networkID = 97;
    } else {
      account = yield getAccounts();
      yield put(updateAccount(account[0]));
      networkID = yield getNetworkId();
    }

    const collateralToken = yield select((state) => state.collateralToken);
    let mtlxBalance = 0;
    let usdtBalance = 0;
    let ethBalance = 0;
    if (walletAvailability) {
      if (collateralToken.address) {
        usdtBalance = yield userUsdtBalance(
          collateralToken.address,
          account[0],
          collateralToken.decimal
        );
      }
      if (mtlxContract.address[networkID.toString()]) {
        mtlxBalance = yield userMtlxBalance(mtlxContract.address[networkID.toString()], account[0]);
      }
      ethBalance = yield userEthBalance(ethContract.address, account[0]);
    }

    yield put(updateBalance({ id: 'USDT', value: usdtBalance }));
    yield put(updateCurrentBalance({ id: 'USDT', value: usdtBalance }));
    yield put(updateBalance({ id: 'MTLX', value: mtlxBalance }));
    yield put(updateCurrentBalance({ id: 'MTLX', value: mtlxBalance }));
    yield put(updateBalance({ id: 'ETH', value: ethBalance }));
    yield put(updateCurrentBalance({ id: 'ETH', value: ethBalance }));
  } catch (err) {
    console.log(err);
    //retry
    yield put({ type: 'USDT_BALANCE' });
  }
}

function* updateCommodityFilter(action) {
  try {
    let filter = action.payload;
    yield put(updateFilter(filter));
  } catch (error) {
    console.log(error);
  }
}

function* updateGraphFilter(action) {
  try {
    let filter = action.payload;
    yield put(updateGraphSelected(filter));
  } catch (error) {
    console.log(error);
  }
}

function* updateGraphTypeFilter(action) {
  try {
    let filter = action.payload;
    yield put(updateGraphType(filter));
  } catch (error) {
    console.log(error);
  }
}

function* getCommodityValues(action) {
  try {
    const tokenContract = yield positionTokenContract(action.payload.long_token_contract_address);
    const decimal = yield tokenContract.getDecimals();
    if (action.payload.balancer_pool_address) {
      const payload = {
        id: action.payload.id,
        long_address: action.payload.long_token_contract_address,
        short_address: action.payload.short_token_contract_address,
        balancer_pool_address: action.payload.balancer_pool_address,
        strategy_address: action.payload.strategy_address,
        decimal
      };
      yield put({ type: 'INIT_POOL_DETAILS', payload });
    }

    const commodity = (action.payload = {
      ...action.payload,
      decimal: parseInt(decimal),
      error: false
    });
    yield put(updateCommodities(commodity));
    yield put({ type: 'INIT_CONTRACT', payload: commodity });
  } catch (error) {
    console.log(error);
  }
}

function* updateEarn(action) {
  try {
    yield put(updateEarnButton(action.payload));
  } catch (err) {
    console.log(err);
  }
}

function* userSaga() {
  yield takeEvery('USER_WALLET_DETAILS', walletDetails);
  yield takeEvery('USER_WALLET_TOKEN_DETAILS', walletTokenDetails);
  yield takeLatest('USDT_BALANCE', getUsdtBalance);
  yield takeEvery('NETWORK', networkId);
  yield takeEvery('GET_VALUE', getCommodityValues);
  yield takeEvery('UPDATE_FILTER', updateCommodityFilter);
  yield takeEvery('UPDATE_GRAPH_FILTER', updateGraphFilter);
  yield takeEvery('UPDATE_GRAPH_TYPE_FILTER', updateGraphTypeFilter);
  yield takeEvery('UPDATE_EARN_BUTTON', updateEarn);
  let isMetamaskAvailable = yield isMetamaskInstalled();
  if (isMetamaskAvailable) {
    yield fork(watchMetaMaskAccountChange);
  }
}

export default userSaga;
