import React, { useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import TypoGraphy from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import NumberFormat from 'react-number-format';
import TableData from '../../component/Table/index';
import DataNotFound from '../../component/DataNotFound';

const useStyles = makeStyles((theme) => ({
  flexBox: {
    display: 'flex',
    color: 'white',
    background: theme.dropdown.fadedBackgroundColor,
    padding: '9px 20px',
    borderBottom: 'none',
    borderTop: theme.border,
    '& .MuiTypography-root': {
      fontSize: 14,
      color: theme.secondaryText,
      fontFamily: 'MontserratLight'
    },
    '& span': {
      fontSize: 14,
      color: 'white',
      fontFamily: 'MontserratLight'
    }
  },
  pagination: {
    background: theme.dropdown.backgroundColor,
    color: theme.text,
    padding: '0 25px !important',
    width: '20%',
    margin: '0 auto',
    '& button[title="Previous page"]': {
      padding: 0,
      color: theme.text,
      position: 'absolute',
      top: '50%',
      left: '0',
      transform: 'translatey(-50%)'
    },
    '& button.Mui-disabled': {
      opacity: 0.3
    },
    '& button[title="Next page"]': {
      padding: 0,
      color: theme.text,
      position: 'absolute',
      top: '50%',
      right: '0',
      transform: 'translatey(-50%)'
    },
    '& .MuiTablePagination-caption': {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)'
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: '40px'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
      color: 'white !important'
    }
  },
  cardTitle: {
    color: '#5C5C61',
    fontSize: '16px',
    fontWeight: 500
  },
  cardSubTitle: {
    color: '#7C7C7D',
    fontSize: '16px',
    fontWeight: 400
  }
}));

const SuppliedLiquidity = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [totalValue, setTotal] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [filledRows, setFilledRows] = React.useState([]);

  const tableHeadings = [
    {
      key: 'name',
      label: 'Market',
      type: 'tx'
    },
    {
      key: 'percentage',
      label: 'APY',
      type: 'tx'
    },
    {
      key: 'total',
      label: 'Total',
      type: 'tx'
    }
  ];

  const totalPages = (value) => {
    const total = Math.ceil(value.length / rowsPerPage);
    if (total < 1) {
      return 1;
    } else {
      return total;
    }
  };

  useEffect(() => {
    setPage(0);
  }, [props.empty]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const renderLiquidity = (data) => {
    return (
      <TableData
        isHistoryTable={false}
        handleSelected={props.handleSelected}
        headings={tableHeadings}
        rowData={data || []}
        history={props.history}
        emptyText={<DataNotFound displayText={'No Transactions to Show'} />}
      />
    );
  };

  const createData = (name, percentage, total, value) => {
    return { name, percentage, total, value };
  };

  useEffect(() => {
    let values = [];
    let filledValues = [];
    let data = 0,
      totalRewards = 0;
    let keys = Object.keys(props.commodities);
    for (let i = 0; i < keys.length; i++) {
      const id = keys[i];
      if (props.commodities[id].commodity_name) {
        const name = `${props.commodities[id].commodity_name} V${props.commodities[id].version}`;

        const liquidity = props.commodities[id].liquidity_pool_address;
        if (props.liquidityPool[liquidity]) {
          const conversionRate = props.liquidityPool[liquidity].conversionRate;
          const mxUSDT = props.liquidityPool[liquidity].mxUSDT;
          const investedUSDT = props.liquidityPool[liquidity].investedUSDT;

          totalRewards += mxUSDT * conversionRate - investedUSDT;
          const total = mxUSDT * conversionRate;
          const createdAt = props.commodities[id].created_at;
          const DifferenceInTime = new Date().getTime() - new Date(createdAt).getTime();
          var DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);

          data = data + total;
          if (DifferenceInDays < 1) DifferenceInDays = 1;
          let percentage = 0;
          if (conversionRate)
            percentage = ((conversionRate - 1) * (365.25 / DifferenceInDays) * 100).toFixed(2);
          // let settled = props.tokenPrices[props.commodities[id].id] ? props.tokenPrices[props.commodities[id].id].isSettled : 0
          // if ((props.selected.active && props.selected.settled)
          //   || (!settled && props.selected.active)
          //   || (settled && props.selected.settled))
          if (total) {
            filledValues.push(
              createData(
                name,
                props.liquidityPool[liquidity]
                  ? parseFloat(percentage !== 0 ? percentage : 0)
                  : parseFloat(0),
                total,
                id
              )
            );
          }
          values.push(
            createData(
              name,
              props.liquidityPool[liquidity]
                ? parseFloat(percentage !== 0 ? percentage : 0)
                : parseFloat(0),
              total,
              id
            )
          );
        }
      }
    }
    props.handle(totalRewards);
    setFilledRows(filledValues);
    setRows(values);
    setTotal(data);
  }, [props.selected, props.liquidityPool, props.commodities, props.handle, props]);

  return props.empty ? (
    <>
      {renderLiquidity(filledRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))}
      {filledRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length > 0 && (
        <div className={classes.flexBox} style={{ justifyContent: 'space-between' }}>
          <TypoGraphy className={classes.cardTitle}>
            Total <span className={classes.cardSubTitle}>({props.coinTokenName})</span>
          </TypoGraphy>
          <TypoGraphy className={classes.cardSubTitle}>
            <NumberFormat decimalScale={2} value={totalValue || 0} displayType={'text'} />
          </TypoGraphy>
        </div>
      )}
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[]}
        component="div"
        count={filledRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        labelDisplayedRows={({ page }) => `Page ${page + 1} of ` + totalPages(filledRows)}
      />
    </>
  ) : (
    <>
      {/* <TypoGraphy className={classes.cardTitle}>Supplied Liquidity <span className={classes.cardSubTitle}>({props.coinTokenName})</span></TypoGraphy> */}
      {/* <br /> */}
      {renderLiquidity(rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))}
      {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length > 0 && (
        <div className={classes.flexBox} style={{ justifyContent: 'space-between' }}>
          <TypoGraphy className={classes.cardTitle}>
            Total <span className={classes.cardSubTitle}>({props.coinTokenName})</span>
          </TypoGraphy>
          <TypoGraphy className={classes.cardSubTitle}>
            <NumberFormat decimalScale={2} value={totalValue || 0} displayType={'text'} />
          </TypoGraphy>
        </div>
      )}
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        labelDisplayedRows={({ page }) => `Page ${page + 1} of ` + totalPages(rows)}
      />
    </>
  );
};

export default SuppliedLiquidity;
