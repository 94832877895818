import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '../../assets/arrow-down.svg';
import { connect } from 'react-redux';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';
//import { relativeTimeRounding } from "moment";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'grid',
      alignItems: 'center',
      color: theme.text
    },
    paper: {
      zIndex: 9999,
      backgroundColor: theme.dropdown.backgroundColor
    },
    drawer: {
      zIndex: 9999,
      top: '8px !important'
    },
    menu: {
      color: theme.text,
      fontWeight: '500',
      padding: 0,
      '& div': {
        '& .MuiMenuItem-root': {
          borderBottom: '1px solid #414658'
        }
      },
      '& div:last-child': {
        '& .MuiMenuItem-root:last-child': {
          borderBottom: 'none'
        }
      },
      '& .MuiList-root': {
        padding: 0
      },
      '& .placeholder': {
        padding: theme.spacing(1),
        backgroundColor: theme.dropdown.backgroundColor,
        color: theme.secondaryText
      }
    },
    menuItem: {
      padding: '8px 10px !important',
      backgroundColor: 'rgb(45 46 61)',
      color: theme.secondaryText,
      minWidth: 106,
      borderBottom: '1px solid #414658',
      fontSize: 14,
      height: 40,
      '&:hover': {
        color: theme.color,
        backgroundColor: '#2d2e3d'
      },
      '&:hover .MuiTypography-root': {
        color: `${theme.color} !important`
      },
      '& svg': {
        verticalAlign: 'middle'
      },
      '&:last-child': {
        borderBottom: 'none'
      },
      '& .listItem': {
        flex: 'none'
      },
      fontWeight: '500'
    },
    list: {
      borderRadius: 5,
      color: theme.text,
      textTransform: 'none',
      fontSize: 14,
      padding: '0px 4px',
      backgroundColor: '#293949',
      '&:hover': {
        //backgroundColor: "transparent",
        backgroundColor: '#293949',
        opacity: 0.5
      },
      '& .MuiTypography-root': {
        fontSize: 14
      }
    },
    heading: {
      color: 'grey'
    },
    listText: {
      textAlign: 'right'
    },
    typrography: {
      color: 'grey',
      fontWeight: '500',
      padding: '8px 0'
    },
    arrowDropDown: {
      width: 30,
      height: 30,
      padding: 8,
      borderRadius: 5,
      left: 3,
      backgroundColor: '#34495e',
      position: 'relative',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      cursor: 'pointer'
    }
  };
});

const MenuListComposition = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [menuPosition, setMenuPosition] = React.useState(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    if (menuPosition) {
      return;
    }
    setMenuPosition({});
  };

  const handleClose = (event, value) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (value) {
      localStorage.setItem('collateralToken', value);
      window.location.reload();
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const createMenuItem = (collateralToken) => {
    return (
      <MenuItem
        key={collateralToken.token_address}
        onClick={(event) => handleClose(event, `${collateralToken.token_address}`)}
        className={classes.menuItem}
      >
        <ListItemText className="listItem" primary={`${collateralToken.token_name}`} />
      </MenuItem>
    );
  };

  return (
    <Box className={`${classes.root} list-items`} key={'collateralToken'}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.list}
      >
        {props.collateralToken.name ? (
          <>
            <ListItemText primary={`${props.collateralToken.name}`} />
            &nbsp;&nbsp;
          </>
        ) : (
          <Typography style={{ fontWeight: 'bold' }}>Select</Typography>
        )}
        <img src={ArrowDropDownIcon} alt="drop-down-arrow" className={classes.arrowDropDown} />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 999 }}
        className={classes.drawer}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper elevation={3} className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  open={open}
                  onClose={handleClose}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  className={classes.menu}
                >
                  {Object.values(props.collateralList).map((collateralToken) => {
                    return createMenuItem(collateralToken);
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  collateralList: state.collateralList.tokens,
  collateralToken: state.collateralToken
});

export default connect(mapStateToProps)(MenuListComposition);
