import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import MintWhite from '../../assets/mint_white.svg';
import LiquidityWhite from '../../assets/liquidity_white.svg';
import Mint from '../../assets/mint.png';
import Liquidity from '../../assets/liquidity.png';
import AppMenuItem from './AppMenuItem';

import tag from '../../assets/poweredByDark.svg';
import logo from '../../assets/logo.svg';
import dashboardWhite from '../../assets/dashboard_white.svg';
import dashboard from '../../assets/dashboard.png';

// import Faucet from '../../assets/faucet.png';
// import FaucetWhite from '../../assets/faucet_white.svg';
import wallet from '../../assets/Wallet.svg';

import transaction from '../../assets/txhistory.svg';
import { NavLink } from 'react-router-dom';
const appMenuItems = [
  {
    name: 'Dashboard',
    link: '/dashboard',
    Icon: dashboardWhite,
    hover: dashboard
  },
  {
    name: 'Trade',
    link: '/trade',
    Icon: MintWhite,
    hover: Mint
  },
  {
    name: 'Liquidity',
    link: '/liquidity',
    Icon: LiquidityWhite,
    hover: Liquidity
  },
  // {
  //   name: 'Faucet',
  //   link: '/faucet',
  //   Icon: FaucetWhite,
  //   hover: Faucet
  // },
  {
    name: 'Transactions',
    link: '/transactions',
    Icon: transaction,
    hover: transaction
  },
  {
    name: 'Account',
    link: '/account',
    Icon: wallet,
    hover: wallet
  }
];

const AppMenu = (props) => {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {window.location === window.parent.location && (
        <ListItem
          to={'/'}
          button
          disableTouchRipple
          component={forwardRef((props, ref) => (
            <NavLink exact {...props} innerRef={ref} className={classes.logoContainer} />
          ))}
        >
          <img src={logo} alt="logo" className={classes.logo} />
          <img src={tag} alt="logo-tag" className={classes.logoTag + ' logoTag'} />
        </ListItem>
      )}
      <br />
      <br />
      {appMenuItems.map((item, index) => {
        if (item.name === 'Faucet' && props.collateralToken && props.collateralToken.isRealToken)
          return null;
        // else
        return <AppMenuItem {...item} key={index} />;
      })}
    </List>
  );
};

const drawerWidth = 100;

const useStyles = makeStyles((theme) => {
  return {
    appMenu: {
      width: '100%',
      padding: '0 15px',
      transformOrigin: 'top left'
    },
    navList: {
      width: drawerWidth
    },
    menuItem: {
      width: drawerWidth
    },
    menuItemIcon: {
      color: '#97c05c'
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'flex-start'
    },
    logoTag: {
      marginLeft: '8px'
    },
    bottomPush: {
      position: 'fixed',
      bottom: 0,
      textAlign: 'right',
      paddingBottom: 10,
      left: '12%'
    },
    appVersion: {
      fontSize: 10
    }
  };
});

const mapStateToProps = (state) => ({
  networkID: state.user.networkID,
  collateralToken: state.collateralToken
});

export default connect(mapStateToProps)(AppMenu);
