import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles, Button, InputBase } from '@material-ui/core';
import { createAction } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { networks } from '../../constant';
import { BigNumber } from 'bignumber.js';
import { validateInputField } from '../utils';
import LoaderOverlay from '../../component/Loader';

const mintToket = createAction('MINT_TOKEN');
const redeemToket = createAction('REDEEM_TOKEN');

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: theme.border,
    paddingBottom: 10,
    marginBottom: 5
  },
  feeGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: 'none',
    paddingBottom: 2,
    marginBottom: 2
  },
  secondaryGrid: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  typography: {
    color: theme.text,
    fontFamily: 'MontserratLight',
    fontSize: '13px'
  },
  alignEnd: {
    textAlign: 'end'
  },
  greyTypography: {
    color: theme.secondaryText
  },
  input: {
    width: '100%',
    color: theme.text,
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  button: {
    marginTop: 51,
    padding: '8px 35px',
    fontSize: '14px',
    fontFamily: 'MontserratMedium',
    marginLeft: 'auto',
    borderRadius: '0.25rem',
    background: theme.grid.color,
    border: theme.grid.border,
    color: 'black',
    '&:hover': {
      background: theme.grid.colorHover,
      border: theme.grid.borderHover,
      color: 'black'
    },
    '&:disabled': {
      background: '#585d6e',
      border: theme.borderAlt,
      color: 'black'
    }
  },
  maxButton: {
    marginRight: '0',
    color: theme.grid.color,
    background: 'rgb(104 219 218 / 15%)',
    borderRadius: '0.25rem',
    padding: '4px 18px',
    '&:disabled': {
      color: 'white',
      background: '#585d6e'
    }
  },
  span: {
    fontSize: 12,
    color: '#e83f4c'
  },
  box: {
    backgroundColor: theme.dropdown.backgroundColor,
    padding: '8px 16px',
    borderRadius: '0.25rem',
    '& input': {
      color: theme.text,
      fontSize: 13
    }
  },
  setHeight: {
    display: 'grid',
    margin: '0px auto',
    padding: 0,
  }
}));

const MintRedeemView = (props) => {
  const classes = useStyles();
  const [amount, setAmount] = useState(0);
  const [usdtFees, setUsdtFees] = useState(0);
  const [net, setNet] = useState(0);
  const [collateralLocked, setCollateralLocked] = useState();
  const [maxAmount, setMaxAmount] = useState(0);
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  // const truncateDecimals = (amount, decimalDigit) => {
  //   let amountValue = amount.toString();
  //   if (amountValue.indexOf(".") > 0) {
  //     amountValue = amountValue.slice(0, (amountValue.indexOf(".")) + decimalDigit + 1)
  //   };
  //   return Number(amountValue)
  // }

  let invalidChars = ['-', '+', 'e', 'E'];

  const handleKeyPress = (e) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleAmount = (event) => {
    if (event === '' || undefined) {
      event = 0;
    }
    setError('');
    // event = parseFloat(event)
    // event = truncateDecimals(event, props.selectedCommodity.decimal)
    if (new BigNumber(event).lt(BigNumber(0))) {
      setError("Amount can't be negetive");
      return;
    }

    if (
      event.toString().split('.').length === 2 &&
      parseInt(
        event
          .toString()
          .split('.')[1]
          .charAt(event.toString().split('.')[1].length - 1)
      ) === 0
    ) {
      setAmount(event);
    } else {
      event = new BigNumber(event).decimalPlaces(5).toString(10);
      setAmount(event);
    }

    const product = new BigNumber(props.selectedCommodity.collateral_per_unit).multipliedBy(event);
    const division = new BigNumber(10)
      .pow(props.selectedCommodity.decimal)
      .dividedBy(new BigNumber(10).pow(props.collateralToken.decimal));
    const collateral = new BigNumber(product).multipliedBy(division).toString(10);
    // const collateral = (parseInt(props.selectedCommodity.collateral_per_unit) * parseFloat(event) * (10 ** props.selectedCommodity.decimal)) / (10**props.collateralToken.decimal)
    setCollateralLocked(collateral);
    if (props.title === 'Mint') {
      // const fees = (parseInt(props.selectedCommodity.collateral_fee_per_unit) * parseFloat(event) * (10 ** props.selectedCommodity.decimal)) / (10**props.collateralToken.decimal)
      const product = new BigNumber(props.selectedCommodity.collateral_fee_per_unit)
        .multipliedBy(event)
        .toString(10);
      const division = new BigNumber(10)
        .pow(props.selectedCommodity.decimal)
        .dividedBy(new BigNumber(10).pow(props.collateralToken.decimal))
        .toString(10);
      const fees = new BigNumber(product).multipliedBy(division).toString(10);
      setUsdtFees(fees);
      const Net = new BigNumber(fees).plus(collateral).toString(10);
      setNet(Net);
    } else {
      setNet(collateral);
    }
  };

  const handleClick = () => {
    if (!props.walletAvailability) {
      setError(`Please Connect Wallet`);
    } else {
      if (new BigNumber(amount).isEqualTo(0)) {
        setError(`Amount must be greater than 0`);
      } else if (amount > parseFloat(maxAmount)) {
        setError(`Cannot ${props.title.toLowerCase()} more than MAX`);
      } else if (amount < 1 / 10 ** props.selectedCommodity.decimal) {
        setError(`Cannot ${props.title.toLowerCase()} less than MIN`);
      } else if (props.title === 'Mint') {
        props.mintToket({
          id: props.selected,
          mintAmount: amount * 10 ** props.selectedCommodity.decimal,
          contractAddress: props.contractAddress,
          collateralPerUnit: props.selectedCommodity.collateral_per_unit,
          collateralFeePerUnit: props.selectedCommodity.collateral_fee_per_unit
        });
        props.resetTab();
      } else {
        props.redeemToket({
          id: props.selected,
          redeemAmount: amount * 10 ** props.selectedCommodity.decimal,
          contractAddress: props.contractAddress
        });
        props.resetTab();
      }
    }
  };

  useEffect(() => {
    if (props.title === 'Mint') {
      const costPerTokenSummation = new BigNumber(props.selectedCommodity.collateral_per_unit).plus(
        props.selectedCommodity.collateral_fee_per_unit
      );
      const costPerTokenMul = costPerTokenSummation.multipliedBy(
        new BigNumber(10).pow(props.selectedCommodity.decimal)
      );
      const costPerToken = costPerTokenMul
        .dividedBy(new BigNumber(10).pow(props.collateralToken.decimal))
        .toString(10);
      // const costPerToken = ((parseInt(props.selectedCommodity.collateral_per_unit) + parseInt(props.selectedCommodity.collateral_fee_per_unit)) * (10 ** props.selectedCommodity.decimal) / (10**props.collateralToken.decimal))
      // const max = truncateDecimals((parseFloat(props.balances.USDT) / costPerToken), props.selectedCommodity.decimal)
      const max = new BigNumber(props.balances.USDT)
        .dividedBy(costPerToken)
        .decimalPlaces(props.selectedCommodity.decimal)
        .toString(10);
      setMaxAmount(max);
    } else if (props.balances.long && props.title === 'Redeem') {
      setMaxAmount(
        BigNumber.minimum(props.balances.long[2], props.balances.short[2])
          .decimalPlaces(props.selectedCommodity.decimal)
          .toString(10)
      );
      // setMaxAmount(truncateDecimals(Math.min(parseFloat(props.balances.long[2]), parseFloat(props.balances.short[2])), props.selectedCommodity.decimal))
    }
  }, [props.selectedCommodity, props.balances, props.title, props.collateralToken]);

  const handleMaxClick = () => {
    handleAmount(maxAmount);
  };

  useEffect(() => {
    setAmount(0);
    setError('');
    setUsdtFees(0);
    setNet(0);
    setCollateralLocked('');
  }, [props.selected]);

  useEffect(() => {
    if (!props.address) {
      setIsDisabled(true);
    } else {
      if (networks[props.networkID] && props.selected !== undefined) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [props.networkID, props.address, props.selected]);

  useEffect(() => {
    if (!props.address) {
      setIsDisabled(true);
    } else {
      if (
        (props.title === 'Mint' && props.isSettled) ||
        !networks[props.networkID] ||
        props.selected === undefined
      ) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    }
  }, [props.isSettled, props.title, props.address, props.networkID, props.selected]);

  return (
    <>
      <Box style={{ display: 'flex', marginBottom: 10 }}>
        <Typography
          component={'span'}
          className={`${classes.typography} ${classes.greyTypography}`}
        >
          Amount To {props.title}:
        </Typography>
      </Box>
      <Box display="flex" paddingX="12px" paddingTop="9px" className={classes.box}>
        <InputBase
          disabled={isDisabled || props.walletAvailability === false || props.disabledTab === true || props.isLoading === true}
          value={amount}
          type="number"
          size="small"
          className={classes.input}
          onKeyPress={handleKeyPress}
          onChange={(event) => {
            if (validateInputField(event.target.value)) {
              handleAmount(event.target.value);
            }
          }}
        />
        <Button
          size="small"
          variant="outlined"
          className={classes.maxButton}
          onClick={() => handleMaxClick()}
          disabled={isDisabled || props.walletAvailability === false || props.disabledTab === true || props.isLoading === true}
        >
          Max
        </Button>
      </Box>
      {<span className={classes.span}>{error || ''}</span>}
      <Box style={{ marginTop: 16 }}>
        <Grid className={classes.grid}>
          <Typography
            component={'span'}
            className={`${classes.typography} ${classes.greyTypography}`}
          >
            Order Total:
          </Typography>
          <Typography component={'span'} className={`${classes.typography} ${classes.alignEnd}`}>
            {amount || 0}
          </Typography>
        </Grid>
        <Grid className={classes.grid}>
          <Typography
            component={'span'}
            className={`${classes.typography} ${classes.greyTypography}`}
          >
            Collateral {props.title === 'Mint' ? 'Locked' : 'Unlocked'}:
          </Typography>
          <Typography component={'span'} className={`${classes.typography} ${classes.alignEnd}`}>
            {collateralLocked ? collateralLocked : '0'} {' ' + props.collateralToken.name}
          </Typography>
        </Grid>
        {props.title === 'Redeem' && <br></br>}
        <Grid className={classes.grid}>
          <Typography
            component={'span'}
            style={{ fontFamily: 'MontserratMedium' }}
            className={`${classes.typography} ${classes.greyTypography}`}
          >
            Applicable Fees:
          </Typography>
        </Grid>
        {props.title === 'Mint' && (
          <>
            <Grid className={classes.feeGrid}>
              <Typography
                component={'span'}
                className={`${classes.typography} ${classes.greyTypography}`}
              >
                {props.collateralToken.name}:{' '}
              </Typography>
              <Typography
                component={'span'}
                className={`${classes.typography} ${classes.alignEnd}`}
              >
                {usdtFees && usdtFees !== '' ? usdtFees : '-'}
              </Typography>
            </Grid>
          </>
        )}
        <Grid className={classes.grid}>
          <Typography
            component={'span'}
            className={`${classes.typography} ${classes.greyTypography}`}
          >
            Net:
          </Typography>
          <Typography component={'span'} className={classes.typography}>
            {net ? net : '-'}
          </Typography>
        </Grid>
      </Box>
      <Grid style={{ display: 'flex' }}>
        {props.isLoading ? 
        <div className={classes.setHeight}>
          <LoaderOverlay title={'Transaction is in progress...'} css={{backdrop_abs: false}}/>
        </div> :
        <Button
        size="small"
        className={classes.button}
        onClick={() => handleClick()}
        disabled={isDisabled || props.walletAvailability === false || props.disabledTab === true}
        >
          {props.title}
        </Button>
        }
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  networkID: state.user.networkID,
  address: state.user.address,
  collateralToken: state.collateralToken,
  walletAvailability: state.user.walletAvailability,
  isLoading: state.user.isLoading
});

export default connect(mapStateToProps, { redeemToket, mintToket })(MintRedeemView);
