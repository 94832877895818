import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { LightTooltip } from '../LightToolTip';
const useStyles = makeStyles((theme) => ({
  textElipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '55%'
  }
}));

const OverflowTooltip = (props) => {
  const classes = useStyles();
  const textElementRef = useRef();

  const compareSize = () => {
    const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    []
  );

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  return (
    <LightTooltip title={props.children} interactive disableHoverListener={!hoverStatus}>
      <div ref={textElementRef} className={classes.textElipsis}>
        {props.children}
      </div>
    </LightTooltip>
  );
};

export default OverflowTooltip;
