import React from 'react';
import TableData from '../../component/Table';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import { connect } from 'react-redux';
import LoaderOverlay from '../../component/Loader';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      maxHeight: 265,
      minHeight: 265,
      position: 'relative'
    },
    backdrop: {
      position: 'relative',
      zIndex: 1,
      color: '#fff',
      maxHeight: 265,
      minHeight: 265
    },
    typography: {
      fontSize: 18,
      padding: theme.spacing(1),
      marginTop: theme.spacing(0.5)
    },
    pagination: {
      background: theme.dropdown.backgroundColor,
      color: theme.text,
      padding: '0 25px !important',
      width: '50%',
      margin: '0 auto',
      '& button[title="Previous page"]': {
        padding: 0,
        color: theme.text,
        position: 'absolute',
        top: '50%',
        left: '0',
        transform: 'translatey(-50%)'
      },
      '& button.Mui-disabled': {
        opacity: 0.3
      },
      '& button[title="Next page"]': {
        padding: 0,
        color: theme.text,
        position: 'absolute',
        top: '50%',
        right: '0',
        transform: 'translatey(-50%)'
      },
      '& .MuiTablePagination-caption': {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)'
      },
      '& .MuiTablePagination-toolbar': {
        minHeight: '40px'
      },
      '& .MuiSvgIcon-root': {
        fontSize: '16px'
      }
    },
    loaderDimensions: {
      maxHeight: 60,
      minHeight: 60,
      marginTop: 100
    },
    loaderText: {
      textAlign: 'center'
    }
  };
});

const MarketGraph = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(11);

  const totalPages = () => {
    const total = Math.ceil(rows.length / rowsPerPage);
    if (total < 1) {
      return 1;
    } else {
      return total;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const mintHeadings = [
    { key: 'name', label: 'Name' },
    {
      key: 'volume',
      label: 'Volume',
      toolTip: 'Total value of all currently open long and short positions.'
    },
    {
      key: 'liquidity',
      label: 'Liquidity',
      toolTip: 'Total amount of liquidity that is currently available.'
    },
    {
      key: 'percentage',
      label: 'Fees APY',
      toolTip: 'Estimated annual percentage yield from trading fees.'
    },
    {
      key: 'daily_drip_rate',
      label: 'MTLX/Day',
      toolTip: 'Daily MTLX tokens drip-rate rewarded to deposited and staked liquidity.'
    }
  ];

  const createData = (name, reward, volume, liquidity, percentage, value, daily_drip_rate) => {
    return { name, reward, volume, liquidity, percentage, value, daily_drip_rate };
  };

  let rows = [];
  let keys = Object.keys(props.commodities);
  for (let i = 0; i < keys.length; i++) {
    const id = keys[i];
    if (props.commodities[id].commodity_name) {
      const name = `${props.commodities[id].commodity_name} V${props.commodities[id].version}`;
      const reward = props.commodities[id].mtlx_rewards_available;

      const longToken = props.commodities[id].long_token_contract_address;
      const shortToken = props.commodities[id].short_token_contract_address;
      const liquidity = props.commodities[id].liquidity_pool_address;
      const conversionRate = props.liquidityPool[liquidity]
        ? props.liquidityPool[liquidity].conversionRate
        : 1;
      let similarPoolAdd =
        props.commodities &&
        Object.values(props.commodities).filter(
          (obj) => props.commodities[id].balancer_pool_address === obj.balancer_pool_address
        );
      let createdAtDate = similarPoolAdd.map((value) => new Date(value.created_at));
      var minDateForPoolAdd = createdAtDate.reduce(function (a, b) {
        return a < b ? a : b;
      });

      const createdAt = minDateForPoolAdd;
      const DifferenceInTime = new Date().getTime() - new Date(createdAt).getTime();
      var DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
      if (DifferenceInDays < 1) DifferenceInDays = 1;
      let percentage = 0;
      if (conversionRate)
        percentage = ((conversionRate - 1) * (365.25 / DifferenceInDays) * 100).toFixed(2);

      let settled = props.commodities[id].is_settled;
      if (
        (props.selected.active && props.selected.settled) ||
        (!settled && props.selected.active) ||
        (settled && props.selected.settled)
      ) {
        rows.push(
          createData(
            name,
            reward,
            props.balancerPool[id] && props.balancerPool[id].balance
              ? props.balancerPool[id].balance[longToken] +
                  props.balancerPool[id].balance[shortToken]
              : 0,
            props.liquidityPool[liquidity] ? props.liquidityPool[liquidity].totalUSDT : 0,
            props.liquidityPool[liquidity]
              ? parseFloat(percentage !== 0 ? percentage : 0)
              : parseFloat(0),
            id,
            props.commodities[id].daily_drip_rate
          )
        );
      }
    }
  }
  return (
    <div className="scrollTable boxDesign">
      <TableContainer className={classes.container}>
        {props.commodityIsLoading[0] === 1 ? (
          <LoaderOverlay title={'Loading Commodities...'} />
        ) : (
          <TableData
            isHistoryTable={false}
            headings={mintHeadings}
            rowData={props.commodities ? rows : []}
            history={props.history}
            handleSelected={props.handleSelected}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        )}
      </TableContainer>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelDisplayedRows={({ page }) => `Page ${page + 1} of ` + totalPages()}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  balancerPool: state.balancerPool,
  commodityIsLoading: state.user.commodityIsLoading
});
export default connect(mapStateToProps, null)(React.memo(MarketGraph));
