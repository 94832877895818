import * as React from 'react';
import './index.css';
import { widget } from '../../charting_library/charting_library.min';
import { connect } from 'react-redux';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

class TVChartContainer extends React.PureComponent {
  static defaultProps = {
    interval: 'D', //D
    containerId: 'tv_chart_container',
    libraryPath: '../../charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {}
  };

  tvWidget = null;

  componentDidMount() {
    let datafeedURL =
      'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/graphData/' +
      this.props.selectedToken +
      '/' +
      this.props.commodity.network_id +
      '/' +
      this.props.commodity.id;
    const widgetOptions = {
      symbol: this.props.selectedToken,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(datafeedURL),
      interval: this.props.interval,
      container_id: this.props.containerId,
      library_path: this.props.libraryPath,
      locale: getLanguageFromURL() || 'en',
      disabled_features: ['volume_force_overlay'],
      enabled_features: [
        'study_templates',
        'hide_left_toolbar_by_default',
        'move_logo_to_main_pane'
      ],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
      theme: this.props.enabled ? 'Dark' : 'Light'
    };

    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.mainSeriesPriceFormatter()._fractionalLength = 2;
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute('title', 'Click to show a notification popup');
        button.classList.add('apply-common-tooltip');
        button.addEventListener('click', () =>
          tvWidget.showNoticeDialog({
            title: 'Notification',
            body: 'TradingView Charting Library API works correctly',
            callback: () => {
              console.log('Noticed!');
            }
          })
        );
        button.innerHTML = 'Check API';
      });
    });
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  render() {
    return <div id={this.props.containerId} className={'TVChartContainer'} />;
  }
}

const mapStateToProps = (state) => ({
  enabled: state.user.enabled
});

export default connect(mapStateToProps)(TVChartContainer);
