import { put, takeLatest } from 'redux-saga/effects';
import { updatecollateralList } from '../reducer/collateralList';
import { updatecollateralToken } from '../reducer/collateralToken';

function* startSaga() {
  yield takeLatest('UPDATE_COLLATERAL_TOKEN', updateCollateralToken);
}

// set current collateral token
export function* updateCollateralToken(action) {
  const collateralTokens = action.payload;
  let collateralToken = null;
  if (localStorage.getItem('collateralToken')) {
    collateralToken = collateralTokens.find(
      (token) => token.token_address === localStorage.getItem('collateralToken')
    );
  }
  // if(!collateralToken)  collateralToken = collateralTokens[1]
  if (!collateralToken)
    collateralToken = collateralTokens.find(
      (obj) => obj.token_name === 'USDT' || obj.token_name === 'BUSD' || obj.token_name === 'wUSDT'
    );
  if (collateralToken) yield put(updatecollateralToken(collateralToken));
  localStorage.setItem('collateralToken', collateralToken.token_address);
  yield put(updatecollateralList(collateralTokens));
}

export default startSaga;
