import { takeEvery, put, select } from 'redux-saga/effects';
import {
  updateIsLoading,
  // updateLoadingMessage,
  updateIsLoadingIndividual
} from '../reducer/user';
import { transactionHistoryURL } from '../constant/index';
import { txHistory, txCount, setSelected } from '../reducer/userTransactionHistory';
import { getNetworkId } from '../utils';
import { userHistory } from './transactionHistory';

function* userTransactionHistory() {
  yield takeEvery('USER_TRANSACTION_HISTORY_ALL', allTransaction);
}

function* allTransaction(action) {
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(true));
  } else {
    // yield put(updateIsLoading(true));;
  }
  // yield put(updateLoadingMessage("Fetching values. Please wait."));;
  const page = action.payload.page.toString();
  const records = action.payload.pageSize.toString();
  let walletAvailability = yield select((state) => state.user.walletAvailability);
  let networkID;
  if (!walletAvailability) {
    networkID = 97;
  } else {
    networkID = yield getNetworkId();
  }
  const commodity_list = yield select((state) => state.commodities);
  const collateral_token = yield select((state) => state.collateralToken);
  const address = action.payload.address;
  try {
    let history = {};
    history = yield userHistory(
      transactionHistoryURL,
      page - 1,
      records,
      address,
      networkID,
      Object.keys(commodity_list).join(','),
      collateral_token.name
    );
    let userTxHistory = [];
    if (history && history.result) {
      userTxHistory = history.result;
    }
    yield put(
      txHistory({ history: userTxHistory, page: parseInt(page), records: parseInt(records) })
    );
    yield put(txCount({ count: history.total }));
    // yield put(txCount({ count: userTxHistory.length }))
    yield put(setSelected({ selected: 1 }));
  } catch (err) {
    console.log(err);
  }
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(false));
  } else {
    yield put(updateIsLoading(false));
  }
}

export default userTransactionHistory;
