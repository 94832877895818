import { createMuiTheme } from '@material-ui/core/styles';

export const lightTheme = createMuiTheme({
  body: '#FAFAFA',
  text: 'black',
  typography: {
    //"fontFamily": `"GTWalsheimRegular", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontFamily: `"MontserratRegular", "Roboto", "Helvetica", "Arial", sans-serif`
  },
  dropdown: {
    backgroundColor: 'white'
  },
  card: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '5px',
    opacity: 1
  },
  maxButton: {
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #5643CC',
    color: '#5643CC'
  },
  grid: {
    color: '#5643CC'
  },
  divider: {
    backgroundColor: 'rgb(169,169,169,0.5)'
  },
  borderBottom: '1px solid rgb(211,211,211, 0.3)',
  backgroundColor: 'rgb(211,211,211, 0.3)',
  chart: {
    color: '#799bff'
  }
});

export const darkTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#68dbda'
    },
    secondary: {
      main: '#212230'
    }
  },
  body: 'linear-gradient(0deg, #0e0e15, #1a1d29)',
  secondaryText: '#9098AC',
  text: 'white',
  typography: {
    fontFamily: `"MontserratRegular", "Roboto", "Helvetica", "Arial", sans-serif`
  },
  dropdown: {
    backgroundColor: '#2d2e3d',
    fadedBackgroundColor: '#222330'
  },
  card: {
    background: '#2d2e3d',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '5px',
    opacity: 1
  },
  maxButton: {
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #68dbda',
    hoverColor: '#7ae6e5',
    color: '#68dbda',
    activeColor: '#000000'
  },
  grid: {
    color: '#68dbda',
    colorHover: '#7ae6e5',
    border: '1px solid #68dbda',
    borderHover: '1px solid #7ae6e5'
  },
  divider: {
    backgroundColor: 'rgb(169,169,169,0.5)'
  },
  border: '1px solid #2d2e3d',
  borderAlt: '1px solid #4A4D5F',
  borderBottom: '1px solid rgb(211,211,211, 0.1)',
  backgroundColor: '#0e0e15',
  chart: {
    color: '#68dbda'
  }
});
