import { select, takeEvery, put } from 'redux-saga/effects';
import { updateIsLoading, updateLoadingMessage } from '../reducer/user';
import { basicAuth, faucetTransferURL } from '../constant/index';
import { updateHistory, uploadHistory } from '../reducer/faucetTransfers';
import { getAccounts, getNetworkId, convertAmountToString } from '../utils/index';

function* faucetTransaction() {
  yield takeEvery('FAUCET_TRANSFER', startTranfer);
  yield takeEvery('FAUCET_TRANSACTIONS', faucetTransactions);
}

function* startTranfer(action) {
  // yield put(updateIsLoading(true));;
  yield put(updateLoadingMessage('Transaction in progress. Please wait.'));
  const walletAddress = yield select((state) => state.user.address);
  const network_id = yield select((state) => state.user.networkID);
  const collateralToken = yield select((state) => state.collateralToken);
  const amount = convertAmountToString(action.payload.amount, collateralToken.decimal);
  try {
    const faucet_data = yield transferAmount(
      faucetTransferURL,
      walletAddress,
      amount,
      network_id.toString(),
      collateralToken
    );
    yield put(updateHistory(faucet_data));
    yield put({ type: 'USDT_BALANCE' });
  } catch (err) {
    console.log(walletAddress, err);
  }
  yield put(updateIsLoading(false));
}

const transferAmount = async (url, walletAddress, amount, network_id, collateralToken) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      Authorization: basicAuth,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      wallet_address: walletAddress,
      transfer_amount: amount,
      network_id: network_id,
      collateral_token: collateralToken.address,
      decimal: collateralToken.decimal
    })
  });
  const result = await response.text();
  if (response.status !== 200) throw response.status;
  const data = JSON.parse(result);
  return data;
};

function* faucetTransactions() {
  //   // yield put(updateIsLoading(true));;
  //   // yield put(updateLoadingMessage("Fetching values. Please wait."));;
  let walletAvailability = yield select((state) => state.user.walletAvailability);
  let account;
  let network_id;
  if (!walletAvailability) {
    account = [process.env.REACT_APP_STATIC_WALLET_ADD];
    network_id = 97;
  } else {
    account = yield getAccounts();
    network_id = yield getNetworkId();
  }
  const collateralToken = yield select((state) => state.collateralToken);
  const walletAddress = account[0];
  const params = `wallet_address=${walletAddress}&network_id=${network_id}&collateral_token=${collateralToken.address}&decimal=${collateralToken.decimal}`;
  try {
    if (network_id !== 56) {
      const faucet_transactions = yield transaction(faucetTransferURL, params);
      const Data = {
        wallet_address: walletAddress,
        history: faucet_transactions.result,
        withdrawal_amount: faucet_transactions.withdrawal_amount
      };
      yield put(uploadHistory(Data));
    }
  } catch (err) {
    console.log(err);
  }
  yield put(updateIsLoading(false));
}

const transaction = async (url, walletAddress) => {
  const response = await fetch(`${url}?${walletAddress}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: basicAuth,
      'Content-Type': 'application/json'
    })
  });
  const result = await response.text();
  if (response.status !== 200) throw response.status;
  const data = JSON.parse(result);
  return data;
};

export default faucetTransaction;
