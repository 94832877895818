import { put, select, takeEvery } from 'redux-saga/effects';
import mtlxContract from '../ABI/MTLX_20210517.json';
import { basicAuth, fetchliquidityURL } from '../constant';
import liquidityPoolContract from '../contractInterface/liquidityPoolContract';
import positionTokenContract from '../contractInterface/positionTokenContract';
import rewardPollContract from '../contractInterface/rewardPollContract';
import { updateMtlxBallance, updateStackMtllxBallance } from '../reducer/currentCommodity';
import {
  updateConversionRate,
  updatemxUSDTBalance,
  updateTotalmxUSDT,
  updateTotalUSDTBalance
} from '../reducer/liquidityPool';
import {
  updateBalance,
  updateCommodityIsLoading,
  updateGasEstimate,
  updateIsLoading,
  updateIsDepositLoading,
  updateIsWithdrawLoading,
  updateLoadingMessage
} from '../reducer/user';
import { convertAmountToString, getAccountAddress, getAccounts, getNetworkId } from '../utils';
import { userMtlxBalance } from '../utils/index';
import { updateCurrentBalance } from '../reducer/currentCommodity';
import { BigNumber } from 'bignumber.js';

function* liquidityProviderSaga() {
  yield takeEvery('MTLX_DEPOSIT', depositMtlx);
  yield takeEvery('MTLX_WITHDRAW', withdrawMtlx);
  yield takeEvery('MTLX_CLAIM', claimMtlx);
  yield takeEvery('DEPOSIT_LIQUIDITY', depositLiquidity);
  yield takeEvery('WITHDRAW_LIQUIDITY', withdrawLiquidity);
  yield takeEvery('UPDATE_LIQUIDITY', updateLiquidity);
  yield takeEvery('UPDATE_USER_LIQUIDITY', updateUserLiquidityBalance);
  yield takeEvery('INIT_LIQUIDITY', fetchLiquidityValues);
  yield takeEvery('EARN_LIQUIDITY', earnLiquidity);
  yield takeEvery('GAS_ESTIMATE', gasEstimate);
}
let coinToken;
function* fetchLiquidityValues(action) {
  yield put(updateCommodityIsLoading({ id: action.payload.id, value: 1 }));
  // yield put(updateLoadingMessage("Fetching values. Please wait."));;
  try {
    let walletAvailability = yield select((state) => state.user.walletAvailability);
    let networkID;
    let address;
    if (!walletAvailability) {
      address = process.env.REACT_APP_STATIC_WALLET_ADD;
      networkID = 97;
    } else {
      address = yield getAccountAddress();
      networkID = yield getNetworkId();
    }
    const liquidityPoolAddress = action.payload.liquidityPoolAddress;
    coinToken = yield select((state) => state.collateralToken);
    let { conversionRate, totalUSDT, totalmxUSDT, mxUSDT } = yield fetchLiquidity(
      networkID,
      coinToken.decimal,
      liquidityPoolAddress,
      address
    );
    let handleBigMXUSDT = new BigNumber(mxUSDT);
    let bigMXUSDT = handleBigMXUSDT.dividedBy(10 ** coinToken.decimal).toString(10);
    yield put(updateConversionRate({ conversionRate: conversionRate || 1, liquidityPoolAddress }));
    yield put(updateTotalUSDTBalance({ totalUSDT: totalUSDT || 0, liquidityPoolAddress }));
    yield put(updateTotalmxUSDT({ totalmxUSDT: totalmxUSDT || 0, liquidityPoolAddress }));
    yield put(updatemxUSDTBalance({ mxUSDT: bigMXUSDT || 0, liquidityPoolAddress }));
  } catch (err) {
    console.log(err);
  }
  yield put(updateCommodityIsLoading({ id: action.payload.id, value: -1 }));
}

export const fetchLiquidity = async (networkId, decimal, liquidityAddress, address) => {
  const response = await fetch(fetchliquidityURL, {
    method: 'POST',
    headers: new Headers({
      Authorization: basicAuth,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      liquidity_address: liquidityAddress,
      network_id: networkId.toString(),
      decimal: decimal,
      address: address
    })
  });
  const result = await response.text();
  if (response.status !== 200) throw response.status;
  const data = JSON.parse(result);
  return data;
};

function* updateUserLiquidityBalance(action) {
  // yield put(updateIsLoading(true));;
  // yield put(updateLoadingMessage("Fetching values. Please wait."));;
  try {
    const liquidityPoolAddress = action.payload.liquidityPoolAddress;
    const coinToken = yield select((state) => state.collateralToken);
    let walletAvailability = yield select((state) => state.user.walletAvailability);
    let account;
    if (!walletAvailability) {
      account = [process.env.REACT_APP_STATIC_WALLET_ADD];
    } else {
      account = yield getAccounts();
    }
    const lContract = liquidityPoolContract(liquidityPoolAddress);
    const mxUSDT = yield lContract.getUserBalance(account[0], coinToken.decimal);
    yield put(updatemxUSDTBalance({ mxUSDT, liquidityPoolAddress }));
  } catch (err) {
    console.log(err);
  }
  yield put(updateIsLoading(false));
}

function* updateLiquidity(action) {
  // yield put(updateIsLoading(true));;
  // yield put(updateLoadingMessage("Fetching values. Please wait."));;
  try {
    yield fetchLiquidityValues(action);
    yield put({ type: 'USDT_BALANCE' });
  } catch (err) {
    console.log(err);
  }
  yield put(updateIsLoading(false));
}

function* depositLiquidity(action) {
  yield put(updateIsDepositLoading(true));
  yield put(updateLoadingMessage('Transaction in progress. Please wait.'));
  try {
    const coinToken = yield select((state) => state.collateralToken);
    const lContract = liquidityPoolContract(action.payload.liquidityPoolAddress);
    const walletAddress = yield select((state) => state.user.address);
    const amount = convertAmountToString(action.payload.amount, coinToken.decimal);
    const ApproveTokenContract = positionTokenContract(coinToken.address);
    yield ApproveTokenContract.approve(action.payload.liquidityPoolAddress, amount, walletAddress);
    yield lContract.depositUsdt(walletAddress, amount);
    yield lContract.earnLiquidity(walletAddress);
    yield put({
      type: 'UPDATE_LIQUIDITY',
      payload: { liquidityPoolAddress: action.payload.liquidityPoolAddress }
    });
  } catch (err) {
    yield put(updateIsDepositLoading(false));
    if (err.code === 4001) {
      yield put({ type: 'UPDATE_EARN_BUTTON', payload: false });
    }
    console.log(err);
  }
  yield put(updateIsDepositLoading(false));
}

function* withdrawLiquidity(action) {
  yield put(updateIsWithdrawLoading(true));;
  yield put(updateLoadingMessage('Transaction in progress. Please wait.'));
  try {
    const coinToken = yield select((state) => state.collateralToken);
    const lContract = liquidityPoolContract(action.payload.liquidityPoolAddress);
    const walletAddress = yield select((state) => state.user.address);
    const amount = convertAmountToString(action.payload.amount, coinToken.decimal);
    yield lContract.withdrawUsdt(walletAddress, amount);
    yield put({
      type: 'UPDATE_LIQUIDITY',
      payload: { liquidityPoolAddress: action.payload.liquidityPoolAddress }
    });
  } catch (err) {
    yield put(updateIsWithdrawLoading(false));
    console.log(err);
  }
  yield put(updateIsWithdrawLoading(false));
}

function* depositMtlx(action) {
  // yield put(updateIsLoading(true));;
  yield put(updateLoadingMessage('Transaction in progress. Please wait.'));
  try {
    let pId = action.payload.pId;
    let rewardAddress = '0xDe782AD88fCa3dE535bbE50c021c402ea88d90Bd';
    const coinToken = yield select((state) => state.collateralToken);
    const rewardDetails = yield select((state) => state.liquidityPool.mtlxRewardDetails);
    if (rewardDetails.length > 0) {
      const [{ pool_id, reward_contract_address }] = rewardDetails;
      console.log('pool', pool_id);
      pId = pool_id;
      rewardAddress = reward_contract_address;
    }
    const lContract = rewardPollContract(rewardAddress);
    const walletAddress = yield select((state) => state.user.address);
    const amount = convertAmountToString(action.payload.amount, coinToken.decimal);
    const ApproveTokenContract = positionTokenContract(action.payload.liquidityPoolAddress);
    yield ApproveTokenContract.approve(rewardAddress, amount, walletAddress);
    yield lContract.depositMtlx(pId, amount, walletAddress);

    const walletBallance = yield lContract.getMTLXBalance(pId, walletAddress);
    yield put(updateMtlxBallance(walletBallance));

    const liquidityPoolAddress = action.payload.liquidityPoolAddress;
    const LiquidityContract = liquidityPoolContract(liquidityPoolAddress);
    const mxUSDT = yield LiquidityContract.getUserBalance(walletAddress, coinToken.decimal);
    yield put(updatemxUSDTBalance({ mxUSDT, liquidityPoolAddress }));

    const stackBallance = yield lContract.getTotalStackBalance(
      pId,
      walletAddress,
      coinToken.decimal
    );
    yield put(updateStackMtllxBallance(stackBallance.amount));

    let walletAvailability = yield select((state) => state.user.walletAvailability);
    let networkID;
    if (!walletAvailability) {
      networkID = 97;
    } else {
      networkID = yield getNetworkId();
    }
    let mtlxBalance = 0;

    if (mtlxContract.address[networkID.toString()])
      mtlxBalance = yield userMtlxBalance(
        mtlxContract.address[networkID.toString()],
        walletAddress
      );

    yield put(updateBalance({ id: 'MTLX', value: mtlxBalance }));
    yield put(updateCurrentBalance({ id: 'MTLX', value: mtlxBalance }));
  } catch (err) {
    if (err.code === 4001) {
      // yield put({ type: 'UPDATE_EARN_BUTTON', payload: false })
    }
    console.log(err);
  }
  yield put(updateIsLoading(false));
}

function* withdrawMtlx(action) {
  // yield put(updateIsLoading(true));;
  yield put(updateLoadingMessage('Transaction in progress. Please wait.'));
  try {
    let pId = action.payload.pId;
    let rewardAddress = '0xDe782AD88fCa3dE535bbE50c021c402ea88d90Bd';
    const coinToken = yield select((state) => state.collateralToken);
    const walletAddress = yield select((state) => state.user.address);
    const rewardDetails = yield select((state) => state.liquidityPool.mtlxRewardDetails);
    if (rewardDetails.length) {
      const [{ pool_id, reward_contract_address }] = rewardDetails;
      pId = pool_id;
      rewardAddress = reward_contract_address;
    }
    const amount = convertAmountToString(action.payload.amount, coinToken.decimal);
    const lContract = rewardPollContract(rewardAddress);
    yield lContract.withdrawMtlx(pId, amount, walletAddress);
    const walletBallance = yield lContract.getMTLXBalance(pId, walletAddress);
    yield put(updateMtlxBallance(walletBallance));

    const stackBallance = yield lContract.getTotalStackBalance(
      pId,
      walletAddress,
      coinToken.decimal
    );
    yield put(updateStackMtllxBallance(stackBallance.amount));

    const liquidityPoolAddress = action.payload.liquidityPoolAddress;
    const LiquidityContract = liquidityPoolContract(liquidityPoolAddress);
    const mxUSDT = yield LiquidityContract.getUserBalance(walletAddress, coinToken.decimal);
    yield put(updatemxUSDTBalance({ mxUSDT, liquidityPoolAddress }));

    let walletAvailability = yield select((state) => state.user.walletAvailability);
    let networkID;
    if (!walletAvailability) {
      networkID = 97;
    } else {
      networkID = yield getNetworkId();
    }
    let mtlxBalance = 0;

    if (mtlxContract.address[networkID.toString()])
      mtlxBalance = yield userMtlxBalance(
        mtlxContract.address[networkID.toString()],
        walletAddress
      );

    yield put(updateBalance({ id: 'MTLX', value: mtlxBalance }));
    yield put(updateCurrentBalance({ id: 'MTLX', value: mtlxBalance }));

    yield put({
      type: 'UPDATE_LIQUIDITY',
      payload: { liquidityPoolAddress: action.payload.liquidityPoolAddress }
    });
  } catch (err) {
    console.log(err);
  }
  yield put(updateIsLoading(false));
}
function* claimMtlx(action) {
  // yield put(updateIsLoading(true));;
  yield put(updateLoadingMessage('Transaction in progress. Please wait.'));
  try {
    let pId = action.payload.pId;
    let rewardAddress = '0xDe782AD88fCa3dE535bbE50c021c402ea88d90Bd';

    let walletAvailability = yield select((state) => state.user.walletAvailability);
    let networkID;
    if (!walletAvailability) {
      networkID = 97;
    } else {
      networkID = yield getNetworkId();
    }
    let mtlxBalance = 0;
    const walletAddress = yield select((state) => state.user.address);

    if (mtlxContract.address[networkID.toString()])
      mtlxBalance = yield userMtlxBalance(
        mtlxContract.address[networkID.toString()],
        walletAddress
      );

    yield put(updateBalance({ id: 'MTLX', value: mtlxBalance }));
    yield put(updateCurrentBalance({ id: 'MTLX', value: mtlxBalance }));

    const rewardDetails = yield select((state) => state.liquidityPool.mtlxRewardDetails);
    if (rewardDetails.length) {
      const [{ pool_id, reward_contract_address }] = rewardDetails;
      pId = pool_id;
      rewardAddress = reward_contract_address;
    }
    const lContract = rewardPollContract(rewardAddress);
    const amount = 0;
    yield lContract.depositMtlx(pId, amount, walletAddress);
    const walletBallance = yield lContract.getMTLXBalance(pId, walletAddress);
    yield put(updateMtlxBallance(walletBallance));

    const stackBallance = yield lContract.getTotalStackBalance(
      pId,
      walletAddress,
      coinToken.decimal
    );
    yield put(updateStackMtllxBallance(stackBallance.amount));

    yield put({
      type: 'UPDATE_LIQUIDITY',
      payload: { liquidityPoolAddress: action.payload.liquidityPoolAddress }
    });
  } catch (err) {
    console.log(err);
  }
  yield put(updateIsLoading(false));
}

function* earnLiquidity(action) {
  // yield put(updateIsLoading(true));;
  yield put(updateLoadingMessage('Transaction in progress. Please wait.'));
  try {
    const lContract = liquidityPoolContract(action.payload.liquidityPoolAddress);
    const walletAddress = yield select((state) => state.user.address);
    yield lContract.earnLiquidity(walletAddress);
    yield put({
      type: 'UPDATE_LIQUIDITY',
      payload: { liquidityPoolAddress: action.payload.liquidityPoolAddress }
    });
  } catch (err) {
    if (err.code === 4001) {
      yield put({ type: 'UPDATE_EARN_BUTTON', payload: true });
    }
    console.log(err);
  }
  yield put(updateIsLoading(false));
}

function* gasEstimate(action) {
  // yield put(updateIsLoading(true));;
  // yield put(updateLoadingMessage("Fetching values. Please wait."));;
  try {
    let walletAvailability = yield select((state) => state.user.walletAvailability);
    let estimate = 0;
    if (walletAvailability) {
      const liquidityPoolAddress = action.payload.liquidityPoolAddress;
      const walletAddress = yield select((state) => state.user.address);
      const lContract = liquidityPoolContract(liquidityPoolAddress);
      estimate = yield lContract.getGasAmount(walletAddress);
    }
    yield put(updateGasEstimate(estimate));
  } catch (err) {
    console.log(err);
  }
  yield put(updateIsLoading(false));
}

export default liquidityProviderSaga;
