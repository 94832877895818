import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { truncateDecimals } from '../../utils';

const useStyles = makeStyles((theme) => ({
  flexBox: {
    display: 'flex'
  },
  cardTitle: {
    color: theme.text,
    fontSize: '12px',
    fontFamily: 'MontserratMedium',
    textTransform: 'uppercase',
    padding: '0 5px'
  },
  cardSubTitle: {
    color: theme.secondaryText,
    fontSize: '12px',
    fontFamily: 'MontserratMedium'
  },
  daysText: {
    color: '#D6D6D9'
  },
  active: {
    color: '#5643CC'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '10px'
  },
  contentText: {
    color: '#7C7C7D',
    fontSize: '14px'
  },

  incrementStyle: {
    color: '#2FD593',
    fontSize: '14px'
  },
  grid: {
    background: theme.dropdown.fadedBackgroundColor,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '9px 20px 10px',
    marginTop: 10,
    marginBottom: 10,
    borderRadius: '8px'
  }
}));

const LiquidityRewards = (props) => {
  const classes = useStyles();
  return (
    <CardContent style={{ marginTop: 10, display: 'flex', padding: 0 }}>
      <Grid item md={4} xs={12} style={{ padding: '0 8px' }}>
        <Typography className={classes.cardTitle}>
          P&L <span className={classes.cardSubTitle}>({props.coinTokenName})</span>
        </Typography>
        <div className={`${classes.grid}`}>
          <Typography className={classes.contentText}>Total P&L</Typography>
          <Typography
            className={classes.netValueText}
            style={props.balance < 0 ? { color: '#EE3737' } : { color: '#2FD593' }}
          >
            {' '}
            <NumberFormat
              decimalScale={2}
              value={props.balance || 0}
              displayType={'text'}
              thousandSeparator={true}
            />
          </Typography>
        </div>
      </Grid>

      <Grid item md={4} xs={12} style={{ padding: '0 8px' }}>
        <Typography className={classes.cardTitle}>
          Liquidity P&L <span className={classes.cardSubTitle}>({props.coinTokenName})</span>
        </Typography>
        <div className={`${classes.grid}`}>
          <Typography className={classes.contentText}>Earned</Typography>
          <Typography
            className={classes.incrementStyle}
            style={props.rewards < 0 ? { color: '#EE3737' } : { color: '#2FD593' }}
          >
            <NumberFormat decimalScale={2} value={props.rewards || 0} displayType={'text'} />
          </Typography>
        </div>
      </Grid>

      <Grid style={{ padding: '0 8px' }} item md={4} xs={12}>
        <Typography className={classes.cardTitle}>MTLX</Typography>
        <div className={`${classes.grid}`}>
          <Typography className={classes.contentText}>Earned</Typography>
          <Typography className={classes.incrementStyle}>
            {truncateDecimals(props.mtlxRewardBalances, 2)}
          </Typography>
        </div>
      </Grid>
    </CardContent>
  );
};

export default LiquidityRewards;
