import React from 'react';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '../../assets/arrow-down.svg';
import { connect } from 'react-redux';
import ListItemText from '@material-ui/core/ListItemText';
import ErrorIcon from '@material-ui/icons/Error';
import NestedMenuItem from 'material-ui-nested-menu-item';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Typography, Divider } from '@material-ui/core';
import GreenTick from '../../assets/green_tick.svg';
import HelpPopup from './helpPopup';
import { LightTooltip } from '../LightToolTip';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      color: theme.text,
      alignItems: 'center'
    },
    paper: {
      zIndex: 9999,
      backgroundColor: theme.dropdown.backgroundColor
    },
    drawer: {
      zIndex: 9999,
      transform: 'none !important',
      top: '40px !important'
      // left: "16px !important",
    },
    menu: {
      color: theme.text,
      fontWeight: '500',
      padding: 0,
      '& div': {
        '& .MuiMenuItem-root': {
          borderBottom: '1px solid #414658'
        }
      },
      '& div:last-child': {
        '& .MuiMenuItem-root:last-child': {
          borderBottom: 'none'
        }
      },
      '& .MuiList-root': {
        padding: 0
      },
      '& .MuiMenuItem-root': {
        padding: `8px 16px !important`,
        minWidth: 162
      },
      '& .placeholder': {
        padding: theme.spacing(1),
        backgroundColor: theme.dropdown.backgroundColor,
        color: theme.secondaryText
      }
    },
    menuItem: {
      padding: '8px 10px !important',
      backgroundColor: 'rgba(55, 56, 75, 1)',
      color: theme.secondaryText,
      minWidth: 162,
      borderBottom: '1px solid #414658',
      fontSize: 14,
      height: 40,
      '&:hover': {
        color: theme.text,
        backgroundColor: 'rgba(69, 76, 103, 1)'
      },
      '&:hover .MuiTypography-root': {
        color: `${theme.text} !important`
      },
      '& svg': {
        verticalAlign: 'middle'
      },
      '&:last-child': {
        borderBottom: 'none'
      },
      '& .listItem': {
        flex: 'none'
      },
      fontWeight: '500'
    },
    list: {
      padding: '6px 0',
      borderRadius: 0,
      color: theme.text,
      textTransform: 'none',
      fontSize: 16,
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '& .MuiTypography-root': {
        fontSize: 16
      }
    },
    heading: {
      color: 'grey'
    },
    listText: {
      textAlign: 'right'
    },
    typrography: {
      color: 'grey',
      fontWeight: '500',
      padding: '8px 0'
    },
    arrowDropDown: {
      marginLeft: 13
    },
    mtlxSymbol: {
      padding: '0 5px',
      background: 'white',
      color: theme.card.background,
      margin: '0px 5px',
      borderRadius: '6px',
      fontSize: '10px'
    }
  };
});

const MenuListComposition = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [menuPosition, setMenuPosition] = React.useState(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    if (menuPosition) {
      return;
    }
    setMenuPosition({});
  };

  const handleClose = (event, value) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (value) {
      props.handleSelected(value);
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const groupCommodities = (settled) => {
    let commoditiesGroup = {};
    let settledCommoditiesGroup = {};
    let symbolGroup = {};

    Object.values(props.commodities).map((commodity) => {
      symbolGroup = {};

      if (!commoditiesGroup[commodity.category]) commoditiesGroup[commodity.category] = [];

      if (!settledCommoditiesGroup[commodity.category])
        settledCommoditiesGroup[commodity.category] = [];

      symbolGroup = [];
      symbolGroup.push(commodity);
      if (commodity.is_settled) {
        settledCommoditiesGroup[commodity.category].push(symbolGroup);
      } else {
        commoditiesGroup[commodity.category].push(symbolGroup);
      }
      return 0;
    });
    if (settled) {
      return Object.assign(
        ...Object.entries(settledCommoditiesGroup)
          .sort()
          .map(([key, value]) => {
            return {
              [key]: value
            };
          })
      );
    } else {
      return Object.assign(
        ...Object.entries(commoditiesGroup)
          .sort()
          .map(([key, value]) => {
            return {
              [key]: value
            };
          })
      );
    }
  };

  const createMenuItem = (commodity) => {
    if (!commodity.id) {
      commodity = commodity[0];
    }
    return (
      <MenuItem
        key={commodity.id}
        onClick={(event) => handleClose(event, `${commodity.id}`)}
        className={classes.menuItem}
      >
        <ListItemText
          className="listItem"
          primary={`${commodity.commodity_symbol}:${commodity.commodity_name} V${commodity.version}`}
        />
        {commodity.id === props.commodities[props.selected].id && (
          <img style={{ marginLeft: 10, marginRight: 6 }} src={GreenTick} alt="" />
        )}
        {commodity.mtlx_rewards_available === true && (
          <ListItemText className="listItem" style={{ textAlign: 'right' }}>
            <div className={classes.mtlxSymbol}>MTLX</div>
          </ListItemText>
        )}
        {commodity.is_settled && (
          <ListItemText className="listItem" style={{ textAlign: 'right' }}>
            <ErrorIcon color="white" />
          </ListItemText>
        )}
      </MenuItem>
    );
  };

  const handleSettled = (commodityGroup) => {
    let commodityGroupList = [];

    commodityGroup.map((commodityObj) => {
      commodityGroupList.push(commodityObj);
      return null;
    });

    commodityGroupList.sort((a, b) => (a.commodity_symbol > b.commodity_symbol ? 1 : -1));
    return createNestedMenuItem(commodityGroupList);
  };

  const createNestedMenuItem = (commodityGroup, type) => {
    let commodity;
    let label;
    if (type) {
      commodity = commodityGroup[0];
      label = `${commodity.commodity_symbol} ${(
        <img style={{ marginLeft: 16 }} src={GreenTick} alt="" />
      )}`;
    } else {
      commodity = commodityGroup[0][0];
      label = commodity.category == null ? 'Other' : commodity.category;
    }
    commodityGroup.sort((a, b) => (a.commodity_symbol > b.commodity_symbol ? 1 : -1));
    let show_reward = commodityGroup.filter((c) => {
      return c[0].mtlx_rewards_available === true ? c : null;
    });
    if (commodity)
      return (
        <NestedMenuItem
          key={commodity.commodity_symbol}
          rightIcon={
            <>
              <ListItemText style={{}}>
                {commodity.category === props.commodities[props.selected].category && (
                  <img style={{ marginLeft: 10 }} src={GreenTick} alt="" />
                )}
              </ListItemText>
              {
                // to show outer list
              }
              {show_reward.length > 0 ? (
                <span className={classes.mtlxSymbol} style={{ margin: '0px 15px' }}>
                  MTLX
                </span>
              ) : null}
              <ListItemText
                style={{ textAlign: 'right', position: 'absolute', right: 16, marginTop: '2px' }}
              >
                <ArrowForwardIosIcon color="white" style={{ fontSize: 10 }} />
              </ListItemText>
            </>
          }
          label={label}
          parentMenuOpen={!!menuPosition}
          className={classes.menuItem}
        >
          {commodityGroup.map((commodityObj) => {
            if (commodityObj.length > 1) {
              return createNestedMenuItem(commodityObj, 'symbol-nested');
            } else {
              return createMenuItem(commodityObj);
            }
          })}
        </NestedMenuItem>
      );
    return '';
  };

  return (
    <Box className={`${classes.root} list-items`} key={props.commodities[props.selected]}>
      {props.selected && props.commodities[props.selected] ? (
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.list}
        >
          <>
            <ListItemText
              primary={`${props.commodities[props.selected].commodity_symbol}:${
                props.commodities[props.selected].commodity_name
              } V${props.commodities[props.selected].version} `}
            />
            {props.commodities[props.selected].is_settled && (
              <ListItemText style={{ height: 24 }} className={classes.listText}>
                <LightTooltip title="It’s a settled commodity" placement="bottom">
                  <ErrorIcon style={{ marginLeft: 10 }} color="white" />
                </LightTooltip>
                <p></p>
              </ListItemText>
            )}
            {
              // to show selected commodity
              props.commodities[props.selected].mtlx_rewards_available === true && (
                <ListItemText className="listItem" style={{ textAlign: 'center' }}>
                  <LightTooltip title="MTLX rewards active for this commodity" placement="bottom">
                    <span className={classes.mtlxSymbol}>MTLX</span>
                  </LightTooltip>
                </ListItemText>
              )
            }
            <img src={ArrowDropDownIcon} alt="drop-down-arrow" className={classes.arrowDropDown} />
          </>
        </Button>
      ) : (
        <Button className={classes.list}>
          <Typography style={{ fontWeight: 'bold' }}>Fetching Commodities...</Typography>
        </Button>
      )}

      {props.selected && props.commodities[props.selected] && (
        <HelpPopup
          commodityName={`${props.commodities[props.selected].commodity_symbol}:${
            props.commodities[props.selected].commodity_name
          } V${props.commodities[props.selected].version} `}
          commodityDescription={props.commodities[props.selected].commodity_info}
          commodityExchange={props.commodities[props.selected].exchange}
          commodityFloor={props.commodities[props.selected].floor}
          commodityCap={props.commodities[props.selected].cap}
        />
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 999 }}
        className={classes.drawer}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper elevation={3} className={classes.paper}>
              <div onMouseLeave={handleClose}>
                <MenuList
                  open={open}
                  onClose={handleClose}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  className={classes.menu}
                >
                  {props.filter.active && (
                    <>
                      {Object.values(groupCommodities()).map((commodityGroup) => {
                        if (commodityGroup.length) {
                          return handleSettled(commodityGroup);
                        }
                        return null;
                      })}
                    </>
                  )}
                  {props.filter.settled && (
                    <>
                      <Divider></Divider>
                      {Object.values(groupCommodities(true)).map((settledCommoditiesGroup) => {
                        if (settledCommoditiesGroup.length) {
                          return handleSettled(settledCommoditiesGroup);
                        }
                        return null;
                      })}
                    </>
                  )}
                </MenuList>
              </div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  commodities: state.commodities,
  filter: state.user.filter
});

export default connect(mapStateToProps)(MenuListComposition);
