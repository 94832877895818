import React, { useState, useEffect } from 'react';
import { makeStyles, Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import LaunchIcon from '@material-ui/icons/Launch';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => {
  return {
    feedbackWrapper: {
      zIndex: 1000,
      position: 'relative',
      background: '#2d2e3d',
      fontWeight: 'bold',
      textAlign: 'center',
      padding: 6,
      display: 'flex',
      alignItems: 'center',
      maxWidth: '100%',
      '&[animate="1"]': {
        animation: 'fadeOut 1s 2'
      }
    },
    closeIco: {
      textAlign: 'right',
      color: '#ffffff',
      float: 'right',
      fontSize: '1.3rem',
      cursor: 'pointer',
      minWidth: 'auto',
      padding: 0
    },
    typography: {
      display: 'inline-flex',
      alignItems: 'center',
      fontFamily: 'MontserratMedium',
      justifyContent: 'center',
      fontSize: '0.8rem',
      color: '#dcdcde',
      padding: 2,
      '&:hover': {
        textDecoration: 'none'
      }
    },
    feedbackLink: {
      lineHeight: '1rem',
      marginLeft: 5,
      '&:hover': {
        textDecoration: 'none'
      }
    },
    iconSize: {
      fontSize: '1.2rem',
      color: '#68dbda',
      paddingLeft: 5
    }
  };
});

const UserFeedback = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const visible = localStorage.getItem('formModal');
  const [animate, setAnimate] = useState(0);

  const handleClose = () => {
    setAnimate(1);
    setTimeout(() => setOpen(false), 500);
  };
  useEffect(() => {
    if (!visible) {
      localStorage.setItem('formModal', 1);
      setOpen(true);
    }
  }, [visible]);
  return (
    <div>
      {open ? (
        <Container
          maxWidth="lg"
          animate={animate}
          className={`${classes.container} ${classes.feedbackWrapper}`}>
          <Grid item xs={12}>
            <Link className={classes.feedbackLink} target="_blank" href="https://mettalex.com/blog/the-mettalex-dex-is-moving-to-fetch-ai-network/">
              <Typography className={classes.typography}>
                The Mettalex DEX is moving to{' '}
                <Box color="#68DBD8" display="contents">
                  Fetch.ai
                </Box>{' '}
                Network. This UI is now in demo mode and no longer in use for trading. MTLX LP
                rewards for all markets will be turned off on June 30, 2022. The BNB Smart Chain
                mainnet version of the DEX will become inaccessible on August 31, 2022.{' '}
                <LaunchIcon className={classes.iconSize} />
              </Typography>
            </Link>
            <Button onClick={handleClose} className={classes.closeIco}>
              <CloseIcon />
            </Button>
          </Grid>
        </Container>
      ) : null}
    </div>
  );
};

export default UserFeedback;
