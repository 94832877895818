import { select, takeEvery, put } from 'redux-saga/effects';
import {
  updatePoolAddress,
  updatePoolBalance,
  updatePoolPrice,
  updatePoolLpf
} from '../reducer/balancerPool';
import balancerPoolContract from '../contractInterface/balancerPoolContract';
import { basicAuth, fetchbpoolURL } from '../constant';
import strategyBalancerContract from '../contractInterface/strategyBalancerContract';
import { getNetworkId } from '../utils';
import {
  updateBalancerPoolBalance,
  updateBalancerPoolLpf,
  updateBalancerPoolPrice
} from '../reducer/currentCommodity';
import {
  updateWalletBalances,
  updateBalPool,
  updateTradeWalletBalancesLoading
} from '../reducer/tradePageReducer';

export function* getPoolSpotPrices(action) {
  const commodityId = action.payload.id;
  const decimal = action.payload.decimal;
  const longTokenAddress = action.payload.long_address;
  const shortTokenAddress = action.payload.short_address;
  const coinToken = yield select((state) => state.collateralToken);
  const balancerPoolAddress = action.payload.balancer_pool_address;
  let prices = {};
  prices['id'] = commodityId;
  const strategyContract = strategyBalancerContract(action.payload.strategy_address);
  const bPoolContract = yield balancerPoolContract(balancerPoolAddress);
  try {
    let tokenIsBound = yield bPoolContract.isBound(longTokenAddress);
    if (tokenIsBound) {
      const longTokenPriceSell = yield strategyContract.getOutputConversion(
        longTokenAddress,
        coinToken.address,
        1
      );
      const longTokenPriceBuy = yield strategyContract.getInputConversion(
        coinToken.address,
        longTokenAddress,
        1
      );

      prices[longTokenAddress] = parseFloat(
        (longTokenPriceSell[0] + longTokenPriceBuy[0]) / 2
      ).toFixed(decimal);
    }
    tokenIsBound = yield bPoolContract.isBound(shortTokenAddress);
    if (tokenIsBound) {
      const shortTokenPriceSell = yield strategyContract.getOutputConversion(
        shortTokenAddress,
        coinToken.address,
        1
      );
      const shortTokenPriceBuy = yield strategyContract.getInputConversion(
        coinToken.address,
        shortTokenAddress,
        1
      );
      prices[shortTokenAddress] = parseFloat(
        (shortTokenPriceSell[0] + shortTokenPriceBuy[0]) / 2
      ).toFixed(decimal);
    }
  } catch (err) {
    console.log(err);
  }
  yield put(updateBalancerPoolPrice(prices));
  yield put(updatePoolPrice(prices));
}

function* getPoolBalances(action) {
  const commodityId = action.payload.id;
  const decimal = action.payload.decimal;
  const longTokenAddress = action.payload.long_address;
  const shortTokenAddress = action.payload.short_address;
  const balancerPoolAddress = action.payload.balancer_pool_address;
  const coinToken = yield select((state) => state.collateralToken);
  const contract = yield balancerPoolContract(balancerPoolAddress);
  let balances = {};
  balances['id'] = commodityId;
  try {
    let tokenIsBound = yield contract.isBound(longTokenAddress);
    if (tokenIsBound) {
      const longTokenBalance = yield contract.getBalance(longTokenAddress, decimal);
      balances[longTokenAddress] = longTokenBalance ? longTokenBalance : 0;
    }
    tokenIsBound = yield contract.isBound(shortTokenAddress);
    if (tokenIsBound) {
      const shortTokenBalance = yield contract.getBalance(shortTokenAddress, decimal);
      balances[shortTokenAddress] = shortTokenBalance ? shortTokenBalance : 0;
    }
    tokenIsBound = yield contract.isBound(coinToken.address);
    if (tokenIsBound) {
      const coinTokenBalance = yield contract.getBalance(coinToken.address, coinToken.decimal);
      balances[coinToken.address] = coinTokenBalance ? coinTokenBalance : 0;
    }
  } catch (error) {
    console.log(commodityId, error);
  }
  yield put(updateBalancerPoolBalance(balances));
  yield put(updatePoolBalance(balances));
}

function* getPoolAddress(action) {
  try {
    const commodityId = action.payload.id;
    yield put(
      updatePoolAddress({ id: commodityId, address: action.payload.balancer_pool_address })
    );
  } catch (error) {
    console.log(error);
  }
}

function* getPoolDetails(action) {
  try {
    yield put(updateTradeWalletBalancesLoading({ id: action.payload.id, value: true }));
    let walletAvailability = yield select((state) => state.user.walletAvailability);
    let networkID;
    if (!walletAvailability) {
      networkID = 97;
    } else {
      networkID = yield getNetworkId();
    }
    const commodityId = action.payload.id;
    const decimal = action.payload.decimal;
    const longTokenAddress = action.payload.long_address;
    const shortTokenAddress = action.payload.short_address;
    const balancerPoolAddress = action.payload.balancer_pool_address;
    const strategyAddress = action.payload.strategy_address;

    const coinToken = yield select((state) => state.collateralToken);

    let { lpf, balance, price } = yield fetchBpool(
      networkID,
      decimal,
      coinToken.decimal,
      balancerPoolAddress,
      longTokenAddress,
      shortTokenAddress,
      coinToken.address,
      strategyAddress
    );

    if (balance) {
      balance['id'] = commodityId;
      yield put(updateBalancerPoolBalance(balance));
      yield put(updatePoolBalance(balance));
    }
    if (price) {
      price['id'] = commodityId;
      yield put(updateBalancerPoolPrice(price));
      yield put(updatePoolPrice(price));
    }
    yield put(updateBalancerPoolLpf({ id: commodityId, lpf: lpf }));
    yield put(updatePoolLpf({ id: commodityId, lpf: lpf }));
    const currentCommodity = yield select((state) => state.currentCommodity);
    const userBalance = yield select((state) => state.user.balances);
    let WalletBalances = yield draftWalletBalance(currentCommodity, coinToken, userBalance);
    yield put(updateWalletBalances(WalletBalances));
    let BalPool = yield draftBalPool(currentCommodity, coinToken);
    yield put(updateBalPool(BalPool));

    yield put(updateTradeWalletBalancesLoading({ id: commodityId, value: false }));
  } catch (err) {
    yield put(updateTradeWalletBalancesLoading({ id: action.payload.id, value: false }));
    console.log(err);
  }
}

function* initPoolDetails(action) {
  yield put({ type: 'GET_POOL_ADDRESS', payload: action.payload });
  try {
    let walletAvailability = yield select((state) => state.user.walletAvailability);
    let networkID;
    if (!walletAvailability) {
      networkID = 97;
    } else {
      networkID = yield getNetworkId();
    }
    const commodityId = action.payload.id;
    const decimal = action.payload.decimal;
    const longTokenAddress = action.payload.long_address;
    const shortTokenAddress = action.payload.short_address;
    const balancerPoolAddress = action.payload.balancer_pool_address;
    const strategyAddress = action.payload.strategy_address;
    const coinToken = yield select((state) => state.collateralToken);

    let { lpf, balance, price } = yield fetchBpool(
      networkID,
      decimal,
      coinToken.decimal,
      balancerPoolAddress,
      longTokenAddress,
      shortTokenAddress,
      coinToken.address,
      strategyAddress
    );

    if (balance) {
      balance['id'] = commodityId;
      yield put(updatePoolBalance(balance));
    }
    if (price) {
      price['id'] = commodityId;
      yield put(updatePoolPrice(price));
    }
    yield put(updatePoolLpf({ id: commodityId, lpf: lpf }));
  } catch (err) {
    console.log(err);
  }
}

export const fetchBpool = async (
  networkId,
  decimal,
  usdtDecimal,
  bpoolAddress,
  longAddress,
  shortAddress,
  usdtAddress,
  strategyAddress
) => {
  const response = await fetch(fetchbpoolURL, {
    method: 'POST',
    headers: new Headers({
      Authorization: basicAuth,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      bpool_address: bpoolAddress,
      long_token_address: longAddress,
      short_token_address: shortAddress,
      usdt_token_address: usdtAddress,
      strategy_contract_address: strategyAddress,
      network_id: networkId,
      decimal: decimal,
      usdt_decimal: usdtDecimal
    })
  });
  const result = await response.text();
  if (response.status !== 200) throw response.status;
  const data = JSON.parse(result);
  return data;
};

const draftWalletBalance = (data, collateralToken, userBalance) => {
  let bal = {
    loader: false,
    USDT: parseFloat(data.balances.USDT).toFixed(collateralToken.decimal),
    MTLX: data.balances.MTLX,
    long: [
      data.metadata.long_token_contract_address,
      data.metadata.long_symbol,
      userBalance[data.metadata.long_token_contract_address]
    ],
    short: [
      data.metadata.short_token_contract_address,
      data.metadata.short_symbol,
      userBalance[data.metadata.short_token_contract_address]
    ]
  };
  return bal;
};

const draftBalPool = (data, collateralToken) => {
  let bal = {
    loader: false,
    longVolume: data.balancerPool.balance[data.metadata.long_token_contract_address],
    shortVolume: data.balancerPool.balance[data.metadata.short_token_contract_address],
    coinVolume: data.balancerPool.balance[collateralToken.address],
    longSpotPrice: data.balancerPool.price[data.metadata.long_token_contract_address],
    shortSpotPrice: data.balancerPool.price[data.metadata.short_token_contract_address]
  };
  return bal;
};

function* BalancerPoolSaga() {
  yield takeEvery('GET_POOL_BALANCES', getPoolBalances);
  yield takeEvery('GET_POOL_SPOT_PRICES', getPoolSpotPrices);
  yield takeEvery('GET_POOL_ADDRESS', getPoolAddress);
  // yield takeEvery("GET_LPF", getPoolLpf)
  yield takeEvery('GET_POOL_DETAILS', getPoolDetails);
  yield takeEvery('INIT_POOL_DETAILS', initPoolDetails);
}

export default BalancerPoolSaga;
