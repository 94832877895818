import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'swapStats',
  initialState: {},
  reducers: {
    setData(state, action) {
      state[action.payload.id] = action.payload.data;
    }
  }
});

const { actions, reducer } = configSlice;

export const { setData } = actions;

export default reducer;
