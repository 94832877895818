import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'collateralToken',
  initialState: {
    version: 1,
    commodityIndex: 0,
    isCoinToken: true
  },
  reducers: {
    startUpdateAccount() {},
    updatecollateralToken(state, action) {
      state.address = action.payload.token_address;
      state.decimal = action.payload.decimal;
      state.name = action.payload.token_name;
      state.token = action.payload.token_name;
      state.network_id = action.payload.network_id;
      state.isRealToken = action.payload.is_real;
    }
  }
});

const { actions, reducer } = configSlice;

export const { updatecollateralToken } = actions;

export default reducer;
