import React from 'react';
import { Button, makeStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
    // gridGap: "8px",
    textAlign: 'right',
    marginTop: 15,
    '@media (min-width:1441px)': {
      // eslint-disable-next-line
      marginTop: 4
    }
  },
  button: {
    marginTop: 4,
    fontSize: '12px',
    fontFamily: 'MontserratMedium',
    marginLeft: 'auto',
    borderRadius: '0.25rem',
    minWidth: '-webkit-fill-available',
    // width: "auto",
    // padding: "7px 5px",
    paddingTop: '4px',
    paddingBottom: '4px',
    background: theme.card.background,
    border: theme.borderAlt,
    color: theme.text,
    '&:hover': {
      background: theme.grid.color,
      border: theme.grid.border,
      color: 'black'
    },
    '&.MuiButton-root.Mui-disabled': {
      background: '#585d6e',
      border: theme.borderAlt,
      color: 'black'
    },
    '@media (max-width:1440px)': {
      // eslint-disable-next-line
      padding: '2px'
    }
  },
  space: {
    marginRight: '4px'
  }
}));

const Buttons = (props) => {
  const classes = useStyles();
  return (
    <Grid item xs={6} md={4} className={classes.grid}>
      <Grid item xs={12} className={classes.space}>
        <Button
          disabled={props.disabled}
          className={classes.button}
          size="small"
          color="primary"
          onClick={() => props.handleClick(25)}
        >
          25%
        </Button>
        <Button
          disabled={props.disabled}
          className={classes.button}
          size="small"
          color="primary"
          onClick={() => props.handleClick(75)}
        >
          75%
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          disabled={props.disabled}
          className={classes.button}
          size="small"
          color="primary"
          onClick={() => props.handleClick(50)}
        >
          50%
        </Button>
        <Button
          disabled={props.disabled}
          className={classes.button}
          size="small"
          color="primary"
          onClick={() => props.handleClick(100)}
        >
          100%
        </Button>
      </Grid>
    </Grid>
  );
};

export default Buttons;
