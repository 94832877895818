import React, { useEffect } from 'react';
import { makeStyles, Grid, Button, CardContent, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import MarketsChart from './MarketsCountGraph';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TradingGraph from './TradingGraph';
import SwapVerticalCircleRoundedIcon from '@material-ui/icons/SwapVerticalCircleRounded';
import DirectionsRoundedIcon from '@material-ui/icons/DirectionsRounded';
import ApyAndMtlxCard from '../../component/ApyAndMtlxCard';
import { LightTooltip } from '../../component/LightToolTip';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  typography: {
    fontSize: 18,
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.5)
  },
  bar: {
    color: theme.text,
    background: '#2d2e3d',
    display: 'flex',
    padding: '12px',
    width: '100%',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  mintRedeemView: {
    marginTop: theme.spacing(2)
  },
  cardContent: {
    padding: '8px 0 0 0',
    fontSize: '14px'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: '8px 0',
    color: theme.text,
    '& .MuiTypography-root': {
      fontFamily: `"MontserratMedium"`
    }
  },
  redirectButton: {
    color: '#68dbda',
    fontFamily: `"MontserratMedium"`,
    textTransform: 'none',
    fontSize: 13,
    background: 'transparent',
    padding: '8px',
    borderRadius: '.25rem',
    border: '1px solid #68dbda',
    '&:hover': {
      backgroundColor: '#68dbda!important',
      color: '#222330'
    }
  },
  subHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: '8px 0',
    color: theme.text
  },
  dotted: {
    display: 'flex',
    background: theme.card.background,
    padding: '0 20px',
    marginBottom: 10
  },
  alignAPYContent: {
    alignSelf: 'start'
  },
  card: {
    padding: '4px 5px',
    background: theme.card.background,
    boxShadow: theme.card.boxShadow,
    // borderRadius: "5px",
    opacity: 1,
    height: 307
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  grid: {
    textAlign: 'end',
    color: theme.text
  },
  background: {
    backgroundColor: theme.backgroundColor
  },
  secondaryText: {
    color: theme.secondaryText,
    margin: '10px 0',
    fontSize: '12px'
  },
  primaryText: {
    margin: '10px 0'
  },
  statsContainer: {
    display: 'flex',
    marginTop: '1%',
    '@media (min-width:768px) and (max-width:991px)': {
      display: 'inline-block'
    }
  },
  liquidityCardContent: {
    flex: '42%',
    '& p': {
      margin: '2px 0'
    },
    '& .MuiGrid-root': {
      textAlign: 'left'
    },
    marginRight: '10px'
  },
  countCardContent: {
    flex: '25%',
    '@media (min-width:768px) and (max-width:991px)': {
      width: '49.5%',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    '& p': {
      margin: '2px 0'
    },
    '& .MuiGrid-root': {
      textAlign: 'left'
    },
    marginRight: '10px'
  },
  valueCardContent: {
    flex: '25%',
    '@media (min-width:768px) and (max-width:991px)': {
      width: '49%',
      display: 'inline-block',
      verticalAlign: 'top',
      marginRight: 0
    },
    '& p': {
      margin: '2px 0'
    },
    '& .MuiGrid-root': {
      textAlign: 'left'
    },
    marginRight: '10px'
  },
  commDetailCardContent: {
    flex: '25%',
    '@media (min-width:768px) and (max-width:991px)': {
      width: '49.5%',
      display: 'inline-block',
      verticalAlign: 'top',
      padding: 0
    },
    '& p': {
      margin: '2px 0'
    },
    '& .MuiGrid-root': {
      textAlign: 'left'
    },
    marginRight: '10px'
  },
  liquidityDotted: {
    justifyContent: 'space-between',
    placeItems: 'center',
    padding: '12px 20px',
    height: '72px'
  },
  positionButton: {
    justifyContent: 'space-around'
  },
  progressContainer: {
    textAlign: 'center',
    flex: '25%',
    background: theme.card.background,
    marginTop: '8px',
    height: 155,
    position: 'relative',
    boxShadow: '0px 0px 10px 6px #0e0e15',
    padding: 20,
    '@media (min-width:768px) and (max-width:991px)': {
      width: '49%',
      display: 'inline-block',
      verticalAlign: 'top',
      marginTop: 0
    }
  },
  mtlxSymbol: {
    padding: '0 5px',
    marginLeft: '5px',
    background: 'white',
    color: theme.card.background,
    margin: '0px 5px',
    borderRadius: '6px',
    fontSize: '10px'
  },
  item: {
    textAlign: 'left',
    position: 'relative',
    padding: '0 5px 0 5px',
    '&:not(:last-child):after': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      width: 1,
      background: '#585862',
      height: '100%'
    }
  },
  itemGrid: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.text,
    marginTop: '5%'
  },
  itemGreyText: {
    fontSize: 12,
    color: theme.secondaryText
  },
  tooltipFont: {
    fontSize: '14px'
  },
  '@media (min-width:1024px) and (max-width:1400px)': {
    liquidityDotted: {
      padding: '12px 12px'
    },
    cardContent: {
      fontSize: 12
    },
    redirectButton: {
      fontSize: 12,
      padding: 4
    },
    icnSize: {
      fontSize: '1.25rem'
    }
  },
  marketChart: {
    paddingRight: 5,
    '@media (min-width:768px) and (max-width:991px)': {
      paddingRight: 0
    }
  },
  tradingGraph: {
    paddingLeft: 5,
    '@media (min-width:768px) and (max-width:991px)': {
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: 10
    }
  }
}));

const Dashboard = ({ handleSelected, selected, ...props }) => {
  const classes = useStyles();
  const [totalSupply, setTotalSupply] = React.useState(0);
  const [EarningPercentage, setEarningPercentage] = React.useState(0);
  const [MtlxValue, setMtlxValue] = React.useState(0);
  const [OverallEarning, setOverallEarning] = React.useState(0);
  const [openPositionValue, setOpenPositionValue] = React.useState(0);
  const [poolTVL, setPoolTVL] = React.useState(0);

  useEffect(() => {
    if (
      Object.values(props.commodityIsLoading).some((obj) => {
        return obj;
      })
    )
      return;
    let totalSupplyUSDT = 0;
    // let totalSupplymxUSDT = 0
    var keys = Object.keys(props.liquidityPool);
    for (var i = 0; i < keys.length; i++) {
      if (keys[i] === 'gasEstimate') continue;
      var value = props.liquidityPool[keys[i]];
      if (value && value.totalUSDT) {
        totalSupplyUSDT += value.totalUSDT;
      }
    }
    setTotalSupply(totalSupplyUSDT.toFixed(1));

    keys = Object.keys(props.commodities);
    let poolTVL = 0;
    let openPositionValue = 0;

    let apy = 0;
    let mtlxRewardCount = 0;
    for (let i = 0; i < keys.length; i++) {
      const id = keys[i];
      if (props.commodities[id].commodity_name && !props.commodities[id].is_settled) {
        mtlxRewardCount += props.commodities[id].daily_drip_rate;
        const liquidity = props.commodities[id].liquidity_pool_address;
        const collateralPerUnit =
          props.commodities[id].network_id === '97'
            ? props.commodities[id].collateral_per_unit / 10 ** 3
            : props.commodities[id].collateral_per_unit / 10 ** props.commodities[id].decimal;
        const longToken = props.commodities[id].long_token_contract_address;
        const shortToken = props.commodities[id].short_token_contract_address;
        const conversionRate = props.liquidityPool[liquidity]
          ? props.liquidityPool[liquidity].conversionRate
          : 1;

        let similarPoolAdd =
          props.commodities &&
          Object.values(props.commodities).filter(
            (obj) => props.commodities[id].balancer_pool_address === obj.balancer_pool_address
          );
        let createdAtDate = similarPoolAdd.map((value) => new Date(value.created_at));
        var minDateForPoolAdd = createdAtDate.reduce(function (a, b) {
          return a < b ? a : b;
        });

        const createdAt = minDateForPoolAdd;
        const DifferenceInTime = new Date().getTime() - new Date(createdAt).getTime();
        var DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
        if (DifferenceInDays < 1) DifferenceInDays = 1;
        let percentage = 0;
        if (conversionRate)
          percentage = ((conversionRate - 1) * (365.25 / DifferenceInDays) * 100).toFixed(2);
        let data = props.liquidityPool[liquidity] ? (percentage !== 0 ? percentage : 0) : 0;
        apy = apy + parseFloat(data);
        poolTVL = poolTVL + props.liquidityPool[liquidity].totalUSDT;
        openPositionValue =
          openPositionValue +
          collateralPerUnit *
            (props.balancerPool[id].balance
              ? props.balancerPool[id].balance[longToken] +
                props.balancerPool[id].balance[shortToken]
              : 0);
      }
    }
    if (apy) setEarningPercentage(apy / keys.length);

    setPoolTVL(poolTVL);
    setOpenPositionValue(openPositionValue);
    setMtlxValue(mtlxRewardCount);
    setOverallEarning((totalSupplyUSDT * apy) / keys.length / 100);
    // eslint-disable-next-line
  }, [props.commodityIsLoading, props.liquidityPool, props.commodities]);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item md={12} sm={12}>
          <Grid>
            <div className={classes.statsContainer}>
              <CardContent className={`${classes.cardContent} ${classes.countCardContent}`}>
                <Grid className={`${classes.dotted} ${classes.liquidityDotted} boxDesign`}>
                  <Grid>
                    <Typography className={classes.secondaryText}>
                      Commodities Markets Available
                    </Typography>
                  </Grid>
                  <Grid className={classes.grid}>
                    <Typography className={classes.itemWhiteText}>
                      <NumberFormat
                        value={
                          Object.keys(
                            Object.values(props.commodities).filter(
                              (value) =>
                                !value.is_spread &&
                                value.is_settled === (props.filter.settled ? true : null)
                            )
                          ).length
                        }
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </Typography>
                  </Grid>
                </Grid>

                <Grid className={`${classes.dotted} ${classes.liquidityDotted} boxDesign`}>
                  <Grid>
                    <Typography className={classes.secondaryText}>
                      Spread Markets Available
                    </Typography>
                  </Grid>
                  <Grid className={classes.grid}>
                    <Typography className={classes.itemWhiteText}>
                      <NumberFormat
                        value={
                          Object.keys(
                            Object.values(props.commodities).filter(
                              (value) =>
                                value.is_spread &&
                                value.is_settled === (props.filter.settled ? true : null)
                            )
                          ).length
                        }
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent className={`${classes.cardContent} ${classes.valueCardContent}`}>
                <Grid className={`${classes.dotted} ${classes.liquidityDotted} boxDesign`}>
                  <Grid>
                    <Typography className={classes.secondaryText}>Total Value Locked</Typography>
                  </Grid>
                  <Grid className={classes.grid}>
                    <Typography className={classes.itemWhiteText}>
                      <NumberFormat
                        value={totalSupply}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className={`${classes.dotted} ${classes.liquidityDotted} boxDesign`}>
                  <Grid>
                    <Typography className={classes.secondaryText}>
                      Current Annual Yield <br />
                      (Fees Only)
                    </Typography>
                  </Grid>
                  <Grid className={classes.grid}>
                    <Typography className={classes.itemWhiteText}>
                      {OverallEarning === 0 ? (
                        <NumberFormat
                          value={OverallEarning}
                          displayType={'text'}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={'$'}
                        />
                      ) : (
                        <NumberFormat
                          value={OverallEarning}
                          displayType={'text'}
                          thousandSeparator={true}
                          decimalScale={2}
                          prefix={'$'}
                        />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent className={`${classes.cardContent} ${classes.commDetailCardContent}`}>
                <Grid className={`${classes.dotted} ${classes.liquidityDotted} boxDesign`}>
                  <Grid>
                    <Typography className={classes.secondaryText}>
                      Currently Selected Market
                    </Typography>
                  </Grid>
                  <Grid className={classes.grid}>
                    <LightTooltip
                      title={
                        props.commodities[selected]
                          ? props.commodities[selected].commodity_info
                          : ''
                      }
                      classes={{ tooltip: classes.tooltipFont }}
                    >
                      <Typography className={classes.itemWhiteText}>
                        {props.commodities[selected]
                          ? `${props.commodities[selected].commodity_name} V${props.commodities[selected].version}`
                          : null}
                        {props.commodities[selected] &&
                        props.commodities[selected]?.mtlx_rewards_available === true ? (
                          <span className={classes.mtlxSymbol}>MTLX</span>
                        ) : (
                          <></>
                        )}
                      </Typography>
                    </LightTooltip>
                  </Grid>
                </Grid>
                <Grid
                  className={`${classes.dotted} ${classes.liquidityDotted} ${classes.positionButton} boxDesign`}
                  style={{ padding: '12px 10px' }}
                >
                  <Grid>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => props.history.push('/trade')}
                      className={classes.redirectButton}
                    >
                      <SwapVerticalCircleRoundedIcon
                        className={classes.icnSize}
                        style={{ marginRight: 5 }}
                      />{' '}
                      Trade Now
                    </Button>
                  </Grid>
                  <Grid className={classes.grid}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => props.history.push('/liquidity')}
                      className={classes.redirectButton}
                    >
                      <DirectionsRoundedIcon
                        className={classes.icnSize}
                        style={{ marginRight: 3 }}
                      />{' '}
                      Supply Liquidity
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>

              <ApyAndMtlxCard
                page="dashboard"
                boxStyles={classes.progressContainer}
                dottedStyles={classes.dotted}
                alignContent={classes.alignAPYContent}
                apyValue={`${parseFloat(EarningPercentage).toFixed(2) > 0 ? '+' : ''}${parseFloat(
                  EarningPercentage
                ).toFixed(2)}%`}
                MtlxValue={MtlxValue.toFixed(2)}
                poolTVL={poolTVL}
                openPositionValue={openPositionValue}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item md={6} sm={12} className={classes.marketChart}>
          <MarketsChart
            liquidityPool={props.liquidityPool}
            commodities={props.commodities}
            history={props.history}
            handleSelected={handleSelected}
            selected={props.filter}
          />
        </Grid>

        <Grid item md={6} sm={12} className={classes.tradingGraph}>
          <Grid>
            <Grid className={`${classes.card} boxDesign`}>
              <TradingGraph
                commodity={props.commodities[selected] ? props.commodities[selected] : null}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  commodityIsLoading: state.user.commodityIsLoading,
  commodities: state.commodities,
  liquidityPool: state.liquidityPool,
  filter: state.user.filter,
  loadingMessage: state.user.loadingMessage,
  balancerPool: state.balancerPool
});

export default withRouter(connect(mapStateToProps, null)(Dashboard));
