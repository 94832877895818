import React, { forwardRef } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { NavLink } from 'react-router-dom';

const AppMenuItemComponent = (props) => {
  const { className, onClick, link, children } = props;

  if (!link || typeof link !== 'string') {
    return <ListItem button className={className} children={children} onClick={onClick} />;
  }

  return (
    <ListItem
      button
      className={className}
      children={children}
      component={forwardRef((props, ref) => (
        <NavLink
          exact
          {...props}
          innerRef={ref}
          isActive={(match, location) => {
            if (
              location.pathname === props.to ||
              (window.location.pathname === '/' && link === '/dashboard')
            ) {
              return true;
            }
          }}
        />
      ))}
      to={link}
    />
  );
};

export default AppMenuItemComponent;
