import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { uniqueId } from '../../utils';
import LoaderOverlay from '../../component/Loader';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.text,
    display: 'flex'
  },
  marginTB: {
    margin: '8px 0px 10px 0px',
    '@media (min-width:768px) and (max-width:991px)': {
      margin: '8px 0px 8px 0px'
    }
  },
  grid: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.text,
    fontFamily: 'MontserratLight',
    background: theme.dropdown.fadedBackgroundColor,
    padding: '10px 12px 9px',
    borderBottom: theme.border,
    '&:last-child': {
      borderBottom: 'none',
      padding: '10px 12px'
    }
  },
  slider: {
    width: '90%',
    marginLeft: '5%',
    marginBottom: '3.2%'
  },
  floorTypography: {
    width: '20%',
    marginTop: '2%',
    color: theme.text,
    fontSize: 14
  },
  capTypography: {
    marginTop: '2%',
    color: theme.text,
    margin: 'auto',
    marginRight: 0,
    fontSize: 14
  },
  barGrid: {
    display: 'flex',
    margin: '0 auto',
    width: '99.5%',
    '& span': {
      color: '#7C7C7D'
    }
  },
  typography: {
    textAlign: 'end',
    color: theme.text
  },
  background: {
    backgroundColor: theme.backgroundColor
  },
  subHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: '8px 0',
    color: theme.text
  },
  item: {
    position: 'relative',
    flexBasis: 'auto'
  },
  itemGreyText: {
    background: '#2d2e3d',
    color: theme.secondaryText,
    fontFamily: 'MontserratMedium',
    padding: '10px 12px',
    fontSize: 12,
    '@media (max-width:1225px)': {
      // eslint-disable-next-line
      minHeight: '60px'
    }
  },
  itemLightGreyText: {
    color: theme.secondaryText
  },
  table: {
    overflowY: 'scroll',
    color: theme.text,
    tableLayout: 'fixed'
  },
  tableHead: {
    color: theme.secondaryText,
    background: theme.dropdown.backgroundColor,
    padding: '10px 12px',
    fontFamily: `"MontserratMedium"`,
    fontSize: 12,
    border: 'none',
    '@media only screen and (min-width:992px) and (max-width:1150px)': {
      padding: '2px 6px'
    },
    '@media only screen and (min-width:1151px) and (max-width:1279px)': {
      padding: '9px 12px'
    },
    '@media (min-width:1280px) and (max-width:1879px)': {
      padding: '1px 12px'
    },
    '@media (min-width:2000px) and (max-width:2599px)': {
      padding: '9px 12px'
    },
    '@media (min-width:2600px) and (max-width:3099px)': {
      padding: '7px 12px'
    },
    '@media (min-width:3100px) and (max-width:3599px)': {
      padding: '5px 12px'
    },
    '@media (min-width:3600px)': {
      padding: '3px 12px'
    },
    '& .MuiTableSortLabel-root:hover': {
      color: theme.text
    },
    '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
      opacity: 1
    }
  },
  tableName: {
    color: '#4033AA',
    '& a': {
      color: theme.grid.color,
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: theme.grid.color,
        textDecoration: 'none'
      }
    }
  },
  tableCell: {
    color: '#ffffff',
    background: theme.dropdown.fadedBackgroundColor,
    fontSize: '12px',
    fontWeight: '300',
    border: 'none',
    borderBottom: theme.border,
    fontFamily: 'MontserratLight',
    padding: '10px 12px 9px',
    '&:last-child': {
      color: theme.text
    }
  },
  loaderText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#9098AC'
  },
  loaderDimensions: {
    height: 20,
    width: 20
  },
  loaderCell: {
    borderBottom: 'none',
    background: '#222330'
  },
  tableHeight: {
    position: 'relative',
    height: 75
  },
  setBorder: {
    padding: 0,
    border: 0
  }
}));

const WidgetBalance = (props) => {
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  useEffect(() => {
    setRows(props.pnlStatsTable);
  }, [props.pnlStatsTable]);

  const Headings = [
    {
      key: 'symbol',
      title: 'Token Symbol',
      styled: false
    },
    {
      key: 'spent',
      title: 'Total Spent',
      styled: true
    },
    {
      key: 'swap',
      title: 'Tokens Purchased',
      styled: false
    },
    {
      key: 'price',
      title: 'Purchase Price/Token',
      styled: false
    },
    {
      key: 'cost',
      title: 'Current Price/Token',
      styled: false
    },
    {
      key: 'pnl_per_token',
      title: 'P&L / token',
      styled: true
    },
    {
      key: 'returns',
      title: 'Total P&L(%)',
      styled: true
    },
    {
      key: 'pnl',
      title: 'Total P&L',
      styled: true
    }
  ];

  const renderBalanceRow = (row, Headings) => {
    return (
      <TableRow className={classes.tableRow} key={uniqueId()}>
        {Headings.map((heading, index) => {
          if (heading.key === 'symbol') {
            return (
              <TableCell className={classes.tableCell} key={index + heading.key}>
                {row[heading.key] || 0}
              </TableCell>
            );
          } else {
            return (
              <TableCell
                className={classes.tableCell}
                key={index + heading.key}
                style={
                  heading.styled && row[heading.key] < 0
                    ? { color: '#EE3737' }
                    : heading.styled && row[heading.key] > 0
                    ? { color: '#2FD593' }
                    : null
                }
              >
                <NumberFormat
                  value={row[heading.key] || 0}
                  displayType={'text'}
                  thousandSeparator={true}
                />
                {heading.key === 'returns' ? '%' : null}
              </TableCell>
            );
          }
        })}
      </TableRow>
    );
  };

  return (
    <Grid item md={12} sm={12} className={`${classes.root} ${classes.marginTB} boxDesign`}>
      <Table stickyHeader className={classes.table}>
        <TableHead>
          <TableRow>
            {Headings.map((heading) => {
              return (
                <TableCell key={heading.key} className={classes.tableHead}>
                  {' '}
                  {`${heading.title} ${
                    heading.key === 'pnl' || heading.key === 'spent'
                      ? ` (${props.collateralToken?.name})`
                      : ''
                  }`}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length > 0 ? (
            <>
              {rows.map((row) => {
                return renderBalanceRow(row, Headings);
              })}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={8} className={classes.setBorder}>
                <div className={classes.tableHeight}>
                  <LoaderOverlay title={'Calculating Values...'} />
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  collateralToken: state.collateralToken,
  pnlStatsTable: state.tradePage.pnlStatsTable
});

export default withRouter(connect(mapStateToProps, null)(WidgetBalance));
