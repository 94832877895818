import React, {useState } from 'react';
import MenuItem from '../menuItem';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { createAction } from '@reduxjs/toolkit';
import SwitchButton from '../../component/SwitchButton';
import Button from '@material-ui/core/Button';
import RadialGradient from '../../assets/radial_gradient.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import CollateralMenuItem from '../collateralMenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { LightTooltip } from "../LightToolTip";

const updateFilter = createAction('UPDATE_FILTER');
const disconnectWallet = createAction('DISCONNECT_WALLET');

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      position: 'relative'
    },
    paper: {
      padding: theme.spacing(1.5),
      color: theme.text,
      background: theme.card.background,
      boxShadow: theme.card.boxShadow,
      borderRadius: '5px'
    },
    typography: {
      color: 'darkGrey',
      fontSize: '12px'
    },
    address: {
      color: theme.text,
      fontWeight: 'bold',
      fontSize: 16
    },
    checkbox: {
      marginRight: 0,
      '& span': {
        fontSize: 14,
        fontWeight: 300,
        color: theme.text,
        lineHeight: 0.9
      },
      '& svg': {
        color: theme.grid.color
      },
      '& .MuiButtonBase-root': {
        padding: 5
      }
    },
    button: {
      color: `${theme.grid.color} !important`,
      textDecoration: 'underline'
    },
    contractAddressContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      '@media (min-width:768px) and (max-width:991px)': {
        justifyContent: 'flex-end'
      }
    },
    contractAddress: {
      //background: theme.card.background,
      background: 'transparent',
      height: 30,
      borderRadius: 5,
      marginRight: 14,
      padding: '8px 16px',
      border: '1px solid #68dbda',
      '&:hover': {
        background: theme.card.background
      }
    },
    radialGradient: {
      marginRight: 8
    },
    contractText: {
      fontSize: 14,
      textTransform: 'none',
      color: theme.text
    },
    grid: {
      marginTop: '-7px !important'
    },
    btnLogout: {
      width: 36,
      height: 30
    }
  };
});

const AppHeader = ({
  selected,
  handleSelected,
  commodityInfo,
  address,
  filter,
  updateFilter,
  commodities,
  pathName,
  disableSwitch,
  walletAvailability,
  openWalletConnectModal,
  disconnectWallet
}) => {
  const classes = useStyles();
  const [keyselected, setSelected] = React.useState(0);
  const [copied, setCopied] = useState(false);
  const [value, setValue] = useState('');
  const onCopy = (text) => {
    if (!copied) {
      setCopied(true);
      setValue(text);
      setTimeout(() => {
        setCopied(false);
        setValue('');
      }, 1000);
    }
  };

  const handleCopy = (address) => {
    if (address)
      return (
        <>
          <CopyToClipboard text={address} onCopy={() => onCopy(address)} className={classes.chip}>
            <LightTooltip title={copied && address === value ? 'Copied!' : 'Click to copy'}>
              <Button className={classes.contractAddress}>
                <img
                  src={RadialGradient}
                  alt="radial-gradient"
                  className={classes.radialGradient}
                />
                <Typography className={classes.contractText}>{textFormat(address)}</Typography>
              </Button>
            </LightTooltip>
          </CopyToClipboard>
          {/* <Button className={classes.contractAddress} onClick={() => {
          disconnectWallet()
        } }>
          <Typography className={classes.contractText}>Disconnect Wallet</Typography>
        </Button> */}
        </>
      );
  };

  const textFormat = (address) => {
    if (address) return `${address.slice(0, 10)}...${address.slice(-4)}`;
    return `...`;
  };

  const handleKey = (value) => {
    handleSelected(value);
    setSelected(1);
  };

  const handleReverse = (value) => {
    handleSelected(value);
    setSelected(0);
  };

  const handleChange = (event) => {
    if (event === 'Active') {
      var keys = Object.keys(commodities);
      if (keys[0]) {
        const found = Object.values(commodities).find(
          (commodity) => !commodity.is_settled && !keyselected
        );
        if (found) handleKey(found.id);
      }
    } else {
      // eslint-disable-next-line no-redeclare
      var keys = Object.keys(commodities);
      if (keys[0]) {
        const found = Object.values(commodities).find((commodity) => commodity.is_settled);
        if (found) handleReverse(found.id);
      }
    }
    updateFilter({
      ...filter,
      active: event === 'Active' ? true : false,
      settled: event === 'Settled' ? true : false
    });
  };

  return (
    <Grid container className={classes.root}>
      
      <Grid item xs={5} md={5} lg={4}  className={classes.grid}>
        <MenuItem selected={selected} handleSelected={handleSelected} />
        {commodityInfo && <Typography className={classes.typography}>{commodityInfo}</Typography>}
      </Grid>
      
      <Grid container item spacing={2} xs={7} md={7} lg={8} className="justifyContentcss">
      <Grid item className="app_header_btn">
        <CollateralMenuItem />
      </Grid>
      <Grid item className={classes.contractAddressContainer}>
        {
          walletAvailability && address !== process.env.REACT_APP_STATIC_WALLET_ADD ? handleCopy(address):
          <Button className={classes.contractAddress} 
          onClick={()=>{openWalletConnectModal(true)}}
          >
            <Typography className={classes.contractText}>Connect Wallet</Typography>
          </Button>
        }
        <SwitchButton handleChange={handleChange} disableSwitch={disableSwitch} filter={filter}/>
        {
          walletAvailability && address !== process.env.REACT_APP_STATIC_WALLET_ADD ?
          <LightTooltip title={"Disconnect"}>
            <Button variant={'outlined'} size="small" className={`${classes.btnLogout} btn-info`} color="primary" onClick={()=>disconnectWallet()} endIcon={<ExitToAppIcon />}/>
          </LightTooltip>: null
        }
      </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  address: state.user.address,
  filter: state.user.filter,
  commodities: state.commodities,
  walletAvailability: state.user.walletAvailability
});

export default connect(mapStateToProps, { updateFilter, disconnectWallet })(AppHeader);
