import { select, put, takeEvery } from 'redux-saga/effects';
import { updatePnLisLoading } from '../reducer/user';
// import { swapTransactionHistoryURL,mintRedeemTransactionHistoryURL } from '../constant/index';
// import {  userCommodityHistory } from './transactionHistory';
import { basicAuth, calculatePnlURL } from '../constant/index';
import { getAccountAddress, getNetworkId } from '../utils';
import BigNumber from 'bignumber.js';
import { setData } from '../reducer/swapStats';
import { updateSwapStats } from '../reducer/currentCommodity';
import { updatePnLStatsTable } from '../reducer/tradePageReducer';
function* startSaga() {
  yield takeEvery('FETCH_USER_COMMODITY_PNL', fetchUserCommodityPnl);
}

// develops current user Commodity PnL
export function* fetchUserCommodityPnl(action) {
  const commodity = action.payload;
  yield put(updatePnLisLoading({ id: commodity.id, value: true }));
  const collateralToken = yield select((state) => state.collateralToken);
  let walletAvailability = yield select((state) => state.user.walletAvailability);
  let walletAddress;
  let networkID;
  if (!walletAvailability) {
    walletAddress = process.env.REACT_APP_STATIC_WALLET_ADD;
    networkID = 97;
  } else {
    walletAddress = yield getAccountAddress();
    networkID = yield getNetworkId();
  }
  let PNLData = {};
  try {
    PNLData = yield fetchPNLData(
      commodity.id,
      networkID.toString(),
      walletAddress,
      commodity.decimal,
      collateralToken.decimal,
      collateralToken.address,
      commodity.balancer_pool_address,
      commodity.long_token_contract_address,
      commodity.short_token_contract_address,
      commodity.strategy_address,
      commodity.long_symbol,
      commodity.short_symbol
    );
  } catch (err) {
    console.log(commodity.commodity_symbol, err);
  }
  const data = {};
  data[commodity.long_token_contract_address] = {
    PNLPerToken: PNLData.hasOwnProperty(commodity.long_symbol)
      ? PNLData[commodity.long_symbol].pnl_per_token
      : 0,
    purchasePrice: PNLData.hasOwnProperty(commodity.long_symbol)
      ? PNLData[commodity.long_symbol].purchase_price
      : 0,
    returns: PNLData.hasOwnProperty(commodity.long_symbol)
      ? PNLData[commodity.long_symbol].returns
      : 0,
    swappedAmount: PNLData.hasOwnProperty(commodity.long_symbol)
      ? PNLData[commodity.long_symbol].swapped_amount
      : 0,
    totalPNL: PNLData.hasOwnProperty(commodity.long_symbol)
      ? PNLData[commodity.long_symbol].total_pnl
      : 0,
    currentPrice: PNLData.hasOwnProperty(commodity.long_symbol)
      ? PNLData[commodity.long_symbol].current_price
      : 0,
    tokenSymbol: commodity.long_symbol,
    version: commodity.version
  };
  data[commodity.short_token_contract_address] = {
    PNLPerToken: PNLData.hasOwnProperty(commodity.short_symbol)
      ? PNLData[commodity.short_symbol].pnl_per_token
      : 0,
    purchasePrice: PNLData.hasOwnProperty(commodity.short_symbol)
      ? PNLData[commodity.short_symbol].purchase_price
      : 0,
    returns: PNLData.hasOwnProperty(commodity.short_symbol)
      ? PNLData[commodity.short_symbol].returns
      : 0,
    swappedAmount: PNLData.hasOwnProperty(commodity.short_symbol)
      ? PNLData[commodity.short_symbol].swapped_amount
      : 0,
    totalPNL: PNLData.hasOwnProperty(commodity.short_symbol)
      ? PNLData[commodity.short_symbol].total_pnl
      : 0,
    currentPrice: PNLData.hasOwnProperty(commodity.short_symbol)
      ? PNLData[commodity.short_symbol].current_price
      : 0,
    tokenSymbol: commodity.short_symbol,
    version: commodity.version
  };
  yield put(setData({ data, id: commodity.id }));
  if (action.payload.current) {
    yield put(updateSwapStats(data));
    let pnlStatsTableData = yield calculateTableValues(data);
    yield put(updatePnLStatsTable(pnlStatsTableData));
  }
  yield put(updatePnLisLoading({ id: commodity.id, value: false }));
}

export const fetchPNLData = async (
  commodityId,
  networkId,
  walletAddress,
  decimal,
  usdtDecimal,
  usdtAddress,
  bpoolAddress,
  longAddress,
  shortAddress,
  strategyAddress,
  longSymbol,
  shortSymbol
) => {
  const response = await fetch(calculatePnlURL, {
    method: 'POST',
    headers: new Headers({
      Authorization: basicAuth,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      commodity_id: commodityId,
      address: walletAddress,
      network_id: networkId,
      collateral_token: usdtAddress,
      usdt_decimal: usdtDecimal,
      balancer_pool_address: bpoolAddress,
      decimal: decimal,
      long_token_contract_address: longAddress,
      short_token_contract_address: shortAddress,
      strategy_address: strategyAddress,
      long_symbol: longSymbol,
      short_symbol: shortSymbol
    })
  });
  const result = await response.text();
  if (response.status !== 200) throw response.status;
  const data = JSON.parse(result);
  return data;
};

const calculateTableValues = (data) => {
  let pnlTable = [];
  const tokens = Object.keys(data);
  tokens.map(async (key) => {
    let tempValue = Object.assign(
      {},
      {
        symbol: data[key].tokenSymbol,
        price: new BigNumber(data[key].purchasePrice).decimalPlaces(3).toString(10),
        swap: new BigNumber(data[key].swappedAmount).decimalPlaces(3).toString(10),
        cost: new BigNumber(data[key].currentPrice).decimalPlaces(3).toString(10),
        pnl_per_token: new BigNumber(data[key].PNLPerToken).decimalPlaces(3).toString(10),
        pnl: new BigNumber(data[key].totalPNL).decimalPlaces(3).toString(10),
        returns: new BigNumber(data[key].returns || 0).decimalPlaces(3).toString(10),
        spent: new BigNumber(data[key].purchasePrice)
          .multipliedBy(data[key].swappedAmount)
          .decimalPlaces(3)
          .toString(10)
      }
    );
    tempValue.swap === 0
      ? pnlTable.push(
          Object.assign({}, tempValue, {
            price: 0,
            pnl_per_token: 0,
            pnl: 0,
            returns: 0
          })
        )
      : pnlTable.push(tempValue);
    return await pnlTable;
  });
  return pnlTable;
};

export default startSaga;
