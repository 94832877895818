import React, { useState, useEffect, useCallback } from 'react';
// import ReactApexChart from "react-apexcharts";
import defaultOptions from '../Charts/defaultOptions';
// import defaultCandleOptions from "../Charts/defaultCandleOptions";
import { connect } from 'react-redux';
import { Button, makeStyles, Grid } from '@material-ui/core';
import { createAction } from '@reduxjs/toolkit';
import TVChartContainer from '../TVChartContainer';
import Enlarge from '../../assets/enlarge.svg';
import Home from '../../assets/home.svg';
import { darkTheme, lightTheme } from '../../Theme';
import HistoryChart from '../HistoryChart';
import LoaderOverlay from '../../component/Loader';

const updateGraphTypeFilter = createAction('UPDATE_GRAPH_TYPE_FILTER');
const updateGraphFilter = createAction('UPDATE_GRAPH_FILTER');
const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.dropdown.fadedBackgroundColor,
    padding: '20px',
    paddingBottom: '4px',
    minHeight: '450px',
    position: 'relative'
  },
  maxButton: {
    margin: '0px 4px',
    '&:hover': {
      opacity: 0.8
    },
    '&:disabled': {
      color: 'grey'
    },
    color: theme.text,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    padding: '4px 18px',
    marginLeft: 0,
    border: '0px',
    borderRadius: 5,
    background: '#2C2D3D'
  },
  chartContainer: {
    marginLeft: '-13px',
    marginRight: '-9px'
  },
  active: {
    background: theme.maxButton.color,
    color: theme.maxButton.activeColor,
    '&:hover': {
      opacity: 1,
      background: theme.maxButton.hoverColor
    }
  },
  filter: {
    background: '#68dbda !important',
    color: `${theme.maxButton.activeColor} !important`,
    '&:hover': {
      background: theme.text
    }
  },
  date: {
    margin: '0px 4px',
    // borderColor: theme.divider.backgroundColor,
    '&:hover': {
      opacity: 1,
      background: `${theme.maxButton.hoverColor} !important`
    },
    '&:disabled': {
      color: 'grey'
    },
    color: theme.text,
    padding: '4px 10px',
    marginLeft: 0,
    borderRadius: '0.25rem',
    minWidth: '10px !important',
    background: '#2C2D3D'
  },
  loaderText: {
    textAlign: 'center',
    fontSize: 12,
    color: '#9098AC'
  },
  loaderDimensions: {
    height: 20,
    width: 20,
    margin: '8px'
  },
  loaderCell: {
    borderBottom: 'none',
    background: '#222330'
  }
}));

const TradingGraph = (props) => {
  const classes = useStyles();
  const [series, setSeries] = useState([{ data: [] }]);
  const [spreadSeries, setSpreadSeries] = useState([]);
  const [options, setOptions] = useState(defaultOptions);
  const [yAxis, setYAxis] = useState([]);
  const [selected, setSelected] = useState(props.graphSelected);
  const [date, setDate] = useState('all');
  const [lastDate, setLastDate] = useState('');
  const [chart, setChart] = useState(props.graphType);

  const transformToDataset = useCallback(
    (data) =>
      data.map((element) => {
        if (date === 'week') {
          if (
            new Date(element['trade_date']).getFullYear() === new Date(lastDate).getFullYear() &&
            new Date(element['trade_date']).getMonth() === new Date(lastDate).getMonth() &&
            new Date(element['trade_date']).getDate() > new Date(lastDate).getDate() - 6
          )
            return [element['trade_date'], element['weighted_price']];
        } else if (date === 'month') {
          if (
            new Date(element['trade_date']).getFullYear() === new Date(lastDate).getFullYear() &&
            new Date(element['trade_date']).getMonth() === new Date(lastDate).getMonth()
          )
            return [element['trade_date'], element['weighted_price']];
        } else if (date === 'year') {
          if (new Date(element['trade_date']).getFullYear() === new Date(lastDate).getFullYear())
            return [element['trade_date'], element['weighted_price']];
        } else if (date === 'all') return [element['trade_date'], element['weighted_price']];
        return [];
      }),
    [date, lastDate]
  );

  useEffect(() => {
    if (!props.commodity) {
      setSeries([{ data: [] }]);
      return;
    }
    const commodity = props.commodity;
    let series = [];
    if (chart === 'area') {
      if (props.historyPrices && !lastDate) {
        const value = props.historyPrices.length;
        value && setLastDate(new Date(props.historyPrices[value - 1].trade_date));
      }

      let data = transformToDataset(props.historyPrices);
      data =
        data.length &&
        data.filter(function (el) {
          return el.length !== 0;
        });
      series = [{ data, name: commodity.commodity_name }];
      setSeries(series);
    }
  }, [props.commodity, props.historyPrices, transformToDataset, lastDate, chart]);

  useEffect(() => {
    if (props.commodity && chart === 'area') {
      const commodity = props.commodity;
      setYAxis([
        {
          ...defaultOptions.yaxis,
          seriesName: commodity.commodity_name,
          opposite: true,
          tickAmount: 6,
          decimalsInFloat: 2,
          labels: {
            style: {
              fontSize: '12px',
              colors: props.enabled ? darkTheme.secondaryText : lightTheme.secondaryText
            }
          }
        }
      ]);
    }
  }, [props.commodity, props.enabled, chart]);

  useEffect(() => {
    const commodity = props.commodity;
    if (chart === 'area' && commodity && commodity.is_spread) {
      let code1history = props.historyPricesCode1;
      let code2history = props.historyPricesCode2;
      const data1 = transformToDataset(code1history);
      const data2 = transformToDataset(code2history);
      let spreadSeries = [
        { data: data1, name: commodity.code1 },
        { data: data2, name: commodity.code2 }
      ];
      setSpreadSeries(spreadSeries);
    }
  }, [
    props.commodity,
    props.historyPricesCode2,
    props.historyPricesCode1,
    transformToDataset,
    chart
  ]);

  useEffect(() => {
    let theme, fill, chartObj, stroke;
    if (spreadSeries.length === 0) {
      stroke = {
        colors: ['#9098ac80', '#9098ac', '#9098ac80']
      };
      theme = {
        mode: 'dark',
        monochrome: {
          enabled: true,
          shadeTo: 'dark'
        }
      };
      fill = {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'vertical',
          shadeIntensity: 0.9,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 0,
          colorStops: [
            [
              {
                offset: 0,
                color: '#2C2D3D',
                opacity: 1
              },
              {
                offset: 1,
                color: '#484A65',
                opacity: 1
              },
              {
                offset: 100,
                color: '#2C2D3D',
                opacity: 0.5
              }
            ]
          ]
        },
        colors: ['#799BFF', '#E91E63', '#9C27B0']
      };
    } else {
      stroke = {
        colors: ['#799BFF', '#E91E63', '#9C27B0']
      };
      theme = {
        mode: 'dark',
        monochrome: {
          enabled: false
        }
      };
      fill = {
        gradient: {
          enabled: true,
          opacityFrom: 0.8,
          opacityTo: 0,
          colorStops: []
        },
        colors: ['#799BFF', '#E91E63', '#9C27B0']
      };
    }
    chartObj = {
      id: 'area-datetime',
      type: 'area',
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true
      },
      toolbar: {
        show: true,
        offsetX: window.screen.width * 0.75 + window.screen.width * -1,
        offsetY: -37,
        tools: {
          download: false,
          selection: true,
          zoom: `<div class="custom-icon" style="margin:0px -27px !important; width: 35px !important; height: 35px !important">
                    <img src="${Enlarge}" width="15" style="margin: 8.5px auto;display: block;">
                  </div>`,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: `<div class="custom-icon" style="margin:0px -12px !important; width: 35px !important; height: 35px !important">
                      <img src="${Home}" width="15" style="margin: 8.5px auto;display: block;" />
                    </div>`
        }
      }
    };

    setOptions({
      ...defaultOptions,
      yaxis: yAxis,
      theme: theme,
      fill: fill,
      chart: chartObj,
      stroke: stroke,
      colors: ['#799BFF', '#E91E63', '#9C27B0']
    });
  }, [yAxis, props.enabled, spreadSeries]);

  const graph = () => {
    if (chart === 'area') {
      const seriesGraph = {
        0: [...series, ...spreadSeries],
        1: [spreadSeries[1]],
        2: [spreadSeries[0]],
        3: [series[0]]
      };
      return (
        <div className={classes.chartContainer}>
          <HistoryChart options={options} series={seriesGraph[selected]} type="area" height={300} />
        </div>
      );
    }
    if (chart === 'candlestick') {
      if (props.commodity) {
        let tradeGraph = (
          <TVChartContainer key={selected} commodity={props.commodity} selectedToken={selected} />
        );
        if (props.commodity.long_symbol === selected) {
          tradeGraph = (
            <TVChartContainer
              key={props.commodity.long_symbol}
              commodity={props.commodity}
              selectedToken={props.commodity.long_symbol}
            />
          );
        } else if (props.commodity.short_symbol === selected) {
          tradeGraph = (
            <TVChartContainer
              key={props.commodity.short_symbol}
              commodity={props.commodity}
              selectedToken={props.commodity.short_symbol}
            />
          );
        } else {
          tradeGraph = (
            <TVChartContainer
              key={props.commodity.long_symbol}
              commodity={props.commodity}
              selectedToken={props.commodity.long_symbol}
            />
          );
        }
        return tradeGraph;
      }
    }
  };

  const handleSelected = (value) => {
    setDate(value);
  };

  const handleChart = (value) => {
    setChart(value);
    props.updateGraphTypeFilter(value);
    if (value === 'candlestick' && props.commodity) {
      setSelected(props.commodity.long_symbol);
      props.updateGraphFilter(props.commodity.long_symbol);
      setLastDate(0);
    } else {
      setSelected(0);
      props.updateGraphFilter(0);
    }
  };

  const charts = () => {
    return (
      <Grid style={{ display: 'flex' }}>
        <Grid item xs>
          {props.commodity && (
            <Button
              variant="outlined"
              onClick={() => handleChart('area')}
              className={`${chart === 'area' ? classes.active : ''} ${classes.maxButton}`}
            >
              {props.commodity.commodity_symbol}
            </Button>
          )}
          {props.commodity &&
            [props.commodity.long_symbol, props.commodity.short_symbol].map((data, index) => {
              if (data !== 'commodity_id')
                return (
                  <Button
                    key={index}
                    className={`${selected === data ? classes.active : ''} ${classes.maxButton}`}
                    variant="outlined"
                    onClick={() => {
                      handleChart('candlestick');
                      setSelected(data);
                      props.updateGraphFilter(data);
                    }}
                  >
                    {data}
                  </Button>
                );
              return <></>;
            })}
        </Grid>
        {chart !== 'candlestick' && (
          <Grid item xs style={{ textAlign: 'end' }}>
            <Button
              disabled={chart === 'area' ? false : true}
              variant="outlined"
              onClick={() => handleSelected('week')}
              className={`${date === 'week' ? classes.filter : ''} ${classes.date}`}
            >
              1W
            </Button>
            <Button
              disabled={chart === 'area' ? false : true}
              variant="outlined"
              onClick={() => handleSelected('month')}
              className={`${date === 'month' ? classes.filter : ''} ${classes.date}`}
            >
              1M
            </Button>
            <Button
              disabled={chart === 'area' ? false : true}
              variant="outlined"
              onClick={() => handleSelected('year')}
              className={`${date === 'year' ? classes.filter : ''} ${classes.date}`}
            >
              1Y
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleSelected('all')}
              className={`${date === 'all' ? classes.filter : ''} ${classes.date}`}
            >
              ALL
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };

  const buttons = () => {
    if (series.length > 1 && chart === 'area') {
      const value = [...series, ...spreadSeries].map((data, index) => (
        <Button
          className={`${selected === index + 1 ? classes.active : ''} ${classes.maxButton}`}
          variant="outlined"
          size="small"
          onClick={() => setSelected(index + 1)}
        >
          {data.name}
        </Button>
      ));
      return (
        <>
          {charts()}
          <Button
            className={`${selected === 0 ? classes.active : ''} ${classes.maxButton}`}
            variant="outlined"
            size="small"
            onClick={() => setSelected(0)}
          >
            All
          </Button>
          {value}
        </>
      );
    }
    return charts();
  };

  return (
    <div id="chart" className={`${classes.container} boxDesign`}>
      <div id="chart-timeline">
        {series[0]?.data !== 0 ? (
          <>
            {buttons()}
            {graph()}
          </>
        ) : (
          <LoaderOverlay title={'Getting Graph Data...'} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  historyPricesCode1: state.currentCommodity.historyPricesCode1,
  historyPricesCode2: state.currentCommodity.historyPricesCode2,
  historyPrices: state.currentCommodity.historyPrices,
  enabled: state.user.enabled,
  graphType: state.user.graphType,
  graphSelected: state.user.graphSelected,
  commodity: state.currentCommodity.metadata
});
export default connect(mapStateToProps, { updateGraphFilter, updateGraphTypeFilter })(TradingGraph);
