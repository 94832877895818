import { combineReducers } from 'redux';
import metamask from '../reducer/metaMask';
import user from '../reducer/user';
import commodities from '../reducer/commdoities';
import balancerPool from '../reducer/balancerPool';
import liquidityPool from '../reducer/liquidityPool';
// import commodityPrices from '../reducer/commodityPrices';
import faucetTransfer from '../reducer/faucetTransfers';
import transactionHistory from '../reducer/transactionHistory';
import liquidityTransactionHistory from '../reducer/liquidityTransactionHistory';
import userTransactionHistory from '../reducer/userTransactionHistory';
import collateralToken from '../reducer/collateralToken';
import collateralList from '../reducer/collateralList';
import swapStats from '../reducer/swapStats';
import currentCommodity from '../reducer/currentCommodity';
import tradePage from '../reducer/tradePageReducer';

function lastAction(state = null, action) {
  return action;
}

const createRootReducer = () =>
  combineReducers({
    metamask,
    user,
    commodities,
    balancerPool,
    liquidityPool,
    // commodityPrices,
    faucetTransfer,
    transactionHistory,
    userTransactionHistory,
    collateralToken,
    collateralList,
    swapStats,
    currentCommodity,
    tradePage,
    liquidityTransactionHistory,
    lastAction
  });

export default createRootReducer;
