export function validateInputField(value) {
  let regex = /^(?:[0-9]\d*|0)?(?:\.\d+)?$/;
  let res = regex.test(value) ? true : false;
  return res;
}

export function correctInputFieldValues(value) {
  let regex = /[^0-9.]/gi;
  value = value.replace(regex, '');
  return value;
}
