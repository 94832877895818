import mettAlexContract from '../ABI/METALEXCONTRACT_20200820.json';
import { decimals } from '../constant';
class MettAlex {
  constructor(address) {
    this.UserContract = new window.web3.eth.Contract(mettAlexContract.abi, address);
  }

  getInstance = async () => this.UserContract;

  getCapFloor = async () => {
    const mettAlexInstance = await this.getInstance();
    const cap = await mettAlexInstance.methods.priceCap().call();
    const floor = await mettAlexInstance.methods.priceFloor().call();
    const collateralPerUnit = await mettAlexInstance.methods.collateralPerUnit().call();
    const collateralFeePerUnit = await mettAlexInstance.methods.collateralFeePerUnit().call();
    return {
      cap: (parseFloat(cap) / decimals.cap).toFixed(decimals.capDigits),
      floor: (parseFloat(floor) / decimals.floor).toFixed(decimals.floorDigits),
      collateralPerUnit,
      collateralFeePerUnit
    };
  };

  getIsSettled = async () => {
    const mettAlexInstance = await this.getInstance();
    const isSettled = await mettAlexInstance.methods.isSettled().call();
    return isSettled;
  };

  mintTokens = async (value, walletAddress) => {
    const mettAlexInstance = await this.getInstance();
    await mettAlexInstance.methods.mintPositions(value).send({
      from: walletAddress
    });
  };

  redeemToken = async (value, walletAddress) => {
    const mettAlexInstance = await this.getInstance();
    await mettAlexInstance.methods.redeemPositions(value).send({
      from: walletAddress
    });
  };

  settleToken = async (walletAddress) => {
    const mettAlexInstance = await this.getInstance();
    await mettAlexInstance.methods.settlePositions().send({
      from: walletAddress
    });
  };
}

let mettAlex = null;

const getContract = (address) => {
  mettAlex = new MettAlex(address);
  Object.freeze(mettAlex);
  return mettAlex;
};

export default getContract;
