import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'collateralList',
  initialState: {
    tokens: []
  },
  reducers: {
    startUpdateAccount() {},
    updatecollateralList(state, action) {
      state.tokens = action.payload;
    }
  }
});

const { actions, reducer } = configSlice;

export const { updatecollateralList } = actions;

export default reducer;
