import React from 'react';
import Taken from '../../src/assets/taken.svg'

const errorBoundaryDiv= { color: 'white', height: '100vh',display: 'flex', flexDirection: 'column',alignItems: 'center',justifyContent:'center'}
const imageStyle = {height:"40%", width:"30%"}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={errorBoundaryDiv}>
          <img src={Taken} alt={"Something wrong"} style={imageStyle}/>
          <h1 >
            Oops, Something went wrong...!!{' '}
          </h1>
          <h3 style={{color: 'aqua'}}>
            Try refreshing or visit after some time..
          </h3>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
