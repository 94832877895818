import Enlarge from '../../assets/enlarge.svg';
import Home from '../../assets/home.svg';
import { darkTheme } from '../../Theme/';

const defaultChartOptions = {
  chart: {
    id: 'area-datetime',
    type: 'area',
    stacked: true,
    zoom: {
      enabled: true,
      type: 'x',
      autoScaleYaxis: true
    },
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: false,
        selection: true,
        zoom: `<div class="custom-icon" style="margin:0px -20px !important">
                  <img src="${Enlarge}" width="15" style="margin: 7.5px auto;display: block;">
                </div>`,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: `<div class="custom-icon" style="margin:0px -12px !important">
                    <img src="${Home}" width="15" style="margin: 7.5px auto;display: block;" />
                  </div>`
      }
    }
  },
  xaxis: {
    type: 'datetime',
    tickAmount: 6,
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px',
        fontFamily: darkTheme.typography.fontFamily
      }
    },
    labels: {
      style: {
        fontSize: '12px',
        colors: darkTheme.secondaryText,
        fontFamily: darkTheme.typography.fontFamily
      },
      offsetX: -25,
      offsetY: 0
    },
    axisTicks: {
      show: false
    },
    axisBorder: {
      show: false
    },
    crosshairs: {
      show: true,
      width: 1,
      position: 'front',
      opacity: 0.9,
      stroke: {
        color: '#F97E5D',
        width: 1,
        dashArray: 2
      },
      fill: {
        type: 'gradient',
        gradient: {
          type: 'vertical',
          opacityFrom: 0.5,
          opacityTo: 0
        }
      }
    }
  },
  yaxis: {
    opposite: true,
    tickAmount: 6,
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px',
        fontFamily: darkTheme.typography.fontFamily
      }
    },
    labels: {
      style: {
        fontSize: '12px',
        colors: darkTheme.secondaryText,
        fontFamily: darkTheme.typography.fontFamily
      }
    },
    crosshairs: {
      show: true,
      width: 1,
      position: 'front',
      opacity: 0.9,
      stroke: {
        color: '#799BFF',
        width: 1,
        dashArray: 2
      }
    }
  },
  dataLabels: {
    enabled: false,
    style: {
      colors: ['#333', '#E91E63', '#9C27B0']
    }
  },
  markers: {
    size: 0,
    style: 'hollow',
    colors: ['#799BFF', '#E91E63', '#9C27B0']
  },
  animations: {
    enabled: true,
    easing: 'easeinout',
    speed: 800,
    animateGradually: {
      enabled: true,
      delay: 150
    },
    dynamicAnimation: {
      enabled: true,
      speed: 350
    }
  },
  tooltip: {
    x: {
      format: 'dd MMM yyyy'
    },
    marker: {
      fillColors: ['#799BFF', '#E91E63', '#9C27B0']
    }
  },
  noData: {
    text: 'Getting Graph Data..',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: '14px'
    }
  },
  grid: {
    borderColor: '#3E3F56',
    xaxis: {
      lines: {
        show: false
      }
    },
    yaxis: {
      lines: {
        show: true
      }
    }
  },
  legend: {
    fontSize: '12px',
    fontFamily: darkTheme.typography.fontFamily,
    labels: {
      colors: darkTheme.secondaryText
    }
  },
  stroke: {
    colors: ['#9098ac80', '#9098ac', '#9098ac80'],
    width: 1.5,
    curve: 'straight'
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'vertical',
      shadeIntensity: 0.9,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 0,
      colorStops: [
        [
          {
            offset: 0,
            color: '#2C2D3D',
            opacity: 1
          },
          {
            offset: 1,
            color: '#484A65',
            opacity: 1
          },
          {
            offset: 100,
            color: '#2C2D3D',
            opacity: 0.5
          }
        ]
      ]
    },
    colors: ['#799BFF', '#E91E63', '#9C27B0']
  },
  colors: ['#799BFF', '#E91E63', '#9C27B0']
};

export default defaultChartOptions;
