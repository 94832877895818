import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'user',
  initialState: {
    selectedCommodityCheck: false,
    address: '',
    walletAvailability: true,
    balances: {},
    network: '',
    networkID: '',
    commodityIsLoading: {},
    PnLisLoading: {},
    LiquidityRewardsisLoading: {},
    MtlxRewardsisLoading: {},
    transactionMetaData: {
      profit: 0,
      loss: 0
    },
    isLoading: false,
    isDepositeLoading: false,
    isWithdrawLoading: false,
    isTransactionLoading: false,
    isLoadingIndividual: false,
    loadingMessage: '',
    enabled: true,
    earnButton: false,
    filter: {
      active: true,
      settled: false
    },
    graphType: 'area',
    graphSelected: 0,
    gasEstimate: 0
  },
  reducers: {
    startUpdateAccount() {},
    updateAccount(state, action) {
      state.address = action.payload;
    },
    updateWalletAvailability(state, action) {
      state.walletAvailability = action.payload;
    },
    updateBalance(state, action) {
      state.balances[action.payload.id] = action.payload.value;
    },
    updatePnLisLoading(state, action) {
      state.PnLisLoading[action.payload.id] = action.payload.value;
    },
    updateLiquidityRewardsisLoading(state, action) {
      state.LiquidityRewardsisLoading[action.payload.id] = action.payload.value;
    },
    updateMtlxRewardsisLoading(state, action) {
      state.MtlxRewardsisLoading[action.payload.id] = action.payload.value;
    },
    updateTransactionMetaData(state, action){
      // This updated Trade metadata
      state.transactionMetaData = {
        profit: action.payload.profit,
        loss: action.payload.loss
      }
    },
    resetTransactionMetaData(state, action) {
      // This updated Trade metadata
      state.transactionMetaData = {
        profit: 0,
        loss: 0
      }
    },
    updateNetwork(state, action) {
      state.network = action.payload;
    },
    updateNetworkID(state, action) {
      state.networkID = action.payload;
    },
    updateIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    updateIsDepositLoading(state, action) {
      state.isDepositeLoading = action.payload;
    },
    updateIsWithdrawLoading(state, action) {
      state.isWithdrawLoading = action.payload;
    },
    updateTransionIsLoading(state, action) {
      state.isTransactionLoading = action.payload;
    },
    updateIsLoadingIndividual(state, action) {
      state.isLoadingIndividual = action.payload;
    },
    updateCommodityIsLoading(state, action) {
      if (
        !state.commodityIsLoading[action.payload.id] ||
        state.commodityIsLoading[action.payload.id] < 0
      )
        state.commodityIsLoading[action.payload.id] = 0;
      state.commodityIsLoading[action.payload.id] += action.payload.value;
    },
    updateLoadingMessage(state, action) {
      state.loadingMessage = action.payload;
    },
    updateToggle(state, action) {
      state.enabled = action.payload;
    },
    updateEarnButton(state, action) {
      state.earnButton = action.payload;
    },
    updateFilter(state, action) {
      if (!action.payload.active && !action.payload.settled)
        state.filter = {
          active: true,
          settled: false
        };
      else state.filter = action.payload;
    },
    updateGasEstimate(state, action) {
      state.gasEstimate = action.payload;
    },
    updateGraphType(state, action) {
      state.graphType = action.payload;
    },
    updateGraphSelected(state, action) {
      state.graphSelected = action.payload;
    },
    updateSelectedCommodityCheck(state, action) {
      state.selectedCommodityCheck = action.payload;
    }
  }
});

const { actions, reducer } = configSlice;

export const {
  updateLiquidityRewardsisLoading,
  updateMtlxRewardsisLoading,
  updateGraphType,
  updateGraphSelected,
  updateGasEstimate,
  updateFilter,
  updateEarnButton,
  updateAccount,
  updateBalance,
  updateNetwork,
  updateNetworkID,
  updateCommodityIsLoading,
  updateIsLoading,
  updateTransionIsLoading,
  updateIsLoadingIndividual,
  updateLoadingMessage,
  updateToggle,
  updatePnLisLoading,
  updateWalletAvailability,
  updateSelectedCommodityCheck,
  updateTransactionMetaData,
  resetTransactionMetaData,
  updateIsDepositLoading,
  updateIsWithdrawLoading
} = actions;

export default reducer;
