import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'txHistory',
  initialState: {
    txHistory: [],
    count: 0,
    page: 1,
    records: 16,
    selected: 1
  },
  reducers: {
    txHistory(state, action) {
      state.txHistory = action.payload.history;
      state.page = action.payload.page;
      state.records = action.payload.records;
    },
    txCount(state, action) {
      state.count = action.payload.count;
    },
    setSelected(state, action) {
      state.selected = action.payload.selected;
    }
  }
});

const { actions, reducer } = configSlice;

export const { txHistory, txCount, setSelected } = actions;

export default reducer;
