import { select, takeEvery, put } from 'redux-saga/effects';
import { convertAmountToString } from '../utils/index';
import positionTokenContract from '../contractInterface/positionTokenContract';
// import { updateVolumes } from '../reducer/tokenPrices';
import { updateLoadingMessage, updateTransionIsLoading, updateTransactionMetaData, resetTransactionMetaData } from '../reducer/user';
import strategyContract from '../contractInterface/strategyBalancerContract';

function* swapTokenSaga() {
  yield takeEvery('SWAP_TOKEN', startSwap);
}

function* startSwap(action) {
  if (action.payload?.open && action.payload?.open === true) {
    yield put(updateTransionIsLoading(true));
    yield put(updateTransactionMetaData({profit: action.payload.profit, loss: action.payload.loss}));
  }
  yield put(updateLoadingMessage('Transaction in progress. Please wait.'));
  try {
    const commodityId = action.payload.commodityId;
    const walletAddress = yield select((state) => state.user.address);
    const commodities = yield select((state) => state.commodities);
    const contract = strategyContract(action.payload.strategyAddress);

    const toPositionTokenContract = positionTokenContract(action.payload.toAddress);
    const toAmountdecimals = yield toPositionTokenContract.getDecimals();
    const fromPositionTokenContract = positionTokenContract(action.payload.fromAddress);
    const fromAmountdecimals = yield fromPositionTokenContract.getDecimals();

    const amount = convertAmountToString(action.payload.fromAmount, fromAmountdecimals);
    const minRecieved = convertAmountToString(action.payload.minRecieved, toAmountdecimals);

    yield fromPositionTokenContract.approve(action.payload.strategyAddress, amount, walletAddress);
    yield contract.swapTokens(
      action.payload.fromAddress,
      action.payload.toAddress,
      amount,
      minRecieved,
      walletAddress
    );
    const networkID = yield select((state) => state.user.networkID);
    triggerStoreSwapTxn(networkID);
    const commodity = commodities[commodityId];

    yield put({ type: 'USER_WALLET_TOKEN_DETAILS', payload: commodity });

    yield put({
      type: 'GET_POOL_DETAILS',
      payload: {
        id: commodityId,
        long_address: commodity.long_token_contract_address,
        short_address: commodity.short_token_contract_address,
        decimal: commodity.decimal,
        balancer_pool_address: commodity.balancer_pool_address,
        strategy_address: commodity.strategy_address
      }
    });
    // yield put({
    //   type: 'GET_POOL_BALANCES', payload: {
    //     id: commodityId,
    //     long_address: commodity.long_token_contract_address,
    //     short_address: commodity.short_token_contract_address,
    //     decimal: commodity.decimal,
    //     balancer_pool_address: commodity.balancer_pool_address
    //   }
    // })
    // const longPositioncontract = positionTokenContract(commodity.long_token_contract_address);
    // const shortPositioncontract = positionTokenContract(commodity.short_token_contract_address);
    // const shortVolume = yield shortPositioncontract.getVolume();
    // const longVolume = yield longPositioncontract.getVolume();
    // yield put(updateVolumes({
    //   shortVolume: [commodity.short_symbol, shortVolume],
    //   longVolume: [commodity.long_symbol, longVolume],
    //   id: commodityId
    // }))
    yield put({
      type: 'UPDATE_LIQUIDITY',
      payload: { liquidityPoolAddress: commodity.liquidity_pool_address }
    });
    yield put({
      type: 'FETCH_USER_COMMODITY_PNL',
      payload: {
        id: commodity.id,
        long_token_contract_address: commodity.long_token_contract_address,
        long_symbol: commodity.long_symbol,
        short_symbol: commodity.short_symbol,
        short_token_contract_address: commodity.short_token_contract_address,
        balancer_pool_address: commodity.balancer_pool_address,
        strategy_address: commodity.strategy_address,
        decimal: commodity.decimal
      }
    });
  } catch (err) {
    console.log(err);
    yield put(updateTransionIsLoading(false));
    yield put(resetTransactionMetaData());
  }
  yield put(updateTransionIsLoading(false));
  yield put(resetTransactionMetaData());
}

const triggerStoreSwapTxn = async (networkID) => {
  const response = await fetch(
    `https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/store_swap_txn?network_id=${networkID}`,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
  );
  const result = await response.text();
  console.log(result);
};

export default swapTokenSaga;
