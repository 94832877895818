import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import MintRedeem from '../containers/MintRedeem';
import AppHeader from '../component/AppHeader';
import LiquidityProvider from '../containers/LiquidityProvider';
import Dashboard from '../containers/Dashboard';
import NetworkImage from '../assets/network.png';
import AppMenu from '../component/AppMenu';
import { createAction } from '@reduxjs/toolkit';
// import FaucetProvider from '../containers/Faucet';
import Transaction from '../containers/TxHistory';
import WalletModal from '../component/WalletModal';
import { updateIsLoading } from '../reducer/user';
import AccountDetails from '../containers/Account';
import UserFeedback from '../component/FeedbackRibbon';
import Footer from '../component/Footer';
import Typography from '@material-ui/core/Typography';
import ErrorBoundary from './ErrorBoundary';

const updateLiquidity = createAction('UPDATE_LIQUIDITY');
const updateBalancerPool = createAction('GET_POOL_DETAILS');
const updateTxnsHistory = createAction('TRANSACTION_HISTORY_COMMODITY');
const updateGasEstimate = createAction('GAS_ESTIMATE');
const UserCommodityPnl = createAction('FETCH_USER_COMMODITY_PNL');
const UserCommodityLiquidity = createAction('FETCH_USER_COMMODITY_LIQUIDITY_DEPOSIT');
const UserCommodityReward = createAction('FETCH_USER_COMMODITY_MTLX_REWARD');
const updateGraphTypeFilter = createAction('UPDATE_GRAPH_TYPE_FILTER');
const updateGraphFilter = createAction('UPDATE_GRAPH_FILTER');
const updateCurrentCommodity = createAction('UPDATE_CURRENT_COMMODITY');
const updateCurrentCommodityBalances = createAction('USER_WALLET_TOKEN_DETAILS');
const updateConsideredCommodities = createAction('FETCH_CONSIDERED_COMMODITIES');

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      background: theme.body,
      position: 'relative'
    },
    backdrop: {
      zIndex: 222,
      color: '#fff'
    },
    typography: {
      fontSize: 18,
      padding: theme.spacing(1),
      marginTop: theme.spacing(0.5)
    },
    metamaskCard: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '40px 0',
      textAlign: 'center'
    },
    notLogin: {
      display: 'block'
    },
    arrowImage: {
      top: '34%',
      left: '70%',
      padding: '40px 0',
      position: 'absolute',
      transform: 'translate(-50%, -50%)'
    },
    drawerPaper: {
      whiteSpace: 'nowrap',
      paddingTop: '38px',
      paddingBottom: '36px',
      background: theme.body,
      width: 75,
      transition: 'all 0.3s ease-in-out',
      position: 'absolute',
      overflow: 'hidden',
      transformOrigin: 'top left',
      zIndex: 999,
      '@media (min-width:992px) and (max-width:1279px)': {
        paddingTop: 25
      },
      '&:hover': {
        width: 264,
        '& .MuiTypography-root': {
          opacity: 1
        },
        '& .logoTag': {
          opacity: 1
        },
        '& .network': {
          opacity: 1
        }
      },
      '@media (min-width:1200px) and (max-width:1400px)': {
        paddingTop: '21px'
      },
      '& .MuiTypography-root': {
        color: 'white',
        opacity: 0,
        transition: 'opacity 0.3s ease-in-out'
      },
      '& .logoTag': {
        opacity: 0,
        transition: 'opacity 0.3s ease-in-out'
      },
      '& .network': {
        opacity: 0,
        transition: 'opacity 0.3s ease-in-out'
      }
    },
    content: {
      flexGrow: 1,
      // overflow: 'auto'
    },
    container: {
      paddingTop: theme.spacing(2),
      paddingBottom: 5,
      transformOrigin: 'top',
      paddingLeft: 80,
      maxWidth: '100vw !important',
      minHeight: 'calc(100vh - 50px)',
      '@media (min-width:768px) and (max-width:991px)': {
        paddingRight: 20
      }
    },
    network: {
      position: 'absolute',
      bottom: 36,
      left: 44,
      background: '#2C2D3D',
      display: 'flex',
      padding: '16px 0 0 16px',
      width: 148,
      height: 160,
      flexDirection: 'column',
      transformOrigin: 'bottom left'
    },
    networkSmallText: {
      display: 'block',
      color: '#9098AC',
      fontSize: 12
    },
    networkBoldText: {
      display: 'block',
      fontFamily: `"MontserratMedium"`,
      fontSize: 14,
      color: 'white',
      textTransform: 'capitalize'
    },
    networkImage: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '130px'
    },
    '@media screen and (min-width: 1386px)': {
      container: {
        maxWidth: '100%',
        paddingLeft: '6%',
        paddingBottom: '32px',
        paddingRight: '1%',
        paddingTop: '32px'
      }
    },
    feedbackWrapper: {
      background: '#1976d2',
      height: 30,
      position: 'relative',
      top: 0,
      zIndex: 1000
    },
    closeIco: {
      textAlign: 'right',
      color: '#ffffff',
      float: 'right',
      fontSize: '1.3rem',
      marginRight: 8,
      marginTop: 3
    },
    appVersion: {
      position: 'relative',
      top: '80%',
      left: '74%',
      fontSize: 10
    }
  };
});

const App = (props) => {
  const classes = useStyles();
  const [commodityInfo, setCommodityInfo] = useState('No Info Available');
  const [selected, setSelected] = useState();
  const [selectedCommodity, setSelectedCommodity] = useState();
  const [pathName, setpathName] = useState(window.location.pathname);
  const [open, setOpen] = useState(false);
  const [disableSwitch, setDisableSwitch] = useState('none');

  useEffect(() => {
    if (props.user.address) setOpen(false);
  }, [props.user.address]);

  useEffect(() => {
    if (selectedCommodity) {
      props.updateCurrentCommodity(selectedCommodity);
    }
    // eslint-disable-next-line
  }, [selectedCommodity]);

  const handleSelected = useCallback(
    (value) => {
      const commodity = props.commodities[value];

      // HANDLESELECTED FUNCTION IS CALLED WHEN USER WANT'S TO SWITCH TO ANOTHER COMMODITY
      // FROM THE SELECTED ONE.
      // THE CODE BELOW IS TRYING TO FETCH ALL THE ACTIVE/SETTLED COMMODITIES
      // DEPENDS UPON WHICH TYPE OF COMMODITY USER IS CURRENTLY SEING
      // COMMENTING THE BELOW CODE WILL JUST SKIP THE STEP OF UPDATE ALL COMMODITIES DATA
      // AND WILL SWITCH TO ANOTHER COMMODITY WITHOUT REFETCHING THE DATA FROM CF AS REDUCER
      // ALREADY HAVE THE DATA STORED.

      const payload = {
        id: commodity.id,
        long_address: commodity.long_token_contract_address,
        long_symbol: commodity.long_symbol,
        short_address: commodity.short_token_contract_address,
        short_symbol: commodity.short_symbol,
        balancer_pool_address: commodity.balancer_pool_address,
        strategy_address: commodity.strategy_address,
        decimal: commodity.decimal,
        mtlx_rewards_available: commodity?.mtlx_rewards_available
          ? commodity.mtlx_rewards_available
          : false
      };
      props.updateCurrentCommodityBalances(commodity);
      props.UserCommodityReward(commodity);
      setSelected(value);
      setSelectedCommodity(commodity);
      const settledText = commodity.is_settled ? ' (settled)' : '';
      setCommodityInfo(commodity.commodity_info + settledText);
      props.updateGasEstimate({ liquidityPoolAddress: commodity.liquidity_pool_address });
      if (commodity.liquidity_pool_address) {
        props.updateLiquidity({ liquidityPoolAddress: commodity.liquidity_pool_address });
      }
      if (props.commodities[value].balancer_pool_address) {
        props.updateBalancerPool(payload);
        props.UserCommodityPnl({ ...commodity, current: true });
        props.UserCommodityLiquidity(commodity);
      }
      if (props.txnsHistory.selected === 2) {
        props.updateTxnsHistory({
          page: 1,
          pageSize: props.txnsHistory.records,
          commodity: '' + value + ''
        });
      }
      props.updateGraphFilter(0);
      props.updateGraphTypeFilter('area');
    },
    [props]
  );

  useEffect(() => {
    let allActiveCommodities = Object.keys(props.commodities).filter(
      (key) => props.commodities[key].is_settled !== true
    );
    let allSettledCommodities = Object.keys(props.commodities).filter(
      (key) => props.commodities[key].is_settled === true
    );
    if (!selected) {
      let found;
      if (allActiveCommodities.length === 0 && allSettledCommodities.length !== 0) {
        // Only Settled Commodities
        found = Object.keys(props.commodities).find(
          (token) => props.commodities[token].is_settled && !props.user.commodityIsLoading[token]
        );
        setDisableSwitch('active');
      } else if (allActiveCommodities.length !== 0 && allSettledCommodities.length === 0) {
        // Only Active Commodities
        found = Object.keys(props.commodities).find(
          (token) => !props.commodities[token].is_settled && !props.user.commodityIsLoading[token]
        );
        setDisableSwitch('settled');
      } else {
        // Active commodities available onLoad and all type commodity available
        found = Object.keys(props.commodities).find(
          (token) => !props.commodities[token].is_settled && !props.user.commodityIsLoading[token]
        );
        setDisableSwitch('none');
      }
      if (found) handleSelected(found);
    }
  }, [
    props.user.commodityIsLoading,
    handleSelected,
    selected,
    props.networkID,
    props.commodities,
    props.selectedCommodityCheck
  ]);

  const history = useHistory();
  useEffect(() => {
    return history.listen((location) => {
      setpathName(location.pathname);
    });
  }, [history]);

  const Board = () => (
    <Dashboard
      handleSelected={handleSelected}
      isDisabled={!selectedCommodity || !selectedCommodity.liquidity_pool_address}
      selected={selected}
    />
  );
  const Mint = () => (
    <MintRedeem
      handleSelected={handleSelected}
      selected={selected}
      currentComodityId={selectedCommodity ? selectedCommodity.id : null}
    />
  );
  // const TradeStatic=() => <Trade handleSelected={handleSelected} selected={selected} commodity={selectedCommodity} parentCallback={callback} />
  const Liquidity = () => (
    <LiquidityProvider
      selected={selected}
      handleSelected={handleSelected}
      lifespan={selectedCommodity ? selectedCommodity.created_at : null}
      liquidityPoolAddress={selectedCommodity ? selectedCommodity.liquidity_pool_address : null}
      currentComodityId={selectedCommodity ? selectedCommodity.id : null}
      isDisabled={!selectedCommodity || !selectedCommodity.liquidity_pool_address}
      coinTokenName={props.collateralToken.token}
      mtlxRewardCount={selectedCommodity && selectedCommodity.daily_drip_rate}
    />
  );
  // const Faucet = () => <FaucetProvider coinTokenName={props.collateralToken.token} />;
  const TxHistory = () => (
    <Transaction selected={selected} handleSelected={handleSelected} txnType={'ALL'} />
  );
  const Account = () => (
    <AccountDetails
      history={history}
      handleSelected={handleSelected}
      selected={selected}
      commodity={selectedCommodity}
      openWalletConnectModal={setOpen}
    />
  );

  return (
    <ErrorBoundary>
    <React.Fragment>
      <UserFeedback />
      <WalletModal open={open} setOpen={setOpen} setSelected={setSelected} />
      <Box className={classes.root}>
        <CssBaseline />
        {
          <Drawer
            variant="permanent"
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <AppMenu />
            <div className={classes.network + ' network'}>
              {props.user.walletAvailability ? (
                <>
                  <span className={classes.networkSmallText}>Network</span>
                  <span className={classes.networkBoldText}>{props.network}</span>
                </>
              ) : (
                <>
                  <span className={classes.networkSmallText}>Please</span>
                  <span className={classes.networkBoldText}>Connect Wallet</span>
                </>
              )}
              <img className={classes.networkImage} src={NetworkImage} alt="network" />
              <Typography className={classes.appVersion}>V 3.0.0</Typography>
            </div>
          </Drawer>
        }

        <main className={classes.content}>
          <Container maxWidth="lg" className={classes.container}>
            <AppHeader
              history={props.history}
              selected={selected}
              pathName={pathName}
              handleSelected={handleSelected}
              commodityInfo={commodityInfo}
              disableSwitch={disableSwitch}
              openWalletConnectModal={setOpen}
            />
            <Switch>
              <Route exact path={['/', '/dashboard']} component={Board} />
              <Route exact path="/trade" component={Mint} />
              <Route exact path="/liquidity" component={Liquidity} />
              {/* <Route exact path="/faucet" component={Faucet} /> */}
              <Route exact path="/transactions" component={TxHistory} />
              <Route exact path="/account" component={Account} />
              <Redirect to="/" />
            </Switch>
          </Container>
        </main>
      </Box>
      <Footer />
    </React.Fragment>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  network: state.user.network,
  networkID: state.user.networkID,
  commodities: state.commodities,
  txnsHistory: state.transactionHistory,
  collateralToken: state.collateralToken,
  balancerPool: state.balancerPool,
  selectedCommodityCheck: state.user.selectedCommodityCheck
});

export default connect(mapStateToProps, {
  updateLiquidity,
  updateTxnsHistory,
  updateGraphTypeFilter,
  updateGasEstimate,
  updateBalancerPool,
  updateGraphFilter,
  UserCommodityPnl,
  UserCommodityLiquidity,
  updateCurrentCommodity,
  updateIsLoading,
  updateCurrentCommodityBalances,
  UserCommodityReward,
  updateConsideredCommodities
})(App);
