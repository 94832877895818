import React from 'react';
import { makeStyles } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { connect } from 'react-redux';
import mtlxContract from '../../ABI/MTLX_20210517.json';
import NumberFormat from 'react-number-format';
import BigNumber from 'bignumber.js';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Wallet from '../Table/Wallet';

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: 'center',
    marginTop: '5%'
  },
  title: {
    fontSize: '45px'
  },
  typography: {
    fontSize: 14,
    color: theme.text
  },
  head: {
    fontSize: 14,
    color: theme.secondaryText
  },
  copy: {
    boxShadow: '3px 3px 5px 0px #ccc',
    textAlign: 'center',
    display: 'flex',
    padding: '2px 12px',
    margin: 'auto'
  },
  chip: {
    top: '50%',
    right: '16px',
    position: 'absolute',
    background: 'transparent',
    transform: 'translatey(-50%)',
    height: '24px',
    width: '24px',
    backfaceVisibility: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      height: '14px',
      width: '14px',
      margin: 0
    },
    '&:hover': {
      background: theme.grid.color,
      '& img': {
        filter: 'brightness(0)'
      }
    },
    '& .MuiChip-label': {
      display: 'none'
    }
  },
  cardContent: {
    padding: '25px 0px 8px 0',
    fontSize: '14px',
    '@media (min-width:768px) and (max-width:991px)': {
      padding: '10px 0px 8px 0'
    }
  },
  cardHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: '8px 0',
    color: theme.text,
    '& .MuiTypography-root': {
      fontFamily: `"MontserratMedium"`
    }
  },
  address: {
    display: 'flex',
    padding: '10px 20px 9px',
    background: theme.card.background,
    position: 'relative',
    '& .MuiTypography-root': {
      fontFamily: `"MontserratLight"`
    }
  },
  table: {
    overflowY: 'scroll',
    color: theme.text
  },
  tableHead: {
    color: theme.secondaryText,
    background: theme.dropdown.backgroundColor,
    padding: '8px 20px 8px 20px',
    fontSize: 14,
    fontWeight: 600,
    border: 'none',
    lineHeight: 0,
    '& .MuiTableSortLabel-root:hover': {
      color: theme.text
    },
    '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
      opacity: 1
    }
  },
  walletIco: {
    width: '1.3em',
    height: '1.3em'
  },
  tableHeadText: {
    textAlign: 'right',
    paddingLeft: 0
  },
  tableName: {
    color: '#4033AA',
    cursor: 'pointer',
    '&:hover': {
      color: theme.grid.color
    }
  },
  tableCell: {
    color: theme.secondaryText,
    background: theme.dropdown.fadedBackgroundColor,
    fontSize: '14px',
    fontWeight: '300',
    border: 'none',
    borderBottom: theme.border,
    fontFamily: 'MontserratLight',
    padding: '10px 20px 9px !important',
    '&:last-child': {
      color: theme.text
    }
  },
  primaryText: {
    color: theme.text
  },
  empty: {
    background: theme.dropdown.fadedBackgroundColor,
    fontWeight: '500',
    color: theme.text,
    borderBottom: 'none',
    paddingLeft: '20px!important'
  },
  textColor: {
    color: theme.secondaryText
  },
  tableFirstRow: {
    color: theme.secondaryText,
    paddingLeft: '20px !important',
    paddingRight: '0!important'
  },
  tableLastRow: {
    color: theme.secondaryText,
    paddingRight: '20px !important'
  },
  tableRow: {
    '&:last-child': {
      '& .MuiTableCell-root': {
        borderBottom: 'none',
        padding: '10px 20px !important'
      }
    }
  },
  mtlxSymbol: {
    padding: '0 5px',
    marginLeft: '5px',
    background: 'white',
    color: theme.card.background,
    margin: '0px 5px',
    // padding: "1px 3px",
    borderRadius: '6px',
    fontSize: '10px'
  },
  alignWalletForTrade: {
    padding: '11px 10px 11px 10px'
  },
  alignWalletHeaderForTrade: {
    padding: '10px 10px 10px 10px',
    '@media (min-width:1366px)': {
      // or whatever you are after
      padding: '10px 10px 9px 10px'
    },
    '@media (min-width:1900px)': {
      // or whatever you are after
      padding: '10px 10px 9px 10px'
    }
  }
}));

const UserBalance = (props) => {
  const classes = useStyles();
  // const [copied, setCopied] = useState(false);
  // const [value, setValue] = useState('');

  // const onCopy = (text) => {
  //   if (!copied) {
  //     setCopied(true);
  //     setValue(text);
  //     setTimeout(() => {
  //       setCopied(false);
  //       setValue('');
  //     }, 1000);
  //   }
  // };

  // const handleCopy = (address) => {
  //   if (address)
  //     return <CopyToClipboard text={address} onCopy={() => onCopy(address)} className={classes.chip}>
  //       <Tooltip title={copied && (address === value) ? "Copied!" : "Click to copy"}>
  //         <Chip
  //           size="small"
  //           variant="default"
  //           color={copied && (address === value) ? "primary" : "default"}
  //           key={address}
  //           icon={<img src={CopyIcon} alt="copy"/>}
  //           onDelete={undefined}
  //         />
  //       </Tooltip>
  //     </CopyToClipboard>
  // }

  // const textFomate = (address) => {
  //   if (address)
  //     return `${address.slice(0, 15)}.........${address.slice(-5)}`;
  //   return `..............`;
  // }

  const createData = (address, currency, nonCustodial) => {
    return { address, currency, nonCustodial };
  };

  const rows = [
    props.networkID &&
      createData(
        props.collateralToken.address,
        props.collateralToken.name,
        <NumberFormat
          value={new BigNumber(props.userBalances?.USDT ? props.userBalances?.USDT : 0)
            .decimalPlaces(3)
            .toString(10)}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
    props.networkID &&
      createData(
        mtlxContract.address[props.networkID],
        'MTLX',
        <NumberFormat
          value={new BigNumber(props.userBalances?.MTLX ? props.userBalances?.MTLX : 0)
            .decimalPlaces(3)
            .toString(10)}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
    props.balances?.long &&
      createData(
        props.balances?.long[0],
        props.balances?.long[1],
        <NumberFormat
          value={new BigNumber(
            props.userBalances[props.currentCommodity.metadata.long_token_contract_address]
              ? props.userBalances[props.currentCommodity.metadata.long_token_contract_address]
              : 0
          )
            .decimalPlaces(3)
            .toString(10)}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
    props.balances?.short &&
      createData(
        props.balances?.short[0],
        props.balances?.short[1],
        <NumberFormat
          value={new BigNumber(
            props.userBalances[props.currentCommodity.metadata.short_token_contract_address]
              ? props.userBalances[props.currentCommodity.metadata.short_token_contract_address]
              : 0
          )
            .decimalPlaces(3)
            .toString(10)}
          displayType={'text'}
          thousandSeparator={true}
        />
      )
  ];

  // const mintHeadings = [
  //   {
  //     key: "currency",
  //     label: '',
  //     type: 'mint'
  //   },
  //   {
  //     key: "nonCustodial",
  //     label: "My Wallet",
  //     type: 'mint'
  //   }
  // ];
  return (
    <CardContent className={classes.cardContent}>
      <Wallet
        icon={<AccountBalanceWalletIcon />}
        title={'My Wallet'}
        rowData={props.balances?.USDT ? rows : []}
        emptyText={'Loading...'}
        networkID={props.networkID}
        extendedClass={classes.alignWalletForTrade}
        extendedClassForHeader={classes.alignWalletHeaderForTrade}
        isLoading={
          Object.keys(props.tradeWalletBalancesisLoading).length > 0 &&
          props.tradeWalletBalancesisLoading[props.currentComodityId]
            ? true
            : false
        }
      />
    </CardContent>
  );
};

const mapStateToProps = (state) => ({
  networkID: state.user.networkID,
  network: state.user.network,
  address: state.user.address,
  enabled: state.user.enabled,
  PnLisLoading: state.user.PnLisLoading,
  collateralToken: state.collateralToken,
  walletAvailability: state.user.walletAvailability,
  balances: state.tradePage.walletBalances,
  userBalances: state.user.balances,
  currentCommodity: state.currentCommodity,
  tradeWalletBalancesisLoading: state.tradePage.tradeWalletBalancesisLoading
});

export default connect(mapStateToProps, null)(UserBalance);
