import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  margin: {
    height: theme.spacing(3)
  },
  root: {
    // width: '84%',
    padding: '0 20px',
    flex: parseInt(Math.ceil(window.screen.width / 96))
    // height: '21px',
    // marginTop: '-6px'
    // marginBottom: "20px",
    // "& .MuiSlider-root": {
    //   width: '100%',
    //   "&:before, &:after": {
    //     content: '""',
    //     position: "absolute",
    //     left: '-10px',
    //     height: 20,
    //     width: 20,
    //     backgroundColor: theme.chart.color,
    //     top: '50%',
    //     transform: 'translateY(-50%)',
    //     marginTop: 4,
    //     zIndex: 1,
    //   },
    //   '&:after': {
    //     left: 'auto',
    //     right: '-10px',
    //   },
    //   '& .MuiSlider-mark': {
    //     display: 'none',
    //   }
    // },
  }
}));

const CustomSlider = withStyles({
  markLabel: {
    color: '#68DBDA',
    height: '5px'
  },
  disabled: {
    color: '#68DBDA !important',
    padding: '13px 0'
  },
  track: {
    height: '5px'
  },
  rail: {
    backgroundColor: '#9098AC'
  },
  valueLabel: {
    left: 'calc(-50% + 24px)',
    top: '1px',
    color: '#000000 !important',
    fontSize: '12px',
    '& *': {
      background: 'transparent',
      color: '#000'
    }
  },
  mark: {
    position: 'absolute',
    backgroundColor: '#2C2D3D',
    height: '5px',
    width: 1
  },
  thumb: {
    height: '21px',
    width: '40px',
    borderRadius: 0,
    backgroundColor: '#68DBDA',
    marginTop: -11.5,
    marginLeft: -20
  }
})(Slider);

const CustomizedSlider = ({ min, max, mark, value, cap, floor }) => {
  const classes = useStyles();
  const steps = (max - min) / 40;
  const marks = Array.from(new Array(40).keys()).map((_val, index) => ({
    value: min + steps * index
  }));

  return (
    <div className={classes.root}>
      <CustomSlider
        className={
          cap < value || floor > value
            ? `${classes.slider} color-slider color-change`
            : `${classes.slider} color-slider`
        }
        disabled
        max={max}
        min={min}
        defaultValue={value}
        aria-labelledby="disabled-slider"
        marks={marks}
        valueLabelDisplay="on"
        // marks={value > cap ? [{
        //   label: value,
        //   value: max
        // }] : value < floor ? [{
        //   label: value,
        //   value: min
        // }] : mark}
        // ThumbComponent={ArrowDropUpIcon}
      />
    </div>
  );
};

export default CustomizedSlider;
