import { put, takeLatest } from 'redux-saga/effects';
import { updateMetadata } from '../reducer/currentCommodity';
import { updatePnLStatsTable } from '../reducer/tradePageReducer';
import { resetBalPool, resetWalletBalances } from '../reducer/tradePageReducer';
function* startSaga() {
  yield takeLatest('UPDATE_CURRENT_COMMODITY', updateCurrentCommodity);
}

// develops current user Commodity PnL
export function* updateCurrentCommodity(action) {
  const commodity = action.payload;
  // const commodityPrices =  yield select((state) => state.commodityPrices)
  yield put(updatePnLStatsTable({}));
  yield put(resetBalPool());
  yield put(resetWalletBalances());
  yield put(updateMetadata(commodity));
  yield put({ type: 'REFRESH_PRICES', payload: commodity });
}

export default startSaga;
