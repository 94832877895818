import positionTokenContract from '../ABI/POSITIONTOKEN_20200820.json';
class PositionTokenContract {
  constructor(address) {
    this.UserContract = new window.web3.eth.Contract(positionTokenContract.abi, address);
  }

  getInstance = async () => this.UserContract;

  getVolume = async () => {
    const positionTokenInstance = await this.getInstance();
    const totalSupply = await positionTokenInstance.methods.totalSupply().call();
    const decimals = await positionTokenInstance.methods.decimals().call();
    return totalSupply / 10 ** decimals;
  };

  approve = async (address, value, walletAddress) => {
    console.log('this.approve', address, value);
    const positionTokenInstance = await this.getInstance();
    let gas = 300000;
    try {
      gas = await positionTokenInstance.methods.approve(address, value).estimateGas({
        from: walletAddress
      });
    } catch (err) {
      console.log(err);
    }
    await positionTokenInstance.methods.approve(address, value).send({
      from: walletAddress,
      gas
    });
    return;
  };

  getDecimals = async () => {
    const positionTokenInstance = await this.getInstance();
    const decimals = await positionTokenInstance.methods.decimals().call();
    return decimals;
  };
}

let postionToken = null;

const getPositionContract = (address) => {
  postionToken = new PositionTokenContract(address);
  Object.freeze(postionToken);
  return postionToken;
};

export default getPositionContract;
