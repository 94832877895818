import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'commodities',
  initialState: {},
  reducers: {
    updateCommodities(state, action) {
      Object.assign(state, action.payload);
    },
    resetCommodities(state, action) {
      const networkID = action.payload;
      Object.keys(state).map((id) => {
        if (state[id].network_id !== networkID) delete state[id];
        return null;
      });
    }
  }
});

const { actions, reducer } = configSlice;

export const { updateCommodities, resetCommodities } = actions;

export default reducer;
