import React from 'react';
import ReactApexChart from 'react-apexcharts';

const HistoryChart = ({ options, series }) => {
  let dummySeries = [{ name: '', data: [0] }];
  return (
    <ReactApexChart
      options={options}
      series={series[0]?.data !== 0 ? series : dummySeries}
      type="area"
      height={300}
    />
  );
};
export default React.memo(HistoryChart);
