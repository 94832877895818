import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import { LightTooltip } from '../LightToolTip';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 42,
    //width: 194,
    padding: '12px 2px 0 2px',
    //marginRight: '-12px',
    marginTop: '-12px',
    zIndex: 0,
    overflow: 'hidden',
    position: 'relative',
    boxSizing: 'border-box',
    flexShrink: 0,
    verticalAlign: 'middle',
    textTransform: 'capitalize',
    '& .MuiToggleButton-root': {
      textTransform: 'capitalize',
      border: '1px solid #68dbda',
      padding: 0,
      lineHeight: 1.4
    },
    '& .MuiToggleButton-label': {
      fontSize: '14px',
      backgroundColor: 'transparent',
      //border: theme.border,
      borderRadius: 0,
      color: theme.text,
      padding: '9px 15px'
    },
    '& .MuiToggleButton-root.Mui-selected': {
      backgroundColor: 'transparent'
    },
    '& .MuiToggleButton-root.Mui-selected:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal.Mui-selected':
      {
        backgroundColor: '#68dbda'
      },
    '& .MuiButtonBase-root.MuiToggleButton-root.MuiToggleButtonGroup-grouped.MuiToggleButtonGroup-groupedHorizontal.Mui-selected > span':
      {
        color: 'black'
      },
    '& .MuiToggleButton-root:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

export default function Switches(props) {
  const classes = useStyles();
  const [commodityStatus, setCommodityStatus] = React.useState(
    props.filter.active ? 'Active' : 'Settled'
  );

  React.useEffect(() => {
    setCommodityStatus(props.filter.active ? 'Active' : 'Settled');
  }, [props.filter.active]);

  const handleChange = (event, newStatus) => {
    if (newStatus !== null) {
      setCommodityStatus(newStatus);
      props.handleChange(newStatus);
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      className={classes.root}
      value={commodityStatus}
      exclusive
      onChange={handleChange}
    >
      {props?.disableSwitch === 'active' ? (
        <LightTooltip title={'No Active Commodity'}>
          <span>
            <ToggleButton disabled={true} value="Active">
              Active
            </ToggleButton>
          </span>
        </LightTooltip>
      ) : (
        <ToggleButton value="Active">Active</ToggleButton>
      )}
      {props?.disableSwitch === 'settled' ? (
        <LightTooltip title={'No Settled Commodity'}>
          <span>
            <ToggleButton disabled={true} value="Settled">
              Settled
            </ToggleButton>
          </span>
        </LightTooltip>
      ) : (
        <ToggleButton value="Settled">Settled</ToggleButton>
      )}
    </ToggleButtonGroup>
  );
}
