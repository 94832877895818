import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FindInPageIcon from '@material-ui/icons/FindInPage';

const useStyles = makeStyles((theme) => {
  return {
    imageWrapper: {
      textAlign: 'center',
      padding: '30px 0 50px 0',
      background: "#222330",
      color: "white"
    },
    icoSize: {
      fontSize: '5rem',
      opacity: '0.1'
    },
    Typography: {
      opacity: 0.3
    }
  };
});

const DataNotFound = (props) => {
  const classes = useStyles();
  const { displayText } = props;
  return (
    <Grid container>
      <Grid item sm={12} className={classes.imageWrapper}>
        <FindInPageIcon className={classes.icoSize} />
        <Typography className={classes.Typography}>{displayText}</Typography>
      </Grid>
    </Grid>
  );
};

export default DataNotFound;
