import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { makeStyles, CardContent } from '@material-ui/core';
import TokenDetails from '../../component/tokenDetailsValue/TokenDetailsValue';
import Slider from '../../component/tokenDetailsValue/Slider';
import UserBalance from '../../component/userBalance';
import TradingGraph from '../../component/tradingGraph';
import MintRedeemTabs from '../../component/mintRedeemView/tabs';
import { createAction } from '@reduxjs/toolkit';
import WidgetBalance from './WidgetBalance';

const settleToken = createAction('SETTLE_TOKEN');
const refreshPrices = createAction('REFRESH_PRICES');

const useStyles = makeStyles((theme) => ({
  mintRedeemView: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  typography: {
    fontSize: 18,
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.5)
  },
  maxButton: {
    color: theme.grid.color,
    borderColor: theme.grid.color,
    fontFamily: `"MontserratMedium"`,
    textTransform: 'none',
    fontSize: 16,
    '&:disabled': {
      color: 'grey',
      borderColor: 'grey'
    }
  },
  card: {
    padding: 12,
    background: theme.card.background,
    boxShadow: theme.card.boxShadow,
    borderRadius: '5px',
    opacity: 1
  },
  reverseCards: {
    '@media (max-width:960px)': {
      // eslint-disable-next-line
      flexDirection: 'column-reverse',
      display: 'flex'
    }
  },
  cardContent: {
    padding: '25px 0px 8px 0',
    fontSize: '14px',
    '@media (min-width:768px) and (max-width:991px)': {
      padding: '10px 0px 8px 0'
    }
  },
  subHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: '8px 0',
    color: theme.text
  },
  background: {
    backgroundColor: theme.backgroundColor
  },
  headerContainer: {},
  cardHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: '8px 0',
    color: theme.text,
    '& .MuiTypography-root': {
      fontFamily: `"MontserratMedium"`
    }
  },

  graphSliderWrap: {
    position: 'relative'
  }
}));

const MintRedeem = (props) => {
  const classes = useStyles();

  const handleSettlement = () => {
    props.settleToken({
      id: props.selected,
      contractAddress: props.commodity.mettalex_contract_address
    });
  };

  const handleRefresh = () => {
    props.handleSelected(props.selected);
  };

  return (
    <React.Fragment>
      <Grid container spacing={1} className="redeem">
        <Grid item md={9} sm={12}>
          <CardContent id="trade-chart" className={classes.cardContent}>
            <TokenDetails
              tokenPrices={props.currentCommodity}
              balancerPool={props.balancerPool}
              handleRefresh={handleRefresh}
              handleSettlement={handleSettlement}
              commodity={props.commodity}
            />
            <WidgetBalance />
            <div className={classes.graphSliderWrap}>
              <TradingGraph />
              <Slider tokenDetails={props.commodity} tokenPrices={props.currentCommodity} />
            </div>
          </CardContent>
        </Grid>
        <Grid item md={3} sm={12} className={classes.reverseCards}>
          <Grid className={classes.userBalance}>
            <UserBalance currentComodityId={props.currentComodityId} />
          </Grid>
          <Grid>
            <MintRedeemTabs
              selected={props.selected}
              contractAddress={props.commodity ? props.commodity.mettalex_contract_address : ''}
              balance={props.balance}
              isSettled={props.commodity ? props.commodity.is_settled : false}
              selectedCommodity={props.commodity}
              handleSettlement={handleSettlement}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  balancerPool: state.tradePage.balPool,
  currentCommodity: state.currentCommodity,
  commodity: state.currentCommodity.metadata,
  balance: state.tradePage.walletBalances
});
export default connect(mapStateToProps, { settleToken, refreshPrices })(MintRedeem);
