import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import { useLocation } from 'react-router-dom';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';

import { connect } from 'react-redux';

import { updateTab } from '../../reducer/currentCommodity';
import AppMenuItemComponent from './AppMenuItemComponent';
import { Typography } from '@material-ui/core';

const AppMenuItem = (props) => {
  const { name, link, Icon, items = [] } = props;
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = useState(false);
  let location = useLocation();

  function handleClick() {
    setOpen(!open);
  }

  useEffect(() => {
    if (location.pathname !== '/trade' && props.tab !== 0) props.updateTab({ tab: 0 });
  });

  const MenuItemRoot = (
    <AppMenuItemComponent className={classes.menuItem} link={link} onClick={handleClick}>
      {!!Icon && (
        <ListItemIcon>
          <img src={Icon} alt="menu" style={{ position: 'relative' }} />
        </ListItemIcon>
      )}
      <Typography className={classes.menuText}>{name}</Typography>
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      opacity: 0.5,
      marginBottom: '22px',
      '& .MuiListItemIcon-root': {
        marginLeft: '8px'
      },
      '&:hover': {
        opacity: 1
      },
      '&.active': {
        opacity: 1
      },
      '&.active:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        top: 0,
        left: '100%',
        width: '2px',
        margin: 'auto',
        height: '24px',
        display: 'block',
        background: '#68DBDA'
      },
      menuText: {
        paddingTop: '1px'
      }
    }
  })
);

const mapStateToProps = (state) => ({
  tab: state.currentCommodity.tab
});

export default connect(mapStateToProps, { updateTab })(AppMenuItem);
