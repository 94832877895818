import { select, put, takeEvery } from 'redux-saga/effects';
import { updateLiquidityRewardsisLoading } from '../reducer/user';
import { liquidityTransactionHistoryURL } from '../constant/index';
import { userCommodityHistory } from './transactionHistory';
import { getAccountAddress, getNetworkId } from '../utils';
import { updateInvestedUSDT } from '../reducer/liquidityPool';

function* startSaga() {
  yield takeEvery('FETCH_USER_COMMODITY_LIQUIDITY_DEPOSIT', fetchUserCommodityLiquidityVolume);
}

// develops current user Commodity PnL
export function* fetchUserCommodityLiquidityVolume(action) {
  const commodity = action.payload;
  yield put(updateLiquidityRewardsisLoading({ id: commodity.id, value: true }));
  const coinToken = yield select((state) => state.collateralToken);
  let walletAvailability = yield select((state) => state.user.walletAvailability);
  let walletAddress;
  let networkID;
  if (!walletAvailability) {
    walletAddress = process.env.REACT_APP_STATIC_WALLET_ADD;
    networkID = 97;
  } else {
    walletAddress = yield getAccountAddress();
    networkID = yield getNetworkId();
  }

  let LiquidityData = [];
  try {
    LiquidityData = yield userCommodityHistory(
      liquidityTransactionHistoryURL,
      1,
      1000,
      commodity.id,
      walletAddress,
      networkID
    );
    const data = {
      totalUSDTDeposited: 0,
      totalUSDTWithdrawn: 0,
      investedUSDT: 0
    };

    LiquidityData.data.map((txn) => {
      let volumeTraded = txn.usdt_value / 10 ** coinToken.decimal;
      if (txn.action === 'withdraw') {
        data.totalUSDTWithdrawn += volumeTraded;
        data.investedUSDT -= volumeTraded;
      } else if (txn.action === 'deposit') {
        data.totalUSDTDeposited += volumeTraded;
        data.investedUSDT += volumeTraded;
      }
      return 1;
    });
    yield put(
      updateInvestedUSDT({ ...data, liquidityPoolAddress: commodity.liquidity_pool_address })
    );
  } catch (err) {
    console.log(commodity.commodity_symbol, err);
  }

  yield put(updateLiquidityRewardsisLoading({ id: commodity.id, value: false }));
}

export default startSaga;
