import React from 'react';
import {
  makeStyles,
  Grid
  // Typography,
} from '@material-ui/core';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  flexCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50%'
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 10,
    gridAutoRows: `minmax(70px, auto)`
  },
  valueDisplayContainer: {
    height: '100%'
  },
  figureDisplay: {
    color: '#68dbda',
    fontSize: '1.8em',
    paddingBottom: 5
  },
  itemWhiteText: {
    fontSize: 14,
    color: theme.text
  },
  '@media (min-width:1024px) and (max-width:1400px)': {
    itemWhiteText: {
      fontSize: 12
    },
    figureDisplay: {
      color: '#68dbda',
      fontSize: '1.4em',
      paddingBottom: 5
    }
  }
}));

const ApyAndMtlxCard = ({
  page,
  boxStyles,
  alignContent,
  apyValue,
  MtlxValue,
  poolTVL,
  openPositionValue,
  collateralToken
}) => {
  const classes = useStyles();

  return (
    <Grid item className={`${boxStyles} boxDesign`}>
      <div className={classes.wrapper}>
        <div className={alignContent}>
          <div className={classes.figureDisplay}>
            {!!poolTVL ? parseFloat(poolTVL).toFixed(2) : 0}
          </div>
          <div className={classes.itemWhiteText}>{page === "dashboard" ? "DEX TVL" : "Pool TVL"} ({collateralToken?.name})</div>
        </div>
        <div className={alignContent}>
          <div className={classes.figureDisplay}>
            {!!openPositionValue ? parseFloat(openPositionValue).toFixed(2) : 0}
          </div>
          <div className={classes.itemWhiteText}>
            Value of Open Positions ({collateralToken?.name})
          </div>
        </div>
        <div className={alignContent}>
          <div className={classes.figureDisplay}>{apyValue}</div>
          <div className={classes.itemWhiteText}>Average APY</div>
        </div>
        <div className={alignContent}>
          <div className={classes.figureDisplay}>{MtlxValue}</div>
          <div className={classes.itemWhiteText}>Total MTLX/Day</div>
        </div>
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  collateralToken: state.collateralToken
});

export default connect(mapStateToProps, null)(ApyAndMtlxCard);
