import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Loader from '../../assets/loadingIconWhite.bin';

const useStyles = makeStyles((theme) => {
  return {
    backdrop: {
      position: 'absolute',
      zIndex: 2,
      color: '#9098AC',
      width: '100%',
      height: '100%',
      background: '#222330'
    },
    backdrop2: {
      zIndex: 2,
      color: '#9098AC',
      width: '100%',
      height: '100%',
    },
    loaderDimensions: {
      maxHeight: 30,
      minHeight: 30,
      opacity: 0.5
    },
    loaderText: {
      textAlign: 'center',
      fontSize: 12
    },
    loaderWrapper: {
      position: 'relative',
      transform: 'translateY(-50%)',
      '-webkit-transform': 'translateY(-50%)',
      '-ms-transform': 'translateY(-50%)',
      top: '50%',
      left: 0,
      right: 0,
      textAlign: 'center'
    }
  };
});

const LoaderOverlay = (props) => {
  const { title } = props;
  const classes = useStyles();

  let css = classes.backdrop;
  if(props?.css?.backdrop_abs === false) {
    css = classes.backdrop2;
  }
  return (
    <div className={css}>
      <div className={classes.loaderWrapper}>
        <img src={Loader} alt="loader" className={classes.loaderDimensions}></img>
        <Typography className={classes.loaderText}>{title}</Typography>
      </div>
    </div>
  );
};

export default LoaderOverlay;
