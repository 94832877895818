export const commodityHistoryURL =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/fetch_history_prices?';
export const commodityLatestSpotURL =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/get_commodity_spot_data?';
export const commodityLatestHistoryURL =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/history_prices?';
export const commoditiesURL =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/fetch_commodities';
export const collateralTokenURL =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/collateral_token';
export const faucetTransferURL = 'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/faucet';
export const fetchliquidityURL =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/get_liquidity_values';
export const fetchbpoolURL =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/get_bpool_values';
export const transactionHistoryURL =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/transaction_history';
export const rewardTransactionURL =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/fetch_mettalex_rewards';
export const swapTransactionHistoryURL =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/get-swap-history';
export const liquidityTransactionHistoryURL =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/get-liquidity-history';
export const mintRedeemTransactionHistoryURL =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/get-mint-redeem-history';
export const calculatePnlURL =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/calculate_PNL';
export const rewardTransactionURLCombined =
  'https://europe-west2-fetch-ai-mettalex.cloudfunctions.net/fetch_mettalex_rewards_combined';

export const basicAuth = 'Basic bWludF9yZWRlZW1fcGFnZTo3U1gjJDEzcmVDdA==';

export const etherscanAddressURL = {
  42: 'https://kovan.etherscan.io/address/',
  97: 'https://testnet.bscscan.com/address/',
  56: 'https://www.bscscan.com/address/'
};
export const ethFaucetURL = {
  42: 'https://faucet.kovan.network/',
  97: 'https://testnet.binance.org/faucet-smart'
};
export const txAddressURL = {
  42: 'https://kovan.etherscan.io/tx/',
  97: 'https://testnet.bscscan.com/tx/',
  56: 'https://www.bscscan.com/tx/'
};

export const decimals = {
  cap: 10,
  floor: 10,
  current: 10,
  mtlx: 10 ** 18,
  eth: 10 ** 18,
  liquidityProviderFee: 10 ** 18,
  // decimals to truncate for display purpose
  capDigits: 1,
  floorDigits: 1,
  currentDigits: 2,
  ethDigits: 6,
  mtlxDigits: 6
};

// export const coinTokens = {
//   42: {
//     name: "USDT",
//     token: "USDT",
//     address: "0xe551960F80e5f855bB75d36016Ca92c981314b3E",
//     version: 1,
//     commodityIndex: 0,
//     isCoinToken: true,
//     decimal: 6
//   },
//   97: {
//     name: "USDT",
//     token: "USDT",
//     address: "0xa5Ebc90a713908872f137f7e468c2d887a8A2869",
//     version: 1,
//     commodityIndex: 0,
//     isCoinToken: true,
//     decimal: 6
//   },
//   56: {
//     name: "MUSD",
//     token: "MUSD",
//     address: "0x6e71C530bAdEB04b95C64a8ca61fe0b946A94525",
//     version: 1,
//     commodityIndex: 0,
//     isCoinToken: true,
//     decimal: 6
//   }
// }

// export const collateralTokenNames = ['USDT','MUSD','mxUSDT','mxMUSD']

// export const fetAddress = "0xBcAA62e0c05295C6757886f68A1e6a7Ff5120758";
export const networkName = 'kovan';

export const networks = {
  42: 'kovan',
  97: 'bsc-testnet',
  56: 'bsc-mainnet'
};

export const AlertMessage =
  'This transaction will consume a substantial amount of gas are you sure you want to proceed?';

export const maxFaucetAmount = 100000;
export const faucetDecimalAmount = 1000000;

export const TransactionHistoryRecordSize = 16;
