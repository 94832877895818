import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'currentCommodity',
  initialState: {
    historyPrices: [],
    historyPricesCode1: [],
    historyPricesCode2: [],
    currentPrice: 0,
    timeStamp: '',
    metadata: {},
    balancerPool: {},
    swapStats: {},
    pnlStatsTable: {},
    balances: {},
    isLoading: false,
    tab: 0,
    mtlxRewardBallance: 0,
    stackMtlxBallance: 0
  },
  reducers: {
    updateCommodityPrices(state, action) {
      state.historyPricesCode1 = action.payload.historyPricesCode1;
      state.historyPricesCode2 = action.payload.historyPricesCode2;
      state.historyPrices = action.payload.historyPrices;
      state.currentPrice = action.payload.currentPrice;
      state.timeStamp = action.payload.timeStamp;
    },
    updateMetadata(state, action) {
      state.metadata = action.payload;
    },
    updateBalancerPoolPrice(state, action) {
      state.balancerPool.price = action.payload;
    },
    updateBalancerPoolBalance(state, action) {
      state.balancerPool.balance = action.payload;
    },
    updateBalancerPoolLpf(state, action) {
      state.balancerPool.lpf = action.payload.lpf;
    },
    updateSwapStats(state, action) {
      state.swapStats = action.payload;
    },
    updateCurrentBalance(state, action) {
      state.balances[action.payload.id] = action.payload.value;
    },
    updateTab(state, action) {
      state.tab = action.payload.tab;
    },
    updateMtlxBallance(state, action) {
      state.mtlxRewardBallance = action.payload;
    },
    updateStackMtllxBallance(state, action) {
      state.stackMtlxBallance = action.payload;
    },
    updateCurrentCommodityIsLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});

const { actions, reducer } = configSlice;

export const {
  updateBalancerPoolPrice,
  updateBalancerPoolLpf,
  updateBalancerPoolBalance,
  updateTab,
  updateCommodityPrices,
  updateMetadata,
  updateSwapStats,
  updateCurrentBalance,
  updateMtlxBallance,
  updateStackMtllxBallance,
  updateCurrentCommodityIsLoading
} = actions;

export default reducer;
