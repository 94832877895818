import React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter } from 'react-router-dom';
import { lightTheme, darkTheme } from '../Theme';
import App from './App';
import { startSaga } from './rootSaga';
class ThemeSelector extends React.Component {
  render() {
    const themeMode = this.props.enabled ? darkTheme : lightTheme;
    return (
      <ThemeProvider theme={themeMode}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  enabled: state.user.enabled
});

const WrappedComponent = connect(mapStateToProps, null)(ThemeSelector);
export default () => {
  startSaga();
  return <WrappedComponent />;
};
