import React from 'react';
import { makeStyles, Grid, Box, CardHeader, CardContent } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createAction } from '@reduxjs/toolkit';
import { Backdrop } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../../assets/loadingIconWhite.bin';
import Typography from '@material-ui/core/Typography';
import TableData from '../../component/Table';
import TablePagination from '@material-ui/core/TablePagination';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import { LightTooltip } from '../../component/LightToolTip';
import DataNotFound from '../../component/DataNotFound';

const allTransactions = createAction('TRANSACTION_HISTORY_ALL');
const userTransactions = createAction('TRANSACTION_HISTORY_USER');
const commodityTransactions = createAction('TRANSACTION_HISTORY_COMMODITY');
const allLiquidityTransactions = createAction('LIQUIDITY_TRANSACTION_HISTORY_ALL');
const userLiquidityTransactions = createAction('LIQUIDITY_TRANSACTION_HISTORY_USER');
const commodityLiquidityTransactions = createAction('LIQUIDITY_TRANSACTION_HISTORY_COMMODITY');

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: '0px',
    fontSize: '14px',
    position: 'relative'
  },
  cardHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: '8px 0',
    color: theme.text,
    textTransform: 'uppercase',
    '& .MuiTypography-root': {
      fontFamily: 'MontserratMedium'
    }
  },
  subHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: '8px 0',
    color: theme.text
  },
  dotted: {
    fontWeight: 300,
    borderBottom: theme.borderBottom,
    textDecoration: 'none',
    display: 'flex',
    padding: '5px 0',
    color: theme.text
  },
  card: {
    padding: 12,
    background: theme.card.background,
    boxShadow: theme.card.boxShadow,
    borderRadius: '5px',
    opacity: 1
  },
  formControl: {
    minWidth: 127
  },
  divider: {
    backgroundColor: theme.divider.backgroundColor
  },
  button: {
    width: '100%',
    padding: 6,
    background: theme.maxButton.color,
    '&:hover': {
      backgroundColor: theme.maxButton.color,
      opacity: 0.8
    },
    '&:disabled': {
      backgroundColor: 'grey',
      color: 'white'
    },
    color: 'white'
  },
  maxButton: {
    color: theme.grid.color,
    borderColor: theme.grid.color,
    padding: '7px 9px 6px',
    '&:hover': {
      backgroundColor: theme.grid.color,
      color: 'white'
    },
    '&:disabled': {
      color: 'grey',
      borderColor: 'grey'
    }
  },
  typography: {
    textAlign: 'end',
    color: theme.text,
    fontSize: '12px'
  },
  selectEmpty: {
    padding: '0',
    fontSize: 14,
    color: theme.text,
    background: '#222330',
    borderRadius: '5px',
    textAlign: 'left',
    border: '2px solid #34495e',
    lineHeight: 1.4
  },

  filterClass: {
    textAlign: 'end',
    color: theme.secondaryText,
    fontSize: '14px',
    padding: '5px',
    fontFamily: 'MontserratMedium',
    '& .MuiSelect-root': {
      color: theme.grid.color,
      padding: '5px',
      fontSize: '14px'
    },
    '& .MuiSelect-icon': {
      top: 0,
      color: theme.grid.color
    }
  },
  pagination: {
    background: theme.dropdown.backgroundColor,
    color: theme.text,
    padding: '0 25px !important',
    width: '20%',
    margin: '0 auto',
    '& button[title="Previous page"]': {
      padding: 0,
      color: theme.text,
      position: 'absolute',
      top: '50%',
      left: '0',
      transform: 'translatey(-50%)'
    },
    '& button.Mui-disabled': {
      opacity: 0.3
    },
    '& button[title="Next page"]': {
      padding: 0,
      color: theme.text,
      position: 'absolute',
      top: '50%',
      right: '0',
      transform: 'translatey(-50%)'
    },
    '& .MuiTablePagination-caption': {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)'
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: '40px'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '16px'
    }
  },
  jumpToPage: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '52px',
    color: theme.text,
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: '300'
    },
    '& .MuiOutlinedInput-root': {
      marginLeft: 5
    },
    '& .MuiInputBase-input': {
      padding: 5,
      width: 19
    }
  },
  refreshIco: {
    padding: 3,
    width: '1.1em',
    height: '1.1em'
  },
  menuItem: {
    backgroundColor: theme.dropdown.fadedBackgroundColor,
    color: theme.text,
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    fontSize: 12,
    '&.placeholder': {
      backgroundColor: `${theme.dropdown.backgroundColor} !important`,
      '&:hover': {
        backgroundColor: `${theme.dropdown.backgroundColor} !important`
      }
    },
    '&:hover': {
      color: theme.grid.color,
      backgroundColor: theme.dropdown.fadedBackgroundColor
    },
    '&.MuiListItem-root.Mui-disabled': {
      backgroundColor: theme.dropdown.fadedBackgroundColor,
      color: theme.secondaryText,
      opacity: 1
    },
    '&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: theme.dropdown.fadedBackgroundColor
    }
  },
  backdrop: {
    zIndex: 1
  },
  loaderDimensions: {
    height: 15,
    width: 15
  },
  redirectButton: {
    color: 'theme.grid.color',
    // borderColor: theme.grid.color,
    border: 'revert',
    fontFamily: `"MontserratMedium"`,
    textTransform: 'none',
    fontSize: 14,
    marginRight: 10
  }
}));

const ComponentBackdrop = withStyles({
  root: {
    position: 'absolute',
    color: '#fff'
  }
})(Backdrop);

const TxHistory = (props) => {
  const classes = useStyles();
  const txHeadings = [
    {
      key: 'action',
      label: 'Action',
      type: 'tx'
    },
    {
      key: 'token_amount_in',
      label: 'Token Amount (+)',
      type: 'tx'
    },
    {
      key: 'token_amount_out',
      label: 'Token Amount (-)',
      type: 'tx'
    },
    {
      key: 'caller',
      label: 'Account',
      type: 'tx'
    },
    {
      key: 'created_at',
      label: 'Time',
      type: 'tx'
    }
  ];

  const selectValue =
    props.txnType === 'LIQUIDITY'
      ? props.liquidityTransactionHistory.selected
      : props.txnsHistory.selected;
  const rowData =
    props.txnType === 'LIQUIDITY'
      ? props.liquidityTransactionHistory.txHistory
      : props.txnsHistory.txHistory;
  const counts =
    props.txnType === 'LIQUIDITY'
      ? props.liquidityTransactionHistory.count
      : props.txnsHistory.count;
  const records =
    props.txnType === 'LIQUIDITY'
      ? props.liquidityTransactionHistory.records
      : props.txnsHistory.records;
  const page =
    props.txnType === 'LIQUIDITY' ? props.liquidityTransactionHistory.page : props.txnsHistory.page;

  const handleChange = (value) => {
    if (value === 1)
      props.txnType === 'LIQUIDITY'
        ? props.userLiquidityTransactions({
            shortLoader: true,
            page: 1,
            pageSize: records
          })
        : props.userTransactions({
            shortLoader: true,
            page: 1,
            pageSize: records
          });
    else if (value === 2) {
      if (props.commodities[props.selected])
        props.txnType === 'LIQUIDITY'
          ? props.commodityLiquidityTransactions({
              shortLoader: true,
              page: 1,
              pageSize: records,
              commodity: '' + props.selected + ''
            })
          : props.commodityTransactions({
              shortLoader: true,
              page: 1,
              pageSize: records,
              commodity: '' + props.selected + ''
            });
    } else {
      props.txnType === 'LIQUIDITY'
        ? props.allLiquidityTransactions({
            shortLoader: true,
            page: 1,
            pageSize: records
          })
        : props.allTransactions({
            shortLoader: true,
            page: 1,
            pageSize: records
          });
    }
  };

  const handleChangePage = (event, newPage) => {
    if (selectValue === 1)
      props.txnType === 'LIQUIDITY'
        ? props.userLiquidityTransactions({
            shortLoader: true,
            page: newPage + 1,
            pageSize: records
          })
        : props.userTransactions({
            shortLoader: true,
            page: newPage + 1,
            pageSize: records
          });
    else if (selectValue === 2) {
      if (props.commodities[props.selected]) {
        props.txnType === 'LIQUIDITY'
          ? props.commodityLiquidityTransactions({
              shortLoader: true,
              page: newPage + 1,
              pageSize: records,
              commodity: '' + props.selected + ''
            })
          : props.commodityTransactions({
              shortLoader: true,
              page: newPage + 1,
              pageSize: records,
              commodity: '' + props.selected + ''
            });
      }
    } else {
      props.txnType === 'LIQUIDITY'
        ? props.allLiquidityTransactions({
            shortLoader: true,
            page: newPage + 1,
            pageSize: records
          })
        : props.allTransactions({
            shortLoader: true,
            page: newPage + 1,
            pageSize: records
          });
    }
  };

  const handleRefresh = (value) => {
    handleChange(value);
  };

  const totalPages = () => {
    const total = Math.ceil(counts / records);
    if (total < 1) {
      return 1;
    } else {
      return total;
    }
  };

  return (
    <Box className={classes.transactionBox}>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} style={{ paddingTop: '27px' }}>
          <Grid
            className="tblHeader boxDesign topRadius"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Grid item xs={6}>
              <CardHeader title="History" className={`${classes.cardHeader} heading`} />
            </Grid>
            <Grid item xs={6} className={classes.filterClass}>
              <Button
                onClick={() => handleRefresh(selectValue)}
                size="small"
                className={`${classes.maxButton} btn-info`}
                style={{ marginRight: '10px' }}
              >
                <LightTooltip title="Refresh">
                  <CachedRoundedIcon className={classes.refreshIco} />
                </LightTooltip>
              </Button>
              <FormControl className={classes.formControl}>
                <Select
                  value={selectValue}
                  onChange={(event) => {
                    handleChange(event.target.value);
                  }}
                  className={`${classes.selectEmpty} selectDropdown`}
                  inputProps={{ 'aria-label': 'Without label' }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right'
                    },
                    getContentAnchorEl: null
                  }}
                >
                  <MenuItem className={classes.menuItem} value={1}>
                    User
                  </MenuItem>
                  <MenuItem className={classes.menuItem} value={2}>
                    Commodity
                  </MenuItem>
                  <MenuItem className={classes.menuItem} value={3}>
                    All
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <CardContent className={`${classes.cardContent} boxDesign bottomRadius`}>
            <ComponentBackdrop className={classes.backdrop} open={props.isLoadingIndividual}>
              <Grid style={{ textAlign: 'center' }}>
                <img src={Loader} alt="lock" className={classes.loaderDimensions}></img>
                <Typography className={classes.typography} style={{ textAlign: 'center' }}>
                  {props.loadingMessage}
                </Typography>
              </Grid>
            </ComponentBackdrop>
            <TableData
              headings={txHeadings}
              rowData={rowData || []}
              emptyText={<DataNotFound displayText={'No Transactions to Show'} />}
              networkID={props.networkID}
              isHistoryTable={true}
            />
            <TablePagination
              className={classes.pagination}
              component="div"
              rowsPerPageOptions={[]}
              count={counts}
              rowsPerPage={records}
              page={page - 1}
              onChangePage={handleChangePage}
              labelDisplayedRows={({ page }) => `Page ${page + 1} of ` + totalPages()}
            />
          </CardContent>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  commodities: state.commodities,
  networkID: state.user.networkID,
  isLoadingIndividual: state.user.isLoadingIndividual,
  loadingMessage: state.user.loadingMessage,
  txnsHistory: state.transactionHistory,
  liquidityTransactionHistory: state.liquidityTransactionHistory
});

export default withRouter(
  connect(mapStateToProps, {
    allTransactions,
    userTransactions,
    commodityTransactions,
    allLiquidityTransactions,
    userLiquidityTransactions,
    commodityLiquidityTransactions
  })(TxHistory)
);
