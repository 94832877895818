import StrategyBalancerContract from '../ABI/STRATEGY_BALANCER_20200917.json';
import positionToken from './positionTokenContract';
import { convertAmountToString } from '../utils/index';
// import BigNumber from "big-number";
import { BigNumber } from 'bignumber.js';

class StrategyBalancer {
  constructor(address) {
    this.UserContract = new window.web3.eth.Contract(StrategyBalancerContract.abi, address);
  }

  getInstance = async () => this.UserContract;

  getOutputConversion = async (fromAddress, toAddress, inputAmount) => {
    try {
      if (inputAmount === 0) return [0, 0];
      const fromPositonToken = positionToken(fromAddress);
      let decimals = await fromPositonToken.getDecimals();
      const amount = convertAmountToString(inputAmount, decimals);
      const contract = await this.getInstance();
      const Amounts = await contract.methods
        .getExpectedOutAmount(fromAddress, toAddress, amount)
        .call();

      const toPositonToken = positionToken(toAddress);
      decimals = await toPositonToken.getDecimals();
      let bigValue = new BigNumber(Amounts['tokensReturned']);
      let Balance = bigValue.dividedBy(10 ** decimals);
      //decimal conversion
      return [Balance.toString(10), parseFloat(Amounts['priceImpact'] / 10 ** 18)];
    } catch (err) {
      return { isError: true };
    }
  };

  swapTokens = async (fromAddress, toAddress, inputAmount, minOutputAmount, walletAddress) => {
    try {
      // const maxPrice = BigNumber(2).power(256).subtract(1).toString()
      const maxPrice = new BigNumber(2).pow(256).minus(1).toString(10);
      const contract = await this.getInstance();

      await contract.methods
        .swapExactAmountIn(fromAddress, inputAmount, toAddress, minOutputAmount, maxPrice)
        .send({ from: walletAddress });
    } catch (err) {
      console.log(err);
    }
  };

  getInputConversion = async (fromAddress, toAddress, outputAmount) => {
    try {
      if (outputAmount === 0) return [0, 0];
      const toPositonToken = positionToken(toAddress);
      let decimals = await toPositonToken.getDecimals();
      const amount = convertAmountToString(outputAmount, decimals);
      const contract = await this.getInstance();
      const Amounts = await contract.methods
        .getExpectedInAmount(fromAddress, toAddress, amount)
        .call();
      const fromPositonToken = positionToken(fromAddress);
      decimals = await fromPositonToken.getDecimals();
      //decimal conversion
      let bigValue = new BigNumber(Amounts['tokensReturned']);
      let Balance = bigValue.dividedBy(10 ** decimals);
      // return Balance.toString(10)
      return [Balance.toString(10), parseFloat(Amounts['priceImpact'] / 10 ** 18)];
    } catch (err) {
      return { isError: true };
    }
  };
}

const getContract = (address) => {
  const strategyContract = new StrategyBalancer(address);
  Object.freeze(strategyContract);
  return strategyContract;
};

export default getContract;
