import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import SentimentSlider from './Sentiment.js';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import { LightTooltip } from '../LightToolTip/index.js';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.text,
    //background:theme.card.background,
    background: '#2d2e3d',
    display: 'flex',
    // padding: 0,
    padding: '5px',
    width: '100%',
    // margin: '0px 18px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
    // fontWeight:'bold'
  },
  grid: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.text,
    marginTop: '5%'
  },
  slider: {
    width: '90%',
    marginLeft: '5%',
    marginBottom: '3.2%'
  },
  floorTypography: {
    width: '20%',
    marginTop: '2%',
    color: theme.text,
    fontSize: 14
  },
  capTypography: {
    marginTop: '2%',
    color: theme.text,
    margin: 'auto',
    marginRight: 0,
    fontSize: 14
  },
  barGrid: {
    display: 'flex',
    margin: '0 auto',
    width: '99.5%',
    '& span': {
      color: '#7C7C7D'
    }
  },
  typography: {
    textAlign: 'end',
    color: theme.text
  },
  background: {
    backgroundColor: theme.backgroundColor
  },
  subHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: '8px 0',
    color: theme.text
  },
  item: {
    textAlign: 'left',
    position: 'relative',
    padding: '8px',
    '@media (min-width:992px) and (max-width:1279px)': {
      padding: '0 8px'
    },
    '@media (min-width:1500px) and (max-width:1879px)': {
      padding: 7
    },
    '&:not(:last-child):after': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      width: 1,
      background: '#585862',
      height: '100%'
    }
  },
  itemGreyText: {
    fontSize: 12,
    color: theme.secondaryText
  },
  maxButton: {
    color: theme.grid.color,
    borderColor: theme.grid.color,
    fontFamily: `"MontserratMedium"`,
    textTransform: 'none',
    fontSize: 16,
    float: 'right',
    backgroundColor: '#293949',
    minWidth: '26px',
    padding: 0,
    '&:disabled': {
      color: 'grey',
      borderColor: 'grey'
    }
  },
  iconSize: {
    fontSize: 19
  },
  itemWhiteText: {
    fontSize: 16,
    color: theme.text
  },
  itemDateTime: {
    fontSize: 14,
    color: theme.text
  },
  '@media (min-width:1024px) and (max-width:1400px)': {
    itemDateTime: {
      fontSize: 13
    }
  }
}));

const TokenDetails = (props) => {
  const classes = useStyles();
  let percentage = 0;
  let total = props?.balancerPool?.longVolume + props?.balancerPool?.shortVolume;
  if (total > 0) {
    percentage = (props.balancerPool.shortVolume / total) * 100;
  }
  const currentValues = () => {
    return (
      <Grid item md={12} sm={12} className={`${classes.root} boxDesign`}>
        <Grid item xs className={classes.item}>
          <Grid item className={classes.itemGreyText}>
            Last Price Update
            <Button size="small" className={`${classes.maxButton}`} onClick={props.handleRefresh}>
              <LightTooltip title="Refresh">
                <CachedRoundedIcon className={classes.iconSize} />
              </LightTooltip>
            </Button>
          </Grid>
          <Grid item className={classes.grid}>
            {props.tokenPrices && props.tokenPrices.currentPrice ? (
              <Moment className={classes.itemDateTime} format="MMM D YYYY HH:mm">
                {props.tokenPrices.timeStamp}
              </Moment>
            ) : (
              '-'
            )}
            <Button
              size="small"
              className={`${classes.maxButton}`}
              onClick={props.handleSettlement}
              disabled={props.commodity ? !props.commodity.is_settled : true}
            >
              <LightTooltip title="Settle Positions">
                <SwapHorizIcon className={classes.iconSize} />
              </LightTooltip>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs className={classes.item}>
          <Grid item className={classes.itemGreyText}>
            Last Reported Price
          </Grid>
          <Grid item className={classes.grid}>
            <NumberFormat
              className={classes.itemWhiteText}
              value={Boolean(props.tokenPrices.currentPrice) ? props.tokenPrices.currentPrice : '-'}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              fixedDecimalScale={true}
            />
          </Grid>
        </Grid>
        <Grid item xs className={classes.item}>
          <Grid item className={classes.itemGreyText}>
            Long Price
          </Grid>
          <Grid item className={classes.grid}>
            <NumberFormat
              className={classes.itemWhiteText}
              value={!props.balancerPool.loader ? props.balancerPool.longSpotPrice : '-'}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Grid>
        </Grid>
        <Grid item xs className={classes.item}>
          <Grid item className={classes.itemGreyText}>
            Short Price
          </Grid>
          <Grid item className={classes.grid}>
            <NumberFormat
              className={classes.itemWhiteText}
              value={!props.balancerPool.loader ? props.balancerPool.shortSpotPrice : '-'}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
            />
          </Grid>
        </Grid>
        <Grid item xs className={classes.item}>
          <Grid item className={classes.itemGreyText}>
            Sentiment
            <SentimentSlider percentage={percentage} />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return currentValues();
};

export default TokenDetails;
