import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '2px',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#2FD593'
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#EE3737'
    }
  },
  short: {
    color: theme.text,
    fontSize: 10
  },
  long: {
    color: theme.text,
    fontSize: 10
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default function LinearDeterminate(props) {
  const classes = useStyles();
  const { percentage } = props;
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <span className={classes.short}>S</span>
        <span className={classes.long}>L</span>
      </div>
      <LinearProgress variant={'determinate'} value={percentage} />
    </div>
  );
}
