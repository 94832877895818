import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PopupDialog from '../../component/TradePopup';
import { makeStyles, Button, FormControl, MenuItem, InputBase, Select } from '@material-ui/core';
import { createAction } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { networks } from '../../constant';
import { getBalance, truncateDecimals, isMarketDisabled } from '../../utils/index';
import strategyBalancerContract from '../../contractInterface/strategyBalancerContract';
import Swap from '../../assets/Swap.svg';
import NumberFormat from 'react-number-format';
import { BigNumber } from 'bignumber.js';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { validateInputField, correctInputFieldValues } from '../utils';
import OverflowTooltip from '../../component/OverflowTooltip';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { LightTooltip } from '../LightToolTip';

const swapToken = createAction('SWAP_TOKEN');

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: theme.border,
    paddingBottom: 6,
    margin: '15px 0'
  },
  formControl: {
    marginBottom: 8,
    background: theme.card.background,
    padding: '10px 8px',
    width: '100%',
    fontSize: 12,
    borderRadius: '0.25rem',
    '& .MuiInputBase-root.Mui-disabled': {
      color: theme.text,
      opacity: 0.5
    },
    '& .MuiSelect-icon.Mui-disabled': {
      color: theme.text,
      opacity: 0.5
    }
  },
  cardContent: {
    padding: '13px 0px',
    fontSize: '14px'
  },
  typography: {
    color: theme.text,
    fontFamily: 'MontserratLight',
    fontSize: '14px',
    lineHeight: 1.4
  },
  greyTypography: {
    color: theme.secondaryText
  },
  input: {
    width: '100vh',
    color: theme.text,
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  button: {
    padding: '8px 35px',
    fontSize: '14px',
    fontFamily: 'MontserratMedium',
    marginLeft: 'auto',
    borderRadius: '0.25rem',
    background: theme.grid.color,
    border: theme.grid.border,
    color: 'black',
    '&:hover': {
      background: theme.grid.colorHover,
      border: theme.grid.borderHover,
      color: 'black'
    },
    '&:disabled': {
      background: '#585d6e',
      border: theme.borderAlt,
      color: 'black'
    }
  },
  maxButton: {
    borderRadius: '0',
    background: 'none',
    padding: 0,
    margin: 0,
    border: 0,
    minWidth: 8,
    color: 'aqua',
    minHeight: 0,
    lineHeight: '1em',
    '&:disabled': {
      color: '#41435b'
    },
    arrowIcon: {
      fontSize: '1.2rem'
    }
  },
  span: {
    fontSize: 12,
    color: '#EE3737'
  },
  menuItem: {
    backgroundColor: theme.dropdown.fadedBackgroundColor,
    color: theme.text,
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    fontSize: 14,
    '&.placeholder': {
      backgroundColor: `${theme.dropdown.backgroundColor} !important`,
      '&:hover': {
        backgroundColor: `${theme.dropdown.backgroundColor} !important`
      }
    },
    '&:hover': {
      color: theme.grid.color,
      backgroundColor: theme.dropdown.fadedBackgroundColor
    },
    '&.MuiListItem-root.Mui-disabled': {
      backgroundColor: theme.dropdown.fadedBackgroundColor,
      color: theme.secondaryText,
      opacity: 1
    },
    '&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: theme.dropdown.fadedBackgroundColor
    }
  },
  select: {
    color: theme.text,
    fontWeight: 300,
    '&:before': {
      borderColor: theme.text
    },
    '&:after': {
      borderColor: theme.text
    }
  },
  label: {
    color: theme.text
  },
  icon: {
    color: theme.text,
    width: 25,
    cursor: 'pointer',
    height: 25,
    padding: 0,
    borderRadius: 5,
    backgroundColor: '#34495e'
  },
  box: {
    backgroundColor: theme.dropdown.backgroundColor,
    padding: '8px 10px',
    borderRadius: '0.25rem',
    '& input': {
      color: theme.text,
      padding: '6px 8px 6px 0'
    }
  },
  swap: {
    position: 'absolute',
    right: '-18px',
    top: '50%',
    transform: 'translatey(-50%)',
    backgroundColor: theme.palette.secondary.main,
    width: 25,
    height: 25,
    margin: 'auto',
    borderRadius: 5,
    cursor: 'pointer',
    zIndex: 2,
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '& img': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      backfaceVisibility: 'hidden'
    },
    '&:hover': {
      background: theme.grid.color,
      '& img': {
        filter: 'brightness(0)'
      }
    }
  }
}));

const SwapView = (props) => {
  const classes = useStyles();
  // const [lpf, setLpf] = useState();
  const [minRecieved, setMinRecieved] = useState(0);
  const [priceImpact, setPriceImpact] = useState(0);
  const [positionToken, setPositionToken] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [fromTokenError, setFromTokenError] = useState('');
  const [toTokenError, setToTokenError] = useState('');
  const [tokenList, setTokenList] = useState([]);
  const [toTokens, setToTokens] = useState([]);
  const [fromTokens, setFromTokens] = useState([]);
  const [toToken, setToToken] = useState('token');
  const [fromToken, setFromToken] = useState('token');
  const [toDecimal, setToDecimal] = useState(0);
  const [fromDecimal, setFromDecimal] = useState(0);
  const [fromBalance, setFromBalance] = useState(0);
  const [toPoolBalance, setToPoolBalance] = useState(0);
  const [fromPoolBalance, setFromPoolBalance] = useState(0);
  const [toAmount, setToAmount] = useState(0);
  const [fromAmount, setFromAmount] = useState(0);
  const [tokenPrice, setTokenPrice] = useState();
  const [open, setOpen] = useState(props.isTransactionLoading);
  const [range, setRange] = useState(0);

  let invalidChars = ['-', '+', 'e', 'E'];

  const handleKeyPress = (e) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const getBalancerPoolAddress = (fromCommodity, toCommodity) => {
    let commodity = fromCommodity.isCoinToken ? toCommodity : fromCommodity;
    return commodity.balancerPoolAddress;
  };

  const getStrategyAddress = (fromCommodity, toCommodity) => {
    let commodity = fromCommodity.isCoinToken ? toCommodity : fromCommodity;
    return commodity.strategyAddress;
  };

  const getCommodityId = (fromCommodity, toCommodity) => {
    let commodityId = fromCommodity.isCoinToken
      ? toCommodity.commodityIndex
      : fromCommodity.commodityIndex;
    return commodityId;
  };

  const getTokenAddress = (fromCommodity, toCommodity) => {
    let address = fromCommodity.isCoinToken ? toCommodity.address : fromCommodity.address;
    return address;
  };
  const handleSwapClick = async () => {
    if (validate()) {
      const toCommodity = tokenList.find((token) => token.name === toToken);
      const fromCommodity = tokenList.find((token) => token.name === fromToken);
      const commodityId = getCommodityId(fromCommodity, toCommodity);
      const strategyAddress = getStrategyAddress(fromCommodity, toCommodity);
      if (strategyAddress)
      if(open){
        props.swapToken({
          strategyAddress,
          commodityId,
          fromAddress: fromCommodity.address,
          toAddress: toCommodity.address,
          walletAddress: props.address,
          fromAmount,
          minRecieved,
          profit: range * toAmount - fromAmount,
          loss: fromAmount,
          open: true
        });
      } else {
        props.swapToken({
          strategyAddress,
          commodityId,
          fromAddress: fromCommodity.address,
          toAddress: toCommodity.address,
          walletAddress: props.address,
          fromAmount,
          minRecieved
        });
      }
      else setToTokenError("The Balancer Pool for this does'nt exist");
    }
  };

  const handleTradeClick = async () => {
    if (validate()) {
      const fromCommodity = tokenList.find((token) => token.name === fromToken);
      if (fromCommodity.isCoinToken) {
        // const toCommodity = tokenList.find(token => token.name === toToken)
        const range = parseFloat(
          (props.selectedCommodity.collateral_per_unit * 10 ** props.selectedCommodity.decimal) /
          10 ** fromCommodity.decimal
          );
          setRange(range);
          setOpen(true);
        } else handleSwapClick();
    }
  };

  const reset = () => {
    setFromAmount(0);
    setToAmount(0);
    setToToken('token');
    setMinRecieved(0);
    setPriceImpact(0);
    setPositionToken('');
  };

  const validate = () => {
    if (!props.walletAvailability) {
      setFromTokenError('Please Connect Wallet');
      return false;
    } else {
      if (!fromAmount || new BigNumber(fromAmount).lte(BigNumber(0))) {
        setFromTokenError('Transfer Token needs to be greater than 0');
        return false;
      }
      if (new BigNumber(fromAmount).gt(BigNumber(fromBalance))) {
        setFromTokenError('Transfer Token needs to be less than balance amount');
        return false;
      }
      if (new BigNumber(toAmount).gt(BigNumber(toPoolBalance))) {
        setToTokenError(
          'Maximum Recieved Token that can be traded: ' +
            new BigNumber(toPoolBalance).decimalPlaces(toDecimal).toString(10)
        );
        return false;
      }
      if (new BigNumber(fromAmount).gt(BigNumber(fromPoolBalance))) {
        setFromTokenError(
          'Maximum Transfer Token that can be traded: ' +
            new BigNumber(fromPoolBalance).decimalPlaces(fromDecimal).toString(10)
        );
        return false;
      }
    }
    setFromTokenError();
    setToTokenError();
    return true;
  };

  const checkForMaxAmountAllowed = (amount) => {
    if (amount === '' || undefined) {
      amount = 0;
    }
    if (new BigNumber(amount).isGreaterThan(BigNumber(fromPoolBalance))) {
      props.walletAvailability &&
        setFromTokenError(
          'Transfer Token value can be max: ' +
            new BigNumber(fromPoolBalance).decimalPlaces(fromDecimal).toString(10)
        );
      amount = fromPoolBalance;
    }
    if (new BigNumber(amount).isGreaterThan(BigNumber(fromBalance))) {
      props.walletAvailability &&
        setFromTokenError(
          'Transfer Token value can be max: ' +
            new BigNumber(fromBalance).decimalPlaces(fromDecimal).toString(10)
        );
      amount = fromBalance;
    }
    if (
      amount.toString().split('.').length === 2 &&
      parseInt(amount.toString().split('.')[1]) === 0
    ) {
      if (amount.toString().split('.')[1].length > fromDecimal)
        amount =
          amount.toString().split('.')[0] + amount.toString().split('.')[1].slice(0, fromDecimal);
    } else {
      /**
       * IF USER IS TRYING TO ENTER - 0.207. 0 AFTER 2 THEN BIGNUMBER.JS WAS NOT ACCEPTING IT. THIS FIX HASBEEN IMPLEMENTED TO OVERCOME THAT BUG.
       */
      if (
        amount.toString().split('.').length === 2 &&
        parseInt(
          amount
            .toString()
            .split('.')[1]
            .charAt(amount.toString().split('.')[1].length - 1)
        ) === 0
      ) {
        return amount;
      } else {
        amount = new BigNumber(amount).decimalPlaces(fromDecimal).toString(10);
      }
    }
    return amount;
  };

  const handleMaxClick = async () => {
    const toCommodity = tokenList.find((token) => token.name === toToken);
    let UserFromBalance = fromBalance;
    if (fromBalance > 1000000) UserFromBalance = 999999;
    else if (fromBalance < 0.00001) UserFromBalance = 0;
    let allowedFromValue = checkForMaxAmountAllowed(UserFromBalance);
    const maxAmountUserCanGetOnTrade = await calcToAmount(allowedFromValue, fromToken, toToken);
    const maxToAmount = BigNumber.minimum(maxAmountUserCanGetOnTrade[0], toPoolBalance).toString(
      10
    );
    if (toCommodity) {
      if (allowedFromValue > 0) {
        setFromAmount(allowedFromValue);
        if (new BigNumber(toPoolBalance).isLessThan(BigNumber(maxAmountUserCanGetOnTrade[0]))) {
          handleToAmountChange(maxToAmount, 'handleMax', true);
        } else {
          handleToAmountChange(maxToAmount, 'handleMax');
        }
      } else {
        props.walletAvailability && setFromTokenError('Transfer Token needs to be greater than 0');
      }
    }
  };

  const handleToAmountChange = async (value, check, forceUpdateFromAmount = false) => {
    setToTokenError();
    if (value === '') {
      value = 0;
    }
    if (new BigNumber(value).gt(toPoolBalance)) {
      props.walletAvailability &&
        setToTokenError(
          'Recieved Token value can be max: ' +
            new BigNumber(toPoolBalance).decimalPlaces(toDecimal).toString(10)
        );
      value = toPoolBalance;
    }
    if (
      value.toString().split('.').length === 2 &&
      parseInt(
        value
          .toString()
          .split('.')[1]
          .charAt(value.toString().split('.')[1].length - 1)
      ) === 0
    ) {
      setToAmount(value);
    } else {
      value = new BigNumber(value).decimalPlaces(fromDecimal).toString(10);
      setToAmount(value);
    }
    let fromAmts = await calcFromAmount(value);
    if (check === 'handleInput' || forceUpdateFromAmount === true) {
      setFromAmount(fromAmts[0]);
    }
    setMinRecieved(new BigNumber(value).multipliedBy(0.995).decimalPlaces(toDecimal).toString(10));
    // setPriceImpact(truncateDecimals(fromAmts[1], toDecimal))
    const toCommodity = tokenList.find((token) => token.name === toToken);
    if (toCommodity && toCommodity.isCoinToken) {
      setTokenPrice(new BigNumber(value).dividedBy(fromAmts[0]).toString(10));
    } else {
      setTokenPrice(new BigNumber(fromAmts[0]).dividedBy(value).toString(10));
    }
  };

  const handleSwap = async () => {
    if (fromToken !== 'token' && toToken !== 'token') {
      const toCommodity = tokenList.find((token) => token.name === toToken);
      const toBalance = await getBalance(toCommodity.address, toCommodity.decimal, props.address);

      setToAmount(0);
      setFromAmount(0);
      setMinRecieved(0);
      handleFromAmountChange('', toToken, fromToken);
      if (toBalance !== '0') {
        let temp = fromToken;
        setFromToken(toToken);
        setToToken(temp);
      } else {
        // No Need to display error because we are resetting the values to 0 in case of swap
        //  setFromTokenError("Transfer Token balance should be greater than 0.");
        props.walletAvailability &&
          setFromTokenError(`${toToken.split(' ')[0]} balance must be greator than 0.`);
      }
    }
  };

  const handleFromAmountChange = async (value, fromtkn, totkn) => {
    setFromTokenError();

    let allowedFromValue = checkForMaxAmountAllowed(value);
    setFromAmount(allowedFromValue);
    let toAmts;
    if (allowedFromValue > 0) {
      toAmts = await calcToAmount(allowedFromValue, fromtkn, totkn);
    } else {
      toAmts = [0, 0];
    }

    setToAmount(new BigNumber(toAmts[0]).decimalPlaces(toDecimal).toString(10));
    setMinRecieved(
      new BigNumber(toAmts[0]).multipliedBy(0.995).decimalPlaces(toDecimal).toString(10)
    );
    // setPriceImpact(truncateDecimals(toAmts[1], toDecimal))
    const fromCommodity = tokenList.find((token) => token.name === fromtkn);
    if (fromCommodity && fromCommodity.isCoinToken) {
      setTokenPrice(new BigNumber(allowedFromValue).dividedBy(toAmts[0]).toString(10));
    } else {
      setTokenPrice(new BigNumber(toAmts[0]).dividedBy(allowedFromValue).toString(10));
    }
  };

  //contract calls to calc amount on from amount update
  const calcFromAmount = async (toAmt) => {
    try {
      toAmt = toAmt ? Number(toAmt) : 0;
      const fromCommodity = tokenList.find((token) => token.name === fromToken);
      const toCommodity = tokenList.find((token) => token.name === toToken);
      if (fromCommodity && toCommodity) {
        const bPoolAddress = getBalancerPoolAddress(fromCommodity, toCommodity);
        const strategyAddress = getStrategyAddress(fromCommodity, toCommodity);
        if (!bPoolAddress) return 0;
        if (toAmt < 0) {
          setToTokenError("Amount can't be negative");
          return 0;
        }
        setFromTokenError('Calculating...');
        //contract call to get values to set amounts using output ammount
        const amounts = await strategyBalancerContract(strategyAddress).getInputConversion(
          fromCommodity.address,
          toCommodity.address,
          toAmt
        );
        if (amounts?.isError === true) throw new Error('calculation error');
        setFromTokenError();
        return amounts;
      }
      return [0, 0];
    } catch (err) {
      setFromTokenError('Something went wrong!');
      setToTokenError();
      return [0, 0];
    }
  };

  //contract calls to calc amount on to amount update
  const calcToAmount = async (fromAmt, fromTkn, toTkn) => {
    try {
      fromAmt = fromAmt ? fromAmt : 0;
      const fromCommodity = tokenList.find((token) => token.name === fromTkn);
      const toCommodity = tokenList.find((token) => token.name === toTkn);
      if (fromCommodity && toCommodity) {
        const bPoolAddress = getBalancerPoolAddress(fromCommodity, toCommodity);
        const strategyAddress = getStrategyAddress(fromCommodity, toCommodity);
        if (!bPoolAddress) return 0;
        //String getting compared to Number (0)- Its wrong here
        if (fromAmt < 0) {
          setToTokenError("Amount can't be negetive");
          return 0;
        }
        setToTokenError('Calculating...');
        //contract call to get values to set amounts using output ammount
        const amounts = await strategyBalancerContract(strategyAddress).getOutputConversion(
          fromCommodity.address,
          toCommodity.address,
          fromAmt
        );
        if (amounts?.isError === true) throw new Error('calculation error');
        setToTokenError();
        return amounts;
      }
      return [0, 0];
    } catch (err) {
      setFromTokenError('Something went wrong!');
      setToTokenError();
      return [0, 0];
    }
  };

  //contract calls LPF
  useEffect(() => {
    if (props.balancerPool.lpf) {
      // setLpf(0);
      if (props.balancerPool.lpf >= 0) {
        // setLpf(props.balancerPool[props.selected].lpf)
        setToTokenError();
      } else setToTokenError("The Balancer Pool for this does'nt exist");
    }
  }, [props.balancerPool]);

  //fromtoken change
  useEffect(() => {
    async function fetchCommodityBalance(commodity, setBalance) {
      const balance = await getBalance(commodity.address, commodity.decimal, props.address);
      setBalance(balance);
    }
    const fromCommodity = tokenList.find((token) => token.name === fromToken);
    if (fromCommodity?.isCoinToken) {
      setPositionToken('toAmount');
    } else {
      setPositionToken('fromAmount');
    }
    if (fromCommodity) {
      setFromDecimal(fromCommodity.decimal);
      fetchCommodityBalance(fromCommodity, setFromBalance);
      if (!fromCommodity.isCoinToken) {
        setToTokens(tokenList.filter((el) => el.isCoinToken));
        setToToken(tokenList.filter((el) => el.isCoinToken)[0].name);
      } else setToTokens(tokenList);
    }
  }, [fromToken, props.address, tokenList]);

  //totoken change
  useEffect(() => {
    const toCommodity = tokenList.find((token) => token.name === toToken);
    if (toCommodity?.isCoinToken) {
      setPositionToken('fromAmount');
    } else {
      setPositionToken('toAmount');
    }
    if (toCommodity) {
      setToDecimal(toCommodity.decimal);
      if (!toCommodity.isCoinToken) {
        // setFromTokens(tokenList.filter(el => (el.isCoinToken)))
        setFromTokens(tokenList);
        setFromToken(tokenList.filter((el) => el.isCoinToken)[0].name);
      } else setFromTokens(tokenList);
    }
  }, [toToken, tokenList, props.address]);

  //fromtoken and totoken change
  useEffect(() => {
    const toCommodity = tokenList.find((token) => token.name === toToken);
    const fromCommodity = tokenList.find((token) => token.name === fromToken);
    if (toCommodity?.isCoinToken) {
      setPositionToken('fromAmount');
    } else {
      setPositionToken('toAmount');
    }
    if (toCommodity && fromCommodity) {
      if (props.balancerPool.balance) {
        let maxFromPoolAmountAllowed = props.balancerPool.balance[fromCommodity.address] / 2;
        setFromPoolBalance(
          new BigNumber(maxFromPoolAmountAllowed).decimalPlaces(fromCommodity.decimal).toString(10)
        );
        let maxToPoolAmountAllowed = props.balancerPool.balance[toCommodity.address] / 3;
        setToPoolBalance(
          new BigNumber(maxToPoolAmountAllowed).decimalPlaces(toCommodity.decimal).toString(10)
        );
      } else {
        setTokenPrice();
      }
    }
  }, [toToken, fromToken, tokenList, props.balancerPool]);

  useEffect(() => {
    const toCommodity = tokenList.find((token) => token.name === toToken);
    const fromCommodity = tokenList.find((token) => token.name === fromToken);
    if (toCommodity?.isCoinToken) {
      setPositionToken('fromAmount');
    } else {
      setPositionToken('toAmount');
    }
    if (toCommodity && fromCommodity) {
      if (props.balancerPool.price) {
        const address = getTokenAddress(toCommodity, fromCommodity);
        setPriceImpact(Math.abs(props.balancerPool.price[address] - tokenPrice));
      }
    }
  }, [toToken, fromToken, tokenList, props.balancerPool, tokenPrice]);

  //network checker
  useEffect(() => {
    if (!props.address) {
      setIsDisabled(true);
    } else {
      if (networks[props.networkID]) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [props.networkID, props.address]);

  //token list maker
  useEffect(() => {
    let tokenList = [];
    setTokenList(tokenList);
    setToTokens(tokenList);
    setFromTokens(tokenList);
    if (props.selectedCommodity) {
      let commodity = props.selectedCommodity;

      if (!commodity.is_settled && commodity.balancer_pool_address) {
        tokenList.push({
          name: commodity.short_symbol + ' v' + commodity.version,
          token: commodity.short_symbol,
          address: commodity.short_token_contract_address,
          contractAddress: commodity.mettalex_contract_address,
          version: commodity.version,
          decimal: commodity.decimal,
          commodityIndex: commodity.id,
          isCoinToken: false,
          balancerPoolAddress: commodity.balancer_pool_address,
          strategyAddress: commodity.strategy_address
        });
        tokenList.push({
          name: commodity.long_symbol + ' v' + commodity.version,
          token: commodity.long_symbol,
          address: commodity.long_token_contract_address,
          contractAddress: commodity.mettalex_contract_address,
          version: commodity.version,
          decimal: commodity.decimal,
          commodityIndex: commodity.id,
          isCoinToken: false,
          balancerPoolAddress: commodity.balancer_pool_address,
          strategyAddress: commodity.strategy_address
        });
        setTokenList([...tokenList, props.collateralToken]);
        setToTokens([...tokenList, props.collateralToken]);
        setFromTokens([...tokenList, props.collateralToken]);
      }
    }
  }, [props.selectedCommodity, props.collateralToken]);

  return props.isSettled ? (
    <>
      <Grid style={{ display: 'flex' }}>
        <Typography
          component={'span'}
          className={`${classes.typography} ${classes.greyTypography}`}
          style={{ marginRight: 4, marginTop: 2 }}>
          Settle Positions
        </Typography>
        <Button
          size="medium"
          className={`${classes.maxButton}`}
          onClick={props.handleSettlement}
          disabled={
            props.isSettled
              ? props.userBalances[props.currentCommodityMetaData.long_token_contract_address] >
                  0 ||
                props.userBalances[props.currentCommodityMetaData.short_token_contract_address] > 0
                ? false
                : true
              : true
          }>
          <LightTooltip title="Settle Positions">
            <SwapHorizIcon className={classes.iconSize} />
          </LightTooltip>
        </Button>
      </Grid>
    </>
  ) : (
    <>
      <Grid style={{ display: 'flex' }}>
        <Grid item xs={6} style={{ marginRight: 4 }}>
          <Box style={{ display: 'flex', marginBottom: 10 }}>
            <Typography
              component={'span'}
              className={`${classes.typography} ${classes.greyTypography}`}>
              From{' '}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} style={{ marginRight: 4 }}>
          <Box style={{ display: 'flex', marginBottom: 10 }}>
            <Typography
              component={'span'}
              className={`${classes.typography} ${classes.greyTypography}`}>
              To{' '}
            </Typography>

            <Typography
              component={'span'}
              className={classes.typography}
              style={{ display: 'flex' }}>
              {tokenPrice ? (
                <>
                  &nbsp;
                  <OverflowTooltip>
                    <NumberFormat
                      value={new BigNumber(tokenPrice).decimalPlaces(2).toString(10)}
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                    &nbsp;
                    {props.collateralToken.token + '/Token'}
                  </OverflowTooltip>
                </>
              ) : (
                ''
              )}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid style={{ display: 'flex' }}>
        <Grid item xs={6} style={{ marginRight: 4 }}>
          <div style={{ position: 'relative' }}>
            <FormControl size="small" className={classes.formControl} disabled={!tokenList.length}>
              <Select
                inputProps={{
                  classes: {
                    icon: classes.icon
                  }
                }}
                disabled={props.walletAvailability === false}
                value={fromToken}
                onChange={(event) => {
                  if (event.target.value === toToken) {
                    reset();
                  }
                  setFromToken(event.target.value);
                  setFromAmount(0);
                  handleFromAmountChange(0, event.target.value, toToken);
                }}
                className={`${classes.select} select`}>
                <MenuItem value="token" className={`${classes.menuItem} placeholder`} disabled>
                  Select Token
                </MenuItem>
                {fromTokens.map((token) => {
                  return token.isCoinToken === true && isMarketDisabled(props.collateralToken) ? (
                    null
                  ) : ((props?.balances?.long[2] === '0' || props?.balances?.long[2] === 0) &&
                      token.name.includes(props?.balances?.long[1])) ||
                    ((props?.balances?.short[2] === '0' || props?.balances?.short[2] === 0) &&
                      token.name.includes(props?.balances?.short[1])) ? (
                    null
                  ) : (
                    <MenuItem value={token.name} key={token.name} className={classes.menuItem}>
                      {' '}
                      {token.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box display="flex" paddingX="12px" paddingTop="9px" className={classes.box}>
              <InputBase
                disabled={
                  isDisabled ||
                  fromToken === '' ||
                  fromToken === 'token' ||
                  toToken === '' ||
                  toToken === 'token'
                }
                value={fromAmount > 0 ? fromAmount : 0}
                placeholder={fromAmount.toString()}
                title={''}
                type="number"
                size="small"
                className={classes.input}
                onKeyPress={handleKeyPress}
                onChange={(event) => {
                  if (validateInputField(event.target.value)) {
                    handleFromAmountChange(event.target.value, fromToken, toToken);
                  } else {
                    handleFromAmountChange(
                      correctInputFieldValues(event.target.value),
                      fromToken,
                      toToken
                    );
                  }
                }}
              />
              <Button
                size="small"
                className={classes.maxButton}
                variant="outlined"
                onClick={() => handleMaxClick()}
                disabled={
                  isDisabled ||
                  fromToken === '' ||
                  toToken === '' ||
                  fromToken === 'token' ||
                  toToken === 'token' ||
                  fromToken === toToken ||
                  props.walletAvailability === false
                }>
                <ArrowUpwardIcon className={classes.arrowIcon} />
              </Button>
            </Box>
            <Box className={classes.swap} onClick={() => handleSwap()}>
              <img src={Swap} alt="swap" />
            </Box>
          </div>
        </Grid>
        <Grid item xs={6} style={{ marginLeft: 4 }}>
          <FormControl size="small" className={classes.formControl} disabled={!tokenList.length}>
            <Select
              inputProps={{
                classes: {
                  icon: classes.icon
                }
              }}
              value={toToken}
              disabled={
                fromToken === 'token' || fromToken === toToken || props.walletAvailability === false
              }
              onChange={(event) => {
                setToToken(event.target.value);
                handleFromAmountChange(fromAmount, fromToken, event.target.value);
              }}
              className={`${classes.select} select`}>
              <MenuItem value="token" className={`${classes.menuItem} placeholder`} disabled>
                Select Token
              </MenuItem>
              {toTokens.map(
                (token) =>
                  fromToken !== token.name && (
                    <MenuItem key={token.name} value={token.name} className={classes.menuItem}>
                      {token.name}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
          <Box display="flex" paddingX="12px" paddingTop="9px" className={classes.box}>
            <InputBase
              disabled={
                isDisabled ||
                fromToken === '' ||
                fromToken === 'token' ||
                toToken === '' ||
                toToken === 'token' ||
                props.walletAvailability === false
              }
              value={toAmount > 0 ? toAmount : 0}
              placeholder={toAmount.toString()}
              title={''}
              type="number"
              size="small"
              className={classes.input}
              onKeyPress={handleKeyPress}
              onChange={(event) => {
                if (validateInputField(event.target.value)) {
                  handleToAmountChange(event.target.value, 'handleInput');
                } else {
                  handleToAmountChange(correctInputFieldValues(event.target.value), 'handleInput');
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <span md={12}>
        {fromTokenError && <span className={classes.span}>{fromTokenError}</span>}
        {toTokenError && <span className={classes.span}>{toTokenError}</span>}
      </span>
      <br />
      <Grid className={classes.grid}>
        <Typography
          component={'span'}
          className={`${classes.typography} ${classes.greyTypography}`}>
          Minimum received:{' '}
        </Typography>
        <Typography component={'span'} className={classes.typography}>
          {isFinite(minRecieved) && minRecieved !== 'NaN' ? minRecieved : '-'}
        </Typography>
      </Grid>
      <Grid className={classes.grid}>
        <Typography
          component={'span'}
          className={`${classes.typography} ${classes.greyTypography}`}>
          Trading fee:{' '}
        </Typography>
        <Typography component={'span'} className={classes.typography}>
          {isFinite(priceImpact) && positionToken !== ''
            ? truncateDecimals(
                priceImpact * (positionToken === 'fromAmount' ? fromAmount : toAmount),
                2
              ) + ` ${props.collateralToken.token}`
            : '-'}
        </Typography>
      </Grid>
      <Grid className={classes.grid}>
        <Typography
          component={'span'}
          className={`${classes.typography} ${classes.greyTypography}`}>
          Spread per token:{' '}
        </Typography>
        <Typography component={'span'} className={classes.typography}>
          {isFinite(priceImpact)
            ? truncateDecimals(priceImpact, 2) + ` ${props.collateralToken.token}`
            : '-'}
        </Typography>
      </Grid>
      {/* <Grid className={classes.grid}>
        <Typography component={'span'} className={classes.typography}>Liquidity Provider Fee: </Typography>
        <Typography component={'span'} className={classes.colorTypography}>{fromAmount ? truncateDecimals(fromAmount * lpf, fromDecimal) : '-'}</Typography>
      </Grid>
      <Divider className={classes.divider} /> */}
      <br />
      <Grid style={{ display: 'flex' }}>
        <Button
          size="small"
          className={classes.button}
          onClick={() => handleTradeClick()}
          disabled={
            isDisabled ||
            !isFinite(priceImpact) ||
            fromToken === 'token' ||
            toToken === 'token' ||
            fromToken === '' ||
            toToken === '' ||
            toToken === fromToken ||
            !!toTokenError ||
            !!fromTokenError
          }>
          {props.title}
        </Button>
      </Grid>
      {open && (
        <PopupDialog
          coinTokenName={fromToken}
          handleSwapClick={handleSwapClick}
          open={open}
          handleClose={() => setOpen(false)}
          
          profit={range * toAmount - fromAmount}
          loss={fromAmount}
          loading={props.isTransactionLoading}
        />
      )}
      {open && props.isTransactionLoading && (
        <PopupDialog
          coinTokenName={fromToken}
          handleSwapClick={handleSwapClick}
          open={open}
          handleClose={() => setOpen(false)}
          profit={props.transactionMetaData.profit}
          loss={props.transactionMetaData.loss}
          loading={props.isTransactionLoading}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  networkID: state.user.networkID,
  isTransactionLoading: state.user.isTransactionLoading,
  transactionMetaData: state.user.transactionMetaData,
  address: state.user.address,
  balancerPool: state.currentCommodity.balancerPool,
  currentCommodityMetaData: state.currentCommodity.metadata,
  enabled: state.user.enabled,
  collateralToken: state.collateralToken,
  walletAvailability: state.user.walletAvailability,
  userBalances: state.user.balances
});

export default connect(mapStateToProps, { swapToken })(SwapView);
