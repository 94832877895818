import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'balancerPool',
  initialState: {},
  reducers: {
    startUpdateAccount() {},
    updatePoolPrice(state, action) {
      if (!state[action.payload.id]) state[action.payload.id] = {};
      state[action.payload.id].price = action.payload;
    },
    updatePoolBalance(state, action) {
      if (!state[action.payload.id]) state[action.payload.id] = {};
      state[action.payload.id].balance = action.payload;
    },
    updatePoolAddress(state, action) {
      if (!state[action.payload.id]) state[action.payload.id] = {};
      state[action.payload.id].address = action.payload.address;
    },
    updatePoolLpf(state, action) {
      if (!state[action.payload.id]) state[action.payload.id] = {};
      state[action.payload.id].lpf = action.payload.lpf;
    }
  }
});

const { actions, reducer } = configSlice;

export const { updatePoolPrice, updatePoolBalance, updatePoolAddress, updatePoolLpf } = actions;

export default reducer;
