import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, makeStyles, withStyles } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import LoaderOverlay from '../../component/Loader';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    paddingBottom: '0px'
  },
  textContainer: {
    margin: '24px 0px'
  },
  title: {
    color: theme.text,
    fontSize: '20px',
    lineHeight: '28px'
  },
  subTitle: {
    color: theme.secondaryText,
    fontSize: '14px'
  },
  plText: {
    color: theme.text,
    fontSize: '14px'
  },
  divider: {
    margin: '10px 0px',
    border: theme.borderAlt
  },
  buttonContainer: {
    margin: '24px 20px',
    marginTop: '2px',
    padding: '0px'
  },
  maxButton: {
    flex: 'auto',
    fontSize: '14px',
    fontFamily: theme.typography.fontFamily,
    border: theme.borderAlt,
    borderRadius: '0px',
    color: theme.text,
    textTransform: 'none'
  },
  activeButton: {
    backgroundColor: theme.maxButton.color,
    color: theme.maxButton.activeColor
  },
  setHeight: {
    display: 'grid',
    margin: '0px auto',
    padding: 0,
  }
}));

const CustomDialog = withStyles({
  paper: {
    backgroundColor: '#2C2D3D',
    width: '341px'
  }
})(Dialog);

const PopupDialog = ({
  coinTokenName,
  open,
  handleClose,
  handleSwapClick,
  profit,
  loss,
  loading
}) => {
  const classes = useStyles();

  return (
    <CustomDialog
      disableBackdropClick
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" className={classes.titleContainer}>
        <span className={classes.title}>Note</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography className={classes.subTitle}>
            The following transaction can lead to:
          </Typography>
          <div className={classes.textContainer}>
            <Typography className={classes.plText}>
              Profit (Max):{' '}
              <span style={{ color: '#2FD593', float: 'right' }}>
                {profit <= 0 ? '' : '+'}
                <NumberFormat
                  value={profit}
                  decimalScale={2}
                  displayType={'text'}
                  thousandSeparator={true}
                />{' '}
                {coinTokenName}
              </span>
            </Typography>
            <div className={classes.divider} />
            <Typography className={classes.plText}>
              Loss (Max):{' '}
              <span style={{ color: '#EE3737', float: 'right' }}>
                -
                <NumberFormat
                  value={loss}
                  decimalScale={2}
                  displayType={'text'}
                  thousandSeparator={true}
                />{' '}
                {coinTokenName}
              </span>
            </Typography>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttonContainer}>
        {loading ? (
          <div className={classes.setHeight}>
            <LoaderOverlay title={'Transaction is in progress...'} css={{backdrop_abs: false}}/>
          </div>
        ) : (
          <>
            <Button className={classes.maxButton} onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              className={`${classes.maxButton} ${classes.activeButton}`}
              onClick={handleSwapClick}
              color="primary"
              autoFocus>
              Trade
            </Button>
          </>
        )}
      </DialogActions>
    </CustomDialog>
  );
};

export default PopupDialog;
