import { configureStore } from '@reduxjs/toolkit';
import sagaMiddleware from '../app/rootSaga';
import createRootReducer from '../app/rootReducer';

const rootReducer = createRootReducer();

const middleware = [sagaMiddleware];

const store = configureStore({
  middleware: middleware,
  reducer: rootReducer
});

export default store;
