import React from 'react';
import { makeStyles, Grid, Link } from '@material-ui/core';
import { etherscanAddressURL } from '../../constant';
import OverflowTooltip from '../OverflowTooltip';
import { uniqueId } from '../../utils';
import LoaderOverlay from '../../component/Loader';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    position: 'relative',
    zIndex: 1,
    color: '#fff'
  },
  loaderDimensions: {
    maxHeight: 40,
    minHeight: 40,
    marginTop: '5%'
  },
  loaderText: {
    textAlign: 'center'
  },
  table: {
    overflowY: 'scroll',
    color: theme.text
  },
  cardWrapper: {
    // minHeight:"225px",
    minHeight: '200px'
    // '@media (max-width:1200px)': {
    //   minHeight:"246px",
    // },
  },
  tableHead: {
    color: theme.secondaryText,
    background: theme.dropdown.backgroundColor,
    padding: '8px 20px 8px 20px',
    fontSize: 14,
    fontWeight: 600,
    border: 'none',
    lineHeight: 0,
    '& .MuiTableSortLabel-root:hover': {
      color: theme.text
    },
    '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
      opacity: 1
    }
  },
  tableHeadText: {
    textAlign: 'right',
    paddingLeft: 0
  },
  tableName: {
    color: '#4033AA'
  },
  tableCell: {
    color: theme.secondaryText,
    background: theme.dropdown.fadedBackgroundColor,
    fontSize: '12px',
    fontWeight: '300',
    border: 'none',
    borderBottom: theme.border,
    fontFamily: 'MontserratLight',
    padding: '10px 20px 9px 20px',
    '&:last-child': {
      color: theme.text
    }
  },
  primaryText: {
    color: theme.text
  },
  empty: {
    background: theme.dropdown.fadedBackgroundColor,
    fontWeight: '500',
    color: theme.text,
    borderBottom: 'none',
    paddingLeft: '20px!important'
  },
  textColor: {
    color: theme.secondaryText
  },
  tableFirstRow: {
    color: theme.secondaryText,
    paddingLeft: '20px !important',
    paddingRight: '0!important'
  },
  tableLastRow: {
    color: theme.secondaryText,
    paddingRight: '20px !important'
  },
  tableRow: {
    '&:last-child': {
      '& .MuiTableCell-root': {
        borderBottom: 'none',
        padding: '10px 20px !important'
      }
    }
  },
  walletIco: {
    width: '1.3em',
    height: '1.3em'
  },
  mtlxSymbol: {
    padding: '0 5px',
    marginLeft: '5px',
    background: 'white',
    color: theme.card.background,
    margin: '0px 5px',
    // padding: "1px 3px",
    borderRadius: '6px',
    fontSize: '10px'
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 10px 11px 10px',
    color: theme.secondaryText,
    background: theme.dropdown.backgroundColor,
    fontSize: 14,
    fontWeight: 600,
    alignItems: 'center',
    '@media (max-width:1440px)': {
      // eslint-disable-next-line
      padding: '10px 10px 10px 10px'
    }
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    // padding: '14px 10px 13px 10px !important',
    padding: '11px 10px 10px 10px',
    color: theme.secondaryText,
    background: theme.dropdown.fadedBackgroundColor,
    fontSize: '12px',
    fontWeight: '300',
    borderBottom: theme.border,
    alignItems: 'center'
    // '@media (min-width:1200px) and (max-width:1400px)': { // eslint-disable-next-line
    //   padding: '14px 10px',
    // },
    // '@media (min-width:1401px) and (max-width:1885px)': { // eslint-disable-next-line
    //   padding: '14px 10px',
    // },
  },
  setHeight: {
    position: 'relative',
    height: 160
  }
}));

const Wallet = (props) => {
  const classes = useStyles();

  const renderWalletRow = (rows) => {
    return (
      <Grid item className={`${classes.cardContent} ${props.extendedClass}`} key={uniqueId()}>
        {rows.balance ? (
          <>
            <OverflowTooltip>{rows.currency}</OverflowTooltip>
            <Grid className="textWhite">{rows.balance}</Grid>
          </>
        ) : (
          <>
            <Link
              className={classes.text}
              target="_blank"
              href={`${etherscanAddressURL[props.networkID]}${rows['address']}`}
            >
              {rows.currency}
            </Link>
            <Grid className="textWhite">{rows.nonCustodial}</Grid>
          </>
        )}
      </Grid>
    );
  };

  const tblBody = () => {
    const { rowData, emptyText } = props;
    return (
      <React.Fragment>
        {!rowData.length ? (
          <Grid item className={`${classes.cardContent} ${props.extendedClass}`}>
            {emptyText}
          </Grid>
        ) : (
          rowData.map(renderWalletRow)
        )}
      </React.Fragment>
    );
  };

  return (
    <Grid item className={`${classes.cardWrapper} boxDesign`}>
      <Grid item className={`${classes.cardHeader} ${props.extendedClassForHeader}`}>
        <Grid> {props.icon}</Grid>
        <Grid> {props.title}</Grid>
      </Grid>
      {props.isLoading || !props.rowData.length === true ? (
        <div className={classes.setHeight}>
          <LoaderOverlay title={'Loading balances...'} />
        </div>
      ) : (
        tblBody()
      )}
    </Grid>
  );
};
export default Wallet;
