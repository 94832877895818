import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CustomizedSlider from '../Slider';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.text,
    background: theme.card.background,
    display: 'flex',
    padding: 0
  },
  grid: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.text
  },
  slider: {
    width: '90%',
    marginLeft: '5%',
    marginBottom: '3.2%'
  },
  floorTypography: {
    // width: '12%',
    flex: 2,
    color: '#7C7C7D',
    fontSize: 14,
    margin: 'auto'
  },
  capTypography: {
    // width: '12%',
    flex: 2,
    color: '#7C7C7D',
    textAlign: 'right',
    margin: 'auto',
    marginRight: 0,
    fontSize: 14
  },
  floorCapText: {
    color: `${theme.text}!important`
  },
  barGrid: {
    display: 'flex',
    margin: '0',
    padding: '17px 20px',
    alignItems: 'center',
    background: theme.card.background,
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%'
  },
  typography: {
    textAlign: 'end',
    color: theme.text
  },
  background: {
    backgroundColor: theme.backgroundColor
  },
  subHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: '8px 0',
    color: theme.text
  },
  item: {
    textAlign: 'left',
    position: 'relative',
    padding: '16px 20px',
    '&:after': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      width: 1,
      background: theme.backgroundColor,
      height: '100%'
    }
  },
  itemGreyText: {
    fontSize: 12,
    color: theme.secondaryText
  },
  itemWhiteText: {
    fontSize: 12,
    color: theme.text
  }
}));

const Slider = (props) => {
  const classes = useStyles();
  const [thumb, setThumb] = useState();
  const [buffer, setBuffer] = useState();
  const [max, setMax] = useState();
  const [min, setMin] = useState();

  useEffect(() => {
    if (!props.tokenDetails) return;
    const diff = parseFloat(props.tokenDetails.cap) - parseFloat(props.tokenDetails.floor);
    if (diff) {
      const buffer = diff / 10;
      setBuffer(buffer);
      const max = parseFloat(props.tokenDetails.cap);
      setMax(max);
      const min = parseFloat(props.tokenDetails.floor);
      setMin(min);
    }
  }, [props.tokenDetails]);

  useEffect(() => {
    if (props.tokenPrices && props.tokenPrices.currentPrice) {
      setThumb(parseFloat(props.tokenPrices.currentPrice));
      // console.log('price:=====', props.tokenPrices.currentPrice)
    }
  }, [props.tokenPrices]);

  return props.tokenPrices && props.tokenPrices.currentPrice ? (
    <Grid item className={`${classes.barGrid} boxDesign bottomRadius`}>
      <Typography component={'span'} className={classes.floorTypography}>
        {'Floor: '}
        <span className={classes.floorCapText}>{props.tokenDetails.floor}</span>
      </Typography>
      {thumb && (
        <CustomizedSlider
          cap={parseFloat(props.tokenDetails.cap)}
          floor={parseFloat(props.tokenDetails.floor)}
          max={max}
          min={min}
          step={buffer}
          value={thumb}
          mark={[
            {
              value: thumb,
              label: `${props.tokenPrices.currentPrice}`
            }
          ]}
        />
      )}
      <Typography component={'span'} className={classes.capTypography}>
        {'Cap: '}
        <span className={classes.floorCapText}>{props.tokenDetails.cap}</span>
      </Typography>
    </Grid>
  ) : (
    ''
  );
};

export default Slider;
