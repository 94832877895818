import { all, fork } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import startUpSaga from '../saga/startUpSaga';
import mintTokenSaga from '../saga/mintTokenSaga';
import redeemTokenSaga from '../saga/redeemTokenSaga';
import settleTokenSaga from '../saga/settleTokenSaga';
import userWalletDetailsSaga from '../saga/userWalletDetails';
import swapTokenSaga from '../saga/swapTokenSaga';
import balancerPoolSaga from '../saga/balancerPoolDetails';
import liquidityProviderSaga from '../saga/LiquidityProviderSaga';
import faucetTokenTransfer from '../saga/faucetTokenTransfer';
import transactionHistory from '../saga/transactionHistory';
import userTransactionHistory from '../saga/userTransactionHistory';
import liquidityTransactionHistory from '../saga/liquidityTransactionHistory';
import tokenPriceSaga from '../saga/tokenPriceSaga';
import userPnlSaga from '../saga/userPnLSaga';
import userLiquiditySaga from '../saga/userLiquiditySaga';
import userMtlxRewardSaga from '../saga/userMtlxRewardSaga';
import currentCommoditySaga from '../saga/currentCommoditySaga';
import collateralTokenSaga from '../saga/collateralTokenSaga';

function* rootSaga() {
  yield all([
    fork(startUpSaga),
    fork(collateralTokenSaga),
    fork(mintTokenSaga),
    fork(swapTokenSaga),
    fork(redeemTokenSaga),
    fork(userWalletDetailsSaga),
    fork(settleTokenSaga),
    fork(balancerPoolSaga),
    fork(liquidityProviderSaga),
    fork(userTransactionHistory),
    fork(faucetTokenTransfer),
    fork(transactionHistory),
    fork(tokenPriceSaga),
    fork(userLiquiditySaga),
    fork(userMtlxRewardSaga),
    fork(userPnlSaga),
    fork(currentCommoditySaga),
    fork(liquidityTransactionHistory)
  ]);
}

const sagaMiddleware = createSagaMiddleware();

export const startSaga = () => {
  sagaMiddleware.run(rootSaga);
};

export default sagaMiddleware;
