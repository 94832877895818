import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import defaultOptions from '../../component/Charts/defaultOptions';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { darkTheme, lightTheme } from '../../Theme/index';
import LoaderOverlay from '../../component/Loader';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      maxHeight: 265,
      minHeight: 265
    },
    backdrop: {
      position: 'relative',
      zIndex: 1,
      color: '#fff',
      maxHeight: 265,
      minHeight: 265
    },
    typography: {
      fontSize: 18,
      padding: theme.spacing(1),
      marginTop: theme.spacing(0.5)
    },
    loaderDimensions: {
      maxHeight: 60,
      minHeight: 60,
      marginTop: 100
    },
    loaderText: {
      textAlign: 'center'
    },
    chartHeight: {
      position: 'relative',
      height: 280
    }
  };
});

const transformToDataset = (data) =>
  data.map((element) => {
    return [element['trade_date'], element['weighted_price']];
  });

const TradingGraph = (props) => {
  const classes = useStyles();
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState(defaultOptions);
  const [yAxis, setYAxis] = useState([]);

  useEffect(() => {
    if (!props.commodity || !props.historyPrices.length) {
      setSeries([{ data: [] }]);
      return;
    }
    const commodity = props.commodity;
    const data = transformToDataset(props.historyPrices);
    setSeries([{ data, name: commodity.id }]);
    setYAxis([
      {
        ...defaultOptions.yaxis,
        seriesName: commodity.commodity_name,
        opposite: true,
        labels: {
          style: {
            fontSize: '12px',
            colors: props.enabled ? darkTheme.secondaryText : lightTheme.secondaryText
          }
        }
      }
    ]);
  }, [props.enabled, props.commodity, props.historyPrices]);

  useEffect(() => {
    let theme, fill, title, xaxis;
    if (props.enabled) {
      theme = {
        mode: 'dark',
        monochrome: {
          enabled: true,
          shadeTo: 'dark'
        }
      };
      fill = {
        type: 'gradient',
        gradient: {
          type: 'vertical',
          inverseColors: false,
          opacityFrom: [0.5],
          opacityTo: [0]
        },
        colors: ['#2C2D3D', '#484A65', '#2C2D3D']
      };
      title = {
        text: 'Price History',
        offsetY: 5,
        align: 'left',
        style: {
          color: darkTheme.secondaryText,
          fontFamily: darkTheme.typography.fontFamily,
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '21px'
        }
      };
      xaxis = {
        labels: {
          style: {
            fontSize: '12px',
            colors: darkTheme.secondaryText,
            fontFamily: darkTheme.typography.fontFamily
          }
        },
        axisBorder: {
          show: false
        },
        crosshairs: {
          show: true,
          width: 1,
          position: 'front',
          opacity: 0.9,
          stroke: {
            color: '#F97E5D',
            width: 1,
            dashArray: 2
          },
          fill: {
            type: 'gradient',
            gradient: {
              type: 'vertical',
              opacityFrom: 0.5,
              opacityTo: 0
            }
          }
        }
      };
    } else {
      theme = {
        mode: 'light',
        monochrome: {
          enabled: true,
          shadeTo: 'light'
        }
      };
      fill = {
        type: 'gradient',
        gradient: {
          type: 'vertical',
          inverseColors: false,
          opacityFrom: [0.5],
          opacityTo: [0]
        },
        colors: ['#2C2D3D', '#484A65', '#2C2D3D']
      };
      title = {
        text: 'Price  History',
        align: 'left',
        style: {
          color: lightTheme.secondaryText,
          fontFamily: lightTheme.typography.fontFamily,
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '21px'
        }
      };
      xaxis = {
        labels: {
          style: {
            fontSize: '12px',
            color: lightTheme.secondaryText,
            fontFamily: lightTheme.typography.fontFamily
          }
        },
        axisBorder: {
          show: false
        }
      };
    }

    setOptions({
      ...defaultOptions,
      yaxis: yAxis,
      title,
      xaxis,
      theme,
      fill,
      colors: ['#799BFF', '#E91E63', '#9C27B0']
    });
  }, [yAxis, props.enabled]);

  return (
    <div id="chart-timeline" className={classes.chartHeight}>
      {series[0]?.data.length === 0 ? (
        <LoaderOverlay title={'Getting Graph Data...'} />
      ) : (
        <ReactApexChart options={options} series={series} type="area" height={280} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  historyPrices: state.currentCommodity.historyPrices,
  enabled: state.user.enabled
});

export default connect(mapStateToProps)(TradingGraph);
