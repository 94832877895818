import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Grid,
  TextField,
  Box,
  CardContent,
  makeStyles,
  Tabs,
  Tab
} from '@material-ui/core';
import { connect } from 'react-redux';
import PercentageButtons from '../../component/LiquidityProvider/buttons';
import { decimals } from '../../constant';
import { createAction } from '@reduxjs/toolkit';
import PopupDialog from '../../component/Popup';
import NumberFormat from 'react-number-format';
import Transaction from '../../containers/TxHistory';
import { isEmpty } from 'lodash';
import { BigNumber } from 'bignumber.js';
import { validateInputField } from '../../component/utils';
import { isMarketDisabled } from '../../utils/index';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Wallet from '../../component/Table/Wallet';
import ApyAndMtlxCard from '../../component/ApyAndMtlxCard';
import { LightTooltip } from '../../component/LightToolTip';
import DataNotFound from '../../component/DataNotFound';
import LoaderOverlay from '../../component/Loader';

const depositToken = createAction('DEPOSIT_LIQUIDITY');
const withdrawToken = createAction('WITHDRAW_LIQUIDITY');
const earnToken = createAction('EARN_LIQUIDITY');
const mtlxDeposit = createAction('MTLX_DEPOSIT');
const mtlxWithdraw = createAction('MTLX_WITHDRAW');
const mtlxClaim = createAction('MTLX_CLAIM');
const earnButton = createAction('UPDATE_EARN_BUTTON');

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
      {...other}>
      {value === index && (
        <Box p={1}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    '& div.MuiTabs-scroller': {
      '& .MuiTabs-flexContainer': {
        backgroundColor: '#2d2e3d',
        justifyContent: 'space-around'
      }
    }
  },
  input: {
    width: '100%',
    color: theme.text,
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '&: .MuiInputBase-root': {
      fontWeight: 300
    }
  },
  secondaryTypography: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    fontSize: 12,
    color: theme.text
  },
  button: {
    fontSize: '14px',
    fontFamily: 'MontserratMedium',
    marginTop: 1,
    borderRadius: '0.25rem',
    width: '100%',
    padding: '5px 5px',
    background: 'transparent',
    border: theme.borderAlt,
    color: theme.text,
    textTransform: 'none',
    '&.highlight': {
      background: theme.grid.color,
      border: theme.grid.border,
      color: 'black'
    },
    '&:hover': {
      background: theme.grid.colorHover,
      border: theme.grid.borderHover,
      color: 'black'
    },
    '&:disabled': {
      background: '#585d6e',
      border: theme.borderAlt,
      color: 'black'
    },
    '@media (max-width:1440px)': {
      // eslint-disable-next-line
      fontSize: 12
    }
  },
  maxButton: {
    width: '100%',
    padding: 5,
    background: 'transparent',
    border: theme.maxButton.border,
    '&:hover': {
      opacity: 0.8
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: 'grey',
      border: '1px solid darkgrey'
    },
    color: theme.text
  },
  alignWithdrawBalance: {
    display: 'inline-block',
    width: '60%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    verticalAlign: 'top'
  },
  span: {
    fontSize: 12,
    color: '#EE3737',
    display: 'inline-block'
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  top: {
    position: 'absolute',
    left: 0
  },
  circle: {
    strokeLinecap: 'round'
  },
  tabContainer: {
    background: theme.dropdown.fadedBackgroundColor
  },
  tab: {
    color: theme.grid.color,
    fontSize: 14,
    padding: 0,
    textTransform: 'none',
    fontFamily: 'MontserratMedium',
    marginRight: 0,
    '@media (max-width:1440px)': {
      // eslint-disable-next-line
      fontSize: 12
    }
  },
  disabledTab: {
    color: theme.text,
    textTransform: 'none',
    fontSize: 14,
    opacity: 1,
    marginRight: 0,
    '&:hover': {
      color: theme.grid.color
    },
    '@media (max-width:1440px)': {
      // eslint-disable-next-line
      fontSize: 12
    }
  },
  tabPanel: {
    height: 'calc(100% - 48px)'
  },
  card: {
    padding: 12,
    background: theme.card.background,
    boxShadow: theme.card.boxShadow,
    borderRadius: '5px',
    opacity: 1
  },
  cardContent: {
    padding: '25px 0px 8px 0',
    fontSize: '14px',
    '@media (min-width:768px) and (max-width:991px)': {
      padding: '7px 0px 8px 0'
    }
  },
  cardHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: '8px 0',
    color: theme.text,
    '& .MuiTypography-root': {
      fontWeight: 600
    }
  },
  dotted: {
    display: 'flex',
    background: theme.dropdown.fadedBackgroundColor,
    borderBottom: theme.border,
    justifyContent: 'center'
  },
  alignAPYContent: {
    alignSelf: 'start',
    padding: 15
  },
  dottedHeader: {
    background: theme.dropdown.backgroundColor,
    border: 'none',
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: 600,
      color: theme.secondaryText
    }
  },
  typography: {
    color: theme.text,
    fontSize: '14px',
    fontWeight: 300
  },
  widgetTypography: {
    color: theme.text,
    fontFamily: 'MontserratLight',
    fontSize: '12px'
  },
  widgetGreyTypography: {
    color: theme.secondaryText
  },
  widgetBalTypography: {
    fontSize: '12px',
    display: 'flex'
  },
  box: {
    marginTop: '10px',
    backgroundColor: theme.dropdown.backgroundColor,
    padding: '4px 8px',
    '& input': {
      color: theme.text,
      padding: '6px 0 7px'
    },
    '@media (max-width:1440px)': {
      // eslint-disable-next-line
      marginTop: 4,
      padding: 2
    }
  },
  balanceMargin: {
    marginTop: 6,
    marginBottom: 6,
    '@media (max-width:1440px)': {
      // eslint-disable-next-line
      marginTop: 2,
      marginBottom: 2
    }
  },
  circularProgress: {
    textAlign: 'center',
    flex: '25%',
    boxShadow: '0px 0px 10px 6px #0e0e15',
    marginTop: '25px',
    height: '89%',
    position: 'relative',
    paddingTop: 20,

    '@media (min-width:992px) and (max-width:1024px)': {
      paddingTop: 0,
      height: '86%'
    },
    '@media (min-width:1025px) and (max-width:1180px)': {
      paddingTop: 9
    }
  },
  tabWrapper: {
    marginTop: 25
  },
  transactionContainer: {
    '& .MuiGrid-spacing-xs-2': {
      margin: 0,
      width: '100%'
    },
    '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
      padding: '0 !important'
    }
  },
  table: {
    overflowY: 'scroll',
    color: theme.text
  },
  tableHead: {
    color: theme.secondaryText,
    background: theme.dropdown.backgroundColor,
    padding: '8px 20px 8px 20px',
    fontSize: 14,
    fontWeight: 600,
    border: 'none',
    lineHeight: 0,
    '& .MuiTableSortLabel-root:hover': {
      color: theme.text
    },
    '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
      opacity: 1
    }
  },
  tableHeadText: {
    textAlign: 'right',
    paddingLeft: 0
  },
  walletIco: {
    width: '1.3em',
    height: '1.3em'
  },
  tableName: {
    color: '#4033AA',
    '&:hover': {
      color: theme.grid.color
    }
  },
  tableCell: {
    color: theme.secondaryText,
    background: theme.dropdown.fadedBackgroundColor,
    fontSize: '14px',
    fontWeight: '300',
    border: 'none',
    borderBottom: theme.border,
    fontFamily: 'MontserratLight',
    padding: '10px 20px 9px !important',
    '&:last-child': {
      color: theme.text
    }
  },
  primaryText: {
    color: theme.text
  },
  empty: {
    background: theme.dropdown.fadedBackgroundColor,
    fontWeight: '500',
    color: theme.text,
    borderBottom: 'none',
    paddingLeft: '20px!important'
  },
  textColor: {
    color: theme.secondaryText
  },
  tableFirstRow: {
    color: theme.secondaryText,
    paddingLeft: '20px !important',
    paddingRight: '0!important'
  },
  tableLastRow: {
    color: theme.secondaryText,
    paddingRight: '20px !important'
  },
  tableRow: {
    '&:last-child': {
      '& .MuiTableCell-root': {
        borderBottom: 'none',
        padding: '10px 20px !important'
      }
    }
  },
  mtlxSymbol: {
    padding: '0 5px',
    marginLeft: '5px',
    background: 'white',
    color: theme.card.background,
    margin: '0px 5px',
    // padding: "1px 3px",
    borderRadius: '6px',
    fontSize: '10px'
  },
  itemPadding: {
    padding: '4px 5px 0 5px!important'
  },
  handleBreakLine: {
    '@media (min-width:1441px)': {
      // or whatever you are after
      display: 'none' // Hide BR tag for wider screens
    }
  },
  alignSpacing: {
    '@media (min-width:1921px)': {
      paddingBottom: 1
    }
  },
  setHeight: {
    display: 'grid',
    margin: '0px auto',
    padding: 0
  }
}));

const balanceDecimalPrecision = 3;

const LiquidityProvider = (props) => {
  const [depositAmount, setDepositAmount] = useState(0);
  const [mtlxAmount, setMtlxAmount] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [USDTBalance, setUSDTBalance] = useState(0);
  const [MTLXBalance, setMTLXBalance] = useState(0);
  const [mxUSDTBalance, setmxUSDTBalance] = useState(0);
  const [lifespan, setLifespan] = useState(0);
  const [totalUSDTBalance, setTotalUSDTBalance] = useState(0);
  const [totalMxUSDTBalance, setTotalMxUSDTBalance] = useState(0);
  const [converter, setConverter] = useState(1);
  const [depositError, setDepositError] = useState('');
  const [mtlxError, setMtlxError] = useState('');
  const [withdrawError, setWithdrawError] = useState('');
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [rewardBallance, setRewardBallance] = React.useState(0);
  const [rewardStackMtlxBallances, setRewardStackMtlxBallances] = React.useState(0);
  const [rewardAddress, setRewardAddress] = React.useState('');
  const [MtlxValue, setMtlxValue] = React.useState(0);
  const [poolTVL, setPoolTVL] = React.useState(0);
  const [openPositionValue, setOpenPositionValue] = React.useState(0);
  const classes = useStyles();

  const handleDepositPercentile = (value) => {
    const totalBalance = USDTBalance ? USDTBalance : 0;
    let calculateDepositAmount = new BigNumber(totalBalance)
      .multipliedBy(value)
      .dividedBy(100)
      .decimalPlaces(props.collateralToken.decimal)
      .toString(10);
    setDepositAmount(calculateDepositAmount);
    setDepositError('');
  };

  const handleWithdrawPercentile = (value) => {
    const totalBalance = mxUSDTBalance ? mxUSDTBalance : 0;
    let calculateWithDrawalAmount = new BigNumber(totalBalance)
      .multipliedBy(value)
      .dividedBy(100)
      .decimalPlaces(props.collateralToken.decimal)
      .toString(10);
    setWithdrawAmount(calculateWithDrawalAmount);
    setWithdrawError('');
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEarnClick = () => {
    props.earnButton(false);
    props.earnToken({ liquidityPoolAddress: props.liquidityPoolAddress });
    setDepositError('');
    setWithdrawError('');
    setOpen(false);
  };

  let invalidChars = ['-', '+', 'e', 'E'];

  const handleMtlxDeposit = (isFull) => {
    let amount = mtlxAmount;

    if (isFull) {
      amount = mxUSDTBalance;
    }
    if (amount > mxUSDTBalance) {
      setMtlxError(`Stake amount should be less than or equal to mx${props.coinTokenName}`);
      return false;
    }
    if (amount <= 0) {
      setMtlxError('Please enter a positive amount');
      return false;
    }
    props.mtlxDeposit({ amount, liquidityPoolAddress: props.liquidityPoolAddress, pId: 0 });
    setMtlxError('');
  };

  const handleMtlxWithdraw = () => {
    console.log('rewardStackMtlxBallances', rewardStackMtlxBallances);
    let amount = mtlxAmount;
    console.log('Amount', amount, 'rewardStackMtlxBallances', rewardStackMtlxBallances);
    if (amount > rewardStackMtlxBallances) {
      setMtlxError('Unstake amount should be less than or equal to Amount Staked');
      return false;
    }
    if (amount <= 0) {
      setMtlxError('Please enter a positive amount');
      return false;
    }
    props.mtlxWithdraw({ amount, liquidityPoolAddress: props.liquidityPoolAddress, pId: 0 });
    setMtlxError('');
  };

  const handleMtlxClaim = () => {
    props.mtlxClaim({ amount: 0, liquidityPoolAddress: props.liquidityPoolAddress, pId: 0 });
    setMtlxError('');
  };

  const handleDeposit = (isFull) => {
    let amount = depositAmount;
    if (isFull) {
      amount = USDTBalance;
    }
    if (BigNumber(amount).isGreaterThan(BigNumber(USDTBalance))) {
      setDepositError("Can't deposit more than balance amount");
      return false;
    }
    if (BigNumber(amount).isLessThanOrEqualTo(BigNumber(0))) {
      setDepositError('Please enter positive deposit amount');
      return false;
    }
    props.earnButton(true);
    props.depositToken({ amount, liquidityPoolAddress: props.liquidityPoolAddress });
    setDepositError('');
    setWithdrawError('');
  };

  const handleWithdraw = (isFull) => {
    let amount = withdrawAmount;
    if (isFull) {
      amount = mxUSDTBalance;
    }
    if (BigNumber(amount).isGreaterThan(BigNumber(mxUSDTBalance))) {
      setWithdrawError("Can't withdraw more than balance amount");
      return false;
    }
    if (BigNumber(amount).isLessThanOrEqualTo(BigNumber(0))) {
      setWithdrawError('Please enter positive withdraw amount');
      return false;
    }
    props.withdrawToken({ amount, liquidityPoolAddress: props.liquidityPoolAddress });
    setDepositError('');
    setWithdrawError('');
  };

  const createData = (currency, balance) => {
    return { currency, balance };
  };

  const balanceRows = [
    createData(
      `Deposited Amount (${props.coinTokenName})`,
      <NumberFormat
        value={new BigNumber(totalUSDTBalance ? totalUSDTBalance : 0)
          .decimalPlaces(balanceDecimalPrecision)
          .toString(10)}
        displayType={'text'}
        thousandSeparator={true}
      />
    ),
    createData(
      `Staked Amount (mx${props.coinTokenName})`,
      <NumberFormat
        value={new BigNumber(rewardStackMtlxBallances ? rewardStackMtlxBallances : 0)
          .decimalPlaces(balanceDecimalPrecision)
          .toString(10)}
        displayType={'text'}
        thousandSeparator={true}
      />
    ),
    createData(
      `Current Value of Deposits (${props.coinTokenName})`,
      <NumberFormat
        value={new BigNumber(totalMxUSDTBalance ? totalMxUSDTBalance : 0)
          .decimalPlaces(balanceDecimalPrecision)
          .toString(10)}
        displayType={'text'}
        thousandSeparator={true}
      />
    ),
    createData(
      `Rewards (MTLX)`,
      <NumberFormat
        value={new BigNumber(rewardBallance ? rewardBallance : 0)
          .decimalPlaces(balanceDecimalPrecision)
          .toString(10)}
        displayType={'text'}
        thousandSeparator={true}
      />
    )
  ];

  const myWalletRows = [
    createData(
      'BNB',
      <NumberFormat
        value={new BigNumber(props.userBalance.ETH ? props.userBalance.ETH : 0)
          .decimalPlaces(balanceDecimalPrecision)
          .toString(10)}
        displayType={'text'}
        thousandSeparator={true}
      />
    ),
    createData(
      props.coinTokenName ? props.coinTokenName : '...',
      <NumberFormat
        value={new BigNumber(USDTBalance ? USDTBalance : 0)
          .decimalPlaces(balanceDecimalPrecision)
          .toString(10)}
        displayType={'text'}
        thousandSeparator={true}
      />
    ),
    createData(
      props.coinTokenName ? `mx${props.coinTokenName}` : '...',
      <NumberFormat
        value={new BigNumber(mxUSDTBalance ? mxUSDTBalance : 0)
          .decimalPlaces(balanceDecimalPrecision)
          .toString(10)}
        displayType={'text'}
        thousandSeparator={true}
      />
    ),
    createData(
      `MTLX`,
      <NumberFormat
        value={new BigNumber(MTLXBalance ? MTLXBalance : 0)
          .decimalPlaces(balanceDecimalPrecision)
          .toString(10)}
        displayType={'text'}
        thousandSeparator={true}
      />
    )
  ];

  const handleKeyPress = (e) => {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setUSDTBalance(props.balances.USDT);
    setMTLXBalance(props.balances.MTLX);
  }, [props.balances]);

  useEffect(() => {
    setRewardBallance(props.mtlxRewardBalances);
    setRewardStackMtlxBallances(props.stackMtlxBallances);
  }, [props.mtlxRewardBalances, props.stackMtlxBallances]);

  useEffect(() => {
    if (
      Array.isArray(props.liquidityPool.mtlxRewardDetails) &&
      props.liquidityPool.mtlxRewardDetails.length > 0
    ) {
      setRewardAddress(props.liquidityPool.mtlxRewardDetails[0].reward_contract_address);
    }
  }, [props.liquidityPool.mtlxRewardDetails]);

  useEffect(() => {
    let createdAt = props.lifespan;
    if (props.selected && props.commodities) {
      let similarPoolAdd =
        props.commodities &&
        Object.values(props.commodities).filter(
          (obj) =>
            props.commodities[props.selected].balancer_pool_address === obj.balancer_pool_address
        );
      let createdAtDate = similarPoolAdd.map((value) => new Date(value.created_at));
      createdAt =
        createdAtDate &&
        createdAtDate.length > 0 &&
        createdAtDate.reduce(function (a, b) {
          return a < b ? a : b;
        });
    }
    const DifferenceInTime = new Date().getTime() - new Date(createdAt).getTime();
    var DifferenceInDays = DifferenceInTime / (1000 * 3600 * 24);
    if (DifferenceInDays < 1) DifferenceInDays = 1;
    setLifespan(DifferenceInDays);
  }, [props.commodities, props.selected, props.lifespan]);

  useEffect(() => {
    if (props.liquidityPoolAddress && props.liquidityPool[props.liquidityPoolAddress]) {
      setmxUSDTBalance(props.liquidityPool[props.liquidityPoolAddress].mxUSDT);
      setConverter(props.liquidityPool[props.liquidityPoolAddress].conversionRate || 1);
      setTotalUSDTBalance(props.liquidityPool[props.liquidityPoolAddress].totalUSDTDeposited);
      // Shows the total liquidity available to a commodity.
      setPoolTVL(props.liquidityPool[props.liquidityPoolAddress].totalUSDT);
      setTotalMxUSDTBalance(props.liquidityPool[props.liquidityPoolAddress].totalUSDTWithdrawn);
    }
    let keys = Object.keys(props.commodities);
    let poolTVL = 0;
    let openPositionValue = 0;
    for (let i = 0; i < keys.length; i++) {
      const id = keys[i];
      if (id === props.currentCommodity.metadata.id) {
        const collateralPerUnit =
          props.commodities[id].network_id === '97'
            ? props.commodities[id].collateral_per_unit / 10 ** 3
            : props.commodities[id].collateral_per_unit / 10 ** props.commodities[id].decimal;
        const liquidity = props.commodities[id].liquidity_pool_address;
        const longToken = props.commodities[id].long_token_contract_address;
        const shortToken = props.commodities[id].short_token_contract_address;
        if (props.commodities[id].commodity_name && !props.commodities[id].is_settled) {
          poolTVL =
            poolTVL + props.liquidityPool[liquidity] && props.liquidityPool[liquidity].totalUSDT;
          openPositionValue =
            openPositionValue +
            collateralPerUnit *
              (props.balancerPool[id] && props.balancerPool[id].balance
                ? props.balancerPool[id].balance[longToken] +
                  props.balancerPool[id].balance[shortToken]
                : 0);
        }
      }
    }

    setMtlxValue(props.mtlxRewardCount);
    setOpenPositionValue(openPositionValue);
    // eslint-disable-next-line
  }, [props.liquidityPool, props.liquidityPoolAddress, props.commodities]);

  const tabsTitle = ['Deposit', 'Withdraw', 'MTLX Rewards'];

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <React.Fragment>
      <Grid container spacing={1} style={{ display: 'flex' }}>
        <Grid item lg={3} md={3} xs={6} className={classes.itemPadding}>
          <ApyAndMtlxCard
            page="liquidity"
            boxStyles={classes.circularProgress}
            alignContent={classes.alignAPYContent}
            dottedStyles={classes.dotted}
            apyValue={`${((converter - 1) * (365.25 / lifespan) * 100).toFixed(2) > 0 ? '+' : ''}${(
              (converter - 1) *
              (365.25 / lifespan) *
              100
            ).toFixed(2)}%`}
            MtlxValue={MtlxValue?.toFixed(2)}
            poolTVL={poolTVL}
            openPositionValue={openPositionValue}
          />
        </Grid>

        <Grid item lg={3} md={3} xs={6} className={classes.itemPadding}>
          <div className={`${classes.tabWrapper} boxDesign`}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  backgroundColor: `${!props.enabled ? '#5643CC' : '#68DBDA'}`
                }
              }}
              value={tab}
              onChange={handleChange}
              className={`${classes.tabs} ${classes.flexContainer}`}>
              <Tab className={tab === 0 ? classes.tab : classes.disabledTab} label={tabsTitle[0]} />
              <Tab className={tab === 1 ? classes.tab : classes.disabledTab} label={tabsTitle[1]} />
              <Tab
                className={tab === 2 ? classes.tab : classes.disabledTab}
                label={tabsTitle[2]}
                disabled={isEmpty(rewardAddress) && (isEmpty(rewardAddress) || rewardBallance <= 0)}
              />
            </Tabs>
            <TabPanel value={tab} index={0} className={`${classes.tabContainer} tabs`}>
              <Grid style={{ display: 'flex', gridGap: '10px' }}>
                <Grid item xs={8}>
                  <div className={`${classes.widgetTypography} ${classes.balanceMargin}`}>
                    <span className={`${classes.widgetTypography} ${classes.widgetGreyTypography}`}>
                      Balance:
                    </span>
                    <br className={classes.handleBreakLine} />
                    <NumberFormat
                      value={new BigNumber(USDTBalance)
                        .decimalPlaces(balanceDecimalPrecision)
                        .toString(10)}
                      displayType={'text'}
                      thousandSeparator={true}
                    />
                    &nbsp;{props.coinTokenName}
                  </div>
                  <Box display="flex" className={classes.box}>
                    <TextField
                      disabled={
                        props.isDepositeLoading ||
                        (BigNumber(USDTBalance).isLessThanOrEqualTo(BigNumber(0)) ? true : false) ||
                        props.isDisabled ||
                        props.walletAvailability === false ||
                        isMarketDisabled(props.collateralToken)
                      }
                      value={depositAmount > 0 ? depositAmount : undefined}
                      placeholder={depositAmount.toString()}
                      type="number"
                      size="small"
                      className={classes.input}
                      onKeyPress={handleKeyPress}
                      onChange={(event) => {
                        if (validateInputField(event.target.value)) {
                          let inputVal = event.target.value;
                          let value;
                          if (inputVal === null || inputVal === '') {
                            value = new BigNumber(0)
                              .decimalPlaces(props.collateralToken.decimal)
                              .toString(10);
                          } else {
                            if (
                              inputVal.toString().split('.').length === 2 &&
                              parseInt(inputVal.toString().split('.')[1]) === 0
                            ) {
                              if (
                                inputVal.toString().split('.')[1].length >
                                props.collateralToken.decimal
                              )
                                value =
                                  inputVal.toString().split('.')[0] +
                                  inputVal
                                    .toString()
                                    .split('.')[1]
                                    .slice(0, props.collateralToken.decimal);
                              else value = inputVal;
                            } else {
                              value = inputVal;
                            }
                          }
                          setDepositAmount(value);
                        }
                      }}
                    />
                  </Box>
                </Grid>
                <PercentageButtons
                  handleClick={handleDepositPercentile}
                  disabled={
                    props.isDepositeLoading ||
                    props.isDisabled ||
                    (BigNumber(USDTBalance).isLessThanOrEqualTo(BigNumber(0)) ? true : false) ||
                    props.walletAvailability === false ||
                    isMarketDisabled(props.collateralToken)
                  }
                />
              </Grid>
              {<span className={classes.span}>{depositError || ''}</span>}
              <Grid className={classes.alignSpacing} style={{ display: 'flex', gridGap: '8px' }}>
                {props.isDepositeLoading ? (
                  <div className={classes.setHeight}>
                    <LoaderOverlay
                      title={'Transaction is in progress...'}
                      css={{ backdrop_abs: false }}
                    />
                  </div>
                ) : (
                  <>
                    <Grid item xs={6}>
                      <Button
                        className={`${classes.button} highlight`}
                        color="primary"
                        onClick={() => handleDeposit(false)}
                        disabled={
                          props.isDisabled ||
                          (BigNumber(USDTBalance).isLessThanOrEqualTo(BigNumber(0))
                            ? true
                            : false) ||
                          props.walletAvailability === false ||
                          isMarketDisabled(props.collateralToken)
                        }>
                        Deposit
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        color="default"
                        className={classes.button}
                        onClick={() => handleDeposit(true)}
                        disabled={
                          props.isDisabled ||
                          (BigNumber(USDTBalance).isLessThanOrEqualTo(BigNumber(0))
                            ? true
                            : false) ||
                          props.walletAvailability === false ||
                          isMarketDisabled(props.collateralToken)
                        }>
                        Deposit All
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
              {false && (
                <Grid>
                  <Grid item xs={12}>
                    <Button
                      className={classes.button}
                      color="default"
                      onClick={() => handleClickOpen()}
                      disabled={
                        props.isDisabled ||
                        props.walletAvailability === false ||
                        isMarketDisabled(props.collateralToken)
                      }>
                      Start Earning
                    </Button>
                  </Grid>
                </Grid>
              )}
            </TabPanel>
            <TabPanel value={tab} index={1} className={`${classes.tabContainer} tabs`}>
              <Grid style={{ display: 'flex', gridGap: '10px' }}>
                <Grid item xs={8}>
                  <Typography className={`${classes.widgetTypography} ${classes.balanceMargin}`}>
                    <span className={`${classes.widgetTypography} ${classes.widgetGreyTypography}`}>
                      Balance:&nbsp;
                    </span>
                    <br className={classes.handleBreakLine} />
                    <LightTooltip
                      title={`${new BigNumber(mxUSDTBalance)
                        .decimalPlaces(balanceDecimalPrecision)
                        .toString(10)} mx${props.coinTokenName} (${new BigNumber(mxUSDTBalance)
                        .multipliedBy(converter)
                        .decimalPlaces(balanceDecimalPrecision)
                        .toString(10)}) ${props.coinTokenName}`}>
                      <span className={classes.alignWithdrawBalance}>
                        {`${new BigNumber(mxUSDTBalance)
                          .decimalPlaces(balanceDecimalPrecision)
                          .toString(10)} mx${props.coinTokenName} (${new BigNumber(mxUSDTBalance)
                          .multipliedBy(converter)
                          .decimalPlaces(balanceDecimalPrecision)
                          .toString(10)}) ${props.coinTokenName}`}
                      </span>
                    </LightTooltip>
                  </Typography>
                  <Box display="flex" className={classes.box}>
                    <TextField
                      disabled={
                        props.isWithdrawLoading ||
                        props.isDisabled ||
                        BigNumber(mxUSDTBalance).isLessThanOrEqualTo(BigNumber(0))
                          ? true
                          : false || props.walletAvailability === false
                      }
                      value={withdrawAmount > 0 ? withdrawAmount : null}
                      placeholder={withdrawAmount.toString()}
                      type="number"
                      size="small"
                      className={classes.input}
                      onKeyPress={handleKeyPress}
                      onChange={(event) => {
                        if (validateInputField(event.target.value)) {
                          let inputVal = event.target.value;
                          let value;
                          if (inputVal === null || inputVal === '') {
                            value = new BigNumber(0)
                              .decimalPlaces(props.collateralToken.decimal)
                              .toString(10);
                          } else {
                            if (
                              inputVal.toString().split('.').length === 2 &&
                              parseInt(inputVal.toString().split('.')[1]) === 0
                            ) {
                              if (
                                inputVal.toString().split('.')[1].length >
                                props.collateralToken.decimal
                              )
                                value =
                                  inputVal.toString().split('.')[0] +
                                  inputVal
                                    .toString()
                                    .split('.')[1]
                                    .slice(0, props.collateralToken.decimal);
                              else value = inputVal;
                            } else {
                              value = inputVal;
                            }
                          }
                          setWithdrawAmount(value);
                        }
                      }}
                    />
                  </Box>
                </Grid>
                <PercentageButtons
                  handleClick={handleWithdrawPercentile}
                  disabled={
                    props.isWithdrawLoading ||
                    props.isDisabled || BigNumber(mxUSDTBalance).isLessThanOrEqualTo(BigNumber(0))
                      ? true
                      : false || props.walletAvailability === false
                  }
                />
              </Grid>
              {<span className={classes.span}>{withdrawError || ''}</span>}

              <Grid className={classes.alignSpacing} style={{ display: 'flex', gridGap: '10px' }}>
                {props.isWithdrawLoading ? (
                  <div className={classes.setHeight}>
                    <LoaderOverlay
                      title={'Transaction is in progress...'}
                      css={{ backdrop_abs: false }}
                    />
                  </div>
                ) : (
                  <>
                    <Grid item xs={6}>
                      <LightTooltip
                        title={
                          BigNumber(mxUSDTBalance).isLessThanOrEqualTo(BigNumber(0))
                            ? 'Your balance should be greater than 0 to enable this functionality.'
                            : ''
                        }>
                        <Button
                          className={`${classes.button} highlight`}
                          color="primary"
                          onClick={() => handleWithdraw(false)}
                          disabled={
                            props.isDisabled ||
                            BigNumber(mxUSDTBalance).isLessThanOrEqualTo(BigNumber(0))
                              ? true
                              : false || props.walletAvailability === false
                          }>
                          Withdraw
                        </Button>
                      </LightTooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <LightTooltip
                        title={
                          BigNumber(mxUSDTBalance).isLessThanOrEqualTo(BigNumber(0))
                            ? 'Your balance should be greater than 0 to enable this functionality.'
                            : ''
                        }>
                        <Button
                          color="default"
                          className={classes.button}
                          onClick={() => handleWithdraw(true)}
                          disabled={
                            props.isDisabled ||
                            BigNumber(mxUSDTBalance).isLessThanOrEqualTo(BigNumber(0))
                              ? true
                              : false || props.walletAvailability === false
                          }>
                          Withdraw All
                        </Button>
                      </LightTooltip>
                    </Grid>
                  </>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={2} className={`${classes.tabContainer} tabs`}>
              <Typography className={classes.widgetTypography}>
                <span className={`${classes.widgetTypography} ${classes.widgetGreyTypography}`}>
                  Available to Claim:
                </span>{' '}
                <NumberFormat
                  value={new BigNumber(rewardBallance)
                    .decimalPlaces(balanceDecimalPrecision)
                    .toString(10)}
                  displayType={'text'}
                  thousandSeparator={true}
                />
                &nbsp;MTLX
              </Typography>
              <Box display="flex" className={classes.box}>
                <TextField
                  disabled={
                    props.isDisabled &&
                    new BigNumber(rewardBallance).decimalPlaces(2).eq(BigNumber(0))
                      ? true
                      : false || props.walletAvailability === false
                  }
                  value={mtlxAmount > 0 ? mtlxAmount : null}
                  placeholder={mtlxAmount}
                  type="number"
                  size="small"
                  className={classes.input}
                  onKeyPress={handleKeyPress}
                  onChange={(event) => {
                    if (validateInputField(event.target.value)) {
                      let inputVal = event.target.value;
                      let value;
                      if (inputVal === null || inputVal === '') {
                        value = new BigNumber(0).decimalPlaces(decimals.mtlxDigits).toString(10);
                      } else {
                        if (
                          inputVal.toString().split('.').length === 2 &&
                          parseInt(inputVal.toString().split('.')[1]) === 0
                        ) {
                          if (
                            inputVal.toString().split('.')[1].length > props.collateralToken.decimal
                          )
                            value =
                              inputVal.toString().split('.')[0] +
                              inputVal
                                .toString()
                                .split('.')[1]
                                .slice(0, props.collateralToken.decimal);
                          else value = inputVal;
                        } else {
                          value = inputVal;
                        }
                      }
                      setMtlxAmount(value);
                    }
                  }}
                />
              </Box>
              {<span className={classes.span}>{mtlxError || ''}</span>}
              <Grid
                className={classes.alignSpacing}
                style={{ marginTop: '6px', display: 'flex', gridGap: '8px' }}>
                <Grid item xs={6}>
                  <Button
                    className={`${classes.button} highlight`}
                    color="primary"
                    onClick={() => handleMtlxDeposit(false)}
                    disabled={
                      isEmpty(rewardAddress) ||
                      mxUSDTBalance <= 0 ||
                      props.walletAvailability === false
                    }>
                    Stake
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    className={classes.button}
                    onClick={() => handleMtlxWithdraw()}
                    disabled={
                      isEmpty(rewardAddress) ||
                      rewardStackMtlxBallances <= 0 ||
                      props.walletAvailability === false
                    }>
                    Unstake
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    className={`${classes.button} `}
                    color="primary"
                    onClick={() => handleMtlxClaim(false)}
                    disabled={
                      isEmpty(rewardAddress) ||
                      rewardBallance <= 0 ||
                      props.walletAvailability === false
                    }>
                    Claim
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
          </div>
        </Grid>

        <Grid item lg={3} md={3} xs={6} className={classes.itemPadding}>
          <CardContent className={classes.cardContent}>
            <Wallet
              icon={<AccountBalanceWalletIcon />}
              title={'My Wallet'}
              rowData={myWalletRows}
              emptyText={<DataNotFound displayText={'No Balance to Show.'} />}
              isLoading={
                Object.keys(props.MtlxRewardsisLoading).length > 0 &&
                props.MtlxRewardsisLoading[props.currentComodityId]
                  ? true
                  : false
              }
            />
          </CardContent>
        </Grid>
        <Grid item lg={3} md={3} xs={6} className={classes.itemPadding}>
          <CardContent className={classes.cardContent}>
            <Wallet
              icon={<AccountBalanceIcon />}
              title={'Balances'}
              rowData={balanceRows}
              emptyText={<DataNotFound displayText={'No Balance to Show.'} />}
              isLoading={
                Object.keys(props.MtlxRewardsisLoading).length > 0 &&
                props.MtlxRewardsisLoading[props.currentComodityId]
                  ? true
                  : false
              }
            />
          </CardContent>
        </Grid>

        <Grid item className={classes.transactionContainer} lg={12} md={12} xs={12}>
          <Transaction selected={props.selected} txnType={'LIQUIDITY'} />
        </Grid>
      </Grid>
      {open && (
        <PopupDialog
          symbol={props.network === 'kovan' ? 'ETH' : 'BNB'}
          handleEarnClick={handleEarnClick}
          open={open}
          handleClose={handleClose}
          gasEstimate={props.gasEstimate}
          collateralToken={props.collateralToken}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  network: state.user.network,
  MtlxRewardsisLoading: state.user.MtlxRewardsisLoading,
  liquidityPool: state.liquidityPool,
  enabled: state.user.enabled,
  txnsHistory: state.liquidityTransactionHistory,
  gasEstimate: state.user.gasEstimate,
  earn: state.user.earnButton,
  balances: state.currentCommodity.balances,
  mtlxRewardBalances: state.currentCommodity.mtlxRewardBallance,
  stackMtlxBallances: state.currentCommodity.stackMtlxBallance,
  currentCommodity: state.currentCommodity,
  collateralToken: state.collateralToken,
  commodities: state.commodities,
  walletAvailability: state.user.walletAvailability,
  balancerPool: state.balancerPool,
  userBalance: state.user.balances,
  isDepositeLoading: state.user.isDepositeLoading,
  isWithdrawLoading: state.user.isWithdrawLoading,
});

export default connect(mapStateToProps, {
  depositToken,
  withdrawToken,
  earnToken,
  earnButton,
  mtlxDeposit,
  mtlxWithdraw,
  mtlxClaim
})(LiquidityProvider);
