import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import { makeStyles, Link } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import { etherscanAddressURL, txAddressURL } from '../../constant';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Moment from 'react-moment';
import { BigNumber } from 'bignumber.js';
import { LightTooltip } from '../LightToolTip';

const useStyles = makeStyles((theme) => ({
  table: {
    overflowY: 'scroll',
    color: theme.text,
    tableLayout: 'fixed'
  },
  tableHead: {
    color: theme.secondaryText,
    background: theme.dropdown.backgroundColor,
    paddingLeft: '20px !important',
    padding: '6px 0 8px!important',
    fontFamily: `"MontserratMedium"`,
    border: 'none',
    '& .MuiTableSortLabel-root:hover': {
      color: theme.text
    },
    '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
      opacity: 1
    }
  },
  tableName: {
    color: '#4033AA',
    '& a': {
      color: theme.grid.color,
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: theme.grid.color,
        textDecoration: 'none'
      }
    }
  },
  tableCell: {
    color: '#ffffff',
    background: theme.dropdown.fadedBackgroundColor,
    fontSize: '12px',
    fontWeight: '300',
    border: 'none',
    borderBottom: theme.border,
    fontFamily: 'MontserratLight',
    padding: '10px 27px 9px 20px!important',
    '&:last-child': {
      color: theme.text
    }
  },
  primaryText: {
    color: theme.text
  },
  empty: {
    background: theme.dropdown.fadedBackgroundColor,
    fontWeight: '500',
    color: theme.text,
    borderBottom: 'none',
    paddingLeft: '20px!important'
  },
  textColor: {
    color: theme.secondaryText
  },
  tableFirstRow: {
    color: theme.secondaryText,
    paddingLeft: '20px !important',
    paddingRight: '0!important'
  },
  tableLastRow: {
    color: theme.secondaryText,
    paddingRight: '20px !important'
  },
  tableRow: {
    '&:last-child': {
      '& .MuiTableCell-root': {
        borderBottom: 'none'
      }
    }
  },
  mtlxSymbol: {
    padding: '0 5px',
    marginLeft: '5px',
    background: 'white',
    color: theme.card.background,
    margin: '0px 5px',
    // padding: "1px 3px",
    borderRadius: '6px',
    fontSize: '10px'
  }
}));

const TableData = (props) => {
  const classes = useStyles();
  const { headings, collateralToken, isHistoryTable } = props;
  const [head, setHead] = useState(0);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('created_at');

  useEffect(() => {
    if (head === 0) setHead(headings.length);
  }, [headings, head]);

  useEffect(() => {
    const setOrder = props?.getOrder;
    if (setOrder) setOrder(order, orderBy);
  }, [order, orderBy, props]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function descendingComparator(a, b, orderBy) {
    if (orderBy === 'created_at') {
      if (new Date(b[orderBy]) < new Date(a[orderBy])) {
        return -1;
      }
      if (new Date(b[orderBy]) > new Date(a[orderBy])) {
        return 1;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  const handleClick = (data) => {
    if (!props.handleSelected || !data.value) return;
    props.handleSelected(data.value);
    props.history.push('/trade');
  };

  const headingArrys = [
    'Volume',
    'VOLUME',
    'LIQUIDITY',
    'Liquidity',
    'APY',
    'Fees APY',
    'MTLX/Day',
    'Amount',
    'Return',
    'Total'
  ];

  const compileHeader = (headings, value) => {
    return (
      <TableHead>
        <TableRow key={value}>
          {headings.map((heading, index) =>
            headingArrys.includes(heading.label) ? (
              <TableCell key={`${heading.label}_${index}`} className={classes.tableHead}>
                <TableSortLabel
                  active={orderBy === heading.label}
                  direction={orderBy === heading.label ? order : 'asc'}
                  onClick={createSortHandler(heading.key)}
                >
                  {heading?.toolTip && heading?.toolTip !== '' ? (
                    <LightTooltip title={heading.toolTip} placement="top">
                      <span>{heading.label}</span>
                    </LightTooltip>
                  ) : (
                    <span>{heading.label}</span>
                  )}
                </TableSortLabel>
              </TableCell>
            ) : heading.label === 'AmountF' || heading.label === 'CurrencyF' ? (
              <TableCell
                key={`${heading.label}_${index}`}
                className={classes.tableHead}
                style={heading.label === 'AmountF' ? { textAlign: 'center' } : null}
              >
                {heading.title}
              </TableCell>
            ) : (
              <TableCell key={`${heading.label}_${index}`} className={classes.tableHead}>
                <TableSortLabel
                  active={orderBy === heading.label}
                  direction={orderBy === heading.label ? order : 'asc'}
                  onClick={createSortHandler(heading.key)}
                >
                  {heading.label}
                </TableSortLabel>
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
    );
  };

  const textFormat = (address) => {
    if (address) return `${address.slice(0, 6)}...${address.slice(-4)}`;
    return `..............`;
  };

  const renderHistoryData = (data, heading) => {
    if (heading.key === 'action')
      return (
        <TableCell
          key={`${heading.key}_${data['token_in_symbol']}`}
          className={`${classes.tableName} ${classes.tableCell} ${classes.textColor}`}
        >
          <Link
            className={`${classes.text} ${classes.textColor}`}
            target="_blank"
            href={`${txAddressURL[props.networkID]}${data['transaction_hash']}`}
          >
            {`${data['action'].charAt(0).toUpperCase() + data['action'].slice(1)} ${
              data['token_in_symbol']
            } for ${data['token_out_symbol']}`}
          </Link>
        </TableCell>
      );

    if (heading.key === 'token_amount_in') {
      const isCollateralToken = [collateralToken.name, 'mx' + collateralToken.name].some((obj) => {
        return obj === data['token_in_symbol'];
      });
      const decimal = isCollateralToken
        ? collateralToken.decimal
        : props.commodities[data['commodity_id']]?.decimal
        ? props.commodities[data['commodity_id']]?.decimal
        : 5;
      const divisor = 10 ** decimal;
      return (
        <TableCell
          key={`${heading.key}_${data[heading.key]}_${data['token_in_symbol']}`}
          className={`${classes.tableName} ${classes.tableCell} ${classes.primaryText}`}
        >
          <NumberFormat
            decimalScale={2}
            // value={truncateDecimals(data[heading.key] / divisor, decimal) || 0}
            value={new BigNumber(data[heading.key]).dividedBy(divisor).toString(10) || 0}
            displayType={'text'}
            thousandSeparator={true}
          />
          &nbsp;{data['token_in_symbol']}
        </TableCell>
      );
    }

    if (heading.key === 'token_amount_out') {
      const isCollateralToken = [collateralToken.name, 'mx' + collateralToken.name].some((obj) => {
        return obj === data['token_out_symbol'];
      });
      const decimal = isCollateralToken
        ? collateralToken?.decimal
        : props.commodities[data['commodity_id']]?.decimal
        ? props.commodities[data['commodity_id']]?.decimal
        : 5;
      const divisor = 10 ** decimal;
      return (
        <TableCell
          key={`${heading.key}_${data[heading.key]}_${data['token_out_symbol']}`}
          className={`${classes.tableName} ${classes.tableCell} ${classes.primaryText}`}
        >
          <NumberFormat
            decimalScale={2}
            // value={truncateDecimals(data[heading.key] / divisor, decimal) || 0}
            value={new BigNumber(data[heading.key]).dividedBy(divisor).toString(10) || 0}
            displayType={'text'}
            thousandSeparator={true}
          />
          &nbsp;{data['token_out_symbol']}
        </TableCell>
      );
    }

    if (heading.key === 'caller')
      return (
        <TableCell
          key={`${heading.key}_${data[heading.key]}`}
          className={`${classes.tableName} ${classes.tableCell} ${classes.textColor}`}
        >
          <Link
            className={`${classes.text} ${classes.textColor}`}
            target="_blank"
            href={`${etherscanAddressURL[props.networkID]}${data[heading.key]}`}
          >
            {textFormat(data[heading.key])}
          </Link>
        </TableCell>
      );

    if (heading.key === 'transaction_hash')
      return (
        <TableCell
          key={`${heading.key}_${data[heading.key]}`}
          className={`${classes.tableName} ${classes.tableCell} ${classes.textColor}`}
        >
          <Link
            className={`${classes.text} ${classes.textColor}`}
            target="_blank"
            href={`${txAddressURL[props.networkID]}${data[heading.key]}`}
          >
            {textFormat(data[heading.key])}
          </Link>
        </TableCell>
      );

    if (heading.key === 'created_at') {
      const Tdate = new Date(data[heading.key]);
      return (
        <TableCell
          key={`${heading.key}_${data[heading.key]}`}
          className={`${classes.tableName} ${classes.tableCell}`}
        >
          <Moment date={Tdate} format="D MMM YYYY, HH:mm" />
        </TableCell>
      );
    }
    return (
      <TableCell key={`${heading.key}_${data[heading.key] || 0}`} className={classes.tableCell}>
        <NumberFormat
          decimalScale={2}
          value={data[heading.key] || 0}
          displayType={'text'}
          thousandSeparator={true}
        />
      </TableCell>
    );
  };

  const renderData = (data, heading) => {
    if (data[heading.key] < 0 && heading.key === 'pnl')
      return (
        <TableCell
          className={`${classes.tableCell} ${classes.tableLastRow}`}
          key={`${heading.key}_${data[heading.key]}`}
          style={{ color: '#EE3737' }}
        >
          -
          <NumberFormat
            decimalScale={2}
            value={Math.abs(data[heading.key]) || 0}
            displayType={'text'}
            thousandSeparator={true}
          />
        </TableCell>
      );

    if (data[heading.key] > 0 && heading.key === 'pnl')
      return (
        <TableCell
          className={`${classes.tableCell} ${classes.tableLastRow}`}
          key={`${heading.key}_${data[heading.key]}`}
          style={{ color: '#2FD593' }}
        >
          +
          <NumberFormat
            decimalScale={2}
            value={Math.abs(data[heading.key]) || 0}
            displayType={'text'}
            thousandSeparator={true}
          />
        </TableCell>
      );

    if (heading.key === 'returns')
      return (
        <TableCell
          className={`${classes.tableCell} ${classes.tableLastRow}`}
          key={`${heading.key}_${data[heading.key]}`}
          style={data[heading.key] < 0 ? { color: '#EE3737' } : { color: '#2FD593' }}
        >
          <NumberFormat
            decimalScale={2}
            value={data[heading.key] || 0}
            displayType={'text'}
            thousandSeparator={true}
          />
          %
        </TableCell>
      );

    if (
      heading.key === 'volume' ||
      heading.key === 'liquidity' ||
      heading.key === 'daily_drip_rate'
    )
      return (
        <TableCell key={`${heading.key}_${data[heading.key] || 0}`} className={classes.tableCell}>
          <NumberFormat
            decimalScale={2}
            // value={truncateDecimals(data[heading.key], 2) || 0}
            value={new BigNumber(data[heading.key]).toString(10) || 0}
            displayType={'text'}
            thousandSeparator={true}
          />
        </TableCell>
      );

    if (heading.key === 'symbol')
      return (
        <TableCell
          key={`${heading.key}_${data[heading.key]}`}
          className={`${classes.tableName} ${classes.tableCell}`}
          onClick={() => handleClick(data)}
        >
          {data[heading.key]}
        </TableCell>
      );

    if (heading.key === 'quantity')
      return (
        <TableCell
          key={`${heading.key}_${data[heading.key]}`}
          className={`${classes.tableName} ${classes.tableCell}`}
          style={{ color: '#FFFFFF !important' }}
        >
          <NumberFormat
            // decimalScale={3}
            value={Math.abs(data[heading.key]) || 0}
            displayType={'text'}
            thousandSeparator={true}
          />
        </TableCell>
      );

    if (heading.key === 'pnl')
      return (
        <TableCell
          key={`${heading.key}_${data[heading.key]}`}
          className={`${classes.tableName} ${classes.tableCell}`}
          style={{ color: '#FFFFFF !important' }}
        >
          <NumberFormat
            decimalScale={2}
            value={Math.abs(data[heading.key]) || 0}
            displayType={'text'}
            thousandSeparator={true}
          />
        </TableCell>
      );

    if (heading.key === 'swap')
      return (
        <TableCell
          key={`${heading.key}_${data['token_in_symbol']}`}
          className={`${classes.tableName} ${classes.tableCell} ${classes.textColor}`}
        >
          <Link
            className={`${classes.text} ${classes.textColor}`}
            target="_blank"
            href={`${txAddressURL[props.networkID]}${data['transaction_hash']}`}
          >
            {`Swap ${data['token_in_symbol']} for ${data['token_out_symbol']}`}
          </Link>
        </TableCell>
      );

    if (heading.key === 'name') {
      return (
        <TableCell
          key={`${heading.key}_${data[heading.key]}`}
          className={`${classes.tableName} ${classes.tableCell} ${classes.tableFirstRow}`}
        >
          <Link
            className={`${classes.text} ${classes.textColor}`}
            target="_blank"
            onClick={() => handleClick(data)}
          >
            {data[heading.key]}
            {data['reward'] === true && <span className={classes.mtlxSymbol}>MTLX</span>}
          </Link>
        </TableCell>
      );
    }

    if (heading.key === 'currency')
      return (
        <TableCell
          key={`${heading.key}_${data[heading.key]}`}
          className={`${classes.tableName} ${classes.tableCell}`}
        >
          {data[heading.key]}
        </TableCell>
      );

    if (heading.key === 'total')
      return (
        <TableCell
          key={`${heading.key}_${data[heading.key]}`}
          className={`${classes.tableName} ${classes.tableCell}`}
        >
          <NumberFormat decimalScale={2} value={data[heading.key] || 0} displayType={'text'} />
        </TableCell>
      );

    if (data[heading.key] < 0 && heading.type === 'liquidity')
      return (
        <TableCell
          className={`${classes.tableCell} ${classes.tableLastRow}`}
          key={`${heading.key}_${data[heading.key]}`}
          style={{ color: '#EE3737' }}
        >
          <NumberFormat
            decimalScale={2}
            value={Math.abs(data[heading.key]) || 0}
            displayType={'text'}
            thousandSeparator={true}
          />
        </TableCell>
      );

    if (data[heading.key] < 0 && heading.key === 'percentage')
      return (
        <TableCell
          className={`${classes.tableCell} ${classes.tableLastRow}`}
          key={`${heading.key}_${data[heading.key]}`}
          style={{ color: '#EE3737' }}
        >
          -
          <NumberFormat
            decimalScale={2}
            value={Math.abs(data[heading.key]) || 0}
            displayType={'text'}
            thousandSeparator={true}
          />
          %
        </TableCell>
      );

    if (data[heading.key] > 0 && heading.key === 'percentage')
      return (
        <TableCell
          className={`${classes.tableCell} ${classes.tableLastRow}`}
          key={`${heading.key}_${data[heading.key]}`}
          style={{ color: '#2FD593' }}
        >
          +
          <NumberFormat
            decimalScale={2}
            value={Math.abs(data[heading.key]) || 0}
            displayType={'text'}
            thousandSeparator={true}
          />
          %
        </TableCell>
      );

    if (heading.key === 'percentage')
      return (
        <TableCell
          key={`${heading.key}_${data[heading.key]}`}
          className={`${classes.tableName} ${classes.tableCell}`}
        >
          <NumberFormat
            decimalScale={2}
            value={Math.abs(data[heading.key]) || 0}
            displayType={'text'}
            thousandSeparator={true}
          />
          %
        </TableCell>
      );

    return (
      <TableCell key={`${heading.key}_${data[heading.key] || 0}`} className={classes.tableCell}>
        <NumberFormat
          decimalScale={2}
          value={data[heading.key] || 0}
          displayType={'text'}
          thousandSeparator={true}
        />
      </TableCell>
    );
  };

  const renderCell = (data, heading) => {
    if (isHistoryTable) {
      return renderHistoryData(data, heading);
    } else {
      return renderData(data, heading);
    }
  };

  const renderRow = (row, index) => {
    return (
      <TableRow key={index} className={classes.tableRow}>
        {headings.map((heading) => {
          return renderCell(row, heading);
        })}
      </TableRow>
    );
  };

  const compileBody = () => {
    const { rowData, emptyText } = props;
    return (
      <TableBody>
        {!rowData.length ? (
          <TableRow key="no content">
            <TableCell colSpan={head} className={classes.empty}>
              {emptyText}
            </TableCell>
          </TableRow>
        ) : props.rowsPerPage ? (
          stableSort(rowData, getComparator(order, orderBy))
            .slice(
              props.page * props.rowsPerPage,
              props.page * props.rowsPerPage + props.rowsPerPage
            )
            .map(renderRow)
        ) : (
          stableSort(rowData, getComparator(order, orderBy)).map(renderRow)
        )}
      </TableBody>
    );
  };

  return (
    <div>
      <Table stickyHeader className={classes.table}>
        {compileHeader(headings)}
        {compileBody()}
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  collateralToken: state.collateralToken,
  commodities: state.commodities
});

export default connect(mapStateToProps, {})(TableData);
