import { select, put, takeEvery } from 'redux-saga/effects';
import { updateMtlxRewardsisLoading } from '../reducer/user';
import { rewardTransactionURL } from '../constant/index';
import { userMtlxRewardCommodity } from './transactionHistory';
import { updateMtlxBallance, updateStackMtllxBallance } from '../reducer/currentCommodity';
import { updateMtlxReward } from '../reducer/liquidityPool';
import rewardPollContract from '../contractInterface/rewardPollContract';

function* startSaga() {
  yield takeEvery('FETCH_USER_COMMODITY_MTLX_REWARD', fetchUserCommodityMtlxRewards);
}

// develops current user Commodity PnL
export function* fetchUserCommodityMtlxRewards(action) {
  const commodity = action.payload;
  yield put(updateMtlxRewardsisLoading({ id: commodity.id, value: true }));
  let LiquidityData = [];
  try {
    const walletAddress = yield select((state) => state.user.address);
    const coinToken = yield select((state) => state.collateralToken);
    LiquidityData = yield userMtlxRewardCommodity(rewardTransactionURL, commodity.id);
    yield put(updateMtlxReward(LiquidityData.result));
    if (Array.isArray(LiquidityData.result) && LiquidityData.result.length > 0) {
      const lContract = rewardPollContract(LiquidityData.result[0].reward_contract_address);
      const walletBallance = yield lContract.getMTLXBalance(
        LiquidityData.result[0].pool_id,
        walletAddress
      );
      const stackBallance = yield lContract.getTotalStackBalance(
        LiquidityData.result[0].pool_id,
        walletAddress,
        coinToken.decimal
      );
      console.log('lContract', walletBallance, 'stackBallance', stackBallance);
      yield put(updateMtlxBallance(walletBallance));
      yield put(updateStackMtllxBallance(stackBallance.amount));
    } else {
      yield put(updateMtlxBallance(0));
      yield put(updateStackMtllxBallance(0));
    }
    yield put(updateMtlxRewardsisLoading({ id: commodity.id, value: false }));
    // yield put(updateMtlxReward(LiquidityData.result))
  } catch (err) {
    yield put(updateMtlxRewardsisLoading({ id: commodity.id, value: false }));
    console.log(commodity.commodity_symbol, err);
  }
}

export default startSaga;
