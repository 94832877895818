import { select, takeEvery, put } from 'redux-saga/effects';
// import collateralContract from "../contractInterface/collateralToken";
// import BigNumber from "big-number";
import { BigNumber } from 'bignumber.js';
import mettAlex from '../contractInterface/mettalexContract';
import positionTokenContract from '../contractInterface/positionTokenContract';
// import { updateVolumes } from '../reducer/tokenPrices';
import { updateIsLoading, updateLoadingMessage } from '../reducer/user';
function* mintTokenSaga() {
  yield takeEvery('MINT_TOKEN', startMint);
}

function* startMint(action) {
  yield put(updateIsLoading(true));
  yield put(updateLoadingMessage('Transaction in progress. Please wait.'));
  try {
    const commodityId = action.payload.id;
    const walletAddress = yield select((state) => state.user.address);
    const commodities = yield select((state) => state.commodities);
    const coinToken = yield select((state) => state.collateralToken);
    // const amount = BigNumber(parseInt(action.payload.collateralPerUnit))
    //   .multiply(parseInt(action.payload.mintAmount))
    //   .add(
    //     BigNumber(parseInt(action.payload.collateralFeePerUnit)).multiply(
    //       parseInt(action.payload.mintAmount)
    //     )
    //   )
    //   .toString();

    let { collateralPerUnit, mintAmount, collateralFeePerUnit } = action.payload;
    let calcCFPU = new BigNumber(parseInt(collateralFeePerUnit)).multipliedBy(parseInt(mintAmount));
    let calcCPU = new BigNumber(parseInt(collateralPerUnit)).multipliedBy(parseInt(mintAmount));
    let amount = calcCFPU.plus(calcCPU).toString(10);

    const contract = mettAlex(action.payload.contractAddress);
    const ApproveTokenContract = positionTokenContract(coinToken.address);
    yield ApproveTokenContract.approve(action.payload.contractAddress, amount, walletAddress);
    yield contract.mintTokens(parseInt(action.payload.mintAmount), walletAddress);
    yield put({ type: 'USER_WALLET_TOKEN_DETAILS', payload: commodities[commodityId] });

    // const commodity = commodities[commodityId]
    // const longPositioncontract = positionTokenContract(commodity.long_token_contract_address);
    // const shortPositioncontract = positionTokenContract(commodity.short_token_contract_address);
    // const shortVolume = yield shortPositioncontract.getVolume();
    // const longVolume = yield longPositioncontract.getVolume();
    // yield put(updateVolumes({
    //   shortVolume: [commodity.short_symbol, shortVolume],
    //   longVolume: [commodity.long_symbol, longVolume],
    //   id: commodityId
    // }))
  } catch (err) {
    console.log(err);
    yield put(updateIsLoading(true));
  }
  yield put(updateIsLoading(false));
}

export default mintTokenSaga;
