import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'liquidityPool',
  initialState: {
    mtlxRewardDetails: []
  },
  reducers: {
    startUpdateAccount() {},
    updatemxUSDTBalance(state, action) {
      if (!state[action.payload.liquidityPoolAddress])
        state[action.payload.liquidityPoolAddress] = {};
      state[action.payload.liquidityPoolAddress].mxUSDT = action.payload.mxUSDT;
    },
    updateConversionRate(state, action) {
      if (!state[action.payload.liquidityPoolAddress])
        state[action.payload.liquidityPoolAddress] = {};
      state[action.payload.liquidityPoolAddress].conversionRate = action.payload.conversionRate;
      if (state[action.payload.liquidityPoolAddress].totalmxUSDT === 0)
        state[action.payload.liquidityPoolAddress].conversionRate = 1;
    },
    updateTotalUSDTBalance(state, action) {
      if (!state[action.payload.liquidityPoolAddress])
        state[action.payload.liquidityPoolAddress] = {};
      state[action.payload.liquidityPoolAddress].totalUSDT = action.payload.totalUSDT;
    },
    updateTotalmxUSDT(state, action) {
      if (!state[action.payload.liquidityPoolAddress])
        state[action.payload.liquidityPoolAddress] = {};
      state[action.payload.liquidityPoolAddress].totalmxUSDT = action.payload.totalmxUSDT;
      if (action.payload.totalmxUSDT === 0)
        state[action.payload.liquidityPoolAddress].conversionRate = 1;
    },
    updateInvestedUSDT(state, action) {
      if (!state[action.payload.liquidityPoolAddress])
        state[action.payload.liquidityPoolAddress] = {};
      state[action.payload.liquidityPoolAddress].totalUSDTDeposited =
        action.payload.totalUSDTDeposited;
      state[action.payload.liquidityPoolAddress].totalUSDTWithdrawn =
        action.payload.totalUSDTWithdrawn;
      state[action.payload.liquidityPoolAddress].investedUSDT = action.payload.investedUSDT;
    },
    updateMtlxReward(state, action) {
      state.mtlxRewardDetails = action.payload;
    }
  }
});

const { actions, reducer } = configSlice;

export const {
  updatemxUSDTBalance,
  updateInvestedUSDT,
  updateMtlxReward,
  updateConversionRate,
  updateTotalmxUSDT,
  updateTotalUSDTBalance
} = actions;

export default reducer;
