import { createSlice } from '@reduxjs/toolkit';
import { maxFaucetAmount } from '../constant/index';

const configSlice = createSlice({
  name: 'faucetTransfer',
  initialState: {
    status: null,
    history: [],
    remaining_amount: 100000,
    address: ''
  },
  reducers: {
    updateHistory(state, action) {
      state.history = [
        {
          address: state.address,
          status: action.payload.status,
          txhash: action.payload.tx_hash,
          created_at: new Date().toUTCString()
        },
        ...state.history
      ];
      state.remaining_amount = maxFaucetAmount - action.payload.withdrawal_amount;
    },
    uploadHistory(state, action) {
      state.address = action.payload.wallet_address;
      state.history = action.payload.history;
      state.remaining_amount = maxFaucetAmount - action.payload.withdrawal_amount;
    }
  }
});

const { actions, reducer } = configSlice;

export const { updateHistory, uploadHistory } = actions;

export default reducer;
