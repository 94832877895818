import liquidityPool from '../ABI/LIQUIDITY_POOL_20200923.json';

class LiquidityPool {
  constructor(address) {
    this.UserContract = new window.web3.eth.Contract(liquidityPool.abi, address);
  }

  getInstance = async () => this.UserContract;

  getTotalBalance = async (decimal) => {
    try {
      const contract = await this.getInstance();
      const amount = await contract.methods.totalSupply().call();
      return amount / 10 ** decimal;
    } catch (err) {
      console.log(err);
    }
  };

  getTotalUSDTBalance = async (decimal) => {
    try {
      const contract = await this.getInstance();
      const amount = await contract.methods.balance().call();
      return amount / 10 ** decimal;
    } catch (err) {
      console.log(err);
    }
  };

  getUserBalance = async (walletAddress, decimal) => {
    try {
      const contract = await this.getInstance();
      const amount = await contract.methods.balanceOf(walletAddress).call();
      return amount / 10 ** decimal;
    } catch (err) {
      console.log(err);
    }
  };

  getConverter = async (decimal) => {
    try {
      const contract = await this.getInstance();
      const amount = await contract.methods.getPricePerFullShare().call();
      return amount / 10 ** decimal;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };

  depositUsdt = async (walletAddress, amount) => {
    try {
      const contract = await this.getInstance();
      await contract.methods.deposit(amount).send({
        from: walletAddress
      });
    } catch (err) {
      console.log(err);
    }
  };

  withdrawUsdt = async (walletAddress, amount) => {
    try {
      const contract = await this.getInstance();
      await contract.methods.withdraw(amount).send({
        from: walletAddress
      });
    } catch (err) {
      console.log(err);
    }
  };

  earnLiquidity = async (walletAddress) => {
    try {
      const contract = await this.getInstance();
      await contract.methods.earn().send({
        from: walletAddress
      });
    } catch (err) {
      console.log(err);
    }
  };

  getGasAmount = async (walletAddress) => {
    try {
      const contract = await this.getInstance();
      const gasEstimate = await contract.methods.earn().estimateGas({
        from: walletAddress
      });
      return gasEstimate;
    } catch (err) {
      console.log(err);
    }
  };
}

const getContract = (address) => {
  const contract = new LiquidityPool(address);
  Object.freeze(contract);
  return contract;
};

export default getContract;
