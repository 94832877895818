import React from 'react';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import MintRedeemView from './index';
import { CardContent } from '@material-ui/core';
import SwapView from '../swapView';
import { Button } from '@material-ui/core';
import { isMarketDisabled } from "../../utils/index"
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  tabWrapper: {
    marginTop: 10,
    '@media (min-width:768px) and (max-width:991px)': {
      marginTop: 0
    }
  },
  tabs: {
    '& div.MuiTabs-scroller': {
      '& .MuiTabs-flexContainer': {
        backgroundColor: '#2d2e3d',
        justifyContent: 'start',
        marginLeft: '1.2rem'
      }
    }
  },
  tab: {
    color: theme.grid.color,
    fontSize: 14,
    padding: 0,
    fontWeight: 'bold',
    textTransform: 'none',
    fontFamily: 'MontserratMedium'
  },
  CloseButton: {
    color: theme.grid.color,
    fontSize: 12,
    padding: 0,
    minWidth: 0,
    fontWeight: 'bold',
    textTransform: 'none',
    fontFamily: 'MontserratMedium'
  },
  typography: {
    fontSize: 16,
    margin: theme.spacing(2)
  },
  disabledTab: {
    color: theme.text,
    textTransform: 'none',
    fontSize: 14,
    opacity: 1,
    '&:hover': {
      color: theme.grid.color
    }
  },
  spaceTab: {
    marginRight: 16
  },
  arwIcon: {
    fontSize: '2.9rem',
    width: 33,
    height: 35
  },
  // tabs: {},
  tabContainer: {
    background: theme.dropdown.fadedBackgroundColor,
    padding: '14px 12px'
    // minHeight: "390px",
  },
  tabPanel: {
    height: 'calc(100% - 48px)'
  },
  icon: {
    color: theme.grid.color,
    height: 'auto',
    margin: 'auto',
    marginRight: 0
  },
  cardContent: {
    padding: '0 0 17px',
    color: theme.text
  }
}));

const MintRedeemTabs = ({
  selected,
  contractAddress,
  balance,
  isSettled,
  selectedCommodity,
  enabled,
  collateralToken,
  handleSettlement
}) => {
  const classes = useStyles();
  const tabsTitle = ['Trade', 'Mint', 'Redeem'];
  const [checked, setChecked] = React.useState(true);
  const [tab, setTab] = React.useState(0);
  const handleAdvanced = () => {
    setChecked((prev) => !prev);
    setTab(0);
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const ButtonInTabs = ({ className, onClick, children }) => {
    return <Button className={className} onClick={onClick} children={children} />;
  };
  // const title = [
  //   "Trade Position Tokens",
  //   "Mint Position Tokens",
  //   "Redeem Position Tokens"
  // ]

  return (
    <>
      <CardContent className={classes.cardContent}>
        <div className={`${classes.tabWrapper} boxDesign`}>
          <Tabs
            TabIndicatorProps={{
              style: {
                backgroundColor: `${!enabled ? '#5643CC' : '#68dbda'}`
              }
            }}
            value={tab}
            onChange={handleChange}
            className={`${classes.tabs} ${classes.flexContainer}`}
          >
            <Tab
              className={`${tab === 0 || tab === 3 ? classes.tab : classes.disabledTab} ${
                classes.spaceTab
              }`}
              label={tabsTitle[0]}
            />
            {checked && (
              // <Tab
              //   className={classes.disabledTab}
              //   label="Advanced"
              //   onClick={handleAdvanced}
              // />
              <ButtonInTabs
                onClick={handleAdvanced}
                className={`${classes.disabledTab} ${classes.spaceTab}`}
              >
                Advanced
                <ArrowRightIcon className={classes.arwIcon} />
                {/* <AddIcon style={{ fontSize: '0.8rem' }}/> */}
              </ButtonInTabs>
            )}
            {!checked && (
              <Tab
                className={`${tab === 1 ? classes.tab : classes.disabledTab} ${classes.spaceTab}`}
                label={tabsTitle[1]}
              />
            )}
            {!checked && (
              <Tab
                className={`${tab === 2 ? classes.tab : classes.disabledTab} ${classes.spaceTab}`}
                label={tabsTitle[2]}
              />
            )}
            {!checked && (
              <ButtonInTabs onClick={handleAdvanced} className={classes.CloseButton}>
                <ArrowLeftIcon className={`${classes.arwIcon} arwRightIcon`} />
                {/* <CloseIcon style={{ fontSize: '0.8rem' }}/> */}
              </ButtonInTabs>
            )}
          </Tabs>
          <TabPanel
            value={tab}
            index={0}
            className={`${classes.tabContainer} tabs tradeTab`}
            id="tradeDetails"
          >
            <SwapView
              title="Trade"
              key={tab}
              selected={selected}
              selectedCommodity={selectedCommodity}
              balances={balance}
              isSettled={isSettled}
              handleSettlement={handleSettlement}
            />
          </TabPanel>
          <TabPanel value={tab} index={1} className={`${classes.tabContainer} tabs`}>
            <MintRedeemView
              selectedCommodity={selectedCommodity}
              title="Mint"
              selected={selected}
              contractAddress={contractAddress}
              balances={balance}
              isSettled={isSettled}
              resetTab={handleAdvanced}
              disabledTab={isMarketDisabled(collateralToken)}
            />
          </TabPanel>
          <TabPanel value={tab} index={2} className={`${classes.tabContainer} tabs`}>
            <MintRedeemView
              selectedCommodity={selectedCommodity}
              title="Redeem"
              selected={selected}
              contractAddress={contractAddress}
              balances={balance}
              isSettled={isSettled}
              resetTab={handleAdvanced}
              disabledTab={false}
            />
          </TabPanel>
        </div>
      </CardContent>
    </>
  );
};

const mapStateToProps = (state) => ({
  tab: state.currentCommodity.tab,
  enabled: state.user.enabled,
  collateralToken: state.collateralToken,
});

export default connect(mapStateToProps, {})(MintRedeemTabs);
