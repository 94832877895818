import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  popup: {
    '& .MuiPaper-root': {
      backgroundColor: theme.dropdown.backgroundColor,
      color: theme.text
    },
    '& .MuiDialogContent-dividers': {
      borderTop: '1px solid #414658',
      borderBottom: '1px solid #414658'
    }
  },
  popupTitle: {
    color: '#68dbda',
    fontSize: '14px'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  typo: {
    fontSize: '14px'
  },
  title: {
    fontSize: 16
  },
  itemWhiteText: {
    fontSize: 14,
    color: theme.text
  }
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default function MaxWidthDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button size="small" className="btn-info" onClick={handleClickOpen}>
        <InfoOutlinedIcon fontSize="small" color="primary" />
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        className={classes.popup}
      >
        <div className={`boxDesign`}>
          <DialogTitle id="max-width-dialog-title" className={classes.title} onClose={handleClose}>
            Market Description
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom className={classes.typo}>
              <span className={classes.popupTitle}>Instrument Name:</span>
              <span className={classes.itemWhiteText}>{props.commodityName}</span>
            </Typography>
            <Typography gutterBottom>
              <span className={classes.popupTitle}>Instrument Description:</span>
              <span className={classes.itemWhiteText}>{props.commodityDescription}</span>
            </Typography>
            <Typography gutterBottom>
              <span className={classes.popupTitle}>Price Feed:</span>
              <span className={classes.itemWhiteText}>{props.commodityExchange}</span>
            </Typography>
            <Typography gutterBottom>
              <span className={classes.popupTitle}>Floor:</span>
              <span className={classes.itemWhiteText}>{props.commodityFloor}</span>
            </Typography>
            <Typography gutterBottom>
              <span className={classes.popupTitle}>Cap:</span>
              <span className={classes.itemWhiteText}>{props.commodityCap}</span>
            </Typography>
          </DialogContent>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
