import { select, put, takeEvery } from 'redux-saga/effects';
import {
  updateCommodityIsLoading
  // updateLoadingMessage
} from '../reducer/user';
import { updateTokenPrices } from '../reducer/bPoolTokenPrices';
import { swapTransactionHistoryURL } from '../constant/index';
import { commodityHistory } from './transactionHistory';
import { getNetworkId } from '../utils';
import balancerPoolContract from '../contractInterface/balancerPoolContract';
import strategyBalancerContract from '../contractInterface/strategyBalancerContract';
function* startSaga() {
  yield takeEvery('FETCH_TOKEN_DATA', fetchSwapTxns);
}

// develops candle stick graph
export function* fetchSwapTxns(action) {
  const commodity = action.payload;
  yield put(updateCommodityIsLoading({ id: commodity.id, value: 1 }));
  // yield put(updateLoadingMessage("Fetching values. Please wait."));;
  let walletAvailability = yield select((state) => state.user.walletAvailability);
  let networkID;
  if (!walletAvailability) {
    networkID = 97;
  } else {
    networkID = yield getNetworkId();
  }
  const coinToken = yield select((state) => state.collateralToken);
  let SwapData = [];
  try {
    SwapData = yield commodityHistory(swapTransactionHistoryURL, 1, 100, commodity.id, networkID);
  } catch (err) {
    console.log(commodity.commodity_symbol, err);
  }
  const prices = {};
  prices[commodity.long_symbol] = [];
  prices[commodity.short_symbol] = [];
  SwapData.data.map((txn) => {
    let AmountIn = txn.token_amount_in;
    let AmountOut = txn.token_amount_out;
    let tokenSymbol = txn.token_in_symbol;
    let volumeTraded = (-1 * txn.token_amount_out) / 10 ** coinToken.decimal;
    let created_at = txn.created_at;
    if (txn.token_in_symbol === 'USDT') {
      AmountIn = txn.token_amount_out;
      AmountOut = txn.token_amount_in;
      tokenSymbol = txn.token_out_symbol;
      volumeTraded = txn.token_amount_in / 10 ** coinToken.decimal;
    }
    const price = AmountOut / 10 ** coinToken.decimal / (AmountIn / 10 ** commodity.decimal);
    if (!prices[tokenSymbol]) prices[tokenSymbol] = [];
    prices[tokenSymbol].push({
      price,
      volumeTraded,
      created_at
    });
    return price;
  });

  const keys = Object.keys(prices);
  const candleStickValues = {};
  const currentPrice1 = yield getPoolSpotPrices(
    commodity.long_address,
    commodity.balancer_pool_address,
    commodity.strategy_address,
    networkID
  );
  prices[commodity.long_symbol].push({ price: parseFloat(currentPrice1), created_at: new Date() });
  const currentPrice2 = yield getPoolSpotPrices(
    commodity.short_address,
    commodity.balancer_pool_address,
    commodity.strategy_address,
    networkID
  );
  prices[commodity.short_symbol].push({ price: parseFloat(currentPrice2), created_at: new Date() });
  keys.map((key) => {
    candleStickValues[key] = [];
    const pricesArray = prices[key];
    for (let i = 1; i < pricesArray.length; i++) {
      const prevPriceObj = pricesArray[i - 1];
      const currentPriceObj = pricesArray[i];
      const dataPoint = {
        timestamp: new Date(currentPriceObj.created_at).getTime(),
        high:
          prevPriceObj.price > currentPriceObj.price ? prevPriceObj.price : currentPriceObj.price,
        low:
          prevPriceObj.price < currentPriceObj.price ? prevPriceObj.price : currentPriceObj.price,
        open: prevPriceObj.price,
        close: currentPriceObj.price
      };
      candleStickValues[key].push(dataPoint);
    }
    return 1;
  });

  yield put(updateTokenPrices({ id: commodity.id, data: candleStickValues }));
  yield put(updateCommodityIsLoading({ id: commodity.id, value: -1 }));
}

function* getPoolSpotPrices(tokenAddress, balancerPoolAddress, strategy_address, networkID) {
  const coinToken = yield select((state) => state.collateralToken);
  const strategyContract = strategyBalancerContract(strategy_address);
  const bPoolContract = yield balancerPoolContract(balancerPoolAddress);
  try {
    let tokenIsBound = yield bPoolContract.isBound(tokenAddress);
    let tokenPrice = 0;
    if (tokenIsBound) {
      tokenPrice = yield strategyContract.getOutputConversion(tokenAddress, coinToken.address, 1);
    }
    return parseFloat(tokenPrice[0]);
  } catch (err) {
    console.log(err);
  }
}

export default startSaga;
