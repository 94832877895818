import rewardPool from '../ABI/REWARD.json';

class RewardPool {
  constructor(address) {
    this.UserContract = new window.web3.eth.Contract(rewardPool.abi, address);
  }

  getInstance = async () => this.UserContract;

  getTotalBalance = async (decimal) => {
    try {
      const contract = await this.getInstance();
      const amount = await contract.methods.totalSupply().call();
      return amount / 10 ** decimal;
    } catch (err) {
      console.log(err);
    }
  };

  getTotalStackBalance = async (_pid, _user, decimal) => {
    try {
      const contract = await this.getInstance();
      const amount = await contract.methods.userInfo(_pid, _user).call();
      // return amount
      return { amount: parseInt(amount.amount) / 10 ** decimal };
    } catch (err) {
      console.log(err);
    }
  };

  getMTLXBalance = async (_pid, _user) => {
    try {
      const contract = await this.getInstance();
      const amount = await contract.methods.pendingMTLX(_pid, _user).call();
      console.log('MTLX REWARDs', amount);
      return parseFloat(amount / 10 ** 18);
    } catch (err) {
      console.log(err);
    }
  };

  getConverter = async (decimal) => {
    try {
      const contract = await this.getInstance();
      const amount = await contract.methods.getPricePerFullShare().call();
      return amount / 10 ** decimal;
    } catch (err) {
      console.log(err);
      return 0;
    }
  };

  depositMtlx = async (_pid, _amount, walletAddress) => {
    console.log('depositMtlx Reward', _pid, _amount, walletAddress);
    try {
      const contract = await this.getInstance();
      await contract.methods.deposit(_pid, _amount).send({
        from: walletAddress
      });
    } catch (err) {
      console.log(err);
    }
  };

  withdrawMtlx = async (_pid, _amount, walletAddress) => {
    try {
      const contract = await this.getInstance();
      await contract.methods.withdraw(_pid, _amount).send({
        from: walletAddress
      });
    } catch (err) {
      console.log(err);
    }
  };

  earnLiquidity = async (walletAddress) => {
    try {
      const contract = await this.getInstance();
      await contract.methods.earn().send({
        from: walletAddress
      });
    } catch (err) {
      console.log(err);
    }
  };

  getGasAmount = async (walletAddress) => {
    try {
      const contract = await this.getInstance();
      const gasEstimate = await contract.methods.earn().estimateGas({
        from: walletAddress
      });
      return gasEstimate;
    } catch (err) {
      console.log(err);
    }
  };
}

const getContract = (address) => {
  const contract = new RewardPool(address);
  Object.freeze(contract);
  return contract;
};

export default getContract;
