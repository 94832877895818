import { createSlice } from '@reduxjs/toolkit';
let defaultWalletBalances = {
  loader: true,
  USDT: 0,
  MTLX: 0,
  long: [],
  short: []
};
let defaultBalancerPool = {
  loader: true,
  longVolume: 0,
  shortVolume: 0,
  coinVolume: 0,
  longSpotPrice: 0,
  shortSpotPrice: 0
};
const configSlice = createSlice({
  name: 'tradePage',
  initialState: {
    pnlStatsTable: {},
    walletBalances: defaultWalletBalances,
    balPool: defaultBalancerPool,
    tradeWalletBalancesisLoading: {}
  },
  reducers: {
    updatePnLStatsTable(state, action) {
      state.pnlStatsTable = action.payload;
    },
    updateWalletBalances(state, action) {
      state.walletBalances = action.payload;
    },
    updateBalPool(state, action) {
      state.balPool = action.payload;
    },
    resetWalletBalances(state) {
      state.walletBalances = defaultWalletBalances;
    },
    resetBalPool(state) {
      state.balPool = defaultBalancerPool;
    },
    updateTradeWalletBalancesLoading(state, action) {
      state.tradeWalletBalancesisLoading[action.payload.id] = action.payload.value;
    }
  }
});

const { actions, reducer } = configSlice;

export const {
  updatePnLStatsTable,
  updateWalletBalances,
  updateBalPool,
  resetWalletBalances,
  resetBalPool,
  updateTradeWalletBalancesLoading
} = actions;

export default reducer;
