import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'bPoolTokenPrices',
  initialState: {},
  reducers: {
    updateTokenPrices(state, action) {
      state.id = action.payload.id;
      state.data = action.payload.data;
    }
  }
});

const { actions, reducer } = configSlice;

export const { updateTokenPrices } = actions;

export default reducer;
