import React from 'react';
import { makeStyles, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LanguageIcon from '@material-ui/icons/Language';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TelegramIcon from '@material-ui/icons/Telegram';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginTop: 5
    },
    footerText: {
      color: '#9cc9f5',
      fontSize: '9.5px',
      padding: '0 10px',
      height: 35,
      lineHeight: '40px',
      textTransform: 'uppercase',
      display: 'inline',
      position: 'relative',
      top: '-5px'
    },
    socialLink: {
      padding: '10px 5px 0px 3px',
      color: 'darkgrey',
      margin: '0 5px',
      '&:hover': {
        backgroundColor: '#293949',
        borderRadius: '0.25rem',
        color: '#9cc9f5'
      }
    },
    icoSize: {
      fontSize: '1.2rem'
    },
    linkWrapper: {
      '@media (min-width:768px) and (max-width:1024px)': {
        textAlign: 'center'
      }
    }
  };
});

const Footer = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item sm={12} md={6} className={classes.linkWrapper}>
        <Link className={classes.socialLink} target="_blank" href="https://mettalex.com/">
          <LanguageIcon className={classes.icoSize} />
        </Link>
        <Link className={classes.socialLink} target="_blank" href="https://twitter.com/mettalex">
          <TwitterIcon className={classes.icoSize} />
        </Link>
        <Link
          className={classes.socialLink}
          target="_blank"
          href="https://www.linkedin.com/company/mettalex"
        >
          <LinkedInIcon className={classes.icoSize} />
        </Link>
        <Link className={classes.socialLink} target="_blank" href="https://t.me/mettalex_official">
          <TelegramIcon className={classes.icoSize} />
        </Link>
        <Typography className={classes.footerText}>
          | &nbsp;&nbsp;Copyright &#169; Mettalex 2022
        </Typography>
      </Grid>
      <Grid item sm={12} md={6} className="text-inst">
        <Typography className={classes.footerText}>
          BUSD TRADING IS IN BETA. USE WITH CAUTION! NOT FOR USE BY U.S. INDIVIDUALS !
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
