import { takeEvery, put, select } from 'redux-saga/effects';
import {
  updateIsLoading,
  updateIsLoadingIndividual
  // updateLoadingMessage
} from '../reducer/user';
import { transactionHistoryURL } from '../constant/index';
import {
  liquidityTxHistory,
  liquidityTxCount,
  setLiquidityFilterSelected
} from '../reducer/liquidityTransactionHistory';
import { getNetworkId } from '../utils';
import { userHistory, allHistory, commodityHistory } from './transactionHistory';

function* liquidityTransactionHistory() {
  yield takeEvery('LIQUIDITY_TRANSACTION_HISTORY_ALL', allTransaction);
  yield takeEvery('LIQUIDITY_TRANSACTION_HISTORY_USER', userTransaction);
  yield takeEvery('LIQUIDITY_TRANSACTION_HISTORY_COMMODITY', commodityTransaction);
}

function* userTransaction(action) {
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(true));
  } else {
    // yield put(updateIsLoading(true));;
  }
  // yield put(updateLoadingMessage("Fetching values. Please wait."));;
  const page = action.payload.page.toString();
  const records = action.payload.pageSize.toString();
  const walletAddress = yield select((state) => state.user.address);
  const commodity_list = yield select((state) => state.commodities);
  const collateral_token = yield select((state) => state.collateralToken);
  let walletAvailability = yield select((state) => state.user.walletAvailability);
  let networkID;
  if (!walletAvailability) {
    networkID = 97;
  } else {
    networkID = yield getNetworkId();
  }
  try {
    let history = {};
    history = yield userHistory(
      transactionHistoryURL,
      page - 1,
      records,
      walletAddress,
      networkID,
      Object.keys(commodity_list).join(','),
      collateral_token.name,
      'DEPOSIT,WITHDRAW'
    );
    yield put(
      liquidityTxHistory({
        history: history.result,
        page: parseInt(page),
        records: parseInt(records)
      })
    );
    yield put(liquidityTxCount({ count: history.total }));
    yield put(setLiquidityFilterSelected({ selected: 1 }));
  } catch (err) {
    console.log(err);
  }
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(false));
  } else {
    yield put(updateIsLoading(false));
  }
}

function* commodityTransaction(action) {
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(true));
  } else {
    // yield put(updateIsLoading(true));;
  }
  // yield put(updateLoadingMessage("Fetching values. Please wait."));;
  const page = action.payload.page.toString();
  const records = action.payload.pageSize.toString();
  const commodity = action.payload.commodity;
  const collateral_token = yield select((state) => state.collateralToken);
  let walletAvailability = yield select((state) => state.user.walletAvailability);
  let networkID;
  if (!walletAvailability) {
    networkID = 97;
  } else {
    networkID = yield getNetworkId();
  }
  try {
    let history = {};
    history = yield commodityHistory(
      transactionHistoryURL,
      page - 1,
      records,
      commodity,
      networkID,
      collateral_token.name,
      'DEPOSIT,WITHDRAW'
    );
    yield put(
      liquidityTxHistory({
        history: history.result,
        page: parseInt(page),
        records: parseInt(records)
      })
    );
    yield put(liquidityTxCount({ count: history.total }));
    yield put(setLiquidityFilterSelected({ selected: 2 }));
  } catch (err) {
    console.log(err);
  }
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(false));
  } else {
    yield put(updateIsLoading(false));
  }
}

function* allTransaction(action) {
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(true));
  } else {
    // yield put(updateIsLoading(true));;
  }
  // yield put(updateLoadingMessage("Fetching values. Please wait."));;
  const page = action.payload.page.toString();
  const records = action.payload.pageSize.toString();
  let walletAvailability = yield select((state) => state.user.walletAvailability);
  let networkID;
  if (!walletAvailability) {
    networkID = 97;
  } else {
    networkID = yield getNetworkId();
  }
  const commodity_list = yield select((state) => state.commodities);
  const collateral_token = yield select((state) => state.collateralToken);
  try {
    let history = {};
    history = yield allHistory(
      transactionHistoryURL,
      page - 1,
      records,
      networkID,
      Object.keys(commodity_list).join(','),
      collateral_token.name,
      'DEPOSIT,WITHDRAW'
    );
    yield put(
      liquidityTxHistory({
        history: history.result,
        page: parseInt(page),
        records: parseInt(records)
      })
    );
    yield put(liquidityTxCount({ count: history.total }));
    yield put(setLiquidityFilterSelected({ selected: 3 }));
  } catch (err) {
    console.log(err);
  }
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(false));
  } else {
    yield put(updateIsLoading(false));
  }
}

export default liquidityTransactionHistory;
