import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
  name: 'tokenPrices',
  initialState: {
    // totalTokensInUSDT: 0
  },
  reducers: {
    updateCapFloor(state, action) {
      if (!state[action.payload.id]) state[action.payload.id] = {};
      state[action.payload.id].cap = action.payload.cap;
      state[action.payload.id].floor = action.payload.floor;
      state[action.payload.id].collateralPerUnit = action.payload.collateralPerUnit;
      state[action.payload.id].collateralFeePerUnit = action.payload.collateralFeePerUnit;
    },
    updateVolumes(state, action) {
      state['totalTokensInUSDT'] -=
        state[action.payload.id].longVolume[1] *
        parseFloat(state[action.payload.id].collateralPerUnit);
      state[action.payload.id].longVolume = action.payload.longVolume;
      state[action.payload.id].shortVolume = action.payload.shortVolume;
      state['totalTokensInUSDT'] +=
        action.payload.longVolume[1] * parseFloat(state[action.payload.id].collateralPerUnit);
    },
    initVolumes(state, action) {
      if (!state[action.payload.id]) state[action.payload.id] = {};
      state[action.payload.id].longVolume = action.payload.longVolume;
      state['totalTokensInUSDT'] +=
        action.payload.longVolume[1] * parseFloat(action.payload.collateralPerUnit);
    }
  }
});

const { actions, reducer } = configSlice;

export const { updateCapFloor, updateVolumes, initVolumes } = actions;

export default reducer;
