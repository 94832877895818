import { takeEvery, put, select } from 'redux-saga/effects';
import {
  updateIsLoading,
  updateIsLoadingIndividual
  // updateLoadingMessage
} from '../reducer/user';
import { basicAuth, transactionHistoryURL } from '../constant/index';
import { txHistory, txCount, setSelected } from '../reducer/transactionHistory';
import { getNetworkId } from '../utils';

function* transactionHistory() {
  yield takeEvery('TRANSACTION_HISTORY_ALL', allTransaction);
  yield takeEvery('TRANSACTION_HISTORY_USER', userTransaction);
  yield takeEvery('TRANSACTION_HISTORY_COMMODITY', commodityTransaction);
}

function* userTransaction(action) {
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(true));
  } else {
    // yield put(updateIsLoading(true));;
  }
  // yield put(updateLoadingMessage("Fetching values. Please wait."));;
  const page = action.payload.page.toString();
  const records = action.payload.pageSize.toString();
  const walletAddress = yield select((state) => state.user.address);
  const commodity_list = yield select((state) => state.commodities);
  const collateral_token = yield select((state) => state.collateralToken);
  let walletAvailability = yield select((state) => state.user.walletAvailability);
  let networkID;
  if (!walletAvailability) {
    networkID = 97;
  } else {
    networkID = yield getNetworkId();
  }
  try {
    let history = {};
    history = yield userHistory(
      transactionHistoryURL,
      page - 1,
      records,
      walletAddress,
      networkID,
      Object.keys(commodity_list).join(','),
      collateral_token.name
    );
    yield put(
      txHistory({ history: history.result, page: parseInt(page), records: parseInt(records) })
    );
    yield put(txCount({ count: history.total }));
    yield put(setSelected({ selected: 1 }));
  } catch (err) {
    console.log(err);
  }
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(false));
  } else {
    yield put(updateIsLoading(false));
  }
}

function* commodityTransaction(action) {
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(true));
  } else {
    // yield put(updateIsLoading(true));;
  }
  // yield put(updateLoadingMessage("Fetching values. Please wait."));;
  const page = action.payload.page.toString();
  const records = action.payload.pageSize.toString();
  const commodity = action.payload.commodity;
  let walletAvailability = yield select((state) => state.user.walletAvailability);
  let networkID;
  if (!walletAvailability) {
    networkID = 97;
  } else {
    networkID = yield getNetworkId();
  }
  const collateral_token = yield select((state) => state.collateralToken);
  try {
    let history = {};
    history = yield commodityHistory(
      transactionHistoryURL,
      page - 1,
      records,
      commodity,
      networkID,
      collateral_token.name
    );
    yield put(
      txHistory({ history: history.result, page: parseInt(page), records: parseInt(records) })
    );
    yield put(txCount({ count: history.total }));
    yield put(setSelected({ selected: 2 }));
  } catch (err) {
    console.log(err);
  }
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(false));
  } else {
    yield put(updateIsLoading(false));
  }
}

function* allTransaction(action) {
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(true));
  } else {
    // yield put(updateIsLoading(true));;
  }
  // yield put(updateLoadingMessage("Fetching values. Please wait."));;
  const page = action.payload.page.toString();
  const records = action.payload.pageSize.toString();
  let walletAvailability = yield select((state) => state.user.walletAvailability);
  let networkID;
  if (!walletAvailability) {
    networkID = 97;
  } else {
    networkID = yield getNetworkId();
  }
  const commodity_list = yield select((state) => state.commodities);
  const collateral_token = yield select((state) => state.collateralToken);
  try {
    let history = {};
    history = yield allHistory(
      transactionHistoryURL,
      page - 1,
      records,
      networkID,
      Object.keys(commodity_list).join(','),
      collateral_token.name
    );
    yield put(
      txHistory({ history: history.result, page: parseInt(page), records: parseInt(records) })
    );
    yield put(txCount({ count: history.total }));
    yield put(setSelected({ selected: 3 }));
  } catch (err) {
    console.log(err);
  }
  if (action.payload.shortLoader === true) {
    yield put(updateIsLoadingIndividual(false));
  } else {
    yield put(updateIsLoading(false));
  }
}

export const allHistory = async (
  url,
  page,
  records,
  networkId,
  commodity_list,
  collateral_token,
  txn_types = 'MINT,REDEEM,SWAP,DEPOSIT,WITHDRAW'
) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      Authorization: basicAuth,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      type: 'all',
      page: page,
      records: records,
      network_id: networkId,
      sortColoum: 'created_at',
      sortDir: 'desc',
      txn_types,
      commodity_list,
      collateral_token
    })
  });
  const result = await response.text();
  if (response.status !== 200) throw response.status;
  const data = JSON.parse(result);
  return data;
};

export const userHistory = async (
  url,
  page,
  records,
  address,
  networkId,
  commodity_list,
  collateral_token,
  txn_types = 'MINT,REDEEM,SWAP,DEPOSIT,WITHDRAW'
) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      Authorization: basicAuth,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      type: 'user',
      page: page,
      records: records,
      address: address,
      network_id: networkId,
      sortColoum: 'created_at',
      sortDir: 'desc',
      commodity_list,
      txn_types,
      collateral_token
    })
  });
  const result = await response.text();
  if (response.status !== 200) throw response.status;
  const data = JSON.parse(result);
  return data;
};

export const commodityHistory = async (
  url,
  page,
  records,
  commodity,
  networkId,
  collateral_token,
  txn_types = 'MINT,REDEEM,SWAP,DEPOSIT,WITHDRAW'
) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      Authorization: basicAuth,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      type: 'commodity',
      page: page,
      records: records,
      commodity_id: commodity,
      network_id: networkId,
      sortColoum: 'created_at',
      sortDir: 'desc',
      txn_types,
      collateral_token
    })
  });
  const result = await response.text();
  if (response.status !== 200) throw response.status;
  const data = JSON.parse(result);
  return data;
};

export const userCommodityHistory = async (url, page, records, commodity, address, networkId) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: new Headers({
      Authorization: basicAuth,
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify({
      type: 'user+commodity',
      page: page,
      records: records,
      commodity_id: commodity,
      network_id: networkId,
      address: address,
      sortColoum: 'created_at',
      sortDir: 'asc'
    })
  });
  const result = await response.text();
  if (response.status !== 200) throw response.status;
  const data = JSON.parse(result);
  return data;
};
export const userMtlxRewardCommodity = async (url, commodityId) => {
  const response = await fetch(`${url}?commodity_id=${commodityId}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: basicAuth,
      'Content-Type': 'application/json'
    })
  });
  const result = await response.text();
  if (response.status !== 200) throw response.status;
  const data = JSON.parse(result);
  return data;
};
export const userMtlxRewardCommodityCombined = async (url, commodityId) => {
  const response = await fetch(`${url}?commodity_id=${commodityId}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: basicAuth,
      'Content-Type': 'application/json'
    })
  });
  const result = await response.text();
  if (response.status !== 200) throw response.status;
  const data = JSON.parse(result);

  let convertedData = {};
  data.data.forEach((i) => {
    convertedData[i[0].commodity_id] = i[0];
  });
  return convertedData;
};

export default transactionHistory;
