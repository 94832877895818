import positionToken from '../ABI/POSITIONTOKEN_20200820.json';
import { decimals } from '../constant';
import Torus from '@toruslabs/torus-embed';
import '@metamask/legacy-web3';
import Web3 from 'web3';
// import BigNumber from "big-number";
import { BigNumber } from 'bignumber.js';
export const isMetamaskInstalled = () => {
  return !!window.ethereum || !!window.web3;
};

export const initializeWeb3 = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    // window.ethereum.autoRefreshOnNetworkChange = false;
  } else {
    console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
  }
};

export const askPermission = async () => {
  try {
    await window.ethereum.request({ method: 'eth_requestAccounts' });
    return;
  } catch (error) {
    throw new Error(error);
  }
};

export const initializeTorusWallet = async (networkId) => {
  const torus = new Torus({
    buttonPosition: 'bottom-right'
  });
  const network = {
    42: { host: 'kovan', chainId: 42 },
    56: { host: 'https://bsc-dataseed1.binance.org:443', chainId: 56 },
    97: { host: 'https://data-seed-prebsc-1-s1.binance.org:8545', chainId: 97 }
  };
  try {
    await torus.init({ showTorusButton: true, network: network[networkId] });
    await torus.login();
    window.web3 = new Web3(torus.provider);
    return true;
  } catch (error) {
    console.log('error in initializeTorusWallet');
    await torus.cleanUp();
    console.log(error);
    return false;
  }
};

export const getNetworkId = async () => await window.web3.eth.net.getId();

export const getAccounts = async () => await window.web3.eth.getAccounts();

export const getAccountAddress = async () => {
  const [address] = await window.web3.eth.getAccounts();
  return address;
};

export const getNetworkName = () => {
  return window.web3.eth.net.getNetworkType();
};

export const isUserLoggedIn = () =>
  new Promise((resolve, reject) => {
    window.web3.eth.getAccounts((err, accounts) => {
      if (err != null) {
        console.log(err);
        reject(err);
      }
      resolve(accounts.length !== 0);
    });
  });

export const getBalance = async (commodity, decimal, address) => {
  let contract = new window.web3.eth.Contract(positionToken.abi, commodity);
  const balance = await contract.methods.balanceOf(address);
  // const decimal = await contract.methods.decimals().call()
  const value = await balance.call();
  // return parseFloat(value / (10 ** decimal))
  let bigValue = new BigNumber(value);
  let Balance = bigValue.dividedBy(10 ** decimal);
  return Balance.toString(10);
};

export const userUsdtBalance = async (commodity, address, decimal) => {
  let contract = new window.web3.eth.Contract(positionToken.abi, commodity);
  const balance = await contract.methods.balanceOf(address);
  const value = await balance.call();
  let bigValue = new BigNumber(value);
  let usdtBal = bigValue.dividedBy(10 ** decimal);
  return usdtBal.toString(10);
};

export const userMtlxBalance = async (commodity, address) => {
  let contract = new window.web3.eth.Contract(positionToken.abi, commodity);
  const balance = await contract.methods.balanceOf(address);
  const value = await balance.call();
  return parseInt(value) / decimals.mtlx;
};

export const userEthBalance = async (commodity, address) => {
  let balance = await window.web3.eth.getBalance(address);
  return parseInt(balance) / decimals.eth;
};

export const metaMaskAccountsChanged = (callback) => {
  window.ethereum.on('chainChanged', callback);
  window.ethereum.on('accountsChanged', callback);
};

export function truncateDecimalsWithStringConversion(
  amountString,
  amountDecimals,
  decimals,
  amount
) {
  // amount = BigNumber(parseInt(amount*(10**Math.min(amountDecimals,decimals)))) -- OLD LOGIC
  if (amountString.indexOf('.') !== -1) {
    if (amountString.split('.')[1].split('').length === Math.min(amountDecimals, decimals)) {
      amount = amountString.split('.').join('');
    } else {
      let parts = amountString.split('.');
      let fractionalPart = parts[1].split('');
      fractionalPart.splice(Math.min(amountDecimals, decimals), 0, '.');
      amount = parts[0] + fractionalPart.join('');
    }
  }
  return amount;
}

export function convertAmountToString(amount, decimals) {
  let amountString = amount.toString();
  let amountDecimals = 0;
  if (amountString.split('.')[1]) amountDecimals = amountString.split('.')[1].length;

  amount = truncateDecimalsWithStringConversion(amountString, amountDecimals, decimals, amount);
  amountString = pad(amount, Math.max(0, decimals - amountDecimals));
  return amountString;
}

function pad(number, length) {
  var str = '' + number;
  for (let i = 1; i <= length; i++) {
    str = str + '0';
  }
  return str;
}

export function truncateDecimals(amount, decimalDigit) {
  decimalDigit = parseInt(decimalDigit);
  amount = amount ? amount : 0;
  let amountValue = amount.toString();
  if (amountValue.indexOf('e+') > 0) amountValue = scientificNumberModificationPost(amountValue);
  if (amountValue.indexOf('e-') > 0) amountValue = scientificNumberModificationPre(amountValue);
  if (amountValue.indexOf('.') > 0) {
    amountValue = amountValue.slice(0, amountValue.indexOf('.') + decimalDigit + 1);
  }
  return Number(amountValue);
}

const scientificNumberModificationPost = (value) => {
  value = value
    .replace('.', '')
    .split('e+')
    .reduce(function (p, b) {
      return p + Array(b - p.length + 2).join(0);
    });
  return value;
};

const scientificNumberModificationPre = (value) => {
  value = value.split('e-').reduce(function (p, b) {
    return '0.' + Array(b - p.split('.')[0].length + 1).join(0) + p.replace('.', '');
  });
  return value;
};

export const cacheLogin = async ({ walletType, networkId, address, provider }) => {
  let login = {
    walletType: walletType,
    networkId: networkId,
    address: address,
    time: Date.now() / 1000, //seconds
    provider: JSON.stringify(provider)
  };
  await localStorage.setItem('mettalexLogin', JSON.stringify(login));
};

export const isLoginExpired = async () => {
  let data = await localStorage.getItem('mettalexLogin');
  data = JSON.parse(data);
  if (data !== null && Date.now() / 1000 - data.time <= 21600) {
    //greator than or equal to 6Hrs
    return false;
  }
  return true;
};

export const isLoginCached = async () => {
  let data = await localStorage.getItem('mettalexLogin');
  data = JSON.parse(data);
  if (data !== null) {
    return true;
  }
  await clearCachedLogin();
  return false;
};

export const clearCachedLogin = async () => {
  await localStorage.removeItem('mettalexLogin');
};

export const getLoginDetails = async () => {
  let data = await localStorage.getItem('mettalexLogin');
  data = JSON.parse(data);
  data['provider'] = JSON.parse(data['provider']);
  return data;
};

export function validateInputField(value) {
  let regex = /^(?:[0-9]\d*|0)?(?:\.\d+)?$/;
  let res = regex.test(value) ? true : false;
  return res;
}

export const uniqueId = () => {
  const head = Date.now().toString();
  const tail = Math.random().toString().substr(2);
  return head + tail;
};

export const isMarketDisabled = (collateralToken) => {
  const disableList = {
    token_address: '0x5801D0e1C7D977D78E4890880B8E579eb4943276',
    token_name: 'USDO',
    network_id: 56
  };

  if (
    collateralToken.name === disableList.token_name ||
    collateralToken.address === disableList.token_address ||
    collateralToken.network_id === disableList.network_id
  ) {
    return true;
  }
  return false;
};
