import { select, takeEvery, put } from 'redux-saga/effects';
import cuMettAlex from '../contractInterface/mettalexContract';
// import positionTokenContract from '../contractInterface/positionTokenContract';
// import { updateVolumes } from '../reducer/tokenPrices';
import { updateIsLoading, updateLoadingMessage } from '../reducer/user';

function* redeemTokenSaga() {
  yield takeEvery('REDEEM_TOKEN', startRedeem);
}

function* startRedeem(action) {
  yield put(updateIsLoading(true));
  yield put(updateLoadingMessage('Transaction in progress. Please wait.'));
  try {
    const walletAddress = yield select((state) => state.user.address);
    const commodities = yield select((state) => state.commodities);
    let commodityId = action.payload.id;
    const contract = cuMettAlex(action.payload.contractAddress);
    yield contract.redeemToken(parseInt(action.payload.redeemAmount), walletAddress);

    yield put({ type: 'USER_WALLET_TOKEN_DETAILS', payload: commodities[commodityId] });

    // const commodity = commodities[commodityId]
    // const longPositioncontract = positionTokenContract(commodity.long_token_contract_address);
    // const shortPositioncontract = positionTokenContract(commodity.short_token_contract_address);
    // const shortVolume = yield shortPositioncontract.getVolume();
    // const longVolume = yield longPositioncontract.getVolume();
    // yield put(updateVolumes({ shortVolume: [commodity.short_symbol, shortVolume], longVolume: [commodity.long_symbol, longVolume], id: commodityId }))
  } catch (err) {
    console.log('Redeem Error', err);
    yield put(updateIsLoading(false));
  }
  yield put(updateIsLoading(false));
}

export default redeemTokenSaga;
