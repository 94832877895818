import balancerPool from '../ABI/BALANCER_POOL_20200916.json';
import { decimals } from '../constant';

class BalancerPool {
  constructor(address) {
    this.UserContract = new window.web3.eth.Contract(balancerPool.abi, address);
  }

  getInstance = async () => this.UserContract;

  getLiquidityProviderfee = async () => {
    const contract = await this.getInstance();
    const fee = await contract.methods.getSwapFee().call();
    //decimal conversion
    return parseFloat(fee / decimals.liquidityProviderFee);
  };

  getBalance = async (address, decimal) => {
    const contract = await this.getInstance();
    const amount = await contract.methods.getBalance(address).call();
    return parseFloat(amount / 10 ** decimal);
  };

  getPrice = async (fromAddress, toAddress, decimal) => {
    const contract = await this.getInstance();
    const amount = await contract.methods.getSpotPrice(toAddress, fromAddress).call();
    return parseFloat(amount / 10 ** decimal);
  };

  isBound = async (address) => {
    try {
      const contract = await this.getInstance();
      const isBound = await contract.methods.isBound(address).call();
      return isBound;
    } catch (err) {
      console.log(err);
    }
  };
}

let contract = null;
const getContract = (address) => {
  contract = new BalancerPool(address);
  Object.freeze(contract);
  return contract;
};

export default getContract;
