import { select, takeEvery, put } from 'redux-saga/effects';
import mettAlex from '../contractInterface/mettalexContract';
// import positionTokenContract from '../contractInterface/positionTokenContract';
// import { updateVolumes } from '../reducer/tokenPrices';
import { updateIsLoading, updateLoadingMessage } from '../reducer/user';

function* settleTokenSaga() {
  yield takeEvery('SETTLE_TOKEN', startSettle);
}

function* startSettle(action) {
  // yield put(updateIsLoading(true));;
  yield put(updateLoadingMessage('Transaction in progress. Please wait.'));
  try {
    const commodities = yield select((state) => state.commodities.commodities);
    const walletAddress = yield select((state) => state.user.address);
    let commodityId = action.payload.id;
    const contract = mettAlex(action.payload.contractAddress);
    yield contract.settleToken(walletAddress);
    yield put({ type: 'USER_WALLET_TOKEN_DETAILS', payload: commodities[commodityId] });

    // const commodity = commodities[commodityId]
    // const longPositioncontract = positionTokenContract(commodity.long_token_contract_address);
    // const shortPositioncontract = positionTokenContract(commodity.short_token_contract_address);
    // const shortVolume = yield shortPositioncontract.getVolume();
    // const longVolume = yield longPositioncontract.getVolume();
    // yield put(updateVolumes({ shortVolume: [commodity.short_symbol, shortVolume], longVolume: [commodity.long_symbol, longVolume], id: commodityId }))
  } catch (err) {
    console.log('Settlement Error', err);
  }
  yield put(updateIsLoading(false));
}

export default settleTokenSaga;
