import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { createAction } from '@reduxjs/toolkit';
import { Backdrop, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import makeStyles from '@material-ui/styles/makeStyles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import TableData from '../../component/Table';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import Loader from '../../assets/loadingIconWhite.bin';
import LiquidityRewards from '../../component/LiquidityRewards';
import SuppliedLiquidity from './supplied';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { BigNumber } from 'bignumber.js';
import { LightTooltip } from '../../component/LightToolTip';
import DataNotFound from '../../component/DataNotFound';

const userTransactions = createAction('USER_TRANSACTION_HISTORY_ALL');

const useStyles = makeStyles((theme) => ({
  accountWrapper: {
    marginTop: theme.spacing(3)
  },
  container: {
    maxHeight: 265,
    minHeight: 265
  },
  textInput: {
    border: '1px solid white'
  },
  searchBar: {
    boxShadow: '0 1px 9px 0 rgba(0,0,0,0.15), 0 6px 17px 0 rgba(0,0,0,0.15)',
    borderRadius: '7px',
    padding: '5px 10px'
  },
  accountNumberText: {
    boxShadow: '0 1px 9px 0 rgba(0,0,0,0.15), 0 6px 17px 0 rgba(0,0,0,0.15)',
    borderRadius: '3px',
    padding: '5px 10px',
    fontSize: '16px',
    color: '#5643CC',
    transform: 'uppercase',
    textAlign: 'center',
    cursor: 'pointer',
    height: '35px'
  },
  pageHeading: {
    color: theme.text,
    fontSize: 20,
    fontFamily: 'MontserratMedium'
  },
  pageSubHeading: {
    color: theme.secondaryText,
    fontSize: 12
  },
  flexBox: {
    display: 'flex'
  },
  active: {
    color: '#5643CC'
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '10px'
  },
  pagination: {
    background: theme.dropdown.backgroundColor,
    color: theme.text,
    padding: '0 25px !important',
    width: '20%',
    margin: '0 auto',
    '& button[title="Previous page"]': {
      padding: 0,
      color: theme.text,
      position: 'absolute',
      top: '50%',
      left: '0',
      transform: 'translatey(-50%)'
    },
    '& button.Mui-disabled': {
      opacity: 0.3
    },
    '& button[title="Next page"]': {
      padding: 0,
      color: theme.text,
      position: 'absolute',
      top: '50%',
      right: '0',
      transform: 'translatey(-50%)'
    },
    '& .MuiTablePagination-caption': {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)'
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: '40px'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '16px'
    }
  },
  cardTitle: {
    color: theme.text,
    fontSize: '12px',
    fontFamily: 'MontserratMedium',
    padding: 14,
    textTransform: 'uppercase'
  },
  cardSubTitle: {
    color: theme.secondaryText,
    fontSize: '16px',
    fontFamily: 'MontserratRegular'
  },
  daysText: {
    color: '#D6D6D9'
  },
  contentText: {
    color: '#7C7C7D',
    fontSize: '14px'
  },
  dataStyle: {
    color: '#1C2128',
    fontSize: '16px',
    fontWeight: 500
  },
  incrementStyle: {
    color: '#2FD593',
    fontSize: '14px'
  },
  accordionContent: {
    width: '100%'
  },
  refreshButtonDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  jumpToPage: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '52px',
    color: theme.text,
    '& .MuiTypography-root': {
      fontSize: '14px',
      fontWeight: '300'
    },
    '& .MuiOutlinedInput-root': {
      marginLeft: 5
    },
    '& .MuiInputBase-input': {
      padding: 5,
      width: 19
    }
  },
  accordionContainer: {
    marginTop: 10,
    '& .MuiPaper-root': {
      background: 'transparent',
      borderRadius: 'inherit',
      borderBottom: theme.border
    },
    '& .MuiAccordionSummary-root': {
      padding: '0'
    },
    '& .Mui-expanded': {
      minHeight: 47
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0
    },
    '& .MuiAccordionSummary-root.MuiButtonBase-root .MuiSvgIcon-root': {
      color: theme.grid.color
    },
    '& .MuiAccordionSummary-content': {
      margin: '0'
    },
    '& .MuiAccordionDetails-root': {
      padding: 0
    }
  },
  contractAddressContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  contractAddress: {
    background: 'transparent',
    height: 30,
    borderRadius: 5,
    marginRight: 4,
    padding: '8px 16px',
    border: '1px solid #68dbda',
    '&:hover': {
      background: theme.card.background
    },
    '& .MuiTypography-root': {
      color: theme.text,
      fontSize: '14px',
      textTransform: 'none'
    }
  },
  radialGradient: {
    marginRight: 8
  },
  switchContainer: {
    '& .MuiFormControlLabel-root': {
      position: 'absolute',
      right: '58px',
      top: '23px',
      transform: 'translatey(-50%)',
      whiteSpace: 'nowrap',
      margin: 0,
      color: 'white'
    },
    '& .MuiSwitch-root': {
      width: '58px',
      height: '36px'
    },
    '& .MuiSwitch-track': {
      background: 'white'
    },
    '& .MuiSwitch-thumb': {
      width: '18px',
      height: '18px'
    },
    '& .MuiFormControlLabel-root .MuiTypography-root': {
      fontSize: '12px'
    }
  },
  circularProgress: {
    background: theme.card.background,
    padding: '19px 24px',
    position: 'relative',
    marginTop: '45px',
    height: '160px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1
  },
  cardHeader: {
    width: 'auto',
    display: 'inline-block',
    padding: '8px 0',
    color: theme.text,
    textTransform: 'uppercase',
    '& .MuiTypography-root': {
      fontFamily: 'MontserratMedium'
    }
  },
  itemPadding: {
    padding: '4px 5px 0 5px!important'
  },
  btnLogout: {
    width: 36,
    height: 30
  },
  marginTop25: {
    marginTop: 25
  },
  refreshIco: {
    padding: 3,
    width: '1.1em',
    height: '1.1em'
  },
  button: {
    width: '100%',
    padding: 6,
    background: theme.maxButton.color,
    '&:hover': {
      backgroundColor: theme.maxButton.color,
      opacity: 0.8
    },
    '&:disabled': {
      backgroundColor: 'grey',
      color: 'white'
    },
    color: 'white'
  },
  maxButton: {
    color: theme.grid.color,
    borderColor: theme.grid.color,
    padding: '7px 9px 6px',
    '&:hover': {
      backgroundColor: theme.grid.color,
      color: 'white'
    },
    '&:disabled': {
      color: 'grey',
      borderColor: 'grey'
    }
  }
}));

const ComponentBackdrop = withStyles({
  root: {
    position: 'absolute',
    color: '#fff'
  }
})(Backdrop);

const tableHeadings = [
  {
    key: 'action',
    label: 'Action',
    type: 'tx'
  },
  {
    key: 'token_amount_in',
    label: 'Token Amount (+)',
    type: 'tx'
  },
  {
    key: 'token_amount_out',
    label: 'Token Amount (-)',
    type: 'tx'
  },
  {
    key: 'caller',
    label: 'Account',
    type: 'tx'
  },
  {
    key: 'transaction_hash',
    label: 'Txn Hash',
    type: 'tx'
  },
  {
    key: 'created_at',
    label: 'Time',
    type: 'tx'
  }
];

const walletBalanceTableHeadings = [
  {
    key: 'symbol',
    label: 'Token',
    type: 'tx'
  },
  {
    key: 'quantity',
    label: 'Quantity',
    type: 'tx'
  },
  {
    key: 'pnl',
    label: 'P&L',
    type: 'tx'
  }
];

const AccountDetails = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [balanceData, setBalanceData] = useState([]);
  const [filledBalanceData, setFilledBalanceData] = useState([]);
  const [totalRewards, setTotalRewards] = React.useState(0);
  const [totalBalance, setTotalBalance] = React.useState(0);
  const [hideEmpty, setHideEmpty] = useState(true);
  const [hideEmptySupply, setHideEmptySupply] = useState(true);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('created_at');

  const totalPages = (balanceData) => {
    const total = Math.ceil(balanceData.length / rowsPerPage);
    if (total < 1) {
      return 1;
    } else {
      return total;
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleUserTxChangePage = (event, newPage) => {
    props.userTransactions({
      page: newPage + 1,
      pageSize: props.userTxRecords,
      address: props.address
    });
  };

  const userTxTotalPages = () => {
    const total = Math.ceil(props.userTxCount / props.userTxRecords);
    if (total < 1) {
      return 1;
    } else {
      return total;
    }
  };

  const createData = (symbol, quantity, pnl, value) => {
    return { symbol, quantity, pnl, value };
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  useEffect(() => {
    if (props.commodities && props.user.balances && Object.keys(props.swapStats).length) {
      let rows = [
        {
          symbol: props.collateralToken.name,
          quantity: new BigNumber(props.user.balances.USDT).decimalPlaces(3).toString(10),
          pnl: 0
        },
        {
          symbol: 'MTLX',
          quantity: new BigNumber(props.user.balances.MTLX).decimalPlaces(3).toString(10),
          pnl: 0
        }
      ];
      let filledRows = [
        {
          symbol: props.collateralToken.name,
          quantity: new BigNumber(props.user.balances.USDT).decimalPlaces(3).toString(10),
          pnl: 0
        },
        {
          symbol: 'MTLX',
          quantity: new BigNumber(props.user.balances.MTLX).decimalPlaces(3).toString(10),
          pnl: 0
        }
      ];
      let total = 0;
      Object.keys(props.swapStats).map((data) => {
        return Object.keys(props.swapStats[data]).map((key) => {
          const pnl = props.swapStats[data][key].totalPNL || 0;
          let value = createData(
            `${props.swapStats[data][key].tokenSymbol} V${props.swapStats[data][key].version}`,
            props.swapStats[data][key].tokenSymbol.includes('LONG')
              ? new BigNumber(props.user.balances[key]).decimalPlaces(3).toString(10)
              : new BigNumber(props.user.balances[key]).decimalPlaces(3).toString(10),
            pnl,
            data
          );
          total = total + pnl;
          if (props.user.balances[key] > 0) {
            filledRows.push(value);
          }
          return rows.push(value);
        });
      });
      setFilledBalanceData(filledRows);
      setBalanceData(rows);
      setTotalBalance(total);
    }
  }, [
    props.user.balances,
    props.commodities,
    props.collateralToken,
    props.swapStats,
    props.balancerPool,
    props.user
  ]);

  const renderWalletBalanceCard = () => (
    <>
      {hideEmpty ? (
        <>
          <TableData
            isHistoryTable={false}
            headings={walletBalanceTableHeadings}
            rowData={
              stableSort(filledBalanceData, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ) || []
            }
            emptyText={<DataNotFound displayText={'No Balance to Show.'} />}
            history={props.history}
            handleSelected={props.handleSelected}
            getOrder={(order, orderBy) => {
              setOrder(order);
              setOrderBy(orderBy);
            }}
          />
          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[]}
            component="div"
            count={filledBalanceData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            labelDisplayedRows={({ page }) =>
              `Page ${page + 1} of ` + totalPages(filledBalanceData)
            }
          />
        </>
      ) : (
        <>
          <TableData
            isHistoryTable={false}
            headings={walletBalanceTableHeadings}
            rowData={balanceData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || []}
            emptyText={<DataNotFound displayText={'No Balance to Show.'} />}
            history={props.history}
            handleSelected={props.handleSelected}
          />
          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[]}
            component="div"
            count={balanceData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            labelDisplayedRows={({ page }) => `Page ${page + 1} of ` + totalPages(balanceData)}
          />
        </>
      )}
    </>
  );

  const handleRewards = (data) => {
    setTotalRewards(data);
  };

  const handleRefresh = () => {
    props.userTransactions({
      page: 1,
      pageSize: props.userTxRecords,
      address: props.address,
      shortLoader: true
    });
  };

  const handleChange = (e) => {
    setHideEmpty((prevHideEmpty) => !prevHideEmpty);
    setPage(0);
  };

  const handleChangeSupply = (e) => {
    setHideEmptySupply((prevHideEmptySupply) => !prevHideEmptySupply);
  };

  return (
    <Box className={classes.accountWrapper}>
      <Grid container className={`boxDesign`}>
        <Grid item md={12} xs={12}>
          <LiquidityRewards
            LiquidityRewardsisLoading={props.LiquidityRewardsisLoading}
            rewards={totalRewards}
            balance={totalBalance}
            mtlxRewardBalances={props.mtlxRewardBalances}
            coinTokenName={props.collateralToken.token}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ marginTop: 10 }} className={classes.accordionContainer}>
          <Accordion defaultExpanded>
            <Grid className="boxDesign topRadius">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.cardTitle}>Wallet Balance</Typography>
              </AccordionSummary>
            </Grid>
            <AccordionDetails>
              <div className={`${classes.accordionContent} boxDesign bottomRadius`}>
                <div className={classes.switchContainer}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={hideEmpty}
                        color="primary"
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'checkbox' }}
                      />
                    }
                    label="Hide zero balances"
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                {renderWalletBalanceCard()}
              </div>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }} className={classes.accordionContainer}>
          <Accordion defaultExpanded>
            <Grid className="boxDesign topRadius">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.cardTitle}>
                  Supplied Liquidity <span>({props.collateralToken.token})</span>
                </Typography>
              </AccordionSummary>
            </Grid>
            <AccordionDetails>
              <div className={`${classes.accordionContent} boxDesign bottomRadius`}>
                <div className={classes.switchContainer}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={hideEmptySupply}
                        color="primary"
                        onChange={handleChangeSupply}
                        inputProps={{ 'aria-label': 'checkbox' }}
                      />
                    }
                    label="Hide zero balances"
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                <SuppliedLiquidity
                  empty={hideEmptySupply}
                  balancerPool={props.balancerPool}
                  liquidityPool={props.liquidityPool}
                  commodities={props.commodities}
                  history={props.history}
                  handleSelected={props.handleSelected}
                  selected={props.filter}
                  handle={handleRewards}
                  coinTokenName={props.collateralToken.token}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className={classes.accordionContainer}>
          <Accordion defaultExpanded>
            <Grid className="boxDesign topRadius">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.refreshButtonDiv}>
                  <Typography className={classes.cardTitle}>User Transaction History</Typography>
                  <Button
                    onClick={handleRefresh}
                    size="small"
                    className={`${classes.maxButton} btn-info`}
                    style={{ marginRight: '10px' }}
                  >
                    <LightTooltip title="Refresh">
                      <CachedRoundedIcon className={classes.refreshIco} />
                    </LightTooltip>
                  </Button>
                </div>
              </AccordionSummary>
            </Grid>
            <AccordionDetails>
              <div
                className={`${classes.accordionContent} boxDesign bottomRadius`}
                style={{ position: 'relative' }}
              >
                <ComponentBackdrop className={classes.backdrop} open={props.isLoadingIndividual}>
                  <Grid style={{ textAlign: 'center' }}>
                    <img src={Loader} alt="lock" width="20%"></img>
                    <Typography className={classes.typography} style={{ textAlign: 'center' }}>
                      {props.loadingMessage}
                    </Typography>
                  </Grid>
                </ComponentBackdrop>
                <TableData
                  headings={tableHeadings}
                  rowData={props.userTransactionHistory || []}
                  networkID={props.networkID}
                  emptyText={<DataNotFound displayText={'No Transactions to Show'} />}
                  isHistoryTable={true}
                />
                <div style={{ display: 'flex' }}>
                  <div></div>
                  <TablePagination
                    className={classes.pagination}
                    rowsPerPageOptions={[]}
                    component="div"
                    count={props.userTxCount}
                    rowsPerPage={props.userTxRecords}
                    page={props.userTxPage - 1}
                    onChangePage={handleUserTxChangePage}
                    labelDisplayedRows={({ page }) => `Page ${page + 1} of ` + userTxTotalPages()}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  address: state.user.address,
  user: state.user,
  PnLisLoading: state.user.PnLisLoading,
  LiquidityRewardsisLoading: state.user.LiquidityRewardsisLoading,
  commodities: state.commodities,
  networkID: state.user.networkID,
  swapStats: state.swapStats,
  balancerPool: state.balancerPool,
  userTransactionHistory: state.userTransactionHistory.txHistory,
  userTxCount: state.userTransactionHistory.count,
  userTxPage: state.userTransactionHistory.page,
  userTxRecords: state.userTransactionHistory.records,
  liquidityPool: state.liquidityPool,
  filter: state.user.filter,
  collateralToken: state.collateralToken,
  mtlxRewardBalances: state.currentCommodity.mtlxRewardBallance,
  isLoadingIndividual: state.user.isLoadingIndividual
});

export default connect(mapStateToProps, { userTransactions })(AccountDetails);
